import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { fullDetailsReceptaclesForConsignmentTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

const VALUE_IF_ABSENT = '';

function generateExcelWorkbookReceptaclesForFullDetails(fullReceptacles) {
  const fullReceptaclesTemplateFile = base64.decode(fullDetailsReceptaclesForConsignmentTemplate);
  const template = new XlsxTemplate(fullReceptaclesTemplateFile);
  const boolFormatter = fieldFormatter('bool');

  const receptacles = {
    receptacles: _.map(fullReceptacles, (receptacle) => ({
      receptacleSequenceNumber: receptacle.receptacleSequenceNumber || VALUE_IF_ABSENT,
      receptacleId: receptacle.receptacleId || VALUE_IF_ABSENT,
      receptacleType: receptacle.receptacleType || VALUE_IF_ABSENT,
      receptacleClass: receptacle.receptacleClass || VALUE_IF_ABSENT,
      dangerousGoodsIndicator: boolFormatter({
        value: receptacle.dangerousGoodsIndicator,
      }, VALUE_IF_ABSENT),
      handlingClass: receptacle.handlingClass || VALUE_IF_ABSENT,
      weight: receptacle.weight || VALUE_IF_ABSENT,
      weightType: receptacle.weightType || VALUE_IF_ABSENT,
      container: receptacle.container || VALUE_IF_ABSENT,
    })),
  };

  template.substitute('Receptacles', receptacles);
  return template.generate();
}

export default generateExcelWorkbookReceptaclesForFullDetails;
