import en from './en';
import nl from './nl-be';

export const { defaultLanguage } = process.env;

export const messages = {
  en: en.messages,
  nl: nl.messages,
};

export const supportedLanguages = Object.keys(messages);
