import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from '@ipc/ipc-core';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import { date } from '../../../../react-table/helpers/fieldFormatter';
import { GeneralInfo } from '../../../../common/components/GeneralInfo';

const ConsignmentEventGeneralInfo = ({ event, event: { generalInfo } }) => {
  function getGeneralInfoFields() {
    if (!_.isEmpty(event)) {
      return [
        {
          className: 'interchange-reference',
          intlID: 'app.tracking.timeline.event.details.interchangeReference',
          value: generalInfo.messageInterchangeReference,
        },
        {
          className: 'reference',
          intlID: 'app.tracking.timeline.event.details.reference',
          value: date({ value: generalInfo.messagePreparationDate }),
        },
        {
          className: 'sender',
          intlID: 'app.tracking.timeline.event.details.sender',
          value: event.sender || generalInfo.sender,
        },
        {
          className: 'receiver',
          intlID: 'app.tracking.timeline.event.details.receiver',
          value: event.receiver || generalInfo.receiver,
        },
        {
          className: 'preparation-date',
          intlID: 'app.tracking.timeline.event.details.preparation.date',
          value: date({ value: generalInfo.messagePreparationDate }),
        },
        {
          className: 'common-event-field',
          intlID: 'app.tracking.timeline.event.details.network.date',
          value: date({ value: generalInfo.onNetworkTime }),
        },
        {
          className: 'country-code',
          intlID: 'app.tracking.timeline.event.details.countryCode',
          value: generalInfo.eventLocation && (generalInfo.eventLocation.country),
        },
        {
          className: 'message-version',
          intlID: 'app.tracking.timeline.event.details.messageVersion',
          value: generalInfo.messageTypeVersion,
        },
        {
          className: 'status',
          intlID: 'app.tracking.timeline.event.details.status',
          value: generalInfo.messageStatus,
        },
        {
          className: 'legs',
          intlID: 'app.tracking.timeline.event.details.legs',
          value: event.legs,
        },
        {
          className: 'first-leg-transport',
          intlID: 'app.tracking.timeline.event.details.firstLegTransport',
          value: event.firstLegTransport,
        },
        {
          className: 'last-leg-transport',
          intlID: 'app.tracking.timeline.event.details.lastLegTransport',
          value: event.lastLegTransport,
        },
        {
          className: 'total-receptacles',
          intlID: 'app.tracking.timeline.event.details.totalReceptacles',
          value: event.totalReceptacles,
        },
        {
          className: 'consignment-id',
          intlID: 'app.tracking.timeline.event.details.consignment.id',
          value: event.consignmentId && (
            <Link id="consignmentIdLink" to={getUrl('consignment', event.consignmentId)}>
              {event.consignmentId}
            </Link>
          ),
        },
      ];
    }
    return [];
  }

  return (
    <GeneralInfo
      infoFields={getGeneralInfoFields()}
      event={event}
      fullDetails
    />
  );
};

ConsignmentEventGeneralInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};

export default ConsignmentEventGeneralInfo;
