import styled from 'styled-components';
import Icons from '../../../../../../../components/common/Icons';
import { ItemMapMarker, ReceptacleMapMarker } from '../../../../../../../elements/Colors';

const eventHeaderHeight = '35px;';
const eventHeaderLocationWidth = '80px';

const { MapMarkerIcon } = Icons;

export const Location = styled.div`
  font-size: 1rem;
  line-height: ${eventHeaderHeight};
  overflow: hidden;
  text-overflow: ellipsis;
  text-rendering: auto;
  white-space: nowrap;
  width: ${eventHeaderLocationWidth};
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
`;

export const LocationMapMarkerIcon = styled(MapMarkerIcon)`
  color: ${({ eventType }) => (eventType === 'item' ? ItemMapMarker : ReceptacleMapMarker)};
  font-size: 1.5rem;
  margin-right: 6px;
`;
