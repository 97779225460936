import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TimelineEventDate as IpcTimelineEventDate } from '@ipc/ipc-components';
import { DATES } from '../../../../utils/constants';

const TimelineEventDate = (props) => {
  const {
    format = DATES.DAY_FORMAT,
    date = new Date().toISOString(),
  } = props;

  return (
    <IpcTimelineEventDate date={moment(date).format(format)} />
  );
};

TimelineEventDate.propTypes = {
  format: PropTypes.string,
  date: PropTypes.string,
};

export default TimelineEventDate;
