import styled from 'styled-components';

export const StyledRoleForm = styled.form`
  display: flex;
  flex-direction: column;
    
  & .MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;  
    width: 245px;  
  }
  
  & > * {
    margin-bottom: 10px;
  }
`;
