import _ from 'lodash';

const eventTypes = [
  'PREDES',
  'RESDES',
  'PRECON',
  'RESCON',
  'CARDIT',
  'RESDIT',
  'POD',
];

export const determineType = (eventName) => _.find(eventTypes, (value) => _.startsWith(eventName, value)) || null;
