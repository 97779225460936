import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from '@ipc/ipc-core';
import { getRedirectDone } from './redux/actions/redirectActions';

export const RedirectAdapter = () => {
  const redirect = useSelector((state) => state.redirect);
  const dispatch = useDispatch();
  useEffect(() => {
    if (redirect.status === 'INIT') {
      dispatch(getRedirectDone());
    }
  });

  return redirect.status === 'INIT' && <Redirect to={redirect.url} />;
};
