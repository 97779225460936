import { Notifications } from '@ipc/ipc-components';
import {
  logoutAction,
  OAuthAuthenticationService,
} from '@ipc/ipc-core';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useDispatch } from 'react-redux';
import { AppContainerStyle } from './AppContainer.styles';
import AppContent from './AppContent';
import AppFooter from './AppFooter';
import AppHeader from './header/AppHeader';
import { RedirectAdapter } from './RedirectAdapter';
import { getHistory } from '../../core/modules/common/tools/HistoryTools';

if (process.env.NODE_ENV !== 'production') {
  whyDidYouRender(React);
}

const StyledAppContainer = styled.div`${AppContainerStyle}`;
StyledAppContainer.displayName = 'StyledAppContainer';

const GlobalStyle = createGlobalStyle`  
  #root, body, html {
    margin:0; padding: 0;
    height: 100%;
  }
`;

const ReduxAuthenticationAdapter = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutAction());
  };

  const logoutForAuthorization = () => {
    // We do this because if you login correctly after the logout, you are going to be redirected to the page that made you logout.Stuck in a loop.
    const history = getHistory();
    history.location.pathname = '/';

    logout();
  };

  OAuthAuthenticationService.tokenExpirationHandler = logout;
  OAuthAuthenticationService.noAuthorizationHandler = logoutForAuthorization;

  return null;
};

export const AppContainerComponent = () => (
  <>
    <GlobalStyle />
    <StyledAppContainer>
      <ReduxAuthenticationAdapter />
      <RedirectAdapter />
      <Notifications />
      <AppHeader />
      <AppContent />
      <AppFooter />
    </StyledAppContainer>
  </>
);

export default AppContainerComponent;
