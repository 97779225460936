import { Colors } from '@ipc/ipc-components';
import styled from 'styled-components';
import { White } from '../../elements/Colors';

const {
  secondary,
} = Colors || {};

export const Copyright = styled.span``;

export const Footer = styled.div`
  display: flex;  
  flex: 0 0 auto;
  justify-content: center;
  height: 30px;
  line-height: 30px;
  background-color: ${secondary?.main};
  color: ${White};
`;
