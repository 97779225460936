import { FETCH_ITEM_FAILURE, FETCH_ITEM_REQUEST, FETCH_ITEM_SUCCESS } from './types/ItemActionTypes';

export function fetchItemRequestActionCreator(itemId) {
  return {
    type: FETCH_ITEM_REQUEST,
    params: { itemId },
  };
}

export function fetchItemSuccessActionCreator(payload) {
  return {
    type: FETCH_ITEM_SUCCESS,
    payload,
  };
}

export function fetchItemFailureActionCreator(error) {
  return {
    type: FETCH_ITEM_FAILURE,
    payload: error,
    error: true,
  };
}
