import * as PropTypes from 'prop-types';
import React from 'react';
import {
  Controller, ErrorMessage as RHFErrorMessage, FormContext, useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import { Switch, Input } from '@ipc/ipc-components';
import { useTranslator, usePermissions } from '../../../hooks';
import SwitchGroup from '../../common/ipc-components/SwitchGroup';
import { Required } from '../../common/Required';
import ErrorMessage from '../../form/react-hook-form/ErrorMessage';
import { StyledRoleForm } from './styles';

export const createRoleSchema = yup.object()
  .shape({
    name: yup.string()
      .required(),
  });

const RoleForm = (props) => {
  const { translate } = useTranslator();
  const { permissions } = usePermissions();
  const { onSubmit, children, role } = props;

  const defaultValues = role || {
    name: '',
    permissions: [],
  };

  const methods = useForm({
    validationSchema: createRoleSchema,
    defaultValues,
  });

  const { handleSubmit, errors, register } = methods;

  const createPermissionSwitch = (permission) => <Switch key={permission.id} label={permission.name} value={permission} size="medium" data-testid={permission.name} />;
  return (
    <FormContext {...methods}>
      <StyledRoleForm onSubmit={handleSubmit(onSubmit)}>
        {role && (<input type="hidden" name="id" ref={register} />)}
        <Controller
          name="name"
          as={(
            <Required labelId="administration.user.roles.name">
              <Input id="name" name="name" />
            </Required>
          )}
        />
        <RHFErrorMessage errors={errors} name="name" as={<ErrorMessage />} />
        <div>
          {translate('administration.user.roles.permissions')}
          <Controller
            name="permissions"
            as={(
              <SwitchGroup>
                {permissions && permissions.map(createPermissionSwitch)}
              </SwitchGroup>
            )}
          />
        </div>
        {children}
      </StyledRoleForm>
    </FormContext>
  );
};

RoleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  role: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    permission: PropTypes.arrayOf(PropTypes.number),
  }),
};

export default RoleForm;
