import {
  FETCH_DESPATCH_FAILURE,
  FETCH_DESPATCH_REQUEST,
  FETCH_DESPATCH_SUCCESS,
} from './types/DespatchActionTypes';

export function fetchDespatchRequestActionCreator(despatchId) {
  return {
    type: FETCH_DESPATCH_REQUEST,
    params: { despatchId },
  };
}

export function fetchDespatchSuccessActionCreator(payload) {
  return {
    type: FETCH_DESPATCH_SUCCESS,
    payload,
  };
}

export function fetchDespatchFailureActionCreator(error) {
  return {
    type: FETCH_DESPATCH_FAILURE,
    payload: error,
    error: true,
  };
}
