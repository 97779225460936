import { Link, SecureComponent } from '@ipc/ipc-core';
import React from 'react';
import styled from 'styled-components';
import { White } from '../../../elements/Colors';
import { useTranslator } from '../../../hooks';
import Permissions from '../../../security/permission';

const {
  READ_USER,
} = Permissions;

const MainMenuStyle = styled.div`
  line-height: 70px;
  
  a {
    color: ${White}; 
    
    &:hover {
      color: ${White};
    }
  }
`;

const Menu = styled.div`
  display: inline-block;
  margin:0 15px;
`;

const AdminMenu = () => {
  const { translate } = useTranslator();
  return (
    <SecureComponent roles={[READ_USER]}>
      <Menu><Link to="/administration/user-management/users">{translate('menu.usermanagement')}</Link></Menu>
    </SecureComponent>
  );
};

export const MainMenu = () => (
  <MainMenuStyle>
    <AdminMenu />
  </MainMenuStyle>
);
