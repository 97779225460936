import _ from 'lodash';
import { determineType } from '../../helpers/EventTypeDeterminator';
import { VALUE_IF_ABSENT } from '../../../common/constants/Constants';

function getOverviewMapper(eventName, overview, defaultValue = VALUE_IF_ABSENT) {
  if (_.isEmpty(overview)) {
    return {};
  }

  let overviewParams;
  const eventType = determineType(eventName);
  switch (eventType) {
  case 'PRECON':
    overviewParams = [
      'consignmentCompletionDate', 'departureLocationCode', 'departureDateTime', 'arrivalLocationCode',
      'arrivalDateTime', 'mailClass', 'receptacles', 'weight',
    ];
    break;
  case 'CARDIT':
    overviewParams = [
      'consignmentCompletionDate', 'contractReference', 'postalAirwaybill', 'securityStatus',
      'billingCarrier', 'consignmentOrigin', 'consignmentDestination', 'handoverOriginLocationCode',
      'handoverOriginLocationCutOff', 'handoverDestinationLocationCode', 'handoverDestinationLocationCutOff',
      'mailClass', 'receptacles', 'weight',
    ];
    break;
  case 'RESDIT':
    overviewParams = [
      'postalAirwaybill', 'handoverLocationCode', 'handoverLocationCodeSource', 'handoverLocationName',
      'partnerIdCode', 'partnerIdCodeSource', 'partnerIdName', 'mailClass', 'receptacles', 'weight',
    ];
    break;
  case 'RESCON':
    overviewParams = ['mailClass', 'receptacles', 'weight'];
    break;
  default:
    overviewParams = [];
    break;
  }

  return _.reduce(overviewParams, (obj, key) => {
    obj[key] = overview[key] || defaultValue;
    return obj;
  }, {});
}

export default getOverviewMapper;
