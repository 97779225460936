import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { api } from '../../../../../api';
import { useIsAuthorized } from '../../../../../components/common/ipc-core/useIsAuthorized';
import noop from '../../../../../core/modules/common/tools/noop';
import Permission from '../../../../../security/permission';
import EventContentEdiButton from '../../../../common/components/timeline/eventContentEdi/EventContentEdiButton';
import { FORBIDDEN } from '../../../../common/enum/HTTPStatusCodes';
import { getItemDetailsComponent } from './ItemDetails';
import { useApiQueryWithErrorMessage } from './ItemDetails/utils';
import ItemGeneralInfo from './ItemGeneralInfo';

const getQueryParams = (event, pageId) => {
  switch (event.eventName) {
  case 'ITMATT':
    return {
      queryKey: ['itemDetails', {
        aggregateId: event.aggregateId,
        itemId: pageId,
      }],
      queryFn: api.fetchContentForItem,
      queryOptions: {
        enabled: !!event.aggregateId && pageId,
        retry: false,
      },
    };
  case 'ITMREF':
    return {
      queryKey: ['itmrefDetails', {
        aggregateId: event.aggregateId,
        itemId: pageId,
      }],
      queryFn: api.fetchItmrefDetails,
      queryOptions: {
        enabled: !!event.aggregateId && pageId,
        retry: false,
      },
    };
  case 'REFRSP':
    return {
      queryKey: ['refrspDetails', {
        aggregateId: event.aggregateId,
        itemId: pageId,
      }],
      queryFn: api.fetchRefrspDetails,
      queryOptions: {
        enabled: !!event.aggregateId && pageId,
        retry: false,
      },
    };
  default:
    return {
      queryKey: null,
      queryFn: undefined,
      queryOptions: { enabled: false },
    };
  }
};

const ItemEventContentDetails = (props) => {
  const {
    event = {},
    showEDI = false,
    toggleEventEDI = noop,
    pageId,
  } = props;

  const isAuthorized = useIsAuthorized([event.eventName === 'ITMATT' ? Permission.READ_EDI_ITMATT : Permission.READ_EDI]);
  const {
    queryKey,
    queryFn,
    queryOptions,
  } = getQueryParams(event, pageId);
  const itemDetailData = useApiQueryWithErrorMessage(queryKey, queryFn, queryOptions);

  const EventContentDetail = getItemDetailsComponent(event.eventName);
  const isEdiButtonDisabled = !(!queryOptions.enabled || itemDetailData.isSuccess || (itemDetailData.error && itemDetailData.error.status !== FORBIDDEN));

  return (
    <div className="default-event-content-details">
      <div className="default-event-first-row">
        <ItemGeneralInfo event={event} />
      </div>
      <div className="default-event-second-row">
        {event.rawEventKey && event.rawEventKey !== '' && isAuthorized
          && (
            <EventContentEdiButton
              id="eventContentEdiButton"
              showEDI={showEDI}
              toggleEventEDI={toggleEventEDI}
              disabled={isEdiButtonDisabled}
            />
          )}
      </div>
      <Suspense fallback={null}>
        <div className="event-item-content-details">
          <EventContentDetail contentDetailData={itemDetailData} />
        </div>
      </Suspense>
    </div>
  );
};

ItemEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
  pageId: PropTypes.string,
};

export default ItemEventContentDetails;
