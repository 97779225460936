import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderStyle, HeaderStyleItem } from './SortHeader.styles';

const StyledHeader = styled.div`
  ${HeaderStyle}
`;
const StyledHeaderColumn = styled.div`
  ${HeaderStyleItem}
`;
const ArrowContainer = styled.div`
  margin-left : ${({ theme }) => theme.sizes[2]};
`;

const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const SortHeader = ({
  config = [],
  defaultSortField = '',
  defaultSortDirection = SORT_DIRECTIONS.ASC,
  onSortedChange,
  ...rest
}) => {
  const [{ sortedField, sortedDirection }, setSortedState] = useState({
    sortedField: defaultSortField,
    sortedDirection: defaultSortDirection,
  });
  const flexBasis = (1 / config.length) * 100;

  const handleSortClick = (event, configItem) => {
    const { sortable, fieldName } = configItem;

    if (sortable === false) {
      return;
    }

    let newSortedDirection = '';

    if (fieldName === sortedField) {
      newSortedDirection = !sortedDirection || sortedDirection === SORT_DIRECTIONS.DESC ? SORT_DIRECTIONS.ASC : SORT_DIRECTIONS.DESC;
    } else {
      newSortedDirection = SORT_DIRECTIONS.ASC;
    }

    setSortedState({ sortedField: fieldName, sortedDirection: newSortedDirection });
    if (onSortedChange) onSortedChange(event, fieldName, newSortedDirection);
  };

  const renderSortArrow = () => (
    <ArrowContainer>
      {sortedDirection === SORT_DIRECTIONS.ASC && <span data-testid={`${sortedField}_asc`}>▲</span>}
      {sortedDirection === SORT_DIRECTIONS.DESC && <span data-testid={`${sortedField}_desc`}>▼</span>}
    </ArrowContainer>
  );

  return (
    <StyledHeader {...rest}>
      {config.map((configItem) => {
        const { Header, fieldName, sortable } = configItem;

        return (
          <StyledHeaderColumn
            key={fieldName}
            flexBasis={`${flexBasis}%`}
            onClick={(event) => handleSortClick(event, configItem)}
          >
            <Header />
            {fieldName === sortedField && sortable !== false && renderSortArrow()}
          </StyledHeaderColumn>
        );
      })}
    </StyledHeader>
  );
};

SortHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.arrayOf(PropTypes.any),
  defaultSortField: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf([SORT_DIRECTIONS.ASC, SORT_DIRECTIONS.DESC, '']),
  onSortedChange: PropTypes.func,
};

export default SortHeader;
