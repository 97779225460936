import { LOCATION_CHANGE } from '../actions/types/NavigationActionTypes';
import { DO_SEARCH_FAILURE, DO_SEARCH_REQUEST, DO_SEARCH_SUCCESS } from '../actions/types/SearchActionTypes';
import { getErrorMessage } from './utils';

const initialState = {
  result: {},
  error: false,
  isLoading: false,
  errorMessage: '',
};

export const search = (state = initialState, action) => {
  switch (action.type) {
  case DO_SEARCH_REQUEST:
    return {
      result: {},
      error: false,
      isLoading: true,
      errorMessage: '',
    };
  case DO_SEARCH_SUCCESS:
    return {
      ...state,
      error: false,
      errorMessage: '',
      isLoading: false,
      result: action.payload,
    };
  case DO_SEARCH_FAILURE: {
    const errorMessage = getErrorMessage(action.payload);
    return {
      ...state,
      error: true,
      errorMessage,
      isLoading: false,
    };
  }
  case LOCATION_CHANGE:
    return initialState;
  default:
    return state;
  }
};

export default search;
