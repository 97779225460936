import React from 'react';
import { injectIntl } from 'react-intl';
import ItemEventContentDetails from './ItemEventContentDetails';
import ReceptacleEventContentDetails from './ReceptacleEventContentDetails';
import { getDefault, getOrDefault } from '../../../../common/MapUtils';
import DefaultEventContentDetails from '../../../../common/components/timeline/eventContentDetails/DefaultEventContentDetails';

const eventContentDetailsMap = {
  item: (props) => <ItemEventContentDetails {...props} />,
  receptacle: (props) => <ReceptacleEventContentDetails {...props} />,
  default: (props) => <DefaultEventContentDetails {...props} />,
};

export const EventContentDetailsForItemPage = (props) => ((props.event)
  ? getOrDefault(eventContentDetailsMap, props.event.eventType)(props)
  : getDefault(eventContentDetailsMap)());

export default injectIntl(EventContentDetailsForItemPage);
