import React from 'react';
import PropTypes from 'prop-types';
import { TimelineEvent as IpcTimelineEvent, TimelineEventMarker } from '@ipc/ipc-components';

import TimelineEventDate from './TimelineEventDate';
import EventContent from './eventContent/EventContent';
import { DATES } from '../../../../utils/constants';

const TimelineEvent = (props) => {
  const {
    event,
    className,
  } = props;
  return (
    <IpcTimelineEvent className={className}>
      <div>
        <TimelineEventDate date={event && event.eventDate} format={DATES.HOUR_FORMAT} />
        <TimelineEventMarker />
        <EventContent id="timelineEventContent" event={event} />
      </div>
    </IpcTimelineEvent>
  );
};

TimelineEvent.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  className: PropTypes.string,
};

export default TimelineEvent;
