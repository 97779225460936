import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/common/Button';
import ButtonTabs from '../../../components/common/ButtonTabs';
import Icons from '../../../components/common/Icons';
import IconText from '../../../components/common/IconText';

class TabsPanel extends Component {
  constructor(props) {
    super(props);
    const tabs = this.getTabsFromMap(props.map);
    this.state = {
      activeTab: props.activeTab || tabs[0].label,
      tabs,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ tabs: this.getTabsFromMap(nextProps.map) });
  }

  onTabChange = (activeTab) => {
    const { activeTab: activeTab1 } = this.state;
    const { map } = this.props;
    if (activeTab1 !== activeTab && map[activeTab].onActive) {
      map[activeTab].onActive();
    }
    this.setState({ activeTab });
  };

  getCurrentActiveTab = () => {
    const { activeTab } = this.state;
    return activeTab;
  };

  getTabsFromMap = (map) => Object.keys(map)
    .map((label) => ({
      label,
      value: label,
    }));

  defaultIconByKey = (key) => {
    if (key === 'download') {
      return Icons.DownloadIcon;
    }
    return null;
  };

  renderButtons() {
    const { map } = this.props;
    const { activeTab } = this.state;
    const haveButtons = map[activeTab]
      && !_.isEmpty(map[activeTab].actionButtons);

    if (!haveButtons) return null;

    return map[activeTab].actionButtons.map((btn) => (
      <div className={`action-button ${btn.key}-button`} key={`tabsPanel-${btn.key}-button`}>
        <Button
          id={`${btn.key}FullDetailsButton`}
          onClick={btn.onClick}
        >
          <IconText icon={btn.icon || this.defaultIconByKey(btn.key)} labelId={btn.label || `app.tracking.timeline.${btn.key}`} />
        </Button>
      </div>
    ));
  }

  renderContent() {
    const { map, eventError } = this.props;
    if (eventError) {
      const { error, errorMessage } = eventError;

      if (error && errorMessage) {
        return (
          <div className="event-error"><FormattedMessage id={errorMessage} /></div>
        );
      }
    }

    const { activeTab } = this.state;
    return (
      map[activeTab].tabContent
        ? map[activeTab].tabContent
        : map[activeTab]
    );
  }

  render() {
    const { activeTab, tabs } = this.state;
    return (
      <div>
        <div className="tabs-panel-action-bar">
          <ButtonTabs
            className="tabs-panel-tabs-buttons"
            tabs={tabs}
            defaultLabel={activeTab}
            onChange={this.onTabChange}
          />
          {this.renderButtons()}
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

TabsPanel.propTypes = {
  map: PropTypes.objectOf(PropTypes.shape),
  activeTab: PropTypes.string,
  eventError: PropTypes.objectOf(PropTypes.shape),
};

export default TabsPanel;
