import React from 'react';
import PropTypes from 'prop-types';
import { constructPublicAssetPath } from '../../../core/modules/common/tools/URLTools';
import { VALUE_IF_ABSENT } from '../constants/Constants';

const Tabs = (props) => {
  const {
    tabs = [],
  } = props;

  return (
    <div
      className="tabs"
      style={{ background: `url(${constructPublicAssetPath('images/app/border-background.png')})` }}
    >
      {tabs.map((tab) => (
        <div className="tabs__tab" key={`${tab.label} ${tab.value}`}>
          {tab.label
            && <div className="tabs__tab_label">{tab.label}</div>}
          <div className="tabs__tab_value">{tab.value || VALUE_IF_ABSENT}</div>
        </div>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default Tabs;
