import { useLanguageValue } from '@ipc/ipc-core';
import React from 'react';
import LanguageSwitcher from '../LanguageSwitcher';
import AuthInfo from '../AuthInfo';
import ipcLogoSmall from '../../../images/ipc_logo_small.png';

export const AppHeaderRightContainer = () => {
  const [{ currentLanguage }] = useLanguageValue();
  return (
    <div className="header-right-container">
      <AuthInfo />
      <LanguageSwitcher currentLanguage={currentLanguage} />
      <div className="vertical-divider" />
      <img
        className="logo"
        alt="IPC logo"
        src={ipcLogoSmall}
      />
    </div>
  );
};

export default AppHeaderRightContainer;
