import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { getValueOrDefault } from '../../utils';
import { date } from '../../../../react-table/helpers/fieldFormatter';

const getItemCommonFields = (event) => [
  {
    rootClassName: 'default-event-sender', className: 'event-details-sender-value', title: 'app.tracking.timeline.event.details.sender', value: getValueOrDefault(event.senderMailbox),
  },
  {
    rootClassName: 'default-event-receiver', className: 'event-details-receiver-value', title: 'app.tracking.timeline.event.details.receiver', value: getValueOrDefault(event.recipientMailbox),
  },
  { rootClassName: 'common-event-field', title: 'app.tracking.timeline.event.details.preparation.date', value: date({ value: event.messagePreparationDate }) },
  { rootClassName: 'common-event-field', title: 'app.tracking.timeline.event.details.network.date', value: date({ value: event.onNetworkTime }) },
  { rootClassName: 'common-event-field', title: 'app.tracking.timeline.event.details.load.time', value: date({ value: event.loadDateTime }) },
];

const ItemGeneralInfo = ({ event }) => {
  const intl = useIntl();
  const eventFields = useMemo(() => (event ? getItemCommonFields(event) : []), [event]);

  return (
    <>
      {eventFields.map(({
        rootClassName, className, title, value,
      }) => (
        <div key={title} className={rootClassName}>
          <div className="event-content-details-title">{intl.formatMessage({ id: title })}</div>
          <div className={className}>{value}</div>
        </div>
      ))}
    </>
  );
};

ItemGeneralInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};

export default ItemGeneralInfo;
