import PropTypes from 'prop-types';
import React from 'react';
import TransportTable from '../../../../common/components/tables/TransportTable';
import TabsPanel from '../../../../common/components/TabsPanel';
import { downloadXls, reportType } from '../../../../common/components/timeline/timelineDownloadHelper';
import ReceptaclesTableForDespatch from '../eventContentDetails/ReceptaclesTableForDespatch';
import ResdesGeneralInfo from './ResdesGeneralInfo';

const ResdesFullDetails = (props) => {
  const { activeTab, event } = props;
  const {
    despatchId,
    generalInfo,
    eventInfo = {},
    error,
    errorMessage,
  } = event;
  const filenameInfo = generalInfo && ([despatchId, generalInfo.eventName]);

  const map = {
    receptacles: {
      tabContent: <ReceptaclesTableForDespatch
        isLoaded={!!eventInfo.receptacles}
        receptacles={eventInfo.receptacles}
        error={error}
        showPagination
        showAllColumns
      />,
      actionButtons: [{
        key: 'download',
        onClick: () => {
          downloadXls(
            reportType.fullDetails.receptacles.get(),
            [eventInfo.receptacles],
            filenameInfo,
          );
        },
      }],
    },
    transport: {
      tabContent: <TransportTable
        isLoaded={!!eventInfo.transportLegs}
        transportLegs={eventInfo.transportLegs}
        error={error}
        showPagination
        showAllColumns
      />,
      actionButtons: [{
        key: 'download',
        onClick: () => {
          downloadXls(
            reportType.fullDetails.transport.get(),
            [eventInfo.transportLegs],
            filenameInfo,
          );
        },
      }],
    },
  };

  return (
    <div className="full-details-guttered">
      <ResdesGeneralInfo id="resdesGeneralInfo" {...props} />
      <TabsPanel id="tabsPanel" map={map} eventError={{ error, errorMessage }} activeTab={activeTab} />
    </div>
  );
};

ResdesFullDetails.propTypes = {
  activeTab: PropTypes.string,
  receptacleId: PropTypes.string,
  event: PropTypes.objectOf(PropTypes.shape),
};

ResdesFullDetails.defaultProps = {
  event: {},
};

export default ResdesFullDetails;
