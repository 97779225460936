import { useSelector } from 'react-redux';

const containsPermissions = (currentRoles, expectedRoles) => expectedRoles.some((role) => currentRoles.includes(role));

export const useUserPermissions = () => useSelector((state) => state.global.auth.roles);

export const useIsAuthorized = (permissions) => {
  const userPermissions = useUserPermissions();
  return containsPermissions(userPermissions, permissions);
};

export const useAuthorizationByPermissions = () => {
  const userPermissions = useUserPermissions();
  const isAuthorize = (permissions) => containsPermissions(userPermissions, permissions);

  return {
    isAuthorize,
  };
};

export default {
  useIsAuthorized,
  useUserPermissions,
  useAuthorizationByPermissions,
};
