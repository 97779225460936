export const smallSizes = {
  px: '1px',
  0: '0',
  1: '0.3125rem',
  2: '0.625rem',
  3: '1.875rem',
  4: '3.125rem',
  5: '1.5625rem',
  6: '1.250rem',
};

const widgetSizes = {
  borderRadius: '4px',
  inputSizes: '30px',
};

const buttons = {
  sm: '7.5px',
  md: '15px',
  lg: '30px',
};

const containers = {
  tooltip: '150px',
  smallDialog: '350px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1140px',
};

const sizes = {
  ...smallSizes,
  ...containers,
  ...widgetSizes,
  buttons,
};

export default sizes;
