import { useState, useMemo } from 'react';
import formatXML from 'xml-formatter';
import { useApiQuery } from '../../../../../hooks';
import { api } from '../../../../../api';

const useEdiApiQuery = (...args) => {
  const {
    error, ...rest
  } = useApiQuery(...args);
  const errorMessage = error ? 'app.tracking.timeline.edi.error' : '';

  return {
    error, errorMessage, ...rest,
  };
};

export const useEdiState = (initialSearchTerm = '', initialFormatted = true) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [isFormatted, setFormatted] = useState(initialFormatted);

  return {
    searchTerm,
    isFormatted,
    handleSearchTerm: setSearchTerm,
    handleFormattedText: setFormatted,
  };
};

const useFormatXMLResponses = (response, isSuccess) => {
  const ediMessage = useMemo(() => {
    let innerEdiMessage = '';
    if (isSuccess) {
      const { messageContent, fileContent } = response.data;

      try {
        innerEdiMessage = formatXML(messageContent || fileContent, { indentation: '\t' });
      } catch (e) {
        innerEdiMessage = '';
      }
    }

    return innerEdiMessage;
  }, [isSuccess, response]);

  return ediMessage;
};

export const useEdiDefaultMessage = (eventKey, source, isFormatted) => {
  const {
    data: response, isSuccess, ...rest
  } = useEdiApiQuery(['ediMessage', { eventKey, source, isFormatted }], api.fetchEdi, { retry: false });

  let ediMessage = '';
  if (isSuccess) {
    ediMessage = response.data.messageContent;
  }

  return {
    ediMessage, isSuccess, ...rest,
  };
};

export const useItmattMessage = (eventKey, aggregateId, itemId) => {
  const {
    data: response, isSuccess, ...rest
  } = useEdiApiQuery(['ITMATTMessage', { eventKey, aggregateId, itemId }], api.fetchItmattEvent, { retry: false });
  const ediMessage = useFormatXMLResponses(response, isSuccess);

  return {
    ediMessage, isSuccess, ...rest,
  };
};

export const usePodMessage = (receptacleId, handoverTime, impcCode) => {
  const {
    data: response, isSuccess, ...rest
  } = useEdiApiQuery(['PodMessage', { receptacleId, handoverTime, impcCode }], api.fetchPodEdi, { retry: false });
  const ediMessage = useFormatXMLResponses(response, isSuccess);

  return {
    ediMessage, isSuccess, ...rest,
  };
};
