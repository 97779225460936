import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from '@ipc/ipc-core';
import { date } from '../../../../react-table/helpers/fieldFormatter';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import { GeneralInfo } from '../../../../common/components/GeneralInfo';

const DespatchEventGeneralInfo = ({ event }) => {
  function getGeneralInfoFields() {
    if (!_.isEmpty(event)) {
      return [
        {
          className: 'despatch-id',
          intlID: 'app.tracking.timeline.event.details.despatch.id',
          value: event.despatchId && (
            <Link id="consignmentIdLink" to={getUrl('despatch', event.despatchId)}>
              {event.despatchId}
            </Link>
          ),
        },
        {
          className: 'sender',
          intlID: 'app.tracking.timeline.event.details.sender',
          value: event.sender,
        },
        {
          className: 'receiver',
          intlID: 'app.tracking.timeline.event.details.receiver',
          value: event.receiver,
        },
        {
          className: 'preparation-date',
          intlID: 'app.tracking.timeline.event.details.preparation.date',
          value: date({ value: event.messagePreparationDate }),
        },
        {
          className: 'common-event-field',
          intlID: 'app.tracking.timeline.event.details.network.date',
          value: date({ value: event.onNetworkTime }),
        },
      ];
    }
    return [];
  }

  return (
    <GeneralInfo
      infoFields={getGeneralInfoFields()}
      event={event}
      fullDetails
    />
  );
};

DespatchEventGeneralInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};

export default DespatchEventGeneralInfo;
