import { NotificationProvider } from '@ipc/ipc-components';
import {
  LanguageProvider, LanguageRouting, Route, Router,
} from '@ipc/ipc-core';
import React from 'react';
import { Provider } from 'react-redux';
// import { ReactQueryDevtools } from 'react-query-devtools';
import { defaultLanguage, supportedLanguages } from '../../locales/locales';
import { store } from '../../store';
// import { isDev } from '../../utils/development/tools';
import AppContainer from './AppContainer';
import { AppIntlProvider } from './AppIntlProvider';
import { ErrorBoundary } from './ErrorBoundary';
import { Theme } from './Theme';
import { SidebarProvider, UiProvider } from '../common/contexts';

function languageRouting() {
  return (props) => <LanguageRouting {...props} AppContainer={AppContainer} />;
}

export const Root = () => (
  <Provider store={store}>
    <ErrorBoundary>
      {/* {isDev() && <ReactQueryDevtools initialIsOpen={false} />} */}
      <Theme>
        <LanguageProvider supportedLanguages={supportedLanguages} defaultLanguage={defaultLanguage}>
          <NotificationProvider>
            <SidebarProvider>
              <AppIntlProvider>
                <UiProvider>
                  <Router basename={process.env.basePath}>
                    <Route render={languageRouting()} />
                  </Router>
                </UiProvider>
              </AppIntlProvider>
            </SidebarProvider>
          </NotificationProvider>
        </LanguageProvider>
      </Theme>
    </ErrorBoundary>
  </Provider>
);

export default Root;
