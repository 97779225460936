/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { List, AutoSizer } from 'react-virtualized';

const defaultWidth = 500;
const offsets = {
  top: () => 0,
  center: (containerHeight, rowHeight) => (containerHeight / 2) - rowHeight,
  bottom: (containerHeight, rowHeight) => containerHeight - rowHeight,
};
function escapeRegExp(stringToGoIntoTheRegex) {
  // eslint-disable-next-line no-useless-escape
  return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

const Highlight = ({
  textToHighlight = '',
  highlightWord = '',
  containerHeight,
  rowHeight,
  className = 'virtualized_highlight',
  overscanRowCount = 20,
  elementScrollPosition = 'center',
  isVirtualized = true,
  ...rest
}) => {
  const lines = useMemo(() => textToHighlight.split('\n'), [textToHighlight]);
  const wordRegex = new RegExp(`(${escapeRegExp(highlightWord)})`, 'gi');

  const renderSeparatedHighlight = (text, rowKey, style) => {
    const parts = highlightWord ? text.split(wordRegex) : [text];

    return (
      <span style={{ ...style, width: 'auto' }} key={rowKey}>
        {parts.map((part, i) => (
          highlightWord && part.toLowerCase() === highlightWord.toLowerCase()
            ? (
              <mark key={i} className="highlight">{part}</mark>
            ) : part
        ))}
      </span>
    );
  };

  const renderRow = (rowProps) => {
    const { index, style } = rowProps;
    const textLine = lines[index];

    return renderSeparatedHighlight(textLine, index, style);
  };

  const goToFirstInstanceWord = () => {
    const highlightContainers = document.getElementsByClassName(className);

    if (highlightWord && highlightContainers.length === 1) {
      const highlightContent = highlightContainers[0];
      if (isVirtualized) {
        const firstInstanceRow = lines.findIndex((line) => line.split(wordRegex).length > 2);
        if (firstInstanceRow !== -1) {
          window.requestAnimationFrame(() => {
            highlightContent.scrollTop = (firstInstanceRow * rowHeight) - offsets[elementScrollPosition](containerHeight, rowHeight);
          });
        }
      } else {
        const firstInstanceWord = highlightContent.getElementsByClassName('highlight')[0];
        if (firstInstanceWord) {
          window.requestAnimationFrame(() => {
            highlightContent.scrollTop = (firstInstanceWord.offsetTop - highlightContent.offsetTop) - offsets[elementScrollPosition](containerHeight, rowHeight);
          });
        }
      }
    }
  };

  useEffect(goToFirstInstanceWord,
    [textToHighlight, highlightWord, rowHeight, containerHeight, elementScrollPosition, isVirtualized]);

  return isVirtualized ? (
    <AutoSizer disableHeight>
      { ({ width }) => (
        <List
          className={className}
          height={containerHeight}
          width={width || defaultWidth}
          rowHeight={rowHeight}
          overscanRowCount={overscanRowCount}
          rowCount={lines.length}
          rowRenderer={renderRow}
          {...rest}
        />
      )}
    </AutoSizer>
  ) : (
    <div className={className}>
      {renderSeparatedHighlight(textToHighlight)}
    </div>
  );
};

Highlight.propTypes = {
  textToHighlight: PropTypes.string,
  highlightWord: PropTypes.string,
  containerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  overscanRowCount: PropTypes.number,
  className: PropTypes.string,
  elementScrollPosition: PropTypes.string,
  isVirtualized: PropTypes.bool,
};

export default Highlight;
