import _ from 'lodash';
import { VALUE_IF_ABSENT } from '../../../common/constants/Constants';
import {
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FAILURE,
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_SUCCESS,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FAILURE,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_SUCCESS,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FAILURE,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_SUCCESS,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FAILURE,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_SUCCESS,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_FAILURE,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_SUCCESS,
  FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_FAILURE,
  FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_SUCCESS,
} from '../actions/types/EventInfoActionTypes';
import { FETCH_RECEPTACLE_FAILURE, FETCH_RECEPTACLE_REQUEST, FETCH_RECEPTACLE_SUCCESS } from '../actions/types/ReceptacleActionTypes';
import { getErrorMessage } from './utils';

const initialState = {
  error: false,
  errorMessage: '',
  receptacle: {
    allEvents: [],
  },
};

const separatedBySlash = (...params) => (
  _.every(params, (p) => _.isEmpty(p))
    ? null
    : params.map((p) => p || VALUE_IF_ABSENT)
      .join(' / ')
);

const prepareEvent = (event) => ({
  ...event,
  sender: separatedBySlash(event.senderMailbox, event.originPostalOperatorCode),
  receiver: separatedBySlash(event.recipientMailbox, event.destinationPostalOperatorCode),
});

function getEventInfo(payload) {
  return {
    eventId: payload.consignmentId
      ? payload.consignmentId
      : payload.despatchId,
    transportLegs: payload.transportLegs || [],
    items: payload.items || [],
  };
}

function updateEvent(event, payload) {
  return {
    ...prepareEvent(event),
    eventInfo: getEventInfo(payload),
  };
}

function newEvent(payload) {
  return {
    ...prepareEvent(payload.generalInfo),
    eventInfo: getEventInfo(payload),
  };
}

function putTransportAndItemInfoOnReceptacleEvent(state, action) {
  const toFind = { aggregateId: action.payload.generalInfo.aggregateId };
  const allEvents = _.cloneDeep(state.receptacle.allEvents);
  const eventToUpdate = _.find(allEvents, toFind);
  const indexToUpdate = _.findIndex(allEvents, toFind);

  const event = eventToUpdate
    ? updateEvent(eventToUpdate, action.payload)
    : newEvent(action.payload);

  allEvents.splice(indexToUpdate, 1, event);

  return {
    ...state,
    receptacle: {
      ...state.receptacle,
      allEvents,
    },
  };
}

function putErrorOnReceptacleEvent(state, action) {
  let allEvents = _.cloneDeep(state.receptacle.allEvents);
  const errorMessage = getErrorMessage(action.payload);

  if (_.isEmpty(allEvents)) {
    allEvents = [{
      aggregateId: action.aggregateId,
      messageAggregateId: action.messageAggregateId,
      error: true,
      errorMessage,
    }];
  } else {
    _.map(allEvents, (event) => {
      if (event.aggregateId === action.aggregateId) {
        event.error = true;
        event.errorMessage = errorMessage;
      }
      return event;
    });
  }

  return {
    ...state,
    receptacle: {
      ...state.receptacle,
      allEvents,
    },
  };
}

const receptacle = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_RECEPTACLE_REQUEST:
    return {
      ...state,
      receptacle: {
        receptacleId: action.params.receptacleId,
        isLoaded: false,
      },
    };
  case FETCH_RECEPTACLE_SUCCESS: {
    const { receptacleEvents, receptacleIdentifier, aggregateId } = action.payload;
    const identifier = receptacleIdentifier;
    const allEvents = receptacleEvents.map((rEvt) => {
      const evt = { ...prepareEvent(rEvt) };
      evt.eventType = 'receptacle';
      return evt;
    });

    return {
      error: false,
      errorMessage: '',
      receptacle: {
        isLoaded: true,
        aggregateId,
        receptacleId: identifier.s9Id,
        originImpc: identifier.outwardOE,
        originPost: identifier.originPostalOperatorCode,
        destImpc: identifier.inwardOE,
        destPost: identifier.destinationPostalOperatorCode,
        mailCategory: identifier.mailCategory,
        mailSubclass: identifier.mailSubclass,
        receptacleSerialNumber: identifier.receptacleSerialNumber,
        highestNumberedReceptacleIndicator: identifier.highestNumberedReceptacleIndicator,
        registeredInsuredIndicator: identifier.registeredInsuredIndicator,
        weight: identifier.weight,
        allEvents,
      },
    };
  }

  case FETCH_RECEPTACLE_FAILURE: {
    const errorMessage = getErrorMessage(action.payload);

    return {
      ...state,
      receptacle: {
        receptacleId: action.receptacleId,
        isLoaded: true,
      },
      error: true,
      errorMessage,
    };
  }

  case FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_SUCCESS:
  case FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_SUCCESS:
  case FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_SUCCESS:
  case FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_SUCCESS:
  case FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_SUCCESS:
  case FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_SUCCESS:
    return putTransportAndItemInfoOnReceptacleEvent(state, action);

  case FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_FAILURE:
  case FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_FAILURE:
  case FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FAILURE:
  case FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FAILURE:
  case FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FAILURE:
  case FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FAILURE:
    return putErrorOnReceptacleEvent(state, action);

  default:
    return state;
  }
};

export default receptacle;
