import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { constructPublicAssetPath } from '../../../core/modules/common/tools/URLTools';

const Expander = (props) => {
  const {
    label = '',
    onToggle,
    expanded = false,
    fullWidthOnExpanded = false,
    contentComponent,
  } = props;

  return (
    <div className={
      `expander ${
        !expanded ? 'expander--collapsed ' : ''
      }${fullWidthOnExpanded ? 'expander--full-width' : 'expander--shrink-width'}`
    }
    >
      <span className="expander__label">{label}</span>

      { onToggle
        // Display actions if `onToggle` function is provided
        && (
          <div className="expander__actions">
            { expanded
              ? (
                <a
                  className="expander__actions_action"
                  onClick={() => {
                    onToggle(false);
                  }}
                >
                  <span className="action_close"><FormattedMessage id="app.close" /></span>
                </a>
              )
              : (
                <a
                  className="expander__actions_action"
                  onClick={() => {
                    onToggle(true);
                  }}
                >
                  <span>
                    <img
                      src={constructPublicAssetPath('images/common/icon-three-dots.svg')}
                      alt="three dots"
                    />
                  </span>
                </a>
              )}
          </div>
        )}

      { contentComponent
        // Display component if one is provided
        && (
          <div className="expander__content">
            {contentComponent}
          </div>
        )}
    </div>
  );
};

Expander.propTypes = {
  label: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  onToggle: PropTypes.func,
  fullWidthOnExpanded: PropTypes.bool,
  contentComponent: PropTypes.element,
};

export default Expander;
