import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from '@ipc/ipc-core';
import { TimelineEventContent as IpcTimelineEventContent } from '@ipc/ipc-components';
import { CONSIGNMENT, DESPATCH, RECEPTACLE } from '../../../constants/Constants';
import DefaultEventContentEdi from '../eventContentEdi/EventContentEdi';
import ItmattEdiContent from '../eventContentEdi/ItmattEventEdiContent';
import PodEventEdiContent from '../eventContentEdi/PodEventEdiContent';
import TimelineEventContentDetails from './EventContentDetails';
import TimelineEventContentHeader from './EventContentHeader';
import { usePageState } from '../../../utils';

const getEventContentComponent = (eventName = '') => {
  if (eventName.trim() === 'ITMATT') {
    return ItmattEdiContent;
  }
  if (eventName.trim() === 'POD') {
    return PodEventEdiContent;
  }

  return DefaultEventContentEdi;
};

export function TimelineEventContent({ event, ...rest }) {
  const [{ pageId }, uiState, updateUI] = usePageState(rest.match);
  const eventElementRef = useRef(null);
  const { expandedEventKeys = [], expandedEventEdiKey = '' } = uiState || {};

  const getPageEventId = () => {
    const {
      eventType,
      receptacleId,
      despatchId,
      consignmentId,
    } = event;

    if (eventType === RECEPTACLE && receptacleId) {
      return receptacleId;
    }
    if (eventType === DESPATCH && despatchId) {
      return despatchId;
    }
    if (eventType === CONSIGNMENT && consignmentId) {
      return consignmentId;
    }

    return pageId;
  };

  const eventExpandAction = (aggregateId) => {
    const newExpandedEventKeys = [...expandedEventKeys];
    newExpandedEventKeys.push(aggregateId);

    updateUI('expandedEventKeys', newExpandedEventKeys);
  };

  const eventCollapseAction = (aggregateId) => {
    const newExpandedEventKeys = [...expandedEventKeys];
    _.pull(newExpandedEventKeys, aggregateId);

    updateUI('expandedEventKeys', newExpandedEventKeys);
  };

  const toggleEventDetails = () => {
    if (_.includes(expandedEventKeys, event.aggregateId)) {
      eventCollapseAction(event.aggregateId);
    } else {
      eventExpandAction(event.aggregateId);
    }
  };

  const toggleEventEDI = useCallback(() => {
    let newExpandedEventEdiKey;
    if (
      expandedEventEdiKey === '' || expandedEventEdiKey !== event.aggregateId
    ) {
      newExpandedEventEdiKey = event.aggregateId;
    } else {
      newExpandedEventEdiKey = '';
    }

    updateUI('expandedEventEdiKey', newExpandedEventEdiKey);
  }, [event, expandedEventEdiKey]);

  useEffect(() => {
    const eventElement = eventElementRef.current;
    if (eventElement) {
      eventElement.setAttribute('event-id', event.aggregateId);
    }
  }, []);

  const isExpanded = uiState && _.includes(expandedEventKeys, event.aggregateId);
  const showEdi = uiState && expandedEventEdiKey === event.aggregateId;
  const className = `event-details${showEdi ? ' edi-arrow' : ''}`;
  const pageEventId = getPageEventId();
  const TimelineEventContentEdi = getEventContentComponent(event.eventName);

  return (
    <IpcTimelineEventContent ref={eventElementRef}>
      <div className={className}>
        <TimelineEventContentHeader
          id="timelineEventContentHeader"
          event={event}
          isExpanded={isExpanded}
          toggleEventDetails={toggleEventDetails}
        />
        <div role="region" id={event.rawEventKey}>
          {isExpanded && (
            <TimelineEventContentDetails
              id="timelineEventContentDetails"
              event={event}
              showEDI={showEdi}
              toggleEventEDI={toggleEventEDI}
              pageId={pageEventId}
            />
          )}
        </div>
      </div>
      {isExpanded && showEdi && (
        <TimelineEventContentEdi
          id="timelineEventContentEdi"
          defaultSearchTerm={pageEventId}
          event={event}
          source={event.eventType}
          toggleEventEDI={toggleEventEDI}
          pageId={pageEventId}
        />
      )}
    </IpcTimelineEventContent>
  );
}

TimelineEventContent.defaultProps = {
  event: {
    eventLocation: {},
  },
};

TimelineEventContent.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  eventCollapseAction: PropTypes.func,
  eventExpandAction: PropTypes.func,
};

export default injectIntl(withRouter(TimelineEventContent));
