import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { date } from '../../../../react-table/helpers/fieldFormatter';
import { GeneralInfo } from '../../../../common/components/GeneralInfo';

function getGeneralInfoFields(event) {
  if (!_.isEmpty(event)) {
    return [
      {
        className: 'interchange-reference',
        intlID: 'app.tracking.timeline.event.details.interchangeReference',
        value: event.messageInterchangeReference,
      },
      {
        className: 'reference',
        intlID: 'app.tracking.timeline.event.details.reference',
        value: event.messageReference,
      },
      {
        className: 'status',
        intlID: 'app.tracking.timeline.event.details.status',
        value: event.messageStatus,
      },
      {
        className: 'sender',
        intlID: 'app.tracking.timeline.event.details.sender',
        value: event.sender,
      },
      {
        className: 'receiver',
        intlID: 'app.tracking.timeline.event.details.receiver',
        value: event.receiver,
      },
      {
        className: 'close-date',
        intlID: 'app.tracking.timeline.event.details.closedDate',
        value: date({ value: event.despatchClosedDate }),
      },
      {
        className: 'preparation-date',
        intlID: 'app.tracking.timeline.event.details.preparation.date',
        value: date({ value: event.messagePreparationDate }),
      },
      {
        className: 'common-event-field',
        intlID: 'app.tracking.timeline.event.details.network.date',
        value: date({ value: event.onNetworkTime }),
      },
      {
        className: 'load-date',
        intlID: 'app.tracking.timeline.event.details.loadDate',
        value: date({ value: event.messageLoadDate }),
      },
      {
        className: 'total-receptacles-advised',
        intlID: 'app.tracking.timeline.event.details.advised',
        value: event.totalReceptaclesAdvised,
      },
      {
        className: 'total-receptacles-scanned',
        intlID: 'app.tracking.timeline.event.details.scanned',
        value: event.totalReceptaclesScanned,
      },
    ];
  }
  return [];
}

const PredesGeneralInfo = ({
  event,
  showEDI,
  toggleEventEDI,
}) => {
  const infoFields = useMemo(() => getGeneralInfoFields(event), [event]);

  return (
    <GeneralInfo
      infoFields={infoFields}
      event={event}
      showEDI={showEDI}
      toggleEventEDI={toggleEventEDI}
    />
  );
};

PredesGeneralInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape).isRequired,
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

export default PredesGeneralInfo;
