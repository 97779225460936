import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';
import noop from '../../../core/modules/common/tools/noop';
import { withSecurity } from '../../../security/page';
import Permission from '../../../security/permission';
import ReceptaclePage from '../components/receptacle/ReceptaclePage';
import { fetchReceptacleRequestActionCreator } from '../redux/actions/ReceptacleActions';
import { getViewAndIdentifierFromRoute } from '../../common/utils';
import { useGeneralErrorNotification } from '../../../hooks';

const mapStateToProps = (state) => ({
  receptacle: state.tracking.receptacle.receptacle,
  hasError: state.tracking.receptacle.error,
  errorMessage: state.tracking.receptacle.errorMessage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchReceptacleRequestActionCreator,
  reset,
}, dispatch);

export function ReceptacleContainer({
  match, hasError, errorMessage, receptacle, reset: reset1, fetchReceptacleRequestActionCreator: fetchReceptacleRequestActionCreator1,
}) {
  const { params: { receptacleId } } = match;

  const resetFilterForm = () => {
    const { view, pageId } = getViewAndIdentifierFromRoute(match);

    reset1(`${view}.${pageId}.filters`);
  };

  const fetchReceptacle = (id) => {
    fetchReceptacleRequestActionCreator1(id);
  };

  useEffect(() => {
    fetchReceptacle(receptacleId);
  }, [receptacleId]);

  useGeneralErrorNotification(errorMessage);

  return (
    <ReceptaclePage
      id="receptaclePage"
      resetFilterForm={resetFilterForm}
      receptacle={receptacle}
      hasError={hasError}
      errorMessage={errorMessage === 'app.networkError' ? 'app.tracking.search.errors.not.found' : errorMessage}
      requestedId={receptacleId}
    />
  );
}

ReceptacleContainer.defaultProps = {
  receptacle: {},
  match: {},
  fetchReceptacleRequestActionCreator: noop,
};

ReceptacleContainer.propTypes = {
  receptacle: PropTypes.objectOf(PropTypes.shape),
  reset: PropTypes.func,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  match: PropTypes.shape({ params: PropTypes.shape({ receptacleId: PropTypes.string }) }),
  fetchReceptacleRequestActionCreator: PropTypes.func,
};

const ConnectedReceptacleContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReceptacleContainer);

export default withSecurity(ConnectedReceptacleContainer, [Permission.READ_RECEPTACLE]);
