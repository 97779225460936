import * as PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const IconTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

export const IconText = (props) => {
  const { icon: IconComponent, labelId, text } = props;
  return (
    <IconTextWrapper>
      <IconWrapper>
        <IconComponent />
      </IconWrapper>
      {labelId && <FormattedMessage id={labelId} />}
      {text && text}
    </IconTextWrapper>
  );
};

IconText.propTypes = {
  icon: PropTypes.func,
  text: PropTypes.string,
  labelId: PropTypes.string,
};

export default IconText;
