import { api } from '../api';
import { useApiQuery } from './query';

export const getPermissionIds = (permissions) => [...permissions].map((permission) => permission.id);

export const usePermissions = () => {
  const { data: permissions } = useApiQuery('permissions', api.fetchAllPermissions);
  return {
    permissions,
  };
};
