export default {
  lang: 'en',
  messages: {
    'app.title': 'ATAT',
    'app.copyright': '© {copyrightYear} International Post Corporation',
    'app.signIn': 'sign in',
    'app.signOut': 'sign out',
    'app.header.navigation.logout': 'Logout',
    'app.forms.date_picker.label.ok': 'OK',
    'app.forms.date_picker.label.cancel': 'Cancel',
    'app.login.username': 'Username',
    'app.login.password': 'Password',
    'app.login.login': 'LOGIN',
    'error.credentials.invalid': 'Incorrect username or password.',
    'app.login.validations.required': 'Required',
    'app.login.message.tokenExpired': 'Your session expired. Please login to continue using the application.',
    'app.login.errors.credentials': 'The username and password combination provided is incorrect, please correct and retry.',
    'app.close': 'close',
    'app.loading': 'Loading...',
    'app.table.true': 'Yes',
    'app.table.false': 'No',
    'app.permission.notAuthorized': 'User does not have permission to access this content',
    'app.tracking.search.handle': 'search / filter',
    'app.tracking.search.title': 'START SEARCH',
    'app.tracking.search.results': 'SEARCH RESULTS',
    'app.tracking.search.receptacle.core': 'RECEPTACLE CORE',
    'app.tracking.search.search-button': 'Search',
    'app.tracking.search.searching-button': 'Searching...',
    'app.tracking.search.input.placeholder': 'Search for an identifier: item, receptacle, despatch or consignment',
    'app.tracking.search.input.invalid': 'Search does not match any valid format',
    'app.tracking.search.results.item.id': 'Item Id',
    'app.tracking.search.results.item.view': 'View Item',
    'app.tracking.search.results.receptacle.id': 'Receptacle Id',
    'app.tracking.search.results.receptacle.view': 'View Receptacle',
    'app.tracking.search.results.despatch.id': 'Despatch Id',
    'app.tracking.search.results.despatch.view': 'View Despatch',
    'app.tracking.search.results.consignment.id': 'Consignment Id',
    'app.tracking.search.results.consignment.view': 'View Consignment',
    'app.tracking.item.title': 'ITEM',
    'app.tracking.common.fullDetails.invalidEvent': 'No Full Details available for {eventName}',
    'app.tracking.receptacle.title': 'RECEPTACLE',
    'app.tracking.receptacle.fullDetails.title': 'Receptacle',
    'app.tracking.consignment.title': 'CONSIGNMENT',
    'app.tracking.consignment.fullDetails.title': 'Consignment',
    'app.tracking.despatch.title': 'DESPATCH',
    'app.tracking.despatch.fullDetails.title': 'Despatch',
    'app.tracking.search.errors.not.found': 'Identifier not found',
    'app.tracking.sidebar.sorting': 'SORTING',
    'app.tracking.sidebar.sorting.desc': 'Most Recent to Oldest',
    'app.tracking.sidebar.sorting.asc': 'Oldest to Most Recent',
    'app.tracking.sidebar.clear-filter-button': 'clear filter',
    'app.tracking.sidebar.errors.not.found': 'not found',
    'app.tracking.consignment.tabs.label': 'Country code',
    'app.tracking.timeline.toggle.label': 'Full trace',
    'app.tracking.timeline.toggle.on': 'on',
    'app.tracking.timeline.toggle.off': 'off',
    'app.tracking.timeline.edi.toggle.on': 'edi raw',
    'app.tracking.timeline.edi.toggle.off': 'edi formatted',
    'app.tracking.timeline.sidebar.search': 'search',
    'app.tracking.timeline.expand-all': 'expand all',
    'app.tracking.timeline.collapse-all': 'collapse all',
    'app.tracking.timeline.event-group.more': 'view more {amount}',
    'app.tracking.timeline.event.notFound': 'Message not found',
    'app.tracking.timeline.event.edi.view': 'View EDI',
    'app.tracking.timeline.event.edi.hide': 'Hide EDI',
    'app.tracking.timeline.event.edi.copy': 'Copy to clipboard',
    'app.tracking.timeline.event.edi.title': 'edi message',
    'app.tracking.timeline.event.details.legs': 'Legs',
    'app.tracking.timeline.event.details.totalReceptacles': 'Receptacles',
    'app.tracking.timeline.event.details.firstLegTransport': 'First leg transport',
    'app.tracking.timeline.event.details.lastLegTransport': 'Last leg transport',
    'app.tracking.timeline.event.details.messageVersion': 'Message version',
    'app.tracking.timeline.event.details.countryCode': 'Country code',
    'app.tracking.timeline.event.details.interchangeReference': 'INTREF',
    'app.tracking.timeline.event.details.reference': 'MESREF',
    'app.tracking.timeline.event.details.status': 'Status',
    'app.tracking.timeline.event.details.closedDate': 'Closed date',
    'app.tracking.timeline.event.details.loadDate': 'Load date',
    'app.tracking.timeline.event.details.advised': 'Advised',
    'app.tracking.timeline.event.details.scanned': 'Scanned',
    'app.tracking.timeline.event.details.preparation.date': 'Preparation date',
    'app.tracking.timeline.event.details.network.date': 'On network date',
    'app.tracking.timeline.event.details.load.time': 'Load Time',
    'app.tracking.timeline.event.details.sender': 'Sender',
    'app.tracking.timeline.event.details.receiver': 'Receiver',
    'app.tracking.timeline.event.details.addressee': 'Addressee',
    'app.tracking.timeline.event.details.receptacle.id': 'Receptacle id',
    'app.tracking.timeline.event.details.receptacle.view': 'View receptacle',
    'app.tracking.timeline.event.details.despatch.id': 'Despatch id',
    'app.tracking.timeline.event.details.consignment.id': 'Consignment id',
    'app.tracking.timeline.event.details.full-details': 'Full details',
    'app.tracking.timeline.event.transportForConsignment.table.element': 'Element',
    'app.tracking.timeline.event.transportForConsignment.table.leg': 'Leg {legNumber}',
    'app.tracking.timeline.event.transportForConsignment.table.transportStageQualifier': 'Transport stage qualifier',
    'app.tracking.timeline.event.transportForConsignment.table.carrierCode': 'Carrier code',
    'app.tracking.timeline.event.transportForConsignment.table.carrierCodeSource': 'Carrier code source',
    'app.tracking.timeline.event.transportForConsignment.table.carrierName': 'Carrier name',
    'app.tracking.timeline.event.transportForConsignment.table.conveyanceReference': 'Conveyance reference',
    'app.tracking.timeline.event.transportForConsignment.table.transportMode': 'Mode',
    'app.tracking.timeline.event.transportForConsignment.table.departureLocationCode': 'Departure location code',
    'app.tracking.timeline.event.transportForConsignment.table.departureLocationCodeSource': 'Departure location code source',
    'app.tracking.timeline.event.transportForConsignment.table.departureLocationName': 'Departure location name',
    'app.tracking.timeline.event.transportForConsignment.table.departureDate': 'Transport departure date',
    'app.tracking.timeline.event.transportForConsignment.table.arrivalLocationCode': 'Arrival location code',
    'app.tracking.timeline.event.transportForConsignment.table.arrivalLocationName': 'Arrival location name',
    'app.tracking.timeline.event.transportForConsignment.table.arrivalLocationCodeSource': 'Arrival location code source',
    'app.tracking.timeline.event.transportForConsignment.table.arrivalDate': 'Transport arrival date',
    'app.tracking.timeline.event.transport.table.leg': 'Leg',
    'app.tracking.timeline.event.transport.table.mode': 'Mode',
    'app.tracking.timeline.event.transport.table.carrierCodeAndConveyance': 'Carrier / Conveyance',
    'app.tracking.timeline.event.transport.table.origin': 'Origin',
    'app.tracking.timeline.event.transport.table.destination': 'Destination',
    'app.tracking.timeline.event.transport.table.planned.departure': 'Planned departure',
    'app.tracking.timeline.event.transport.table.planned.arrival': 'Planned arrival',
    'app.tracking.timeline.event.transport.table.dangerousGoodsIndicator': 'Dangerous goods indicator',
    'app.tracking.timeline.event.transport.table.noData': 'No transport data available',
    'app.tracking.timeline.event.detail.items.table.sequence': '#',
    'app.tracking.timeline.event.detail.items.table.itemId': 'Item ID',
    'app.tracking.timeline.event.detail.items.table.handlingClass': 'Handling class',
    'app.tracking.timeline.event.detail.items.table.weight': 'Weight',
    'app.tracking.timeline.event.detail.items.table.chargingMethod': 'Charging method',
    'app.tracking.timeline.event.detail.items.table.nonDeliveryReason': 'Non-Delivery reason',
    'app.tracking.timeline.event.detail.items.table.destinationCountry': 'Destination country',
    'app.tracking.timeline.event.detail.items.table.returnedCharge': 'Returned charge',
    'app.tracking.timeline.event.detail.items.table.insuredValue': 'Insured value',
    'app.tracking.timeline.event.detail.items.table.express': 'Exprès',
    'app.tracking.timeline.event.detail.items.table.dangerousGoodsClass': 'Dangerous goods class',
    'app.tracking.timeline.event.detail.items.table.itemClass': 'Item class',
    'app.tracking.timeline.event.detail.items.table.eventEMD': 'Event EMD',
    'app.tracking.timeline.event.detail.items.table.eventEMI': 'Event EMI',
    'app.tracking.timeline.event.detail.items.table.noData': 'No item data available',
    'app.tracking.timeline.event.detail.receptacle.table.sequence': '#',
    'app.tracking.timeline.event.detail.receptacle.table.receptacleId': 'Receptacle Id',
    'app.tracking.timeline.event.detail.receptacle.table.receptacleClass': 'Receptacle Class',
    'app.tracking.timeline.event.detail.receptacle.table.receptacleType': 'Receptacle Type',
    'app.tracking.timeline.event.detail.receptacle.table.formatOfContents': 'Format Of Contents',
    'app.tracking.timeline.event.detail.receptacle.table.handlingClass': 'Handling class',
    'app.tracking.timeline.event.detail.receptacle.table.items': 'Items',
    'app.tracking.timeline.event.detail.receptacle.table.express': 'Exprès',
    'app.tracking.timeline.event.detail.receptacle.table.weight': 'Weight',
    'app.tracking.timeline.event.detail.receptacle.table.weightType': 'Weight Type',
    'app.tracking.timeline.event.detail.receptacle.table.exempt': 'Exempt',
    'app.tracking.timeline.event.detail.receptacle.table.eventTime': 'Event time',
    'app.tracking.timeline.event.detail.receptacle.table.conditionWhenReceived': 'Condition when received',
    'app.tracking.timeline.event.detail.receptacle.table.dangerousGoodsIndicator': 'Dangerous goods indicator',
    'app.tracking.timeline.event.detail.receptacle.table.container': 'Container',
    'app.tracking.timeline.event.detail.receptacle.table.noData': 'No receptacle data available',
    'app.tracking.timeline.event.detail.fulltransport.table.transportLegSequence': 'Seq',
    'app.tracking.timeline.event.detail.fulltransport.table.transportMode': 'Mode',
    'app.tracking.timeline.event.detail.fulltransport.table.carrierCodeAndConveyanceReference': 'Carrier / Conveyance',
    'app.tracking.timeline.event.detail.fulltransport.table.arrivalLocation': 'To',
    'app.tracking.timeline.event.detail.fulltransport.table.departureLocation': 'From',
    'app.tracking.timeline.event.detail.fulltransport.table.departureDate': 'Departure Date',
    'app.tracking.timeline.event.detail.fulltransport.table.arrivalDate': 'Arrival Date',
    'app.tracking.timeline.event.detail.fulltransport.table.parties': 'Parties',
    'app.tracking.timeline.event.detail.fulltransport.table.totalReceptacles': 'Receptacles',
    'app.tracking.timeline.event.detail.fulltransport.table.id': 'ID',
    'app.tracking.timeline.event.detail.fulltransport.table.eventDate': 'Event date',
    'app.tracking.timeline.event.detail.fulltransport.table.eventCode': 'Code',
    'app.tracking.timeline.event.detail.fulltransport.table.eventDescription': 'Description',
    'app.tracking.timeline.event.detail.fulltransport.table.handoverLocation': 'Place of handover',
    'app.tracking.timeline.event.detail.fulltransport.table.handoverPartnerId': 'Partner ID',
    'app.tracking.timeline.event.detail.fulltransport.table.noData': 'No transport data available.',
    'app.tracking.timeline.event.detail.container.table.containerType': 'Container Type',
    'app.tracking.timeline.event.detail.container.table.containerNumber': 'Container Number',
    'app.tracking.timeline.event.detail.container.table.containerNumberSource': 'Container number source',
    'app.tracking.timeline.event.detail.container.table.receptacles': 'Receptacles',
    'app.tracking.timeline.event.detail.container.table.weight': 'Weight',
    'app.tracking.timeline.event.detail.container.table.seal': 'Seal',
    'app.tracking.timeline.event.detail.container.table.journeyId': 'Journey ID',
    'app.tracking.timeline.event.detail.container.table.noData': 'No container data available.',
    'app.tracking.timeline.event.detail.overview.table.consignmentCompletionDate': 'Consignment completion date',
    'app.tracking.timeline.event.detail.overview.table.contractReference': 'Contract reference',
    'app.tracking.timeline.event.detail.overview.table.postalAirwaybill': 'Postal airwaybill',
    'app.tracking.timeline.event.detail.overview.table.securityStatus': 'Security status',
    'app.tracking.timeline.event.detail.overview.table.billingCarrier': 'Billing carrier',
    'app.tracking.timeline.event.detail.overview.table.consignmentOrigin': 'Consignment origin',
    'app.tracking.timeline.event.detail.overview.table.consignmentDestination': 'Consignment destination',
    'app.tracking.timeline.event.detail.overview.table.handoverOriginLocationCode': 'Handover origin location code',
    'app.tracking.timeline.event.detail.overview.table.handoverOriginLocationCutOff': 'Handover origin location cut-off',
    'app.tracking.timeline.event.detail.overview.table.handoverDestinationLocationCode': 'Handover destination location code',
    'app.tracking.timeline.event.detail.overview.table.handoverDestinationLocationCutOff': 'Handover destination location cut-off',
    'app.tracking.timeline.event.detail.overview.table.mailClass': 'Mail class',
    'app.tracking.timeline.event.detail.overview.table.receptacles': 'Receptacles ',
    'app.tracking.timeline.event.detail.overview.table.weight': 'Weight',
    'app.tracking.timeline.event.detail.overview.table.handoverLocationCodeSource': 'Handover location code source',
    'app.tracking.timeline.event.detail.overview.table.handoverLocationName': 'Handover location name',
    'app.tracking.timeline.event.detail.overview.table.partnerIdCode': 'Partner ID code',
    'app.tracking.timeline.event.detail.overview.table.partnerIdCodeSource': 'Partner ID code Source',
    'app.tracking.timeline.event.detail.overview.table.partnerIdName': 'Partner ID name',
    'app.tracking.timeline.event.detail.overview.table.handoverLocationCode': 'Handover location code',
    'app.tracking.timeline.event.detail.overview.table.departureLocationCode': 'Departure location code',
    'app.tracking.timeline.event.detail.overview.table.departureDateTime': 'Departure date time',
    'app.tracking.timeline.event.detail.overview.table.arrivalLocationCode': 'Arrival location code',
    'app.tracking.timeline.event.detail.overview.table.arrivalDateTime': 'Arrival date time',
    'app.tracking.timeline.event.detail.overview.table.noData': 'No overview data available',
    'app.tracking.timeline.event.detail.overview.table.serviceContractorPartyIdentifier': 'Service contractor party identifier',
    'app.tracking.timeline.event.detail.overview.table.attributesVersionNumber': 'Attributes version number',
    'app.tracking.timeline.event.detail.overview.table.dangerousGoodHazardClass': 'Dangerous good hazard class',
    'app.tracking.timeline.event.detail.overview.table.dangerousGoodTypeCode': 'Dangerous good type code',
    'app.tracking.timeline.event.detail.overview.table.declaredGrossWeight': 'Declared gross weight',
    'app.tracking.timeline.event.detail.overview.table.measuredGrossWeight': 'Measured gross weight',
    'app.tracking.timeline.event.detail.overview.table.insuredValueAmount': 'Insured value',
    'app.tracking.timeline.event.detail.overview.table.postagePaidAmount': 'Postage paid',
    'app.tracking.timeline.event.detail.overview.table.additionalFeesAmount': 'Adittional fees',
    'app.tracking.timeline.event.detail.overview.table.natureOfTransactionCode': 'Nature of transaction code',
    'app.tracking.timeline.event.detail.overview.table.natureOfTransactionDescription': 'Nature of transaction description',
    'app.tracking.timeline.event.detail.overview.table.customsProcedure': 'Custom procedure',
    'app.tracking.timeline.event.detail.overview.table.totalDeclaredValueAmount': 'Total declared value',
    'app.tracking.timeline.event.detail.overview.table.observations': 'Observations',
    'app.tracking.timeline.event.detail.overview.table.name': 'Name',
    'app.tracking.timeline.event.detail.overview.table.reference': 'Reference',
    'app.tracking.timeline.event.detail.overview.table.additionalData': 'Additional data',
    'app.tracking.timeline.event.detail.overview.table.premises': 'Premises',
    'app.tracking.timeline.event.detail.overview.table.localityCode': 'Locality code',
    'app.tracking.timeline.event.detail.overview.table.localityName': 'Locality name',
    'app.tracking.timeline.event.detail.overview.table.localityRegion': 'Locality region',
    'app.tracking.timeline.event.detail.overview.table.countryCode': 'Country code',
    'app.tracking.timeline.event.detail.overview.table.email': 'E-mail',
    'app.tracking.timeline.event.detail.overview.table.telephone': 'Telephone',
    'app.tracking.timeline.event.detail.overview.table.fax': 'Fax',
    'app.tracking.timeline.event.detail.overview.table.status': 'Status',
    'app.tracking.timeline.event.detail.overview.table.code': 'Code',
    'app.tracking.timeline.event.detail.overview.table.reason': 'Reason',
    'app.tracking.timeline.event.detail.overview.table.associatedEntityId': 'Associated entity ID',
    'app.tracking.timeline.event.detail.overview.table.associatedEntityType': 'Associated entity type',
    'app.tracking.timeline.event.detail.overview.table.locationFunction': 'Location function',
    'app.tracking.timeline.event.detail.overview.table.locationCode': 'Location code',
    'app.tracking.timeline.event.detail.overview.table.latestDate': 'Latest date',
    'app.tracking.timeline.event.detail.overview.table.referralReference': 'Referral Reference',
    'app.tracking.timeline.event.detail.overview.table.referralCode': 'Referral Code',
    'app.tracking.timeline.event.detail.overview.table.referralDateTime': 'Referral Date',
    'app.tracking.timeline.event.detail.overview.table.responseReference': 'Response Reference',
    'app.tracking.timeline.event.detail.overview.table.responseCode': 'Response Code',
    'app.tracking.timeline.event.detail.overview.table.responseDateTime': 'Response date',
    'app.tracking.timeline.event.detail.item.itmref.reasonCode': 'Reason Code',
    'app.tracking.timeline.event.detail.item.itmref.reasonText': 'Reason Text',
    'app.tracking.timeline.event.detail.item.refrsp.reasonCode': 'Reason Code',
    'app.tracking.timeline.event.detail.item.refrsp.actionTakenCode': 'Action Taken Code',
    'app.tracking.timeline.event.detail.item.refrsp.responseText': 'Response Text',
    'app.tracking.timeline.event.detail.item.refrsp.responseAuthority': 'Response Authority',
    'app.tracking.timeline.event.detail.item.documents.type': 'Type',
    'app.tracking.timeline.event.detail.item.documents.name': 'Name',
    'app.tracking.timeline.event.detail.item.documents.identifier': 'Identifier',
    'app.tracking.timeline.event.detail.item.documents.noData': 'No documents associated with event',
    'app.tracking.timeline.event.detail.item.content.number': 'Number',
    'app.tracking.timeline.event.detail.item.content.units': 'Number of units',
    'app.tracking.timeline.event.detail.item.content.description': 'Description',
    'app.tracking.timeline.event.detail.item.content.value': 'Declared value',
    'app.tracking.timeline.event.detail.item.content.weight': 'Net-weight',
    'app.tracking.timeline.event.detail.item.content.origin': 'Origin location code',
    'app.tracking.timeline.event.detail.item.content.tariff': 'Tariff heading',
    'app.tracking.timeline.event.detail.item.content.noData': 'No pieces associated with event',
    'app.tracking.timeline.event.detail.errorMessage': 'Cannot load the data due to an unexpected error.',
    'app.tracking.timeline.events.not.found': 'Events not found',
    'app.tracking.timeline.despatch.events.not.found': 'No messages available for this despatch',
    'app.tracking.timeline.despatch.items.showAll': 'Show all',
    'app.tracking.timeline.despatch.items.receptacle': 'Receptacle',
    'app.tracking.timeline.download': 'download',
    'app.tracking.timeline.edi.search': 'search',
    'app.tracking.timeline.edi.error': 'Content is not available.',
    'app.tracking.sorting.asc': 'Oldest to Most Recent',
    'app.tracking.sorting.desc': 'Most Recent to Oldest',
    'app.tracking.back': 'back',
    'app.networkError': 'Your session was lost or an unexpected error occurred. Please try again or contact support if the error persists.',
    'checkbox.select': 'Select All',
    'button.login': 'Login',
    'button.cancel': 'Cancel',
    'button.confirm': 'Confirm',
    'page.message.notFound': 'Page not found.',
    'menu.usermanagement': 'User Management',
    'administration.user.roles.create.title': 'Create Role',
    'administration.user.roles.name': 'Role name',
    'administration.user.roles.permissions': 'Permissions',
    'administration.user.roles.list.title': 'Roles',
    'administration.user.roles.list.table.nameCol': 'Name',
    'administration.user.roles.list.table.actionCol': 'Actions',
    'administration.user.roles.list.createButton': 'Create Role',
    'error.unexpected_error': 'Unexpected error.',
    'administration.user.roles.edit.title': 'Edit Role',
    'administration.user.roles.list.modalTitle': 'Warning',
    'administration.user.roles.list.modalContent': 'Do you really want to delete this role?',
    'administration.user.roles.list.modalCancel': 'Cancel',
    'administration.user.roles.list.modalOk': 'Confirm',
    'administration.user.roles.list.deleteSuccess': 'Role deleted with success',
    'administration.user.users.list.title': 'Users',
    'administration.user.users.list.table.usernameCol': 'Username',
    'administration.user.users.list.table.emailCol': 'Email',
    'administration.user.users.list.table.firstNameCol': 'First Name',
    'administration.user.users.list.table.lastNameCol': 'Last Name',
    'administration.user.users.list.details.username': 'Username',
    'administration.user.users.list.details.email': 'Email',
    'administration.user.users.list.details.firstName': 'First Name',
    'administration.user.users.list.details.lastName': 'Last Name',
    'administration.user.users.list.details.organizations': 'Organizations',
    'administration.user.users.list.details.roles': 'Roles',
    'administration.user.users.list.details.active': 'Active',
    'administration.user.users.list.search': 'Search User',
  },
};
