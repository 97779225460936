import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../../../api';
import { fetchItemFailureActionCreator, fetchItemSuccessActionCreator } from '../actions/ItemActions';
import { FETCH_ITEM_REQUEST } from '../actions/types/ItemActionTypes';

function* fetchItemWorker(action) {
  const {
    params: {
      itemId,
    },
  } = action;
  try {
    const res = yield call(api.fetchItem, itemId);
    yield put(fetchItemSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchItemFailureActionCreator(error));
  }
}

export function* fetchItemWatcher() {
  yield takeLatest(FETCH_ITEM_REQUEST, fetchItemWorker);
}

export default fetchItemWatcher;
