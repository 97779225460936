import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from '@ipc/ipc-core';
import { getViewAndIdentifierFromRoute } from '../../../utils';
import EventContentDetailsForConsignmentPage from '../../../../tracking/components/consignment/eventContentDetails/EventContentDetailsForConsignmentPage';
import EventContentDetailsForDespatchPage from '../../../../tracking/components/despatch/eventContentDetails/EventContentDetailsForDespatchPage';
import EventContentDetailsForItemPage from '../../../../tracking/components/item/eventContentDetails/EventContentDetailsForItemPage';
import EventContentDetailsForReceptaclePage from '../../../../tracking/components/receptacle/eventContentDetails/EventContentDetailsForReceptaclePage';

const viewMap = {
  item: (props) => <EventContentDetailsForItemPage id="eventContentDetailsForItemPage" {...props} />,
  receptacle: (props) => <EventContentDetailsForReceptaclePage id="eventContentDetailsForReceptaclePage" {...props} />,
  despatch: (props) => <EventContentDetailsForDespatchPage id="eventContentDetailsForDespatchPage" {...props} />,
  consignment: (props) => <EventContentDetailsForConsignmentPage id="eventContentDetailsForConsignmentPage" {...props} />,
};

export const TimelineEventContentDetails = (props) => {
  const { view } = getViewAndIdentifierFromRoute(props.match);

  return viewMap[view]({ ...props, view });
};

export default injectIntl(withRouter(TimelineEventContentDetails));
