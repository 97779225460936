import { find, isEqual, isObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Gray2 } from '../../../elements/Colors';

const isInputEvent = (value) => value && isObject(value) && isObject(value.target);

function useSwitchGroup(props) {
  const { defaultValue, value: valueProp, onChange: onChangeProp } = props;

  const [valueState, setValue] = useState(defaultValue || []);
  const { current: isControlled } = useRef(valueProp !== undefined);
  const value = isControlled && typeof valueProp !== 'undefined' ? valueProp : valueState;

  const addValue = (newValue) => [...value, newValue];
  const removeValue = (valueToRemove) => value.filter((item) => !isEqual(item, valueToRemove));

  const updateValue = (nextState) => {
    if (!isControlled) {
      setValue(nextState);
    }

    if (onChangeProp) onChangeProp(nextState);
  };

  const onChange = (eventOrValue) => {
    const isToggled = isInputEvent(eventOrValue) ? eventOrValue.target.checked : !find(value, (checkedValue) => isEqual(checkedValue, eventOrValue));
    const selectedValue = isInputEvent(eventOrValue) ? eventOrValue.target.value : eventOrValue;

    const nextValue = isToggled
      ? addValue(selectedValue)
      : removeValue(selectedValue);

    updateValue(nextValue);
  };

  return {
    value,
    onChange,
    setValue: updateValue,
  };
}

const StyledSwitchGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  & .MuiFormControlLabel-root {
      margin-left: 0;
      margin-right: 5px;
  }
  
  & label {
    border-radius: 5px;
    border: 1px solid ${Gray2};
    
    & .MuiFormControlLabel-label {
      margin: 0 9px;      
    }
  }
`;

function SwitchGroup(props) {
  const { children, className } = props;
  const { value, onChange } = useSwitchGroup(props);

  return (
    <StyledSwitchGroup className={className}>
      {children ? React.Children.map(children, (child) => {
        const handleChange = (event) => onChange(child.props.value ? child.props.value : event);
        const isChecked = !!find(value, (checkedValue) => isEqual(checkedValue, child.props.value));

        return React.cloneElement(child, {
          onChange: handleChange,
          checked: isChecked,
        });
      }) : null}
    </StyledSwitchGroup>
  );
}

SwitchGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SwitchGroup;
