import PropTypes from 'prop-types';
import React from 'react';
import { VALUE_IF_ABSENT } from '../../../../common/constants/Constants';

const HeaderCollapsableTransport = (props) => {
  const { transportInfoType } = props;
  let firstTitleHeader;
  let secondTitleHeader;
  switch (transportInfoType) {
  case 'predesResdesTransportInfo':
    firstTitleHeader = 'PREDES';
    secondTitleHeader = 'RESDES';
    break;
  case 'carditResditTransportInfo':
    firstTitleHeader = 'CARDIT';
    secondTitleHeader = 'RESDIT';
    break;
  case 'preconResconTransportInfo':
    firstTitleHeader = 'PRECON';
    secondTitleHeader = 'RESCON';
    break;
  default:
    firstTitleHeader = VALUE_IF_ABSENT;
    secondTitleHeader = VALUE_IF_ABSENT;
    break;
  }

  return (
    <div id="collapsableFullTransportHeader">
      <span>{firstTitleHeader}</span>
      <span> / </span>
      <span className="high-light-header">{secondTitleHeader}</span>
    </div>
  );
};

HeaderCollapsableTransport.defaultProps = {

};

HeaderCollapsableTransport.propTypes = {
  transportInfoType: PropTypes.string,
};

export default HeaderCollapsableTransport;
