import { all, call, spawn } from 'redux-saga/effects';
import {
  consignmentSagas, despatchSagas, ediSagas, eventInfoSagas, itemSagas, receptacleSagas, searchSaga,
} from './modules/tracking/redux/sagas/index';
import logger from './utils/logger';

function* sagas() {
  const allSagas = [
    searchSaga,
    itemSagas,
    ediSagas,
    receptacleSagas,
    despatchSagas,
    consignmentSagas,
    eventInfoSagas,
  ];

  yield all(allSagas.map((saga) => spawn(function* () {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        logger.log(e);
      }
    }
  })));
}

export default sagas;
