import axios from 'axios';
import { constructServiceURL } from '../../core/modules/common/tools/URLTools';
import config from '../../config/configureSettings';

export const fetchEdiRaw = ({ key, source }) => axios.get(constructServiceURL({
  endpoint: 'fetchEdiRaw',
  stub: config.useStubbedEndpoints,
  queryStringParams: { source },
  params: { key },
}));

export const fetchEdiFormatted = ({ key, source }) => axios.get(constructServiceURL({
  endpoint: 'fetchEdiFormatted',
  stub: config.useStubbedEndpoints,
  queryStringParams: { source },
  params: { key },
}));

export const fetchEdi = async ({ eventKey, source, isFormatted }) => {
  const params = { key: eventKey, source };

  if (isFormatted) {
    return fetchEdiFormatted(params);
  }
  return fetchEdiRaw(params);
};

export const fetchPodEdi = async ({ receptacleId, handoverTime, impcCode }) => axios.get(constructServiceURL({
  endpoint: 'fetchPodEdi',
  stub: config.useStubbedEndpoints,
  params: { receptacleId, handoverTime, impcCode },
}));

export const fetchItmattEvent = async ({ eventKey, aggregateId, itemId }) => axios.get(constructServiceURL({
  endpoint: 'fetchEdiItmattEvent',
  stub: config.useStubbedEndpoints,
  params: { rawEventKey: eventKey, aggregateId, itemId },
}));
