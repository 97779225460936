import { REDIRECT_DONE, REDIRECT_INIT } from './types/redirectActionTypes';

export const getRedirectInit = (url) => ({
  type: REDIRECT_INIT,
  payload: url,
});

export const getRedirectDone = () => ({
  type: REDIRECT_DONE,
});
