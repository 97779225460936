import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from '@ipc/ipc-core';
import { date } from '../../../../react-table/helpers/fieldFormatter';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import { GeneralInfo } from '../../../../common/components/GeneralInfo';

const PredesGeneralInfo = (props) => {
  const { event, event: { despatchId } } = props;

  function getGeneralInfoFields() {
    if (!_.isEmpty(event)) {
      return [
        {
          className: 'despatch-id',
          intlID: 'app.tracking.timeline.event.details.despatch.id',
          value: despatchId && (<Link id="despatchIdLink" to={getUrl('despatch', despatchId)}>{despatchId}</Link>),
        },
        {
          className: 'interchange-reference',
          intlID: 'app.tracking.timeline.event.details.interchangeReference',
          value: event.messageInterchangeReference,
        },
        {
          className: 'reference',
          intlID: 'app.tracking.timeline.event.details.reference',
          value: event.messageReference,
        },
        {
          className: 'status',
          intlID: 'app.tracking.timeline.event.details.status',
          value: event.messageStatus,
        },
        {
          className: 'sender',
          intlID: 'app.tracking.timeline.event.details.sender',
          value: event.sender,
        },
        {
          className: 'receiver',
          intlID: 'app.tracking.timeline.event.details.receiver',
          value: event.receiver,
        },
        {
          className: 'close-date',
          intlID: 'app.tracking.timeline.event.details.closedDate',
          value: date({ value: event.despatchClosedDate }),
        },
        {
          className: 'preparation-date',
          intlID: 'app.tracking.timeline.event.details.preparation.date',
          value: date({ value: event.messagePreparationDate }),
        },
        {
          className: 'common-event-field',
          intlID: 'app.tracking.timeline.event.details.network.date',
          value: date({ value: event.onNetworkTime }),
        },
        {
          className: 'load-date',
          intlID: 'app.tracking.timeline.event.details.loadDate',
          value: date({ value: event.messageLoadDate }),
        },
        {
          className: 'total-receptacles-advised',
          intlID: 'app.tracking.timeline.event.details.advised',
          value: event.totalReceptaclesAdvised,
        },
        {
          className: 'total-receptacles-scanned',
          intlID: 'app.tracking.timeline.event.details.scanned',
          value: event.totalReceptaclesScanned,
        },
      ];
    }
    return [];
  }

  return (
    <GeneralInfo
      infoFields={getGeneralInfoFields()}
      event={event}
      fullDetails
    />
  );
};

PredesGeneralInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};

PredesGeneralInfo.defaultProps = {
  event: {},
};

export default PredesGeneralInfo;
