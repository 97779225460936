import { combineReducers } from 'redux';
import { search } from './SearchReducers';
import itemReducer from './ItemReducers';
import ediReducer from './EdiReducer';
import receptacle from './ReceptacleReducers';
import consignment from './ConsignmentReducers';
import despatch from './DespatchReducers';

export const tracking = combineReducers({
  search,
  item: itemReducer,
  edi: ediReducer,
  receptacle,
  consignment,
  despatch,
});
