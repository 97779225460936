const s10Format = new RegExp(/^[A-Z]{2}\d{9}[A-Z]{2}$/i);
const s9Format = new RegExp(/^[A-Z]{15}\d{14}$/i);
const s8Format = new RegExp(/^[A-Z]{15}\d{5}$/i);
const s32Format = new RegExp(/^[a-zA-Z]{2}\w{10}$/);
const knownServiceIndicators = [
  'A[A-Z]',
  'B[A-Z]',
  'C[A-Z]',
  'D[A-Z]',
  'E[A-Z]',
  'GA',
  'GD',
  'L[A-Z]',
  'M[A-Z]',
  'N[A-Z]',
  'P[A-Z]',
  'Q[A-M]',
  'R[A-Z]',
  'S[A-E]',
  'U[A-Z]',
  'V[A-Z]',
  'Z[A-Z]',
];

export const calculateCheckDigit = (serialNumber) => {
  const weights = [8, 6, 4, 2, 3, 5, 9, 7];

  const sum = serialNumber
    .split('')
    .reduce((element, val, index) => element + (parseInt(val, 10) * weights[index]), 0);

  const remainder = sum % 11;
  let checkDigit = 11 - remainder;

  if (checkDigit === 11) {
    checkDigit = 5;
  }

  if (checkDigit === 10) {
    checkDigit = 0;
  }

  return checkDigit;
};

export const validateS8 = (S8string) => {
  if (!S8string || S8string.length !== 20) {
    return false;
  }
  return s8Format.test(S8string);
};

export const validateS9 = (S9string) => {
  if (!S9string || S9string.length !== 29) {
    return false;
  }
  return s9Format.test(S9string);
};

export const validateS10 = (s10string) => {
  if (!s10string || s10string.length !== 13) {
    return false;
  }
  const validateFormat = (s10) => s10Format.test(s10);
  const validateCheckDigit = (s10) => {
    const serialNumber = s10.substring(2, 10);
    const actualCheckDigit = parseInt(s10.substring(10, 11), 10);

    return actualCheckDigit === calculateCheckDigit(serialNumber);
  };
  const validateServiceIndicator = (s10) => {
    const serviceIndicator = s10.substring(0, 2);
    const knownServiceIndicatorsRegex = new RegExp(knownServiceIndicators.join('|'));

    return knownServiceIndicatorsRegex.test(serviceIndicator);
  };
  return validateFormat(s10string)
    && validateCheckDigit(s10string)
    && validateServiceIndicator(s10string);
};

export const validateS32 = (s32string) => s32Format.test(s32string);
