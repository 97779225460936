import { css } from 'styled-components';

const borderRight = (value) => `border-top-right-radius: ${value};border-bottom-right-radius: ${value};`;
const borderLeft = (value) => `border-top-left-radius: ${value};border-bottom-left-radius: ${value};`;

export const SelectStyle = css`
  &&.MuiInputBase-root {
    background-color: ${({ theme }) => theme.colors.white};
    ${borderRight('0')};
    width: 30%;
  }
`;

export const InputStyles = css`
  flex-grow: 1;

  &.MuiInputBase-root {
    ${borderLeft('0')};    
    margin: 0 15px 0 15px;
  }

  & .MuiOutlinedInput-input {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.tertiary};

    ${borderLeft('0')};
    ${({ withSearchIcon, iconSpacing }) => borderRight(withSearchIcon && iconSpacing === '0' ? '0' : '4px')};
  }
`;

export const IconStyle = css`
  flex-grow: 1;
  height: 30px;
  width: 200px;
`;
