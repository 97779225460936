import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { consignmentTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';
import { separatedBySlash } from '../../../../tracking/helpers/SeparatedBySlash';

function generateExcelWorkbookForConsignmentPage(pFilteredEvents, pConsignment) {
  const consignment = pConsignment
    ? { ...pConsignment }
    : { consignmentId: 'N/A' };

  const consignmentTemplateFile = base64.decode(consignmentTemplate);
  const template = new XlsxTemplate(consignmentTemplateFile);

  const dateFormatter = fieldFormatter('date');

  const events = {
    events: _.map(pFilteredEvents, (event) => ({
      date: dateFormatter({ value: event.eventDate }),
      type: event.eventName,
      description: event.eventDescription,
      location: event.eventLocation ? event.eventLocation.location : '',
      sender: event && separatedBySlash(event.senderMailbox, event.originPostalOperatorCode),
      receiver: event && separatedBySlash(
        event.recipientMailbox,
        event.destinationPostalOperatorCode,
      ),
    })),
  };

  template.substitute('Consignment', consignment);
  template.substitute('Events', events);
  return template.generate();
}

export default generateExcelWorkbookForConsignmentPage;
