import React from 'react';
import { useTranslator } from '../../../../hooks';
import { searchResultType } from '../../../../types';
import { containsType } from './SearchHelper';
import SearchResultReceptacle from './SearchResultReceptacle';
import SearchResultTypes from './SearchResultTypes';
import SingleResult from './SingleResult';

const {
  ITEM, RECEPTACLE, RECEPTACLE_CORE, DESPATCH, CONSIGNMENT,
} = SearchResultTypes;

export const SearchResultDetail = (props) => {
  const { searchResult } = props;
  const { identifier, types } = searchResult;
  const { translate } = useTranslator('app.tracking.search');

  return (
    <div className="search-result-panel">
      <h2>{translate('results')}</h2>
      {containsType(types, ITEM) && <SingleResult type="item" searchResultId={identifier}>Item</SingleResult>}
      {(containsType(types, RECEPTACLE) || containsType(types, RECEPTACLE_CORE)) && <SearchResultReceptacle searchResult={searchResult}>Receptacle</SearchResultReceptacle>}
      {containsType(types, DESPATCH) && <SingleResult type="despatch" searchResultId={identifier}>Despatch</SingleResult>}
      {containsType(types, CONSIGNMENT) && <SingleResult type="consignment" searchResultId={identifier}>Consignment</SingleResult>}
    </div>
  );
};

SearchResultDetail.propTypes = {
  searchResult: searchResultType,
};

export default SearchResultDetail;
