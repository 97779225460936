/* eslint-disable react/prop-types */
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from '@ipc/ipc-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { supportedLanguages, defaultLanguage } from '../../locales/locales';
import './languageSwitcher.scss';

export const LanguageSwitcherComponent = ({ history, currentLanguage = defaultLanguage, languages = supportedLanguages }) => (
  languages.length > 1
  && (
    <div className="language-picker">
      <div className="active-locale">
        <span>{currentLanguage}</span>
        <FontAwesomeIcon icon={faChevronDown} className="icon" />
      </div>

      <div className="languages">
        <div className="language-list">
          {languages.map((lang) => {
            const activeLocaleClass = currentLanguage === lang ? ' active' : '';

            return (
              <div
                id={lang}
                key={lang}
                className={`choice${activeLocaleClass}`}
              >
                <a
                  id={`link_${lang}`}
                  onClick={() => { history.push(history.location.pathname.replace(`/${currentLanguage}`, `/${lang}`)); }}
                >
                  {lang}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
);

LanguageSwitcherComponent.propTypes = {
  history: PropTypes.shape({}),
  currentLanguage: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
};

export default withRouter(LanguageSwitcherComponent);
