/* eslint-disable no-restricted-syntax */
import qs from 'qs';
import config from '../../../../config/configureSettings';
import { getLanguageFromURL } from './Internationalization';

const parameterFormat = new RegExp(/{([a-zA-Z0-9]*)}/g);

const getEndpoint = (endpoint) => config.endpoints[endpoint];

const getURLParameterArray = (url) => url.match(parameterFormat);

const replaceURLParam = (url, paramId, value) => url.replace(paramId, value);

export const replaceURLNamedParameters = (url, object) => {
  const argumentObject = object;
  let newURL = url;

  for (const key in argumentObject) {
    if (argumentObject.hasOwnProperty(key)) {
      newURL = replaceURLParam(newURL, `{${key}}`, argumentObject[key]);
    }
  }

  return newURL;
};

export const populatePathParameters = (path, object) => {
  const argumentObject = object;
  let newPath = path;

  for (const key in argumentObject) {
    if (argumentObject.hasOwnProperty(key)) {
      newPath = replaceURLParam(newPath, `:${key}`, argumentObject[key]);
    }
  }

  return newPath;
};

export const populateURLParameters = (url, parameters) => {
  const urlParams = getURLParameterArray(url) || [];
  const params = parameters || [];
  let newURL = url;

  if (urlParams.length > 0 && params.length > 0) {
    for (let parameterIndex = 0; parameterIndex < params.length; parameterIndex += 1) {
      const argument = params[parameterIndex];

      switch (typeof argument) {
      case 'object':
        newURL = replaceURLNamedParameters(newURL, argument);
        break;
      default:
        newURL = replaceURLParam(newURL, urlParams[parameterIndex], argument);
        break;
      }
    }
  }

  return newURL;
};

const isObjectEmpty = (obj) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return true;
};

/**
 * @param {Object} options
 * @param {Object|string} paramList - contains either strings or objects,
 *  strings replaced by their argument index, object keys used to replace named URL params
 */
export function constructServiceURL(options, ...paramList) {
  const {
    endpoint = 'endpointIdFromConfig',
    stub = false,
    queryStringParams = {},
    host = config.host,
    params = {},
    convertToExpressParams = false,
  } = options;

  if (!stub && !config.host) {
    throw new Error(`define a host in your src/config/${config.env}.json`);
  }

  let url = getEndpoint(endpoint);
  if (!stub) {
    url = host + url;
  }

  if (!isObjectEmpty(queryStringParams)) {
    url = `${url}?${qs.stringify(queryStringParams)}`;
  }

  if (convertToExpressParams) {
    const parameterArray = (getURLParameterArray(url) || [])
      .map((param) => param.replace(parameterFormat, '$1'));

    const paramsObjectByParamName = parameterArray
      .reduce((result, param) => {
        const newResult = result;
        newResult[param] = `:${param}`;
        return newResult;
      }, {});

    return populateURLParameters(url, [paramsObjectByParamName]);
  }
  return populateURLParameters(url, [...paramList, params]);
}

/**
 * @param {String} path - add basePath to resources and links, f.e. in tags; Link, img
 * @param {Object|string} params - Either named params in an object to
 *  replace {param} or based on argument index position
 */
export const constructPath = (path, ...params) => populatePathParameters(
  config.basePath + path,
  params,
);

export const constructPublicAssetPath = (path, ...params) => populatePathParameters(
  config.publicAssetPath + path,
  params,
);

export const getUrl = (...params) => {
  const url = `${getLanguageFromURL()}/${params.join('/')}`;
  return constructPath(url);
};
