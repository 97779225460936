import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Icons from '../../../../../components/common/Icons';
import Button from '../../../../../components/common/Button';
import IconText from '../../../../../components/common/IconText';
import CollapsableReceptacle from './CollapsableReceptacle';

class ItemsByReceptacles extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    showAll: this.props.showAllCollapsables,
  }

  getFallbackMessage() {
    const { isLoaded } = this.props;
    return isLoaded
      ? <FormattedMessage id="app.tracking.timeline.event.detail.receptacle.table.noData" />
      : <FormattedMessage id="app.loading" />;
  }

  getReceptacleList() {
    const {
      receptacles, items, isLoaded, error, showAllCollapsables,
    } = this.props;

    return receptacles.map((receptacle, idx) => {
      const itemsFound = items.filter((item) => item.receptacleId === receptacle.receptacleId);
      return (
        <CollapsableReceptacle
          key={receptacle.receptacleId}
          receptacle={receptacle}
          items={itemsFound}
          isLoaded={isLoaded}
          isCollapsed={!showAllCollapsables && idx !== 0}
          error={error}
        />
      );
    });
  }

  handleShowAll = () => {
    const { showAll } = this.state;
    this.setState({
      showAll: !showAll,
    });
  }

  render() {
    const receptacleList = this.getReceptacleList();

    const { showAll } = this.state;
    return (
      <div>
        {receptacleList.length > 0 ? (
          <div>
            {receptacleList[0]}
            {showAll && receptacleList.slice(1)}
            {!showAll && receptacleList.length > 1
              && (
                <Button
                  id="showAllButton"
                  afterText={(
                    <small className="after-text">
                      (
                      {receptacleList.length}
                      )
                    </small>
                  )}
                  onClick={this.handleShowAll}
                >
                  <IconText icon={Icons.PlusIcon} labelId="app.tracking.timeline.despatch.items.showAll" />
                </Button>
              )}
          </div>
        ) : this.getFallbackMessage()}

      </div>
    );
  }
}

ItemsByReceptacles.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape),
  receptacles: PropTypes.arrayOf(PropTypes.shape),
  isLoaded: PropTypes.bool,
  showAllCollapsables: PropTypes.bool,
  error: PropTypes.bool,
};

ItemsByReceptacles.defaultProps = {
  items: [],
  receptacles: [],
  isLoaded: false,
  showAllCollapsables: false,
  error: false,
};

export default ItemsByReceptacles;
