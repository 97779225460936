import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from '@ipc/ipc-core';
import { injectIntl, FormattedMessage } from 'react-intl';
import noop from '../../../../../core/modules/common/tools/noop';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import { date } from '../../../../react-table/helpers/fieldFormatter';
import { VALUE_IF_ABSENT } from '../../../../common/constants/Constants';
import EventContentEdiButton from '../../../../common/components/timeline/eventContentEdi/EventContentEdiButton';

function getGeneralInfoFields(event) {
  if (!_.isEmpty(event)) {
    return [
      {
        className: 'sender',
        intlID: 'app.tracking.timeline.event.details.sender',
        value: event.sender,
      },
      {
        className: 'receiver',
        intlID: 'app.tracking.timeline.event.details.receiver',
        value: event.receiver,
      },
      {
        className: 'preparation-date',
        intlID: 'app.tracking.timeline.event.details.preparation.date',
        value: date({ value: event.messagePreparationDate }),
      },
      {
        className: 'common-event-field',
        intlID: 'app.tracking.timeline.event.details.network.date',
        value: date({ value: event.onNetworkTime }),
      },
    ];
  }
  return [];
}

export const DespatchEventGeneralInfo = ({ event, showEDI, toggleEventEDI }) => {
  const infoFields = useMemo(() => getGeneralInfoFields(event), [event]);

  return (
    <div className="general-info-container hide-inner-border general-info-timeline">
      <div className="general-info-wrapper">

        {infoFields.map((field) => (
          <div className={`general-info-field ${field.className}`} key={field.className}>
            <div className="general-info-title" key={field.eventName}>
              <FormattedMessage id={field.intlID} key={field.eventName} />
            </div>
            <div className="general-info-value" key={field.eventName}>
              {field.value || VALUE_IF_ABSENT}
            </div>
          </div>
        ))}

        <div className="general-info-event-id">
          <div className="general-info-field despatch-id">
            <div className="general-info-title">
              <FormattedMessage id="app.tracking.timeline.event.details.despatch.id" />
            </div>
            <div className="general-info-value">
              {event.despatchId ? (
                <Link id="despatchIdLink" to={getUrl('despatch', event.despatchId)}>
                  {event.despatchId}
                </Link>
              ) : VALUE_IF_ABSENT}
            </div>
          </div>
          {
            event.rawEventKey
            && (
              <div className="general-info-field edi-button">
                <EventContentEdiButton
                  id="eventContentEdiButton"
                  showEDI={showEDI}
                  toggleEventEDI={toggleEventEDI}
                />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

DespatchEventGeneralInfo.propTypes = {
  event: PropTypes.shape({
    despatchId: PropTypes.string,
    messagePreparationDate: PropTypes.string,
    sender: PropTypes.string,
    receiver: PropTypes.string,
    rawEventKey: PropTypes.string,
  }).isRequired,
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

DespatchEventGeneralInfo.defaultProps = {
  showEDI: false,
  toggleEventEDI: noop,
};

export default injectIntl(DespatchEventGeneralInfo);
