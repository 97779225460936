import { createTheme } from '@mui/material/styles';
import { themeConfig } from '@ipc/ipc-components';
import StyledComponentTheme from './styled-component-theme';
import { Blue } from '../Colors';

const MUITheme = createTheme({
  ...themeConfig,
  palette: {
    ...themeConfig?.palette,
    primary: { main: Blue },
  },
  typography: {
    ...themeConfig?.typography,
    fontSize: 14,
    fontSizes: {
      ...themeConfig?.typography?.fontSizes,
      bodySmall: '14px',
    },
    body1: {
      ...themeConfig?.typography?.body1,
      fontSize: '14px',
    },
    body2: {
      ...themeConfig?.typography?.body2,
      fontSize: '14px',
    },
    label: {
      ...themeConfig?.typography?.label,
      fontSize: '14px',
      textTransform: 'capitalize',
    },
  },
});

const theme = {
  mui: MUITheme,
  ...StyledComponentTheme,
};

export * from './utils';
export default theme;
