import React from 'react';
import { Loading } from '../components/common/ipc-components/Loading';
import { getSimpleEventName } from '../utils/events';
import { SecurePage, NotAuthorizedRoute } from './page';
import Permission from './permission';

const getPermissionDetailsForEventName = (eventName) => Permission[`READ_${eventName}_DETAILS`];

export const getPermissionDetailForEvent = (event) => getPermissionDetailsForEventName(getSimpleEventName(event));

export const secureFullDetails = (FullDetails, event) => {
  if (event?.hasOwnProperty('eventName')) {
    return (
      <SecurePage roles={[getPermissionDetailForEvent(event)]}>
        <FullDetails event={event} />
      </SecurePage>
    );
  }

  if (event?.error) {
    return <NotAuthorizedRoute errorMessage={event.errorMessage} />;
  }

  return <Loading />;
};
