import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { receptacleTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';
import { separatedBySlash } from '../../../../tracking/helpers/SeparatedBySlash';

function generateExcelWorkbookForReceptaclePage(pFilteredEvents, pReceptacle) {
  let receptacle;
  let events;
  if (!pReceptacle) {
    receptacle = {
      receptacleId: 'N/A',
      senderMailbox: 'N/A',
      originPostalOperatorCode: 'N/A',
      recipientMailbox: 'N/A',
      destinationPostalOperatorCode: 'N/A',
      mailCategory: 'N/A',
      mailSubclass: 'N/A',
      receptacleSerialNumber: 'N/A',
      highestNumberedReceptacleIndicator: 'N/A',
      registeredInsuredIndicator: 'N/A,',
      weight: 'N/A',
    };
  } else {
    receptacle = { ...pReceptacle };
  }

  events = pFilteredEvents;

  const receptacleTemplateFile = base64.decode(receptacleTemplate);
  const template = new XlsxTemplate(receptacleTemplateFile);

  const dateFormatter = fieldFormatter('date');

  events = {
    events: _.map(pFilteredEvents, (event) => ({
      date: dateFormatter({ value: event.eventDate }),
      type: event.eventName,
      description: event.eventDescription,
      location: event.eventLocation ? event.eventLocation.location : '',
      sender: event && separatedBySlash(event.senderMailbox, event.originPostalOperatorCode),
      receiver: event && separatedBySlash(
        event.recipientMailbox,
        event.destinationPostalOperatorCode,
      ),
    })),
  };

  template.substitute('Receptacle', receptacle);
  template.substitute('Events', events);
  return template.generate();
}

export default generateExcelWorkbookForReceptaclePage;
