import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { VALUE_IF_ABSENT } from '../../../../common/constants/Constants';
import { date } from '../../../../react-table/helpers/fieldFormatter';

function getGeneralInfoFields(event) {
  if (!_.isEmpty(event)) {
    return [{
      className: 'common-event-field',
      intlID: 'app.tracking.timeline.event.details.network.date',
      value: date({ value: event.onNetworkTime }),
    }];
  }
  return [];
}

function RfIdEventGeneralInfo({ event }) {
  const infoFields = useMemo(() => getGeneralInfoFields(event), [event]);

  return (
    <div className="general-info-container hide-inner-border general-info-timeline">
      <div className="general-info-wrapper">
        {infoFields.map((field) => (
          <div className={`general-info-field ${field.className}`} key={field.className}>
            <div className="general-info-title" key={field.eventName}>
              <FormattedMessage id={field.intlID} key={field.eventName} />
            </div>
            <div className="general-info-value" key={field.eventName}>
              {field.value || VALUE_IF_ABSENT}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

RfIdEventGeneralInfo.propTypes = {
  event: PropTypes.shape({
    onNetworkTime: PropTypes.string,
  }),
};

export default RfIdEventGeneralInfo;
