import { Loading as IpcLoading, Flex, Box } from '@ipc/ipc-components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useTranslator } from '../../../../hooks';

import { LoginContainerStyle } from './index.styles';

const LoadingContainer = styled.div`
  ${LoginContainerStyle}
`;

const FullScreenLoading = ({
  loading, color = 'primary', ...rest
}) => (
  loading && (
    <LoadingContainer color={color}>
      <IpcLoading color="inherit" {...rest} />
    </LoadingContainer>
  )
);

FullScreenLoading.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
};

export const Loading = (props) => {
  const { translate } = useTranslator();
  return (
    <Flex align="center" {...props}>
      <IpcLoading size={20} thickness={5} color="primary" />
      <Box ml="10px">{translate('app.loading')}</Box>
    </Flex>
  );
};

export default FullScreenLoading;
