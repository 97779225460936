import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { fullDetailsTransportForConsignmentTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

function generateExcelWorkbookTransportForConsignmentFullDetails(legs) {
  const fullDetailsTransportForConsignmentTemplateFile = base64
    .decode(fullDetailsTransportForConsignmentTemplate);
  const template = new XlsxTemplate(fullDetailsTransportForConsignmentTemplateFile);
  const dateFormatter = fieldFormatter('date');

  const transportLegs = {
    transportLegs: _.map(legs, (leg) => ({
      transportLegNumber: leg.transportLegNumber,
      transportStageQualifier: leg.transportStageQualifier,
      transportMode: leg.transportMode,
      carrierCode: leg.carrierCode,
      carrierCodeSource: leg.carrierCodeSource,
      carrierName: leg.carrierName,
      conveyanceReference: leg.conveyanceReference,
      departureLocationCode: leg.departureLocationCode,
      departureLocationName: leg.departureLocationName,
      departureLocationCodeSource: leg.departureLocationCodeSource,
      departureDate: dateFormatter({ value: leg.departureDate }, null, ''),
      arrivalLocationCode: leg.arrivalLocationCode,
      arrivalLocationCodeSource: leg.arrivalLocationCodeSource,
      arrivalLocationName: leg.arrivalLocationName,
      arrivalDate: dateFormatter({ value: leg.arrivalDate }, null, ''),
      transportLegRateCode: leg.transportLegRateCode,
      transportLegContract: leg.transportLegContract,
    })),
  };
  template.substitute('Transport', transportLegs);
  return template.generate();
}

export default generateExcelWorkbookTransportForConsignmentFullDetails;
