import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  expandSidebar: true,
  sidebarSearchString: '',
};

export const SidebarContext = createContext();

const SidebarProvider = ({ children }) => {
  const [sidebarState, setSidebarState] = useState(defaultState);

  const setExpandSidebar = (value) => {
    setSidebarState({ ...sidebarState, expandSidebar: value });
  };

  const setSearchSidebar = (value) => {
    setSidebarState({ ...sidebarState, sidebarSearchString: value });
  };

  return (
    <SidebarContext.Provider value={{ ...sidebarState, setExpandSidebar, setSearchSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node,
};

export default SidebarProvider;
