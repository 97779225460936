import { STORE_SELECTED_LANGUAGE } from '../actions/types/languageActionTypes';

export default (state = { lang: process.env.defaultLanguage }, action) => {
  switch (action.type) {
  case STORE_SELECTED_LANGUAGE:
    return { lang: action.payload };
  default:
    return state;
  }
};
