import { DO_SEARCH_FAILURE, DO_SEARCH_REQUEST, DO_SEARCH_SUCCESS } from './types/SearchActionTypes';

export function doSearchRequestActionCreator(params) {
  return {
    type: DO_SEARCH_REQUEST,
    params,
  };
}

export function doSearchSuccessActionCreator(payload) {
  return {
    type: DO_SEARCH_SUCCESS,
    payload,
  };
}

export function doSearchFailureActionCreator(error) {
  return {
    type: DO_SEARCH_FAILURE,
    payload: error,
    error: true,
  };
}
