import PropTypes from 'prop-types';
import React, { useRef, useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Flex, Box, Input } from '@ipc/ipc-components';
import { Loading } from '../../../../../components/common/ipc-components/Loading';
import Button from '../../../../../components/common/Button';
import Highlight from '../../../../../components/common/Highlight';
import noop from '../../../../../core/modules/common/tools/noop';
import { getPositioningStyle } from './EventContentEdiCalculator.helper';
import TimelineEventContentEdiToggle from './EventContentEdiToggle';
import { occurrences } from '../../../utils';
import { VALUE_IF_ABSENT } from '../../../constants/Constants';

const CenteredSpan = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const EDI_CONTENT_HEIGHT = 744;
const DESKTOP_MIN_WIDTH = 1580;

const getStyle = (clientWidth, clientHeight, eventId) => {
  const eventContent = document.querySelector(`.event-content[event-id='${eventId}']`);

  if (eventContent && clientWidth > DESKTOP_MIN_WIDTH - 1) {
    const eventContentValues = eventContent.getBoundingClientRect();
    return getPositioningStyle(clientHeight, eventContentValues, EDI_CONTENT_HEIGHT);
  }
  return { top: 0 };
};

function EventContentEdi(props) {
  const {
    ediMessage,
    isEdiMessageLoading,
    event,
    toggleEventEDI,
    intl,
    searchTerm = '',
    isTextFormatted,
    hasError,
    errorMessage,
    showEdiToggle,
    onToggle,
    onSearch,
  } = props;
  const ediContentWindowStyle = useRef(getStyle(window.innerWidth, window.innerHeight, event.aggregateId)).current;

  const handleToggle = () => {
    onToggle(!isTextFormatted);
  };

  const handleSearch = ({ target }) => {
    onSearch(target.value);
  };

  const searchTextInt = intl.formatMessage({ id: 'app.tracking.timeline.edi.search' });
  const mesref = event.messageReference;
  const type = event.messageType;
  const version = event.messageTypeVersion;
  const classFormattedOrRaw = isTextFormatted ? 'formatted' : 'raw';
  const ediMessageClass = `event-content-edi-message ${classFormattedOrRaw}`;
  const searchHits = useMemo(() => occurrences(ediMessage.toLowerCase(), searchTerm.toLowerCase()), [ediMessage, searchTerm]);

  return (
    <div className="timeline-event-content-edi expander" style={ediContentWindowStyle}>
      <div className="expander__actions">
        <button onClick={toggleEventEDI} className="expander__actions_action" type="button" aria-label="close">
          <span className="action_close">
            <FormattedMessage id="app.close" />
          </span>
        </button>
      </div>
      <h2><FormattedMessage id="app.tracking.timeline.event.edi.title" /></h2>
      <div className="timeline-event-first-row">
        <div className="timeline-event-mesref">
          <div className="event-content-edi-title">mesref</div>
          <div className="event-content-edi-value edi-mesref">{mesref || VALUE_IF_ABSENT}</div>
        </div>
        <div className="timeline-event-type">
          <div className="event-content-edi-title">type</div>
          <div className="event-content-edi-value edi-type">{type || VALUE_IF_ABSENT}</div>
        </div>
        <div className="timeline-event-version">
          <div className="event-content-edi-title">version</div>
          <div className="event-content-edi-value edi-version">{version || VALUE_IF_ABSENT}</div>
        </div>
        <div style={{ clear: 'both' }} />
      </div>
      <div className="timeline-event-second-row">
        <Flex align="center" justify="space-between" my="15px">
          <Box>
            <Input
              id="searchTermEDI"
              name="searchTermEDI"
              className="searchTermEDI"
              onChange={handleSearch}
              placeholder={searchTextInt}
              value={searchTerm}
            />
            {searchTerm && (
              <span className="search-hits-badge">
                {searchHits}
              </span>
            )}
          </Box>
          {showEdiToggle && (
            <TimelineEventContentEdiToggle
              isToggled={isTextFormatted}
              toggleRawEdi={handleToggle}
              key={1}
            />
          )}
        </Flex>
      </div>
      <div className="timeline-event-third-row">
        {(hasError || isEdiMessageLoading) && (
          <div className={ediMessageClass}>
            <CenteredSpan>
              {isEdiMessageLoading ? <Loading /> : <FormattedMessage id={errorMessage} />}
            </CenteredSpan>
          </div>
        )}
        {!hasError && !isEdiMessageLoading && (
          <Highlight
            className={ediMessageClass}
            textToHighlight={ediMessage}
            highlightWord={searchTerm}
            containerHeight={500}
            rowHeight={20}
            isVirtualized={isTextFormatted}
          />
        )}
      </div>
      <div className="actions">
        <CopyToClipboard text={ediMessage} className="timeline-event-edi-copy-button">
          <Button><FormattedMessage id="app.tracking.timeline.event.edi.copy" /></Button>
        </CopyToClipboard>
      </div>
    </div>
  );
}

EventContentEdi.propTypes = {
  ediMessage: PropTypes.string,
  isEdiMessageLoading: PropTypes.bool,
  event: PropTypes.objectOf(PropTypes.shape),
  toggleEventEDI: PropTypes.func,
  showEdiToggle: PropTypes.bool,
  intl: PropTypes.objectOf(PropTypes.shape),
  searchTerm: PropTypes.string,
  isTextFormatted: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onToggle: PropTypes.func,
  onSearch: PropTypes.func,
};

EventContentEdi.defaultProps = {
  ediMessage: '',
  event: {},
  toggleEventEDI: noop,
  showEdiToggle: true,
};

export default injectIntl(React.memo(EventContentEdi));
