import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CollapsableReceptacle from '../eventContentDetails/CollapsableReceptacle';

export class ItemsByReceptaclesForFullDetails extends Component {
  getFallbackMessage() {
    const { isLoaded } = this.props;
    return isLoaded
      ? <FormattedMessage id="app.tracking.timeline.event.detail.receptacle.table.noData" />
      : <FormattedMessage id="app.loading" />;
  }

  getReceptacleList() {
    const {
      receptacles, items, isLoaded, error, collapsedKeys,
    } = this.props;

    return receptacles.map((receptacle) => {
      const itemsFound = items.filter((item) => item.receptacleId === receptacle.receptacleId);
      const {
        onReceptacleExpand, onReceptacleCollapse,
      } = this.props;
      return (
        <CollapsableReceptacle
          key={receptacle.receptacleId}
          receptacle={receptacle}
          items={itemsFound}
          isLoaded={isLoaded}
          showPagination
          showAllColumns
          isCollapsed={_.includes(collapsedKeys, receptacle.receptacleId)}
          onExpand={onReceptacleExpand}
          onCollapse={onReceptacleCollapse}
          error={error}
        />
      );
    });
  }

  render() {
    const receptacleList = this.getReceptacleList();
    return (
      <div>
        {receptacleList.length > 0 ? (
          <div>{receptacleList}</div>
        ) : this.getFallbackMessage()}
      </div>
    );
  }
}

ItemsByReceptaclesForFullDetails.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape),
  receptacles: PropTypes.arrayOf(PropTypes.shape),
  collapsedKeys: PropTypes.arrayOf(PropTypes.string),
  onReceptacleCollapse: PropTypes.func,
  onReceptacleExpand: PropTypes.func,
  isLoaded: PropTypes.bool,
  error: PropTypes.bool,
};

ItemsByReceptaclesForFullDetails.defaultProps = {
  items: [],
  receptacles: [],
  isLoaded: false,
  error: false,
};

export default ItemsByReceptaclesForFullDetails;
