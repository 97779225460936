import React from 'react';
import PropTypes from 'prop-types';
import CommonEventContentDetails from '../../../../common/components/CommonEventContentDetails';
import EventContentEdiButton from '../../../../common/components/timeline/eventContentEdi/EventContentEdiButton';
import Permission from '../../../../../security/permission';
import { useIsAuthorized } from '../../../../../components/common/ipc-core/useIsAuthorized';

const PodGeneralInfo = ({ event, showEDI, toggleEventEDI }) => {
  const isAuthorized = useIsAuthorized([Permission.READ_EDI_MRD]);

  return (
    <div className="default-event-first-row">
      <div className="default-event-sender">
        <div className="event-content-details-title">sender</div>
        <div className="event-details-sender-value">{event.sender}</div>
      </div>
      <div className="default-event-receiver">
        <div className="event-content-details-title">receiver</div>
        <div className="event-details-receiver-value">{event.receiver}</div>
      </div>
      { isAuthorized
          && (
            <EventContentEdiButton
              id="eventContentEdiButton"
              showEDI={showEDI}
              toggleEventEDI={toggleEventEDI}
            />
          )}
    </div>
  );
};

PodGeneralInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

const PodEventContentDetails = (props) => (
  <CommonEventContentDetails
    {...props}
    showTabContent={false}
    showFullDetails={false}
    generalInfoComponent={<PodGeneralInfo {...props} />}
  />
);

export default PodEventContentDetails;
