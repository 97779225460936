import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonEventContentDetails from '../../../../common/components/CommonEventContentDetails';
import { fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator } from '../../../redux/actions/EventInfoActions';
import getOverviewMapper from '../getOverviewMapper';
import ConsignmentEventGeneralInfo from './ConsignmentEventGeneralInfo';
import ContainerTable from './ContainerTable';
import OverviewTable from './OverviewTable';
import ReceptaclesTableForConsignment from './ReceptaclesTableForConsignment';
import TransportTableForConsignment from './TransportTableForConsignment';

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator,
}, dispatch);

const mapStateToProps = (state) => ({
  consignmentId: state.tracking.consignment.consignment.consignmentId,
});

export class CarditEventContentDetails extends Component {
  getComponentMap = (props) => {
    const { eventInfo = {}, error, eventName } = props.event;

    return {
      overview: (
        <OverviewTable
          overview={getOverviewMapper(eventName, eventInfo.overview)}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
      transport: (
        <TransportTableForConsignment
          transportLegs={eventInfo.transportLegs}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
      receptacles: (
        <ReceptaclesTableForConsignment
          receptacles={eventInfo.receptacles}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
      containers: (
        <ContainerTable
          containers={eventInfo.containers}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
    };
  };

  requestEventDetails = () => {
    const { consignmentId, fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator: fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator1, event } = this.props;
    fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator1(
      event.messageAggregateId,
      event.aggregateId,
      consignmentId,
    );
  };

  render() {
    const { consignmentId } = this.props;
    return (
      <CommonEventContentDetails
        {...this.props}
        entityId={consignmentId}
        generalInfoComponent={<ConsignmentEventGeneralInfo {...this.props} />}
        requestEventDetails={this.requestEventDetails}
        getComponentMap={this.getComponentMap}
      />
    );
  }
}

CarditEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator: PropTypes.func,
  consignmentId: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarditEventContentDetails);
