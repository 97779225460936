import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { fullDetailsTransportTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

function generateExcelWorkbookTransportForFullDetails(legs) {
  const fullDetailsTransportTemplateFile = base64.decode(fullDetailsTransportTemplate);
  const template = new XlsxTemplate(fullDetailsTransportTemplateFile);
  const dateFormatter = fieldFormatter('date');

  const transportLegs = {
    transportLegs: _.map(legs, (transportLeg) => ({
      transportLegNumber: transportLeg.transportLegNumber,
      transportMode: transportLeg.transportMode,
      carrierCode: transportLeg.carrierCode,
      conveyanceReference: transportLeg.conveyanceReference,
      departureLocationCode: transportLeg.departureLocationCode,
      arrivalLocationCode: transportLeg.arrivalLocationCode,
      departureDate: dateFormatter({ value: transportLeg.departureDate }, ''),
      arrivalDate: dateFormatter({ value: transportLeg.arrivalDate }, ''),
    })),
  };
  template.substitute('Transport', transportLegs);
  return template.generate();
}

export default generateExcelWorkbookTransportForFullDetails;
