import { constructServiceURL } from '../../core/modules/common/tools/URLTools';
import { get } from '../request';

export const fetchAllPermissions = () => get(constructServiceURL({
  endpoint: 'fetchAllPermissions',
}));

export const fetchPermissions = (roleId) => get(constructServiceURL({
  endpoint: 'fetchPermissionForRole',
  params: {
    id: roleId,
  },
}));
