import React from 'react';
import styled from 'styled-components';
import { Table } from '@ipc/ipc-components';

const TableStyle = `
tbody {
  tr {
    td {
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      white-space: nowrap;
    }
  }
}
`;

const StyledTable = styled(Table)`${TableStyle}`;

export default (props) => (
  <StyledTable
    textAlign="start"
    sortable
    hasResizing
    hasCustomSizes
    maxRows={10}
    headerHeight={30}
    {...props}
  />
);
