import { useNotification } from '@ipc/ipc-components';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';

const apiCallWrapper = (apiCall) => (_, params) => apiCall(params);

export const useApiQuery = (queryKey, queryFn, options, variables) => useQuery({
  queryKey,
  queryFn: apiCallWrapper(queryFn),
  variables,
  config: {
    ...options,
    refetchOnWindowFocus: false,
  },
});

const defaultNotificationProps = {
  notifyOnSuccess: true,
  notifyOnError: true,
  messageTimeoutInSeconds: 5,
};

const getErrorMessage = (error) => (error?.data?.message || error?.response?.data?.message || 'Failed operation');

export const useApiMutation = (mutationFn, options, notificationOptions) => {
  const res = useMutation(mutationFn, options);
  const [, { error, data }] = res;
  const { errorNotification, successNotification } = useNotification();

  const mergedNotificationOptions = {
    ...defaultNotificationProps,
    ...notificationOptions,
  };

  useEffect(() => {
    const { notifyOnError, notificationErrorMessage, messageTimeoutInSeconds } = mergedNotificationOptions;

    if (notifyOnError && error) {
      errorNotification(
        notificationErrorMessage || getErrorMessage(error),
        messageTimeoutInSeconds,
      );
    }
  }, [error]);

  useEffect(() => {
    const { notifyOnSuccess, notificationSuccessMessage, messageTimeoutInSeconds } = mergedNotificationOptions;

    if (notifyOnSuccess && data !== undefined) {
      successNotification(
        notificationSuccessMessage || (data.message || 'Success operation'),
        messageTimeoutInSeconds,
      );
    }
  }, [data]);

  return res;
};
