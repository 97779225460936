import {
  FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_REQUEST,
  FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_SUCCESS,
  FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_FAILURE,

  FETCH_DESPATCH_CLOSED_FOR_DESPATCH_REQUEST,
  FETCH_DESPATCH_CLOSED_FOR_DESPATCH_SUCCESS,
  FETCH_DESPATCH_CLOSED_FOR_DESPATCH_FAILURE,

  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_REQUEST,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_SUCCESS,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_FAILURE,

  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_REQUEST,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_SUCCESS,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_FAILURE,

  FETCH_FULL_TRANSPORT_FOR_PREDES_REQUEST,
  FETCH_FULL_TRANSPORT_FOR_PREDES_SUCCESS,
  FETCH_FULL_TRANSPORT_FOR_PREDES_FAILURE,

  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_REQUEST,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_SUCCESS,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FAILURE,

  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_REQUEST,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_SUCCESS,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_FAILURE,

  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_REQUEST,
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_SUCCESS,
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FAILURE,

  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_REQUEST,
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_SUCCESS,
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_FAILURE,

  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_REQUEST,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_SUCCESS,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FAILURE,

  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_REQUEST,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_SUCCESS,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_FAILURE,

  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_REQUEST,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_SUCCESS,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FAILURE,

  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_REQUEST,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_SUCCESS,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_FAILURE,
} from './types/EventInfoActionTypes';

export function fetchDespatchClosedForReceptacleRequestActionCreator(
  messageAggregateId,
  aggregateId,
  receptacleId,
) {
  return {
    type: FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_REQUEST,
    params: { messageAggregateId, aggregateId, receptacleId },
  };
}

export function fetchDespatchClosedForReceptacleSuccessActionCreator(payload) {
  return {
    type: FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_SUCCESS,
    payload,
  };
}

export function fetchDespatchClosedForReceptacleFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchDespatchClosedForDespatchRequestActionCreator(
  messageAggregateId,
  aggregateId,
) {
  return {
    type: FETCH_DESPATCH_CLOSED_FOR_DESPATCH_REQUEST,
    params: { messageAggregateId, aggregateId },
  };
}

export function fetchDespatchClosedForDespatchSuccessActionCreator(payload) {
  return {
    type: FETCH_DESPATCH_CLOSED_FOR_DESPATCH_SUCCESS,
    payload,
  };
}
export function fetchDespatchClosedForDespatchFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_DESPATCH_CLOSED_FOR_DESPATCH_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchDespatchArrivedInwardOeForDespatchRequestActionCreator(
  messageAggregateId,
  aggregateId,
) {
  return {
    type: FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_REQUEST,
    params: { messageAggregateId, aggregateId },
  };
}

export function fetchDespatchArrivedInwardOeForDespatchSuccessActionCreator(payload) {
  return {
    type: FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_SUCCESS,
    payload,
  };
}

export function fetchDespatchArrivedInwardOeForDespatchFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchFullTransportForPredesRequestActionCreator(messageAggregateId, aggregateId) {
  return {
    type: FETCH_FULL_TRANSPORT_FOR_PREDES_REQUEST,
    params: { messageAggregateId, aggregateId },
  };
}

export function fetchFullTransportForPredesSuccessActionCreator(payload) {
  return {
    type: FETCH_FULL_TRANSPORT_FOR_PREDES_SUCCESS,
    payload,
  };
}

export function fetchFullTransportForPredesFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_FULL_TRANSPORT_FOR_PREDES_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchDespatchArrivedInwardOeForReceptacleRequestActionCreator(
  messageAggregateId,
  aggregateId,
  receptacleId,
) {
  return {
    type: FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_REQUEST,
    params: { messageAggregateId, aggregateId, receptacleId },
  };
}
export function fetchDespatchArrivedInwardOeForReceptacleSuccessActionCreator(payload) {
  return {
    type: FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_SUCCESS,
    payload,
  };
}
export function fetchDespatchArrivedInwardOeForReceptacleFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator(
  messageAggregateId,
  aggregateId,
  consignmentId,
) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_REQUEST,
    params: { messageAggregateId, aggregateId, consignmentId },
  };
}
export function fetchConsignmentAdvisedToCarrierForConsignmentSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_SUCCESS,
    payload,
  };
}
export function fetchConsignmentAdvisedToCarrierForConsignmentFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchConsignmentAdvisedToDestPostRequestActionCreator(
  messageAggregateId,
  aggregateId,
  receptacleId,
) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_REQUEST,
    params: { messageAggregateId, aggregateId, receptacleId },
  };
}
export function fetchConsignmentAdvisedToDestPostSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_SUCCESS,
    payload,
  };
}
export function fetchConsignmentAdvisedToDestPostFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchConsignmentAdvisedToDestPostForConsignmentRequestActionCreator(
  messageAggregateId,
  aggregateId,
  consignmentId,
) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_REQUEST,
    params: { messageAggregateId, aggregateId, consignmentId },
  };
}
export function fetchConsignmentAdvisedToDestPostForConsignmentSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_SUCCESS,
    payload,
  };
}
export function fetchConsignmentAdvisedToDestPostForConsignmentFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchConsignmentAdvisedToCarrierRequestActionCreator(
  messageAggregateId,
  aggregateId,
  receptacleId,
  consignmentId,
) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_REQUEST,
    params: {
      messageAggregateId,
      aggregateId,
      receptacleId,
      consignmentId,
    },
  };
}
export function fetchConsignmentAdvisedToCarrierSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_SUCCESS,
    payload,
  };
}
export function fetchConsignmentAdvisedToCarrierFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchConsignmentArrivedAtDestPostRequestActionCreator(
  messageAggregateId,
  aggregateId,
  receptacleId,
) {
  return {
    type: FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_REQUEST,
    params: { messageAggregateId, aggregateId, receptacleId },
  };
}
export function fetchConsignmentArrivedAtDestPostSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_SUCCESS,
    payload,
  };
}
export function fetchConsignmentArrivedAtDestPostFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchConsignmentArrivedAtDestPostForConsignmentRequestActionCreator(
  messageAggregateId,
  aggregateId,
  consignmentId,
) {
  return {
    type: FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_REQUEST,
    params: { messageAggregateId, aggregateId, consignmentId },
  };
}
export function fetchConsignmentArrivedAtDestPostForConsignmentSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_SUCCESS,
    payload,
  };
}
export function fetchConsignmentArrivedAtDestPostForConsignmentFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchConsignmentReportedOnByCarrierRequestActionCreator(
  messageAggregateId,
  aggregateId,
  receptacleId,
  consignmentId,
) {
  return {
    type: FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_REQUEST,
    params: {
      messageAggregateId, aggregateId, receptacleId, consignmentId,
    },
  };
}
export function fetchConsignmentReportedOnByCarrierSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_SUCCESS,
    payload,
  };
}
export function fetchConsignmentReportedOnByCarrierFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}

export function fetchConsignmentReportedOnByCarrierForConsignmentRequestActionCreator(
  messageAggregateId,
  aggregateId,
  consignmentId,
) {
  return {
    type: FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_REQUEST,
    params: { messageAggregateId, aggregateId, consignmentId },
  };
}
export function fetchConsignmentReportedOnByCarrierForConsignmentSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_SUCCESS,
    payload,
  };
}
export function fetchConsignmentReportedOnByCarrierForConsignmentFailureActionCreator(
  error,
  aggregateId,
  messageAggregateId,
) {
  return {
    type: FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_FAILURE,
    payload: error,
    error: true,
    aggregateId,
    messageAggregateId,
  };
}
