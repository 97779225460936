import { Link } from '@ipc/ipc-core';
import PropTypes from 'prop-types';
import React from 'react';
import { useIsAuthorized } from '../../../../../components/common/ipc-core/useIsAuthorized';
import noop from '../../../../../core/modules/common/tools/noop';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import Permission from '../../../../../security/permission';
import EventContentEdiButton from '../../../../common/components/timeline/eventContentEdi/EventContentEdiButton';
import { VALUE_IF_ABSENT } from '../../../../common/constants/Constants';
import ItemGeneralInfo from './ItemGeneralInfo';

const ReceptacleEventContentDetails = (props) => {
  const {
    event = {},
    showEDI = false,
    toggleEventEDI = noop,
  } = props;

  const receptacleIdPresent = !!event.receptacleId;
  const receptacleId = event.receptacleId ? event.receptacleId : VALUE_IF_ABSENT;
  const isPodEvent = event.eventName && event.eventName.trim() === 'POD';
  const isAuthorized = useIsAuthorized([isPodEvent ? Permission.READ_EDI_MRD : Permission.READ_EDI]);

  return (
    <div className="receptacle-event-content-details general-info-container">
      <div className="receptacle-event-first-row">
        <ItemGeneralInfo event={event} />
      </div>
      <div className="general-info-event-id">
        <div className="receptacle-event-receptacle-id">
          <div className="event-content-details-title">receptacle id</div>
          <div className="event-details-receiver-receptacle-id-value">
            {receptacleIdPresent
              ? (
                <Link to={getUrl('receptacle', receptacleId)}>
                  {receptacleId}
                </Link>
              ) : receptacleId}
          </div>
        </div>
        <div className="general-info-field edi-button">
          {((event.rawEventKey && event.rawEventKey !== '') || (isPodEvent && isAuthorized))
            && (
              <EventContentEdiButton
                id="eventContentEdiButton"
                showEDI={showEDI}
                toggleEventEDI={toggleEventEDI}
              />
            )}
        </div>
      </div>
    </div>
  );
};

ReceptacleEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

export default ReceptacleEventContentDetails;
