import { queryCache } from 'react-query';
import { api } from '../api';
import { pushHistory } from '../core/modules/common/tools/HistoryTools';
import { getPermissionIds } from './permissions';
import { useApiMutation, useApiQuery } from './query';

export const useRole = (roleId) => {
  const { data: roleData } = useApiQuery(['role', roleId], api.fetchRole);
  const { data: permissions } = useApiQuery(['permissions', roleId], api.fetchPermissions, { enabled: roleData });

  const [apiEditRole] = useApiMutation(api.editRole, {
    onSuccess: async () => {
      await queryCache.invalidateQueries(['permissions', roleId]);
      await queryCache.invalidateQueries(['role', roleId]);
      await queryCache.invalidateQueries('roles');
      pushHistory('/administration/user-management/roles');
    },
  });

  const editRole = (role) => {
    const editedRole = {
      name: role.name,
      permissions: getPermissionIds(role.permissions),
    };
    return apiEditRole({
      id: roleId,
      role: editedRole,
    });
  };

  return {
    role: roleData,
    permissions,
    editRole,
  };
};

export const useRoles = () => {
  const { data: roles, isFetching } = useApiQuery('roles', api.fetchRoles);

  const [apiCreateRole] = useApiMutation(api.createRole, {
    onSuccess: () => pushHistory('/administration/user-management/roles'),
  });

  const createRole = (role) => {
    const newRole = {
      ...role,
      permissions: getPermissionIds(role.permissions),
    };
    return apiCreateRole(newRole);
  };

  return {
    roles,
    isFetching,
    createRole,
  };
};
