import React from 'react';
import PropTypes from 'prop-types';
import { VALUE_IF_ABSENT } from '../../../constants/Constants';
import noop from '../../../../../core/modules/common/tools/noop';
import EventContentEdiButton from '../eventContentEdi/EventContentEdiButton';

const DefaultEventContentDetails = (props) => {
  const {
    event = {},
    showEDI = false,
    toggleEventEDI = noop,
  } = props;

  const sender = event.sender || VALUE_IF_ABSENT;
  const receiver = event.receiver || VALUE_IF_ABSENT;

  return (
    <div className="default-event-content-details">
      <div className="default-event-first-row">
        <div className="default-event-sender">
          <div className="event-content-details-title">sender</div>
          <div className="event-details-sender-value">{sender}</div>
        </div>
        <div className="default-event-receiver">
          <div className="event-content-details-title">receiver</div>
          <div className="event-details-receiver-value">{receiver}</div>
        </div>
        { event.rawEventKey && event.rawEventKey !== ''
          && (
            <EventContentEdiButton
              id="eventContentEdiButton"
              showEDI={showEDI}
              toggleEventEDI={toggleEventEDI}
            />
          )}
      </div>
    </div>
  );
};

DefaultEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

export default DefaultEventContentDetails;
