import * as PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { Switch } from '@ipc/ipc-components';
import { useRoles } from '../../../../hooks';
import SwitchGroup from '../../../common/ipc-components/SwitchGroup';

const StyledSwitchGroup = styled(SwitchGroup)`  
  & .MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;  
    width: 245px; 
  }
`;

const createRoleSwitch = (role) => <Switch key={role.id} label={role.name} value={role} data-testid={role.name} size="medium" />;
const createDisabledRoleSwitch = (role) => <Switch key={role.id} label={role.name} value={role} data-testid={role.name} size="medium" disabled />;

const UserRoles = ({
  loading, user, className, disabled,
}) => {
  const { roles: tempAllRoles } = useRoles();

  const allRoles = tempAllRoles ? tempAllRoles.map((role) => ({
    name: role.name,
    id: role.id,
  })) : [];

  return (
    <>
      {user.roles && (
        <Controller
          name="roles"
          as={(
            <StyledSwitchGroup className={className}>
              {!loading && allRoles && allRoles.map(disabled ? createDisabledRoleSwitch : createRoleSwitch)}
            </StyledSwitchGroup>
          )}
        />
      )}
    </>
  );
};

UserRoles.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    roles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  }),
  loading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default UserRoles;
