import PropTypes from 'prop-types';
import React, { Component, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from '../../../../components/common/Button';
import Icons from '../../../../components/common/Icons';
import IconText from '../../../../components/common/IconText';
import { SidebarContext } from '../../../../components/common/contexts';
import { pushHistory } from '../../../../core/modules/common/tools/HistoryTools';
import noop from '../../../../core/modules/common/tools/noop';
import FilterForm from './form/filter/FilterForm';
import { ToggleButtonsStyle } from './Sidebar.styles';
import SidebarSearch from './form/search/SidebarSearch';
import SortForm from './form/sort/SortForm';

const { CrossIcon, ForwardIcon, SearchIcon } = Icons;
const ToggleButton = styled.i`${ToggleButtonsStyle}`;

const CollapseSidebarButton = () => <ToggleButton data-testid="sidebar_closebutton"><ForwardIcon /></ToggleButton>;
const ExpandSidebarButton = () => <ToggleButton data-testid="sidebar_openbutton"><SearchIcon /></ToggleButton>;

const SidebarToggleButton = ({ expanded, ...props }) => (
  expanded ? <CollapseSidebarButton {...props} /> : <ExpandSidebarButton {...props} />
);

SidebarToggleButton.propTypes = {
  expanded: PropTypes.bool,
};

const ClearFilterButton = styled(Button)`
  &.MuiButton-root {
    height: 50px;
    text-align: center;
    border-radius: 0;
  }
`;

export class Sidebar extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      contentFound, events, language, expandSidebar,
    } = this.props;
    const availableTimelineEventsDiffer = events.length !== nextProps.events.length;
    const uiExpandSidebarDiffer = expandSidebar !== nextProps.expandSidebar;
    const languageDiffer = language !== nextProps.language;
    const contentDiffer = contentFound !== nextProps.contentFound;

    return availableTimelineEventsDiffer
      || uiExpandSidebarDiffer
      || languageDiffer
      || contentDiffer;
  }

  componentWillUnmount() {
    const { setSearchSidebar } = this.props;
    setSearchSidebar('');
  }

  toggleSidebar = () => {
    const { setExpandSidebar, expandSidebar } = this.props;
    setExpandSidebar(!expandSidebar);
  };

  submitSidebarSearchForm = (values) => {
    const searchString = values.searchInput;
    pushHistory(`/search/${searchString}`);
  };

  render() {
    const {
      contentFound, onClearFilter, events, expandSidebar,
    } = this.props;

    return (
      <div id="sidebar" className={`sidebar ${!expandSidebar ? 'sidebar--hidden' : ''}`}>
        <div
          onClick={this.toggleSidebar}
          className={`sidebar__toggle ${!expandSidebar ? 'sidebar__toggle--collapsed' : ''}`}
        >
          <p className={expandSidebar ? 'label-shown' : 'label-hidden'}>
            <FormattedMessage id="app.tracking.search.handle" />
          </p>
          <SidebarToggleButton expanded={expandSidebar} />
        </div>

        <div className="sidebar__search">
          <SidebarSearch
            id="sidebarSearch"
            onSubmit={this.submitSidebarSearchForm}
          />
        </div>

        <div className="sidebar__content-wrapper">
          <div className="sidebar__content">
            {contentFound
              && (
                <FilterForm
                  id="filterForm"
                  availableTimelineEvents={events}
                />
              )}
            {contentFound
              && <SortForm id="sortForm" />}
          </div>
        </div>

        <div className="sidebar__clearFilter">
          <ClearFilterButton
            id="clearFilterButton"
            onClick={onClearFilter}
            fullWidth
          >
            <IconText icon={CrossIcon} labelId="app.tracking.sidebar.clear-filter-button" />
          </ClearFilterButton>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape),
  language: PropTypes.string,
  contentFound: PropTypes.bool,
  onClearFilter: PropTypes.func,
  expandSidebar: PropTypes.bool,
  setSearchSidebar: PropTypes.func,
  setExpandSidebar: PropTypes.func,
};

Sidebar.defaultProps = {
  events: [],
  language: '',
  contentFound: false,
  onClearFilter: noop,
};

const useSidebar = () => {
  const {
    expandSidebar, sidebarSearchString, setExpandSidebar, setSearchSidebar,
  } = useContext(SidebarContext);

  return {
    expandSidebar,
    sidebarSearchString,
    setExpandSidebar,
    setSearchSidebar,
  };
};

const SidebarWrapper = (props) => {
  const sidebarProps = useSidebar();

  return <Sidebar {...props} {...sidebarProps} />;
};

export default SidebarWrapper;
