import { queryCache } from 'react-query';
import { api } from '../api';
import { useApiMutation, useApiQuery } from './query';

const getRoleId = (role) => (role.id);

const getRoleIds = (roles) => (roles ? roles.map(getRoleId) : []);

export const useUser = (user) => {
  const {
    id,
    username,
    firstName,
    lastName,
    email,
    organizations,
  } = user;
  const fetchRoles = () => useApiQuery(['roles', id], api.fetchUserRoles);
  const [apiEditUserRoles] = useApiMutation(api.editUserRoles);
  const [apiEditUser] = useApiMutation(api.editUser);

  const updateUser = (updatedUser) => apiEditUser({
    id,
    user: {
      active: updatedUser.active,
      username,
      firstName,
      lastName,
      email,
      organizations,
    },
  }, {
    onSuccess: () => {
      const roles = getRoleIds(updatedUser.roles);
      apiEditUserRoles({
        id,
        roles: {
          roles,
        },
      }, {
        onSuccess: async () => {
          await queryCache.invalidateQueries(['users', id]);
        },
      });
    },
  });

  return {
    fetchRoles,
    updateUser,
  };
};

export const useUsers = (initialUsers) => {
  const { data: users, isFetching } = useApiQuery('users', api.fetchUsers);

  return {
    users: users || initialUsers,
    isFetching,
  };
};
