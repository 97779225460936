import { css } from 'styled-components';

export const HeaderStyle = css`
  margin-bottom: 20px;
  display : flex;
  align-items : center;
`;

export const HeaderStyleItem = css`
  cursor: pointer;
  display : flex;
  align-items : center;
  flex-direction : row;
  flex-basis : ${({ flexBasis }) => flexBasis};

  ${({ theme }) => ({
    color: theme.colors.primary,
    fontWeight: theme.fontWeights.bold,
  })}
`;
