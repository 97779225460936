import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import { fullTransportForFullDetailsTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

const VALUE_IF_ABSENT = '';

function generateExcelWorkbookFullTransportForFullDetails(legs) {
  const dateFormatter = fieldFormatter('date');
  const {
    carditResditTransportInfo = [],
    preconResconTransportInfo = [],
    predesResdesTransportInfo = [],
  } = legs;

  const prepareToExcel = (transportInfo) => ({
    messageType: transportInfo.messageType || VALUE_IF_ABSENT,
    sequence: transportInfo.transportLegSequence || VALUE_IF_ABSENT,
    mode: transportInfo.transportMode || VALUE_IF_ABSENT,
    carrier: transportInfo.carrierCode || VALUE_IF_ABSENT,
    conveyance: transportInfo.conveyanceReference || VALUE_IF_ABSENT,
    departureLocation: transportInfo.departureLocation || VALUE_IF_ABSENT,
    arrivalLocation: transportInfo.arrivalLocation || VALUE_IF_ABSENT,
    departureDate: dateFormatter({ value: transportInfo.departureDate }, null, VALUE_IF_ABSENT),
    arrivalDate: dateFormatter({ value: transportInfo.arrivalDate }, null, VALUE_IF_ABSENT),
    receptacles: transportInfo.totalReceptacles || VALUE_IF_ABSENT,
    parties: transportInfo.parties || VALUE_IF_ABSENT,
    id: transportInfo.id || VALUE_IF_ABSENT,
    eventDate: dateFormatter({ value: transportInfo.eventDate }, null, VALUE_IF_ABSENT),
    eventCode: transportInfo.eventCode || VALUE_IF_ABSENT,
    eventDescription: transportInfo.eventDescription || VALUE_IF_ABSENT,
    handoverLocation: transportInfo.handoverLocation || VALUE_IF_ABSENT,
    handoverPartnerId: transportInfo.handoverPartnerId || VALUE_IF_ABSENT,
  });

  const file = base64.decode(fullTransportForFullDetailsTemplate);
  const template = new XlsxTemplate(file);

  const predesResdes = { predesResdes: predesResdesTransportInfo.map(prepareToExcel) };
  const carditResdit = { carditResdit: carditResditTransportInfo.map(prepareToExcel) };
  const preconRescon = { preconRescon: preconResconTransportInfo.map(prepareToExcel) };

  template.substitute('PREDES vs RESDES', predesResdes);
  template.substitute('CARDIT vs RESDIT', carditResdit);
  template.substitute('PRECON vs RESCON', preconRescon);

  return template.generate();
}

export default generateExcelWorkbookFullTransportForFullDetails;
