import { NOT_FOUND, INTERNAL_SERVER_ERROR, FORBIDDEN } from '../../../common/enum/HTTPStatusCodes';

export const getErrorMessage = (payload) => {
  const { response } = payload;
  let errorMessage = 'app.networkError';

  if (response) {
    switch (response.status) {
    case NOT_FOUND:
      errorMessage = 'app.tracking.search.errors.not.found';
      break;

    case FORBIDDEN:
      errorMessage = 'app.permission.notAuthorized';
      break;

    case INTERNAL_SERVER_ERROR:
    default:
      errorMessage = response.data && response.data.message
        ? response.data.message
        : 'app.tracking.timeline.event.detail.errorMessage';
      break;
    }
  }

  return errorMessage;
};
