import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import { fullDetailsOverviewPreconTemplate } from '../../../constants/ExcelTemplates';
import getOverviewMapper from '../../../../tracking/components/consignment/getOverviewMapper';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

const VALUE_IF_ABSENT = '';

function generateExcelWorkbookOverviewPreconForFullDetails(overviewData) {
  const dateFormatter = fieldFormatter('date');
  const file = base64.decode(fullDetailsOverviewPreconTemplate);
  const template = new XlsxTemplate(file);
  const overview = getOverviewMapper('PRECON', overviewData, VALUE_IF_ABSENT);

  ['consignmentCompletionDate', 'departureDateTime', 'arrivalDateTime']
    .forEach((key) => {
      overview[key] = dateFormatter({ value: overview[key] }, null, VALUE_IF_ABSENT);
    });

  template.substitute('Overview', { overview });

  return template.generate();
}

export default generateExcelWorkbookOverviewPreconForFullDetails;
