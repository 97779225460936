import { Link } from '@ipc/ipc-core';
import { oneOf, string } from 'prop-types';
import React from 'react';
import Button from '../../../../components/common/Button';
import { getUrl } from '../../../../core/modules/common/tools/URLTools';
import { useTranslator } from '../../../../hooks';
import { VALUE_IF_ABSENT } from '../../../common/constants/Constants';

const SingleResult = (props) => {
  const {
    searchResultId = '',
    className = 'search-result',
    type,
  } = props;
  const { translate } = useTranslator(`app.tracking.search.results.${type}`);

  return (
    <div className={className}>
      <div className="search-result-details">
        <div className="search-result-details-title">
          {translate('id')}
        </div>
        <div className="search-result-details-value">{searchResultId || VALUE_IF_ABSENT}</div>
      </div>
      <Link to={searchResultId && (getUrl(type, searchResultId))} data-testid={`${searchResultId}`}>
        <Button className="view-search-result-button">
          {translate('view')}
        </Button>
      </Link>
    </div>
  );
};

SingleResult.propTypes = {
  searchResultId: string,
  type: oneOf(['item', 'receptacle', 'despatch', 'consignment']),
  className: string,
};

export default SingleResult;
