import _ from 'lodash';
import { EventGroup } from '../../classes/EventGroup';
import { eventSortAsc, eventSortDesc } from '../events/eventSortUtil';

function expandByIndividualAndGroupKeys(availableEvents, groupedEvents = []) {
  const expandedEventKeys = _.map(availableEvents, (event) => event.aggregateId);
  const expandedEventGroups = _.reduce(groupedEvents, (arr, element) => {
    if (element instanceof EventGroup) {
      arr.push({
        groupedEventsKey: element.getGroupKey(),
        amountExpanded: element.getSize(),
      });
    }
    return arr;
  }, []);

  return {
    expandedEventKeys,
    expandedEventGroups,
  };
}

function filterEvents(events, filters) {
  let eventFilterValues = [];
  if (filters && filters.values) {
    eventFilterValues = _.keys(_.pickBy(filters.values, (value) => value));
  }
  let filteredTimelineEvents = [...events];
  if (eventFilterValues.length > 0) {
    filteredTimelineEvents = _.filter(events, (event) => eventFilterValues.indexOf(event.eventName) > -1);
  }
  return filteredTimelineEvents;
}

function calculateGroupKey(event) {
  const location = event.eventLocation ? event.eventLocation.location : undefined;
  return `${event.eventDate}|${event.eventName}|${event.eventDescription}|${location}`;
}

function groupEvents(events) {
  const groupedEvents = [];
  let currentGroupingKey;
  let groupedEventsPointer = -1;

  events.forEach((current) => {
    if (calculateGroupKey(current) === currentGroupingKey) {
      if (!(groupedEvents[groupedEventsPointer] instanceof EventGroup)) {
        groupedEvents[groupedEventsPointer] = new EventGroup(groupedEvents[groupedEventsPointer]);
      }
      groupedEvents[groupedEventsPointer].addEvent(current);
    } else {
      groupedEvents.push(current);
      groupedEventsPointer += 1;
    }
    currentGroupingKey = calculateGroupKey(current);
  });
  return groupedEvents;
}

function sortEvents(events, sort) {
  const sortFunction = sort && sort.values && sort.values.sort === 'asc' ? eventSortAsc : eventSortDesc;
  return events.sort(sortFunction);
}

function areAllTheRenderedEventsExpanded(RenderedEventKeys, expandedEventKeys) {
  return _.difference(RenderedEventKeys, expandedEventKeys).length === 0;
}

export default {
  expandByIndividualAndGroupKeys,
  filterEvents,
  calculateGroupKey,
  groupEvents,
  sortEvents,
  areAllTheRenderedEventsExpanded,
};
