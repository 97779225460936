import React from 'react';
import PropTypes from 'prop-types';
import Expander from './Expander';
import Tabs from './Tabs';

const Summary = (props) => {
  const {
    label,
    expanded,
    tabs = [],
    onSummaryToggleExpand,
  } = props;

  return (
    <div className="timeline-summary" data-testid="summary">
      { label
        && (
          <Expander
            label={label}
            expanded={expanded}
            onToggle={onSummaryToggleExpand}
            fullWidthOnExpanded={false}
            contentComponent={(
              <Tabs
                tabs={tabs}
              />
            )}
          />
        )}
    </div>
  );
};

Summary.propTypes = {
  label: PropTypes.string,
  expanded: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape),
  onSummaryToggleExpand: PropTypes.func,
};

export default Summary;
