import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';
import { withSecurity } from '../../../security/page';
import Permission from '../../../security/permission';
import ItemPage from '../components/item/ItemPage';
import { fetchItemRequestActionCreator } from '../redux/actions/ItemActions';
import { getViewAndIdentifierFromRoute } from '../../common/utils';
import { useGeneralErrorNotification } from '../../../hooks';

const mapStateToProps = (state) => ({
  item: state.tracking.item.item,
  hasError: state.tracking.item.error,
  errorMessage: state.tracking.item.errorMessage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchItemRequestActionCreator,
  reset,
}, dispatch);

export function ItemContainer({
  match, hasError, errorMessage, item, reset: reset1, fetchItemRequestActionCreator: fetchItemRequestActionCreator1,
}) {
  const { params: { itemId } } = match;

  const resetFilterForm = () => {
    const { view, pageId } = getViewAndIdentifierFromRoute(match);

    reset1(`${view}.${pageId}.filters`);
  };

  const fetchItem = (id) => {
    fetchItemRequestActionCreator1(id);
  };

  useEffect(() => {
    fetchItem(itemId);
  }, [itemId]);

  useGeneralErrorNotification(errorMessage);

  return (
    <ItemPage
      id="itemPage"
      resetFilterForm={resetFilterForm}
      item={item}
      hasError={hasError}
      errorMessage={errorMessage === 'app.networkError' ? 'app.tracking.search.errors.not.found' : errorMessage}
      requestedId={itemId}
    />
  );
}

ItemContainer.defaultProps = {
  item: {},
};

ItemContainer.propTypes = {
  item: PropTypes.objectOf(PropTypes.shape),
  match: PropTypes.objectOf(PropTypes.shape),
  fetchItemRequestActionCreator: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  reset: PropTypes.func,
};

const ConnectedItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemContainer);

export default withSecurity(ConnectedItemContainer, [Permission.READ_ITEM]);
