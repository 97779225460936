import {
  FETCH_CONSIGNMENT_FAILURE,
  FETCH_CONSIGNMENT_REQUEST,
  FETCH_CONSIGNMENT_SUCCESS,
} from './types/ConsignmentActionTypes';

export function fetchConsignmentRequestActionCreator(consignmentId) {
  return {
    type: FETCH_CONSIGNMENT_REQUEST,
    params: { consignmentId },
  };
}

export function fetchConsignmentSuccessActionCreator(payload) {
  return {
    type: FETCH_CONSIGNMENT_SUCCESS,
    payload,
  };
}

export function fetchConsignmentFailureActionCreator(error) {
  return {
    type: FETCH_CONSIGNMENT_FAILURE,
    payload: error,
    error: true,
  };
}
