/* eslint-disable import/no-dynamic-require */
import commonConfig from './common.json';

const environmentConfig = require(`./${process.env.NODE_ENV}.json`);

export default {
  env: process.env.NODE_ENV,
  ...commonConfig,
  ...environmentConfig,
};
