import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import Table from '../../../../../components/common/Table';
import { VALUE_IF_ABSENT } from '../../../../common/constants/Constants';
import createColumnDefinition from '../../../../react-table/helpers/createColumnDefinition';

export const getCarrierCodeAndConveyance = (row) => `${row.carrierCode || VALUE_IF_ABSENT} / ${row.conveyanceReference || VALUE_IF_ABSENT}`;

export class FullTransportTable extends React.Component {
  getColumnWidth = (smallColWidth, fullScreenWidth = 150) => {
    const {
      showAllColumns,
    } = this.props;

    return showAllColumns ? fullScreenWidth : smallColWidth;
  }

  getTrProps = (rowInfo) => {
    const { validateHighLight } = this.props;
    return ({
      style: {
        background: validateHighLight && validateHighLight(rowInfo ? rowInfo.original.messageType : VALUE_IF_ABSENT) ? '#ffedd6' : 'transparent',
      },
    });
  };

  render() {
    const {
      isLoaded, transportInfo, showPagination, error, intl, showAllColumns, pageSize, type,
    } = this.props;
    const noDataText = intl.formatMessage({ id: 'app.tracking.timeline.event.detail.fulltransport.table.noData' });

    const tableColumns = [
      createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.transportLegSequence', 'transportLegSequence', 1, this.getColumnWidth(15, 45)),
      createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.transportMode', 'transportMode', 2, this.getColumnWidth(20, 60)),
      createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.carrierCodeAndConveyanceReference', getCarrierCodeAndConveyance, 3, this.getColumnWidth(35, 105)),
      createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.departureLocation', 'departureLocation', 5, this.getColumnWidth(20, 60)),
      createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.arrivalLocation', 'arrivalLocation', 6, this.getColumnWidth(20, 60)),
      createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.departureDate', 'departureDate', 7, this.getColumnWidth(45, 135), 'date'),
      createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.arrivalDate', 'arrivalDate', 8, this.getColumnWidth(45, 135), 'date'),
      createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.eventDate', 'eventDate', 8, this.getColumnWidth(45, 135), 'date'),
    ];

    if (showAllColumns) {
      tableColumns.push(
        createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.totalReceptacles', 'totalReceptacles', 9, 75),
        createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.parties', 'parties', 10, 105),
        createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.id', 'id', 11, 170),
      );

      if (type === 'carditResditTransportInfo') {
        tableColumns.push(
          createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.eventCode', 'eventCode', 13, 60),
          createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.eventDescription', 'eventDescription', 14, 88),
          createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.handoverLocation', 'handoverLocation', 15, 118),
          createColumnDefinition('app.tracking.timeline.event.detail.fulltransport.table.handoverPartnerId', 'handoverPartnerId', 16, 103),
        );
      }
    }

    return (
      <div className="FullTransportTable">
        <Table
          id="table"
          columns={tableColumns}
          data={transportInfo}
          noDataText={isLoaded && _.isEmpty(transportInfo) && (noDataText)}
          loading={!isLoaded && !error}
          hasPagination={showPagination}
          defaultPageSize={pageSize}
          getRowProps={this.getTrProps}
          minRows={1}
        />
      </div>
    );
  }
}

FullTransportTable.propTypes = {
  transportInfo: PropTypes.arrayOf(PropTypes.shape),
  type: PropTypes.string,
  validateHighLight: PropTypes.func,
  isLoaded: PropTypes.bool,
  showAllColumns: PropTypes.bool,
  showPagination: PropTypes.bool,
  pageSize: PropTypes.number,
  error: PropTypes.bool,
};

FullTransportTable.defaultProps = {
  showAllColumns: false,
  showPagination: false,
  pageSize: 10,
  isLoaded: false,
  error: false,
};

export default injectIntl(FullTransportTable);
