import { call, put, takeLatest } from 'redux-saga/effects';
import { doSearchFailureActionCreator, doSearchSuccessActionCreator } from '../actions/SearchActions';
import { DO_SEARCH_REQUEST } from '../actions/types/SearchActionTypes';
import { api } from '../../../../api';

function* doSearchWorker(action) {
  const {
    params: {
      searchString,
    },
  } = action;

  try {
    const res = yield call(api.doSearch, searchString);
    yield put(doSearchSuccessActionCreator(res.data));
  } catch (error) {
    yield put(doSearchFailureActionCreator(error));
  }
}

function* doSearchWatcher() {
  yield takeLatest(DO_SEARCH_REQUEST, doSearchWorker);
}

export default doSearchWatcher;
