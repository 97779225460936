import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CollapsableTransport from './CollapsableTransport';

class FullTransport extends Component {
  getFallbackMessage(transportInfoList) {
    const { isLoaded } = this.props;
    return isLoaded && !transportInfoList.length
      ? <FormattedMessage id="app.tracking.timeline.event.detail.fulltransport.table.noData" />
      : <FormattedMessage id="app.loading" />;
  }

  getTransportInfoList() {
    const {
      fullTransport, showAllColumns, showPagination, isLoaded, error,
    } = this.props;

    return Object
      .keys(fullTransport)
      .map((transportInfoType) => (
        <CollapsableTransport
          key={transportInfoType}
          transportInfo={fullTransport[transportInfoType]}
          transportInfoType={transportInfoType}
          isLoaded={isLoaded}
          type={transportInfoType}
          showAllColumns={showAllColumns}
          showPagination={showPagination}
          error={error}
        />
      ));
  }

  render() {
    const transportInfoList = this.getTransportInfoList();

    return (
      <div>
        {transportInfoList.length > 0
          ? transportInfoList
          : this.getFallbackMessage(transportInfoList)}
      </div>
    );
  }
}

FullTransport.propTypes = {
  fullTransport: PropTypes.objectOf(PropTypes.shape),
  showAllColumns: PropTypes.bool,
  showPagination: PropTypes.bool,
  isLoaded: PropTypes.bool,
  error: PropTypes.bool,
};

FullTransport.defaultProps = {
  fullTransport: {},
  showAllColumns: false,
  showPagination: false,
  isLoaded: false,
  error: false,
};

export default FullTransport;
