import { Link } from '@ipc/ipc-core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/common/Button';
import { getUrl } from '../../../core/modules/common/tools/URLTools';
import TransportTable from './tables/TransportTable';
import TabsPanel from './TabsPanel';

export class CommonEventContentDetails extends Component {
  constructor(props) {
    super(props);
    this.requestEventDetails = props.requestEventDetails;
    this.getComponentMap = props.getComponentMap || this.getDefaultComponentMap;
    this.state = {
      map: props.showTabContent ? this.getComponentMap(this.prepareDataForComponentsInMap(props)) : null,
    };
  }

  componentDidMount() {
    const { event } = this.props;
    if (!event.eventInfo && this.requestEventDetails) {
      this.requestEventDetails();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showTabContent) {
      this.setState({
        map: this.getComponentMap(this.prepareDataForComponentsInMap(nextProps)),
      });
    }
  }

  getDefaultComponentMap = (props) => {
    const { eventInfo = {}, error } = props.event;
    const transportTableProps = {
      transportLegs: eventInfo.transportLegs,
      isLoaded: props.isLoaded,
      error,
    };

    return {
      transport: <TransportTable {...transportTableProps} />,
    };
  };

  getFullDetailsUrl() {
    const {
      event, entityId, fullDetailsUrl, view,
    } = this.props;
    return fullDetailsUrl || getUrl(
      view,
      entityId,
      'full-details',
      event.messageAggregateId,
      event.messageType,
      event.aggregateId,
    );
  }

  isDataLoaded = (props) => !!(props.event.eventInfo && props.event.eventInfo.transportLegs);

  prepareDataForComponentsInMap = (props) => ({
    ...props,
    isLoaded: this.isDataLoaded(props),
  });

  ref = (ELEMENT_REF) => {
    this.tabsPanel = ELEMENT_REF;
  };

  render() {
    const {
      event, generalInfoComponent, showTabContent, showFullDetails,
    } = this.props;
    const { error, errorMessage } = event;
    const { map } = this.state;
    return (
      <div className="common-event-content-details">
        {generalInfoComponent}
        {showTabContent && (
          <TabsPanel
            map={map}
            eventError={{
              error,
              errorMessage,
            }}
            ref={this.ref}
          />
        )}
        {showFullDetails && (
          <div className="full-details-button">
            <Link
              id="full-details-link"
              to={this.getFullDetailsUrl()}
            >
              <Button
                id="full-details-button"
                className="action-button download-button"
              >
                <FormattedMessage id="app.tracking.timeline.event.details.full-details" />
              </Button>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

CommonEventContentDetails.defaultProps = {
  showTabContent: true,
  showFullDetails: true,
};

CommonEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  entityId: PropTypes.string,
  requestEventDetails: PropTypes.func,
  getComponentMap: PropTypes.func,
  generalInfoComponent: PropTypes.element,
  fullDetailsUrl: PropTypes.string,
  view: PropTypes.string,
  showTabContent: PropTypes.bool,
  showFullDetails: PropTypes.bool,
};

export default CommonEventContentDetails;
