import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import { fullDetailsOverviewResditTemplate } from '../../../constants/ExcelTemplates';
import getOverviewMapper from '../../../../tracking/components/consignment/getOverviewMapper';

function generateExcelWorkbookOverviewPreconForFullDetails(overviewData) {
  const file = base64.decode(fullDetailsOverviewResditTemplate);
  const template = new XlsxTemplate(file);
  const overview = getOverviewMapper('RESDIT', overviewData, '');

  template.substitute('Overview', { overview });

  return template.generate();
}

export default generateExcelWorkbookOverviewPreconForFullDetails;
