/* eslint-disable global-require,no-console */
import config from '../../../../config/configureSettings';
import { getHistory } from './HistoryTools';

export const supportedLanguages = ['en', 'fr-fr', 'nl-be'].filter((lang) => config.languages.indexOf(lang) !== -1);
export const defaultLanguage = supportedLanguages.indexOf(config.defaultLanguage) >= 0 ? config.defaultLanguage : 'en';

export const areIntlLocalesSupported = (passedLocales) => {
  let locales = passedLocales;
  if (!locales) {
    throw new Error('[Internationalization] : locales must be supplied.');
  }

  if (typeof Intl === 'undefined') {
    console.log('[Internationalization] : window.Intl does not exist');
    return false;
  }

  if (!Array.isArray(locales)) {
    locales = [locales];
  }

  const intlConstructors = [
    Intl.DateTimeFormat,
    Intl.NumberFormat,
  ].filter((intlConstructor) => intlConstructor);

  if (intlConstructors.length === 0) {
    console.log(`[Internationalization] : window.Intl does not contain ${intlConstructors.toString()}`);
    return false;
  }

  return intlConstructors.every((intlConstructor) => {
    const supportedLocales = intlConstructor.supportedLocalesOf(locales);
    const localeSupportedForIntlFeature = supportedLocales.length === locales.length;

    if (!localeSupportedForIntlFeature) {
      console.log(`[Internationalization] : locales [${locales.toString()}] not supported for ${
        intlConstructor.name} [${supportedLocales.toString()}]`);
    }

    return localeSupportedForIntlFeature;
  });
};

export const polyfillIntl = () => {
  const applyPolyfill = () => {
    const IntlPolyfill = require('intl');
    Intl.NumberFormat = IntlPolyfill.NumberFormat;
    Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;

    require('intl/locale-data/jsonp/en');
    require('intl/locale-data/jsonp/fr-FR');
    require('intl/locale-data/jsonp/nl-BE');
  };

  if (!areIntlLocalesSupported(supportedLanguages)) {
    applyPolyfill();
  }
};

export const getLanguageFromURL = () => {
  const history = getHistory();
  const currentRoute = history.location.pathname.replace(config.basePath, '');
  return currentRoute.split('/')[0];
};
