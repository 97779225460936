import typography from '../Typography';
import sizes, { smallSizes } from '../Sizes';
import colors from '../Colors';

const breakpoints = ['768px', '1024px', '1360px', '1440px'];

const theme = {
  ...typography,
  sizes,
  space: smallSizes,
  colors,
  breakpoints,
};

export default theme;

// export default {
//   colors: {
//     primary: Blue,
//   },
// };
