import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from '@ipc/ipc-core';
import Table from '../../../../components/common/Table';
import { getUrl } from '../../../../core/modules/common/tools/URLTools';
import createColumnDefinition from '../../../react-table/helpers/createColumnDefinition';

export const linkCellRender = (row) => (
  <Link to={getUrl('item', row.value)}>{row.value}</Link>
);

export class ItemsTable extends React.Component {
  getColumnWidth = (smallColWidth, fullScreenWidth = 150) => {
    const {
      showAllColumns,
    } = this.props;

    return showAllColumns ? fullScreenWidth : smallColWidth;
  }

  render() {
    const { isLoaded, items } = this.props;
    const {
      error, intl, showPagination, pageSize, showAllColumns,
    } = this.props;
    const noDataText = intl.formatMessage({ id: 'app.tracking.timeline.event.detail.items.table.noData' });
    const tableColumns = [
      createColumnDefinition('app.tracking.timeline.event.detail.items.table.sequence', 'sequenceNumber', 1, this.getColumnWidth(20, 50)),
      createColumnDefinition('app.tracking.timeline.event.detail.items.table.itemId', 'itemId', 2, this.getColumnWidth(67, 165), {
        Cell: linkCellRender,
      }),
      createColumnDefinition('app.tracking.timeline.event.detail.items.table.handlingClass', 'handlingClass', 3, this.getColumnWidth(42, 100)),
      createColumnDefinition('app.tracking.timeline.event.detail.items.table.weight', 'weight', 4, this.getColumnWidth(35, 85)),
      createColumnDefinition('app.tracking.timeline.event.detail.items.table.chargingMethod', 'chargingMethod', 5, this.getColumnWidth(55, 135)),
      createColumnDefinition('app.tracking.timeline.event.detail.items.table.nonDeliveryReason', 'nonDeliveryReason', 6, this.getColumnWidth(55, 135)),
      createColumnDefinition('app.tracking.timeline.event.detail.items.table.destinationCountry', 'destinationCountry', 7, this.getColumnWidth(50, 125)),
      createColumnDefinition('app.tracking.timeline.event.detail.items.table.returnedCharge', 'returnedCharge', 8, this.getColumnWidth(40, 100)),
    ];

    if (showAllColumns) {
      tableColumns.push(
        createColumnDefinition('app.tracking.timeline.event.detail.items.table.insuredValue', 'insuredValue', 9, 95),
        createColumnDefinition('app.tracking.timeline.event.detail.items.table.express', 'express', 10, 95, 'bool'),
        createColumnDefinition('app.tracking.timeline.event.detail.items.table.dangerousGoodsClass', 'dangerousGoodsClass', 11, 135, 'bool'),
        createColumnDefinition('app.tracking.timeline.event.detail.items.table.itemClass', 'itemClass', 12, 70),
        createColumnDefinition('app.tracking.timeline.event.detail.items.table.eventEMD', 'eventEMD', 13, 75, 'date'),
        createColumnDefinition('app.tracking.timeline.event.detail.items.table.eventEMI', 'eventEMI', 14, 75, 'date'),
      );
    }

    return (
      <div className="ItemsTable">
        <Table
          id="table"
          columns={tableColumns}
          data={items}
          noDataText={isLoaded && _.isEmpty(items) && (noDataText)}
          loading={!isLoaded && !error}
          hasPagination={showPagination}
          defaultPageSize={pageSize}
          minRows={1}
        />
      </div>
    );
  }
}

ItemsTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape),
  isLoaded: PropTypes.bool,
  showAllColumns: PropTypes.bool,
  showPagination: PropTypes.bool,
  pageSize: PropTypes.number,
  error: PropTypes.bool,
};

ItemsTable.defaultProps = {
  items: [],
  showAllColumns: false,
  showPagination: false,
  pageSize: 10,
  isLoaded: false,
  error: false,
};

export default injectIntl(ItemsTable);
