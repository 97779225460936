import { useApiQuery } from '../../../../../../hooks';
import { NOT_FOUND, FORBIDDEN } from '../../../../../common/enum/HTTPStatusCodes';
import fieldFormatter from '../../../../../react-table/helpers/fieldFormatter';

export const useApiQueryWithErrorMessage = (...args) => {
  const response = useApiQuery(...args);
  let errorMessage = '';

  if (response.error) {
    const { status = '', data } = response.error;

    switch (status) {
    case NOT_FOUND:
      errorMessage = 'app.tracking.timeline.event.detail.errorMessage';
      break;

    case FORBIDDEN:
      errorMessage = 'app.permission.notAuthorized';
      break;

    default:
      errorMessage = data && data.message
        ? data.message
        : 'app.tracking.timeline.event.detail.errorMessage';
      break;
    }
  }

  return {
    ...response,
    errorMessage,
  };
};

const formatCurrency = (value, currency) => (value ? `${value} ${currency}` : '');

export const formatItemDetails = (data) => {
  const formattedData = {
    ...data,
    insuredValueAmount: formatCurrency(data.insuredValueAmount, data.insuredValueCurrency),
    postagePaidAmount: formatCurrency(data.postagePaidAmount, data.postagePaidCurrency),
    additionalFeesAmount: formatCurrency(data.additionalFeesAmount, data.additionalFeesCurrency),
    totalDeclaredValueAmount: formatCurrency(data.totalDeclaredValueAmount, data.totalDeclaredValueCurrency),
  };

  delete formattedData.insuredValueCurrency;
  delete formattedData.postagePaidCurrency;
  delete formattedData.additionalFeesCurrency;
  delete formattedData.totalDeclaredValueCurrency;

  return formattedData;
};

export const formatContentPieces = (data) => data.map((piece) => ({
  ...piece,
  declaredValueAmount: formatCurrency(piece.declaredValueAmount, piece.declaredValueCurrency),
}));

export const formatPartyData = (data) => {
  const formattedData = {
    ...data,
  };

  delete formattedData.role;
  return formattedData;
};

export const formatEventDetails = (data) => ({
  status: data.status,
  code: data.code,
  reason: data.reason,
  associatedEntityId: data.associatedEntityId,
  associatedEntityType: data.associatedEntityType,
  locationFunction: data.locationFunction,
  locationCode: data.locationCode,
  latestDate: fieldFormatter('date')({ value: data.latestDate }),
});
