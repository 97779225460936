import { applyMiddleware, compose, createStore } from 'redux';
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import freeze from 'redux-freeze';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';
import { isDev } from './utils/development/tools';

const devMode = isDev();

const composeEnhancers = devMode ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
const sagaMiddleware = createSagaMiddleware();

const middleware = [
  sagaMiddleware,
];

if (devMode) {
  middleware.push(freeze);
}

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middleware)),
);

// TODO Review the aggregation of all the sagas, something is not configured properly
sagaMiddleware.run(sagas);

export default store;
