import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  Switch, Flex, Box, AccordionHeader, AccordionItem, AccordionPanel,
} from '@ipc/ipc-components';
import { fontBodyHelper } from '../../../../elements/theme';
import { useTranslator, useUser } from '../../../../hooks';
import Permission from '../../../../security/permission';
import { userType } from '../../../../types';
import Button from '../../../common/Button';
import { useIsAuthorized } from '../../../common/ipc-core/useIsAuthorized';

import UserDetailHeader from './Header';
import UserDetailItem from './Item';
import UserRoles from './UserRoles';

const getRole = (role) => {
  const { id, name } = role;
  return {
    id,
    name,
  };
};

const getOrganization = (organization) => organization?.code;

const getOrganizations = (organizations) => (organizations && organizations.length > 0 ? organizations.map(getOrganization)
  .join(', ') : '');

const getRoles = (roles = []) => roles.map(getRole);

const StyledUserRoles = styled(UserRoles)` 
  max-width: 500px;
`;

const StyledActiveSwitch = styled.div`
  & .MuiFormControlLabel-root {
    margin: 0;
  }
  
  & .MuiFormControlLabel-label {
   ${fontBodyHelper};
  }
`;

const UserDetailForm = ({ user, onSubmit, expanded }) => {
  const { translate } = useTranslator();
  const isAuthorized = useIsAuthorized([Permission.EDIT_USER]);
  const methods = useForm({
    defaultValues: {
      ...user,
    },
  });

  const {
    control, handleSubmit,
  } = methods;
  const {
    id, username, firstName, lastName, email, organizations,
  } = user;

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} data-testid="userForm">
        <AccordionItem id={`user_${id}`} initialOpen={expanded} marginTop={2}>
          {({ isExpanded }) => (
            <>
              <AccordionHeader sx={{ backgroundColor: isExpanded ? 'grayOther' : 'white' }}>
                <UserDetailHeader username={username} mail={email} firstName={firstName} lastName={lastName} />
              </AccordionHeader>
              <AccordionPanel padding={0} backgroundColor="white">
                <Box padding={3}>
                  <Box component="ul" display="inline-flex" flexDirection="column" width="50%">
                    <UserDetailItem title={translate('administration.user.users.list.details.username')} value={username} />
                    <UserDetailItem title={translate('administration.user.users.list.details.email')} value={email} />
                    <UserDetailItem title={translate('administration.user.users.list.details.firstName')} value={firstName} />
                    <UserDetailItem title={translate('administration.user.users.list.details.lastName')} value={lastName} />
                    <UserDetailItem title={translate('administration.user.users.list.details.organizations')} value={getOrganizations(organizations)} />
                  </Box>

                  <Box component="ul" display="inline-flex" flexDirection="column" width="50%" borderLeft="1.22px solid #DDDDDD">
                    <Flex component="li" direction="column" ml={3}>
                      <Box component="span" color="primary.main">
                        <StyledActiveSwitch>
                          <Controller
                            control={control}
                            label={translate('administration.user.users.list.details.active')}
                            name="active"
                            defaultValue={user.active || false}
                            as={Switch}
                            size="medium"
                            disabled={!isAuthorized}
                          />
                        </StyledActiveSwitch>
                      </Box>
                      <Box component="div" color="primary.main" fontSize="paragraph" mb={3} mt={3}>
                        {translate('administration.user.users.list.details.roles')}
                      </Box>
                      <Box component="div" listStyle="inside" color="secondary.main" mb={2}>
                        <StyledUserRoles user={user} disabled={!isAuthorized} />
                        {isAuthorized && <Button type="submit" primary data-testid="confirmButton">{translate('button.confirm')}</Button>}
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </form>
    </FormContext>
  );
};

UserDetailForm.propTypes = {
  user: userType,
  onSubmit: PropTypes.func,
  expanded: PropTypes.bool,
};

const UserDetail = ({ expanded = false, user: userData, ...restProps }) => {
  const { fetchRoles, updateUser } = useUser(userData);
  const { data: rolesData, isSuccess } = fetchRoles();

  const userWithRoles = {
    ...userData,
    roles: getRoles(rolesData),
  };

  return (isSuccess && <UserDetailForm user={userWithRoles} onSubmit={updateUser} expanded={expanded} {...restProps} />);
};

UserDetail.propTypes = {
  expanded: PropTypes.bool,
  user: userType,
};

export default memo(UserDetail);
