export const eventSortIndex = {
  EMA: 10000,
  EDA: 10100,
  ITMATT: 20000,
  EMB: 30000,
  EXA: 40000,
  EXB: 50000,
  EXC: 60000,
  EMC: 70000,
  PREDES: 80000,
  PRECON: 90000,
  CARDIT_1: 100000,
  CARDIT_4: 100100,
  CARDIT_5: 100200,
  CARDIT_6: 100300,
  CARDIT_9: 100400,
  CARDIT_47: 100500,
  MRD_POC: 200000,
  POC: 200000,
  RESDIT_1: 300000,
  RESDIT_5: 300000,
  RESDIT_6: 300000,
  RESDIT_7: 300000,
  RESDIT_14: 300000,
  RESDIT_21: 300000,
  RESDIT_23: 300000,
  RESDIT_24: 300000,
  RESDIT_31: 300000,
  RESDIT_40: 300000,
  RESDIT_41: 300000,
  RESDIT_42: 300000,
  RESDIT_43: 300000,
  RESDIT_48: 300000,
  RESDIT_57: 300000,
  RESDIT_59: 300000,
  RESDIT_74: 300000,
  RESDIT_82: 300000,
  MRD_POD: 400000,
  POD: 400000,
  RESCON: 500000,
  RESDES: 600000,
  EMD: 700000,
  EDB: 800000,
  EME: 800100,
  EXD: 800200,
  EXX: 800300,
  EDC: 900000,
  EMF: 1000000,
  EDD: 1100000,
  EDE: 1200000,
  EDF: 1250000,
  EMG: 1300000,
  EDG: 1400000,
  EDH: 1410000,
  EMJ: 1420000,
  EMK: 1430000,
  EMH: 1500000,
  EMI: 1510000,
};

function replaceSpaceWithUnderscore(string) {
  return string.replace(' ', '_');
}

function getEventSortIndex(eventName) {
  return eventSortIndex[replaceSpaceWithUnderscore(eventName.trim())];
}

function eventDateSortAsc(event1, event2) {
  if (event1.eventDate > event2.eventDate) return 1;
  if (event1.eventDate < event2.eventDate) return -1;
  return 0;
}

function eventNameSortAsc(event1, event2) {
  const eventName1SortIndex = getEventSortIndex(event1.eventName);
  const eventName2SortIndex = getEventSortIndex(event2.eventName);

  if (eventName1SortIndex > eventName2SortIndex) return 1;
  if (eventName1SortIndex < eventName2SortIndex) return -1;
  return 0;
}

export function eventSortAsc(event1, event2) {
  const resultDate = eventDateSortAsc(event1, event2);
  return resultDate === 0 ? eventNameSortAsc(event1, event2) : resultDate;
}

function eventDateSortDesc(event1, event2) {
  return -1 * eventDateSortAsc(event1, event2);
}

function eventNameSortDesc(event1, event2) {
  return -1 * eventNameSortAsc(event1, event2);
}

export function eventSortDesc(event1, event2) {
  const resultDate = eventDateSortDesc(event1, event2);
  return resultDate === 0 ? eventNameSortDesc(event1, event2) : resultDate;
}
