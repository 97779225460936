import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const DefaultFullDetails = (props) => {
  const {
    event = {},
  } = props;

  return (
    <div className="default-full-details">
      <FormattedMessage
        id="app.tracking.common.fullDetails.invalidEvent"
        values={{ eventName: <b>{event.eventName}</b> }}
      />
    </div>
  );
};

DefaultFullDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};

export default DefaultFullDetails;
