import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Switch } from '@ipc/ipc-components';
import noop from '../../../../../core/modules/common/tools/noop';

const TimelineEventContentEdiToggle = (props) => {
  const {
    toggleRawEdi = noop(),
    isToggled = false,
  } = props;

  const label = (
    <div className="timeline-event-content-edi-toggle-component-label">
      <span className={isToggled ? 'toggleOn' : 'toggleOff'}>
        <FormattedMessage
          id={isToggled ? 'app.tracking.timeline.edi.toggle.off' : 'app.tracking.timeline.edi.toggle.on'}
        />
      </span>
    </div>
  );
  return (
    <div className="timeline-event-content-edi-toggle-component">
      <Switch
        label={label}
        size="medium"
        onChange={toggleRawEdi}
        checked={isToggled}
      />
    </div>
  );
};

TimelineEventContentEdiToggle.propTypes = {
  toggleRawEdi: PropTypes.func,
  isToggled: PropTypes.bool,
};

export default TimelineEventContentEdiToggle;
