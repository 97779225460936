import {
  faTrash,
  faEdit,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCompressAlt,
  faDownload,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faMapMarkerAlt,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faSearch,
  faTimes,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

/* eslint-disable */
export default {
  CrossIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faTimes} /></i>,
  ViewMoreIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faPlus} /></i>,
  ExpandIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faExpandAlt} /></i>,
  CollapseIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faCompressAlt} /></i>,
  DownloadIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faDownload} /></i>,
  EyeIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faEye} /></i>,
  EyeSlashIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faEyeSlash} /></i>,
  BackIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faChevronLeft} /></i>,
  ForwardIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faChevronRight} /></i>,
  DownIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faChevronDown} /></i>,
  UpIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faChevronUp} /></i>,
  SearchIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faSearch} /></i>,
  MapMarkerIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faMapMarkerAlt} /></i>,
  PlusIcon: ({ className, ...rest }) => <i className={className}><FontAwesomeIcon icon={faPlusCircle} {...rest} /></i>,
  MinusIcon: ({ className, ...rest }) => <i className={className}><FontAwesomeIcon icon={faMinusCircle} {...rest} /></i>,
  EditIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faEdit} /></i>,
  DeleteIcon: ({ className }) => <i className={className}><FontAwesomeIcon icon={faTrash} /></i>,
  SignOut: ({ className }) => <i className={className}><FontAwesomeIcon icon={faSignOutAlt} /></i>,
};
