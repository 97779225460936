import {
  FETCH_EDI_FORMATTED_FAILURE,
  FETCH_EDI_FORMATTED_REQUEST,
  FETCH_EDI_FORMATTED_SUCCESS,
  FETCH_EDI_RAW_FAILURE,
  FETCH_EDI_RAW_REQUEST,
  FETCH_EDI_RAW_SUCCESS,
} from './types/EdiActionTypes';

export function fetchEdiRawRequestActionCreator(key, source) {
  return {
    type: FETCH_EDI_RAW_REQUEST,
    params: { key, source },
  };
}

export function fetchEdiRawSuccessActionCreator(payload) {
  return {
    type: FETCH_EDI_RAW_SUCCESS,
    payload,
  };
}

export function fetchEdiRawFailureActionCreator(error) {
  return {
    type: FETCH_EDI_RAW_FAILURE,
    payload: error,
    error: true,
  };
}

export function fetchEdiFormattedRequestActionCreator(key, source) {
  return {
    type: FETCH_EDI_FORMATTED_REQUEST,
    params: { key, source },
  };
}

export function fetchEdiFormattedSuccessActionCreator(payload) {
  return {
    type: FETCH_EDI_FORMATTED_SUCCESS,
    payload,
  };
}

export function fetchEdiFormattedFailureActionCreator(error) {
  return {
    type: FETCH_EDI_FORMATTED_FAILURE,
    payload: error,
    error: true,
  };
}
