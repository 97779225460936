import React from 'react';
import styled from 'styled-components';
import { Table } from '@ipc/ipc-components';
import Title from '../../../../../modules/common/components/Title';

export const StyledTitle = styled(Title)`
  margin-bottom : 30px;
`;

export const StyledTable = styled((props) => <Table {...props} />)`
  margin-top : 10px;
`;
