import {
  get, patch, post, destroy,
} from '../request';
import { constructServiceURL } from '../../core/modules/common/tools/URLTools';

export const createRole = (data) => post(constructServiceURL({ endpoint: 'createRole' }), data);

export const fetchRoles = () => get(constructServiceURL({ endpoint: 'getRoles' }));

export const fetchRole = (id) => get(constructServiceURL({
  endpoint: 'getRole',
  params: { id },
}));

export const deleteRole = async (roleId) => {
  await destroy(constructServiceURL({
    endpoint: 'deleteRoles',
    params: {
      roleId,
    },
  }));

  return roleId;
};

export const editRole = (data) => patch(constructServiceURL({
  endpoint: 'editRole',
  params: {
    id: data.id,
  },
}), data.role);
