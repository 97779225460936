import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pushHistory } from '../../../core/modules/common/tools/HistoryTools';
import { secureFullDetails } from '../../../security';
import { FullDetails } from '../components/consignment/full-details/FullDetails';
import {
  fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator,
  fetchConsignmentAdvisedToDestPostForConsignmentRequestActionCreator,
  fetchConsignmentArrivedAtDestPostForConsignmentRequestActionCreator,
  fetchConsignmentReportedOnByCarrierForConsignmentRequestActionCreator,
} from '../redux/actions/EventInfoActions';

const mapDispatchToProps = (dispatch) => (bindActionCreators({
  fetchConsignmentArrivedAtDestPostForConsignmentRequestActionCreator,
  fetchConsignmentReportedOnByCarrierForConsignmentRequestActionCreator,
  fetchConsignmentAdvisedToDestPostForConsignmentRequestActionCreator,
  fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator,
}, dispatch));

const mapStateToProps = (state, props) => ({
  fullDetailsEvent: _.find(
    state.tracking.consignment.consignment.allEvents,
    { aggregateId: props.match.params.aggregateId },
  ) || {},
});

export class FullDetailsForConsignmentContainer extends Component {
  componentDidMount() {
    const {
      fetchConsignmentReportedOnByCarrierForConsignmentRequestActionCreator: fetchConsignmentReportedOnByCarrierForConsignmentRequestActionCreator1,
      fetchConsignmentAdvisedToDestPostForConsignmentRequestActionCreator: fetchConsignmentAdvisedToDestPostForConsignmentRequestActionCreator1,
      fetchConsignmentArrivedAtDestPostForConsignmentRequestActionCreator: fetchConsignmentArrivedAtDestPostForConsignmentRequestActionCreator1,
      fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator: fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator1,
      match,
    } = this.props;
    const { params } = match;
    const {
      aggregateId,
      messageAggregateId,
      messageType,
      consignmentId,
    } = params;

    const urlParams = [messageAggregateId, aggregateId, consignmentId];
    switch (messageType) {
    case 'RESCON':
      fetchConsignmentArrivedAtDestPostForConsignmentRequestActionCreator1(...urlParams);
      break;
    case 'RESDIT':
    case 'IFTSTA':
      fetchConsignmentReportedOnByCarrierForConsignmentRequestActionCreator1(...urlParams);
      break;
    case 'PRECON':
      fetchConsignmentAdvisedToDestPostForConsignmentRequestActionCreator1(...urlParams);
      break;
    case 'CARDIT':
    case 'IFCSUM':
      fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator1(...urlParams);
      break;
    default:
      pushHistory('/');
    }
  }

  render() {
    const { fullDetailsEvent } = this.props;
    return secureFullDetails(FullDetails, fullDetailsEvent);
  }
}

FullDetailsForConsignmentContainer.propTypes = {
  fetchConsignmentArrivedAtDestPostForConsignmentRequestActionCreator: PropTypes.func,
  fetchConsignmentReportedOnByCarrierForConsignmentRequestActionCreator: PropTypes.func,
  fetchConsignmentAdvisedToDestPostForConsignmentRequestActionCreator: PropTypes.func,
  fetchConsignmentAdvisedToCarrierForConsignmentRequestActionCreator: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      consignmentId: PropTypes.string,
      messageAggregateId: PropTypes.string,
      messageType: PropTypes.string,
      aggregateId: PropTypes.string,
    }),
  }),
  fullDetailsEvent: PropTypes.objectOf(PropTypes.shape),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FullDetailsForConsignmentContainer);
