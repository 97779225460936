import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import CommonEventContentDetails from '../../../../common/components/CommonEventContentDetails';
import { fetchConsignmentAdvisedToCarrierRequestActionCreator } from '../../../redux/actions/EventInfoActions';
import ConsignmentEventGeneralInfo from './ConsignmentEventGeneralInfo';

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchConsignmentAdvisedToCarrierRequestActionCreator,
}, dispatch);

const mapStateToProps = (state) => ({
  receptacleId: state.tracking.receptacle.receptacle.receptacleId,
});

export class CarditEventContentDetails extends Component {
  requestEventDetails = () => {
    const { fetchConsignmentAdvisedToCarrierRequestActionCreator: fetchConsignmentAdvisedToCarrierRequestActionCreator1, event, receptacleId } = this.props;
    const { messageAggregateId, aggregateId, consignmentId } = event;
    fetchConsignmentAdvisedToCarrierRequestActionCreator1(
      messageAggregateId,
      aggregateId,
      receptacleId,
      consignmentId,
    );
  };

  fullDetailsUrl() {
    const { event, receptacleId, view } = this.props;
    return getUrl(
      view,
      receptacleId,
      event.consignmentId,
      'full-details',
      event.messageAggregateId,
      event.messageType,
      event.aggregateId,
    );
  }

  render() {
    const { receptacleId } = this.props;
    return (
      <CommonEventContentDetails
        {...this.props}
        entityId={receptacleId}
        fullDetailsUrl={this.fullDetailsUrl()}
        generalInfoComponent={<ConsignmentEventGeneralInfo {...this.props} />}
        requestEventDetails={this.requestEventDetails}
      />
    );
  }
}

CarditEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  fetchConsignmentAdvisedToCarrierRequestActionCreator: PropTypes.func,
  receptacleId: PropTypes.string,
  view: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarditEventContentDetails);
