import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Red } from '../../elements/Colors';
import { useTranslator } from '../../hooks';

const StyledRequiredIndicator = styled.i`
  color: ${Red}
`;

const RequiredIndicator = () => <StyledRequiredIndicator>*</StyledRequiredIndicator>;

export const Required = ({ labelId, children, ...props }) => {
  const { translate } = useTranslator();
  return (
    <div>
      <div>
        {`${translate(labelId)} `}
        <RequiredIndicator />
      </div>
      <div>{React.cloneElement(children, { ...children.props, ...props })}</div>
    </div>
  );
};

Required.propTypes = {
  labelId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
