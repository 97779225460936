import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonEventContentDetails from '../../../../common/components/CommonEventContentDetails';
import TransportTable from '../../../../common/components/tables/TransportTable';
import { fetchDespatchClosedForDespatchRequestActionCreator, fetchFullTransportForPredesRequestActionCreator } from '../../../redux/actions/EventInfoActions';
import FullTransport from './FullTransportForPredes';
import ItemsByReceptacles from './ItemsByReceptacles';
import PredesGeneralInfo from './PredesGeneralInfo';
import ReceptaclesTableForDespatch from './ReceptaclesTableForDespatch';

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDespatchClosedForDespatchRequestActionCreator,
  fetchFullTransportForPredesRequestActionCreator,
}, dispatch);

const mapStateToProps = (state) => ({
  despatchId: state.tracking.despatch.despatch.despatchId,
});

export class PredesEventContentDetails extends Component {
  getComponentMap = (props) => {
    const { eventInfo = {}, error } = props.event;
    return {
      receptacles: (
        <ReceptaclesTableForDespatch
          receptacles={eventInfo.receptacles}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
      transport: (
        <TransportTable
          transportLegs={eventInfo.transportLegs}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
      items: (
        <ItemsByReceptacles
          items={eventInfo.items}
          receptacles={eventInfo.receptacles}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
      'full transport': {
        tabContent: (
          <FullTransport
            fullTransport={eventInfo.fullTransport}
            isLoaded={this.isDataLoadedToFullTransport()}
            error={error}
          />
        ),
        onActive: () => {
          if (!this.isDataLoadedToFullTransport()) {
            this.requestFullTransportEventDetails();
          }
        },
      },
    };
  };

  isDataLoadedToFullTransport = () => {
    const { event } = this.props;
    return event.eventInfo && !_.isEmpty(event.eventInfo.fullTransport || {});
  };

  requestEventDetails = () => {
    const { event, fetchDespatchClosedForDespatchRequestActionCreator: fetchDespatchClosedForDespatchRequestActionCreator1 } = this.props;
    const { messageAggregateId, aggregateId } = event;
    fetchDespatchClosedForDespatchRequestActionCreator1(messageAggregateId, aggregateId);
  };

  requestFullTransportEventDetails = () => {
    const { event, fetchFullTransportForPredesRequestActionCreator: fetchFullTransportForPredesRequestActionCreator1 } = this.props;
    fetchFullTransportForPredesRequestActionCreator1(
      event.messageAggregateId,
      event.aggregateId,
    );
  }

  render() {
    const { despatchId } = this.props;
    return (
      <CommonEventContentDetails
        {...this.props}
        entityId={despatchId}
        generalInfoComponent={<PredesGeneralInfo {...this.props} />}
        requestEventDetails={this.requestEventDetails}
        getComponentMap={this.getComponentMap}
      />
    );
  }
}

PredesEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  fetchDespatchClosedForDespatchRequestActionCreator: PropTypes.func,
  fetchFullTransportForPredesRequestActionCreator: PropTypes.func,
  despatchId: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PredesEventContentDetails);
