import { REDIRECT_DONE, REDIRECT_INIT } from '../actions/types/redirectActionTypes';

const initialState = {
  status: 'DONE',
  url: '',
};

export const redirectReducer = (state = initialState, action) => {
  switch (action.type) {
  case REDIRECT_INIT:
    return {
      status: 'INIT',
      url: action.payload,
    };
  case REDIRECT_DONE:
    return initialState;
    // Add your own action types here if redirect is needed
  default:
    return state;
  }
};
