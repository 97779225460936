import { Redirect, Route, Auth } from '@ipc/ipc-core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  authentication: new Auth(state.global.auth),
});

const getPrivateRouteRenderFunction = ({
  Component, auth, autoRedirectToLogin = true, loginPath = '/login', render, ...others
}) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { location } = props;
  if (auth.isAuthenticated()) {
    return (Component ? <Component {...props} {...others} /> : render(props));
  }

  return autoRedirectToLogin ? (<Redirect to={{ pathname: loginPath, state: { from: location } }} />) : null;
};

const PrivateRouteComponent = ({
  component: Component, authentication: auth, autoRedirectToLogin, loginPath, render, ...attrs
}) => (
  <Route
    {...attrs}
    render={getPrivateRouteRenderFunction({
      Component, auth, autoRedirectToLogin, loginPath, render,
    })}
  />
);

PrivateRouteComponent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  render: PropTypes.func,
  authentication: PropTypes.shape(Auth),
  autoRedirectToLogin: PropTypes.bool,
  loginPath: PropTypes.oneOfType([PropTypes.string, PropTypes.shape]),
};

export default connect(mapStateToProps)(PrivateRouteComponent);
