import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from '../../../../api';
import {
  fetchDespatchClosedForReceptacleFailureActionCreator,
  fetchDespatchClosedForReceptacleSuccessActionCreator,
  fetchDespatchClosedForDespatchFailureActionCreator,
  fetchDespatchClosedForDespatchSuccessActionCreator,
  fetchConsignmentAdvisedToCarrierFailureActionCreator,
  fetchConsignmentAdvisedToCarrierSuccessActionCreator,
  fetchConsignmentAdvisedToCarrierForConsignmentFailureActionCreator,
  fetchConsignmentAdvisedToCarrierForConsignmentSuccessActionCreator,
  fetchConsignmentAdvisedToDestPostFailureActionCreator,
  fetchConsignmentAdvisedToDestPostSuccessActionCreator,
  fetchConsignmentAdvisedToDestPostForConsignmentFailureActionCreator,
  fetchConsignmentAdvisedToDestPostForConsignmentSuccessActionCreator,
  fetchConsignmentArrivedAtDestPostFailureActionCreator,
  fetchConsignmentArrivedAtDestPostSuccessActionCreator,
  fetchConsignmentArrivedAtDestPostForConsignmentFailureActionCreator,
  fetchConsignmentArrivedAtDestPostForConsignmentSuccessActionCreator,
  fetchConsignmentReportedOnByCarrierFailureActionCreator,
  fetchConsignmentReportedOnByCarrierSuccessActionCreator,
  fetchConsignmentReportedOnByCarrierForConsignmentFailureActionCreator,
  fetchConsignmentReportedOnByCarrierForConsignmentSuccessActionCreator,
  fetchDespatchArrivedInwardOeForReceptacleFailureActionCreator,
  fetchDespatchArrivedInwardOeForReceptacleSuccessActionCreator,
  fetchDespatchArrivedInwardOeForDespatchFailureActionCreator,
  fetchDespatchArrivedInwardOeForDespatchSuccessActionCreator,
  fetchFullTransportForPredesSuccessActionCreator,
  fetchFullTransportForPredesFailureActionCreator,
} from '../actions/EventInfoActions';
import {
  FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_REQUEST,
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_REQUEST,
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_REQUEST,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_REQUEST,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_REQUEST,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_REQUEST,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_REQUEST,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_REQUEST,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_REQUEST,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_REQUEST,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_REQUEST,
  FETCH_DESPATCH_CLOSED_FOR_DESPATCH_REQUEST,
  FETCH_FULL_TRANSPORT_FOR_PREDES_REQUEST,
} from '../actions/types/EventInfoActionTypes';

function* fetchDespatchClosedForReceptacleWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId, params.receptacleId];
  try {
    const res = yield call(api.fetchDespatchClosedForReceptacle, ...apiParams);
    yield put(fetchDespatchClosedForReceptacleSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchDespatchClosedForReceptacleFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchDespatchClosedForDespatchWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId];
  try {
    const res = yield call(api.fetchDespatchClosedForDespatch, ...apiParams);
    yield put(fetchDespatchClosedForDespatchSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchDespatchClosedForDespatchFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchDespatchArrivedInwardOeForReceptacleWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId, params.receptacleId];
  try {
    const res = yield call(api.fetchDespatchArrivedInwardOeForReceptacle, ...apiParams);
    yield put(fetchDespatchArrivedInwardOeForReceptacleSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchDespatchArrivedInwardOeForReceptacleFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchDespatchArrivedInwardOeForDespatchWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId];
  try {
    const res = yield call(api.fetchDespatchArrivedInwardOeForDespatch, ...apiParams);
    yield put(fetchDespatchArrivedInwardOeForDespatchSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchDespatchArrivedInwardOeForDespatchFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchDespatchClosedFullTransportForDespatchWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId];
  try {
    const res = yield call(api.fetchDespatchClosedFullTransportForDespatch, ...apiParams);
    yield put(fetchFullTransportForPredesSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchFullTransportForPredesFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchConsignmentAdvisedToDestinationPostWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId, params.receptacleId];
  try {
    const res = yield call(api.fetchConsignmentAdvisedToDestinationPost, ...apiParams);
    yield put(fetchConsignmentAdvisedToDestPostSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentAdvisedToDestPostFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchConsignmentAdvisedToDestinationPostForConsignmentWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId, params.consignmentId];
  try {
    const res = yield call(
      api.fetchConsignmentAdvisedToDestinationPostForConsignment,
      ...apiParams,
    );
    yield put(fetchConsignmentAdvisedToDestPostForConsignmentSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentAdvisedToDestPostForConsignmentFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchConsignmentAdvisedToCarrierWorker(action) {
  const { params } = action;
  const apiParams = [
    params.messageAggregateId,
    params.aggregateId,
    params.receptacleId,
    params.consignmentId,
  ];
  try {
    const res = yield call(api.fetchConsignmentAdvisedToCarrier, ...apiParams);
    yield put(fetchConsignmentAdvisedToCarrierSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentAdvisedToCarrierFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchConsignmentAdvisedToCarrierForConsignmentWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId, params.consignmentId];
  try {
    const res = yield call(api.fetchConsignmentAdvisedToCarrierForConsignment, ...apiParams);
    yield put(fetchConsignmentAdvisedToCarrierForConsignmentSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentAdvisedToCarrierForConsignmentFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchConsignmentArrivedAtDestinationPostWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId, params.receptacleId];
  try {
    const res = yield call(api.fetchConsignmentArrivedAtDestinationPost, ...apiParams);
    yield put(fetchConsignmentArrivedAtDestPostSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentArrivedAtDestPostFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchConsignmentArrivedAtDestinationPostForConsignmentWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId];
  try {
    const res = yield call(
      api.fetchConsignmentArrivedAtDestinationPostForConsignment,
      ...apiParams,
    );
    yield put(fetchConsignmentArrivedAtDestPostForConsignmentSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentArrivedAtDestPostForConsignmentFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchConsignmentReportedOnByCarrierWorker(action) {
  const { params } = action;
  const apiParams = [
    params.messageAggregateId, params.aggregateId, params.receptacleId, params.consignmentId,
  ];
  try {
    const res = yield call(api.fetchConsignmentReportedOnByCarrier, ...apiParams);
    yield put(fetchConsignmentReportedOnByCarrierSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentReportedOnByCarrierFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

function* fetchConsignmentReportedOnByCarrierForConsignmentWorker(action) {
  const { params } = action;
  const apiParams = [params.messageAggregateId, params.aggregateId, params.consignmentId];
  try {
    const res = yield call(api.fetchConsignmentReportedOnByCarrierForConsignment, ...apiParams);
    yield put(fetchConsignmentReportedOnByCarrierForConsignmentSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentReportedOnByCarrierForConsignmentFailureActionCreator(
      error,
      params.aggregateId,
      params.messageAggregateId,
    ));
  }
}

export function* fetchDespatchClosedForReceptacleWatcher() {
  yield takeEvery(
    FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_REQUEST,
    fetchDespatchClosedForReceptacleWorker,
  );
}

export function* fetchDespatchClosedForDespatchWatcher() {
  yield takeEvery(FETCH_DESPATCH_CLOSED_FOR_DESPATCH_REQUEST, fetchDespatchClosedForDespatchWorker);
}

export function* fetchDespatchArrivedInwardOeForReceptacleWatcher() {
  yield takeEvery(
    FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_REQUEST,
    fetchDespatchArrivedInwardOeForReceptacleWorker,
  );
}

export function* fetchDespatchArrivedInwardOeForDespatchWatcher() {
  yield takeEvery(
    FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_REQUEST,
    fetchDespatchArrivedInwardOeForDespatchWorker,
  );
}

export function* fetchDespatchFullTransportForPredesWatcher() {
  yield takeEvery(
    FETCH_FULL_TRANSPORT_FOR_PREDES_REQUEST,
    fetchDespatchClosedFullTransportForDespatchWorker,
  );
}

export function* fetchConsignmentAdvisedToDestinationPostWatcher() {
  yield takeEvery(
    FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_REQUEST,
    fetchConsignmentAdvisedToDestinationPostWorker,
  );
}

export function* fetchConsignmentAdvisedToDestinationPostForConsignmentWatcher() {
  yield takeEvery(
    FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_REQUEST,
    fetchConsignmentAdvisedToDestinationPostForConsignmentWorker,
  );
}

export function* fetchConsignmentAdvisedToCarrierWatcher() {
  yield takeEvery(
    FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_REQUEST,
    fetchConsignmentAdvisedToCarrierWorker,
  );
}

export function* fetchConsignmentAdvisedToCarrierForConsignmentWatcher() {
  yield takeEvery(
    FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_REQUEST,
    fetchConsignmentAdvisedToCarrierForConsignmentWorker,
  );
}

export function* fetchConsignmentArrivedAtDestinationPostWatcher() {
  yield takeEvery(
    FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_REQUEST,
    fetchConsignmentArrivedAtDestinationPostWorker,
  );
}

export function* fetchConsignmentArrivedAtDestinationPostForConsignmentWatcher() {
  yield takeEvery(
    FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_REQUEST,
    fetchConsignmentArrivedAtDestinationPostForConsignmentWorker,
  );
}

export function* fetchConsignmentReportedOnByCarrierWatcher() {
  yield takeEvery(
    FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_REQUEST,
    fetchConsignmentReportedOnByCarrierWorker,
  );
}

export function* fetchConsignmentReportedOnByCarrierForConsignmentWatcher() {
  yield takeEvery(
    FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_REQUEST,
    fetchConsignmentReportedOnByCarrierForConsignmentWorker,
  );
}
