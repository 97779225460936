import { Login } from '@ipc/ipc-components';
import {
  Auth,
  initLoginAction,
  loginFailedAction,
  loginSucceededAction,
  OAuthAuthenticationService,
  Redirect,
} from '@ipc/ipc-core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { LoginContainerStyle } from './Login.style';

const mapStateToProps = (state) => ({
  auth: new Auth(state.global.auth),
});

const mapDispatchToPro = {
  initLogin: initLoginAction,
  loginSucceeded: loginSucceededAction,
  loginFailed: loginFailedAction,
};

const LoginContainer = styled.div`${LoginContainerStyle}`;

const INVALID_GRANT = 'invalid_grant';
const INVALID_CREDENTIALS = 'error.credentials.invalid';
const UNEXPECTED_ERROR = 'error.unexpected_error';

const getErrorFromResponse = (error) => error.response?.data?.error;

const createLoginResultHandler = (loginSucceeded, loginFailed) => ({ error, data }) => {
  if (error) {
    const errorId = getErrorFromResponse(error);
    if (INVALID_GRANT === errorId) {
      loginFailed(INVALID_CREDENTIALS);
    } else {
      loginFailed(UNEXPECTED_ERROR);
    }
  } else {
    loginSucceeded(data);
  }
};

export class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
    };
  }

    login = (username, password) => {
      const {
        initLogin, loginSucceeded, loginFailed,
      } = this.props;

      const loginHandler = createLoginResultHandler(loginSucceeded, loginFailed);
      initLogin(username, password);
      OAuthAuthenticationService.login({ username, password, callback: loginHandler });
    };

    render() {
      const { location, auth } = this.props;
      const { from } = location.state || { from: { pathname: location.pathname } };
      const { redirectToReferrer } = this.state;

      if (auth.isAuthenticated() || redirectToReferrer === true) {
        const to = location.pathname === from.pathname ? '/' : from;
        return <Redirect to={to} />;
      }

      const errorMessage = (auth.error) ? <FormattedMessage id={auth.error} /> : '';
      return (
        <LoginContainer>
          <Login login={this.login} errorMessage={errorMessage} />
        </LoginContainer>
      );
    }
}

LoginComponent.propTypes = {
  location: PropTypes.objectOf(PropTypes.shape),
  initLogin: PropTypes.func,
  loginSucceeded: PropTypes.func,
  loginFailed: PropTypes.func,
  auth: PropTypes.instanceOf(Auth),
};

export default connect(mapStateToProps, mapDispatchToPro)(LoginComponent);
