import {
  editUser, editUserRoles, fetchUserRoles, fetchUsers,
} from './administration/UsersAPIs';
import { doSearch } from './tracking/SearchAPIs';
import {
  fetchItem, fetchContentForItem, fetchItmrefDetails, fetchRefrspDetails,
} from './tracking/ItemAPIs';
import {
  fetchEdiFormatted, fetchEdiRaw, fetchEdi, fetchPodEdi, fetchItmattEvent,
} from './tracking/EdiAPIs';
import {
  fetchDespatchClosedForReceptacle,
  fetchDespatchClosedForDespatch,
  fetchDespatchArrivedInwardOeForReceptacle,
  fetchDespatchArrivedInwardOeForDespatch,
  fetchDespatchClosedFullTransportForDespatch,
  fetchConsignmentAdvisedToCarrier,
  fetchConsignmentAdvisedToCarrierForConsignment,
  fetchConsignmentAdvisedToDestinationPost,
  fetchConsignmentAdvisedToDestinationPostForConsignment,
  fetchConsignmentArrivedAtDestinationPost,
  fetchConsignmentArrivedAtDestinationPostForConsignment,
  fetchConsignmentReportedOnByCarrier,
  fetchConsignmentReportedOnByCarrierForConsignment,
} from './tracking/EventInfoAPIs';
import { fetchReceptacle } from './tracking/ReceptacleAPIs';
import { fetchConsignment } from './tracking/ConsignmentAPIs';
import { fetchDespatch } from './tracking/DespatchAPIs';
import { fetchAllPermissions, fetchPermissions } from './administration/PermissionsAPIs';
import {
  createRole, editRole, fetchRole, fetchRoles, deleteRole,
} from './administration/RolesAPIs';

export const api = {
  doSearch,
  fetchItem,
  fetchEdi,
  fetchContentForItem,
  fetchItmrefDetails,
  fetchRefrspDetails,
  fetchEdiRaw,
  fetchEdiFormatted,
  fetchPodEdi,
  fetchItmattEvent,
  fetchReceptacle,
  fetchConsignment,
  fetchDespatch,
  fetchDespatchClosedForReceptacle,
  fetchDespatchClosedForDespatch,
  fetchDespatchArrivedInwardOeForReceptacle,
  fetchDespatchArrivedInwardOeForDespatch,
  fetchDespatchClosedFullTransportForDespatch,
  fetchConsignmentAdvisedToCarrier,
  fetchConsignmentAdvisedToCarrierForConsignment,
  fetchConsignmentAdvisedToDestinationPost,
  fetchConsignmentAdvisedToDestinationPostForConsignment,
  fetchConsignmentArrivedAtDestinationPost,
  fetchConsignmentArrivedAtDestinationPostForConsignment,
  fetchConsignmentReportedOnByCarrier,
  fetchConsignmentReportedOnByCarrierForConsignment,
  fetchAllPermissions,
  fetchPermissions,
  createRole,
  fetchRole,
  fetchRoles,
  editRole,
  deleteRole,
  fetchUsers,
  fetchUserRoles,
  editUser,
  editUserRoles,
};
