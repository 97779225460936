import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pushHistory } from '../../../core/modules/common/tools/HistoryTools';
import { FullDetails } from '../components/receptacle/full-details/FullDetails';
import { secureFullDetails } from '../../../security';
import { fullDetailsEventType } from '../../../types';
import {
  fetchConsignmentAdvisedToCarrierRequestActionCreator,
  fetchConsignmentAdvisedToDestPostRequestActionCreator,
  fetchConsignmentArrivedAtDestPostRequestActionCreator,
  fetchConsignmentReportedOnByCarrierRequestActionCreator,
  fetchDespatchArrivedInwardOeForReceptacleRequestActionCreator,
  fetchDespatchClosedForReceptacleRequestActionCreator,
} from '../redux/actions/EventInfoActions';

const mapDispatchToProps = (dispatch) => (bindActionCreators({
  fetchDespatchArrivedInwardOeForReceptacleRequestActionCreator,
  fetchConsignmentArrivedAtDestPostRequestActionCreator,
  fetchDespatchClosedForReceptacleRequestActionCreator,
  fetchConsignmentReportedOnByCarrierRequestActionCreator,
  fetchConsignmentAdvisedToDestPostRequestActionCreator,
  fetchConsignmentAdvisedToCarrierRequestActionCreator,
}, dispatch));

const mapStateToProps = (state, props) => ({
  fullDetailsEvent: _.find(
    state.tracking.receptacle.receptacle.allEvents,
    { aggregateId: props.match.params.aggregateId },
  ) || {},
});

export class FullDetailsForReceptacleContainer extends Component {
  componentDidMount() {
    const {
      fetchConsignmentArrivedAtDestPostRequestActionCreator: fetchConsignmentArrivedAtDestPostRequestAction,
      fetchDespatchClosedForReceptacleRequestActionCreator: fetchDespatchClosedForReceptacleRequestAction,
      fetchConsignmentReportedOnByCarrierRequestActionCreator: fetchConsignmentReportedOnByCarrierRequestAction,
      fetchConsignmentAdvisedToCarrierRequestActionCreator: fetchConsignmentAdvisedToCarrierRequestAction,
      fetchConsignmentAdvisedToDestPostRequestActionCreator: fetchConsignmentAdvisedToDestPostRequestAction,
      fetchDespatchArrivedInwardOeForReceptacleRequestActionCreator: fetchDespatchArrivedInwardOeForReceptacleRequestAction,
      match,
    } = this.props;

    const {
      params: {
        aggregateId,
        messageAggregateId,
        messageType,
        receptacleId,
        consignmentId,
      },
    } = match;

    const urlParams = [messageAggregateId, aggregateId, receptacleId];
    switch (messageType) {
    case 'RESDES':
      fetchDespatchArrivedInwardOeForReceptacleRequestAction(...urlParams);
      break;
    case 'RESCON':
      fetchConsignmentArrivedAtDestPostRequestAction(...urlParams);
      break;
    case 'RESDIT':
    case 'IFTSTA':
      fetchConsignmentReportedOnByCarrierRequestAction(
        ...urlParams,
        consignmentId,
      );
      break;
    case 'PRECON':
      fetchConsignmentAdvisedToDestPostRequestAction(...urlParams);
      break;
    case 'CARDIT':
    case 'IFCSUM':
      fetchConsignmentAdvisedToCarrierRequestAction(
        ...urlParams,
        consignmentId,
      );
      break;
    case 'PREDES':
      fetchDespatchClosedForReceptacleRequestAction(...urlParams);
      break;
    default:
      pushHistory('/');
    }
  }

  render() {
    const { fullDetailsEvent } = this.props;
    return secureFullDetails(FullDetails, fullDetailsEvent);
  }
}

FullDetailsForReceptacleContainer.propTypes = {
  fetchDespatchArrivedInwardOeForReceptacleRequestActionCreator: PropTypes.func,
  fetchDespatchClosedForReceptacleRequestActionCreator: PropTypes.func,
  fetchConsignmentArrivedAtDestPostRequestActionCreator: PropTypes.func,
  fetchConsignmentReportedOnByCarrierRequestActionCreator: PropTypes.func,
  fetchConsignmentAdvisedToDestPostRequestActionCreator: PropTypes.func,
  fetchConsignmentAdvisedToCarrierRequestActionCreator: PropTypes.func,
  fullDetailsEvent: fullDetailsEventType,
  match: PropTypes.shape({
    params: PropTypes.shape({
      receptacleId: PropTypes.string,
      consignmentId: PropTypes.string,
      messageAggregateId: PropTypes.string,
      messageType: PropTypes.string,
      aggregateId: PropTypes.string,
    }),
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FullDetailsForReceptacleContainer);
