import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../../elements/theme';

export const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme.mui}>
      {children}
    </MuiThemeProvider>
  </ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node,
};
