import React, { useState } from 'react';
import { queryCache } from 'react-query';
import { Modal } from '@ipc/ipc-components';
import { api } from '../../../../../api';
import { pushHistory } from '../../../../../core/modules/common/tools/HistoryTools';
import { useTranslator, useApiMutation, useApiQuery } from '../../../../../hooks';
import { withSecurity } from '../../../../../security/page';
import Permission from '../../../../../security/permission';
import Button from '../../../../common/Button';
import { useIsAuthorized } from '../../../../common/ipc-core/useIsAuthorized';
import { SecureTableActionToolbar } from '../../../../common/TableActionToolbar';
import { StyledTitle, StyledTable } from './index.styles';

function RolesListPage() {
  const { translate } = useTranslator();
  const [{ open, roleToDelete }, setModalProps] = useState({
    open: false,
    roleToDelete: null,
  });
  const { data: roles, isFetching } = useApiQuery('roles', api.fetchRoles);
  const [deleteRole] = useApiMutation(api.deleteRole,
    { onSuccess: () => queryCache.invalidateQueries(['roles']) },
    { notificationSuccessMessage: translate('administration.user.roles.list.deleteSuccess') });
  const createAuthorized = useIsAuthorized([Permission.CREATE_ROLE]);

  const rolesColumns = [
    { Header: translate('administration.user.roles.list.table.nameCol'), accessor: 'name' },
    {
      Header: translate('administration.user.roles.list.table.actionCol'),
      accessor: 'id',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (
        <SecureTableActionToolbar
          key={`actionbar_${value}`}
          id={value}
          editRoles={[Permission.EDIT_ROLE]}
          removeRoles={[Permission.DELETE_ROLE]}
          editUrl={`/administration/user-management/roles/${value}`}
          removeClick={() => {
            setModalProps({
              open: true,
              roleToDelete: value,
            });
          }}
        />
      ),
    },
  ];

  const closeModal = () => {
    setModalProps({
      open: false,
      roleToDelete: null,
    });
  };

  const handleConfirmModal = async () => {
    closeModal();
    await deleteRole(roleToDelete);
  };

  return (
    <div>
      <StyledTitle id="title" translationKey="administration.user.roles.list.title" />
      {createAuthorized && (
        <Button
          data-testid="create_roles"
          primary
          onClick={() => pushHistory('/administration/user-management/roles/create')}
        >
          {translate('administration.user.roles.list.createButton')}
        </Button>
      )}
      <StyledTable
        columns={rolesColumns}
        data={roles || []}
        loading={isFetching}
        hasPagination={false}
      />
      <Modal
        open={open}
        onClose={closeModal}
        title={translate('administration.user.roles.list.modalTitle')}
        content={translate('administration.user.roles.list.modalContent')}
        cancelText={translate('administration.user.roles.list.modalCancel')}
        okText={translate('administration.user.roles.list.modalOk')}
        onConfirm={handleConfirmModal}
      />
    </div>
  );
}

export default withSecurity(RolesListPage, [Permission.READ_ROLE]);
