import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from '@ipc/ipc-core';
import Table from '../../../../../components/common/Table';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import createColumnDefinition from '../../../../react-table/helpers/createColumnDefinition';

export class ReceptaclesTableForDespatch extends React.Component {
  linkCellRender = (row) => (
    row.value ? <Link to={getUrl('receptacle', row.value)}>{row.value}</Link> : '-'
  );

  getColumnWidth = (smallColWidth, fullScreenWidth = 150) => {
    const {
      showAllColumns,
    } = this.props;

    return showAllColumns ? fullScreenWidth : smallColWidth;
  }

  prepareReceptaclesData() {
    const { receptacles: receptacles1 } = this.props;
    const receptacles = cloneDeep(receptacles1);

    receptacles.forEach((rec) => {
      if (rec.receptacleSequenceNumber) {
        rec.receptacleSequenceNumber = parseInt(rec.receptacleSequenceNumber, 10);
      }
      return rec;
    });

    return receptacles;
  }

  render() {
    const {
      isLoaded, receptacles, error, intl, showPagination, showAllColumns,
    } = this.props;
    const noDataText = intl.formatMessage({ id: 'app.tracking.timeline.event.detail.receptacle.table.noData' });
    const tableColumns = [
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.sequence', 'receptacleSequenceNumber', 1, this.getColumnWidth(20, 35)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.receptacleId', 'receptacleId', 2, this.getColumnWidth(200, 350), {
        Cell: this.linkCellRender,
      }),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.receptacleClass', 'receptacleClass', 3, this.getColumnWidth(63, 110)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.receptacleType', 'receptacleType', 4, this.getColumnWidth(63, 100)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.formatOfContents', 'formatOfContents', 5, this.getColumnWidth(55, 95)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.handlingClass', 'handlingClass', 6, this.getColumnWidth(55, 95)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.items', 'items', 7, this.getColumnWidth(35, 60)),
    ];

    if (showAllColumns) {
      tableColumns.push(
        createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.express', 'express', 8, 65, 'bool'),
        createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.weight', 'weight', 9, 95),
        createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.weightType', 'weightType', 80, 44),
        createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.exempt', 'exempt', 11, 80, 'bool'),
        createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.eventTime', 'eventTime', 12, 155, 'date'),
        createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.conditionWhenReceived', 'conditionWhenReceived', 13, 100),
      );
    }

    return (
      <div className="ReceptaclesTableForDespatch">
        <Table
          id="table"
          columns={tableColumns}
          data={this.prepareReceptaclesData()}
          noDataText={isLoaded && isEmpty(receptacles) && (noDataText)}
          loading={!isLoaded && !error}
          hasPagination={showPagination}
          defaultPageSize={10}
          defaultSortBy={[{ id: 'app.tracking.timeline.event.detail.receptacle.table.sequence', asc: true }]}
          minRows={1}
        />
      </div>
    );
  }
}

ReceptaclesTableForDespatch.propTypes = {
  receptacles: PropTypes.arrayOf(PropTypes.shape),
  isLoaded: PropTypes.bool,
  showPagination: PropTypes.bool,
  showAllColumns: PropTypes.bool,
  error: PropTypes.bool,
};

ReceptaclesTableForDespatch.defaultProps = {
  receptacles: [],
  isLoaded: false,
  showPagination: false,
  showAllColumns: false,
  error: false,
};

export default injectIntl(ReceptaclesTableForDespatch);
