import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { fullDetailsContainersTemplate } from '../../../constants/ExcelTemplates';

const VALUE_IF_ABSENT = '';

function generateExcelWorkbookContainersForFullDetails(fullContainers) {
  const fullContainersTemplateFile = base64.decode(fullDetailsContainersTemplate);
  const template = new XlsxTemplate(fullContainersTemplateFile);

  const containers = {
    containers: _.map(fullContainers, (container) => ({
      containerType: container.containerType || VALUE_IF_ABSENT,
      containerNumber: container.containerNumber || VALUE_IF_ABSENT,
      containerNumberSource: container.containerNumberSource || VALUE_IF_ABSENT,
      receptacles: container.receptacles || VALUE_IF_ABSENT,
      weight: container.weight || VALUE_IF_ABSENT,
      seal: container.seal || VALUE_IF_ABSENT,
      journeyId: container.journeyId || VALUE_IF_ABSENT,
    })),
  };

  template.substitute('Containers', containers);
  return template.generate();
}

export default generateExcelWorkbookContainersForFullDetails;
