export class EventGroup {
  constructor(event) {
    this.events = [event];
  }

    addEvent = (event) => {
      this.events.push(event);
    };

    getGroupKey = () => {
      const tmp = [...this.events];
      // eslint-disable-next-line no-nested-ternary
      tmp.sort((e1, e2) => (e1.aggregateId > e2.aggregateId ? -1 : e1.aggregateId < e2.aggregateId ? 1 : 0));
      return `${tmp[0].eventName}-${tmp[0].aggregateId}`;
    };

    getSize = () => this.events.length;
}
