/* eslint-disable */
import { Checkbox as IPCCheckbox } from '@ipc/ipc-components';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { getStyle } from '../../modules/tracking/material-ui/ThemeHelper';

const Checkbox = styled((props) => <IPCCheckbox {...props} />)({
  /* 'checkbox_label': {
    fontSize: '14px',
    backgroundColor: 'green',
  }, */
  'checkbox_label': {
    label: {
      fontSize: '40px',
    },
  },
  '& input + *': {
    width: 24,
    height: 24,
  },
});

const CheckboxField = (props) => {
  const {
    input,
    styleName = 'checkbox',
  } = props;

  return (
    <Checkbox
      checked={input.checked}
      className="checkbox-field"
      onChange={(event) => {
        const { checked } = event.target;
        input.onChange(checked);
      }}
      label={input.name || ''}
    />
  );
};

CheckboxField.propTypes = {
  input: PropTypes.objectOf(PropTypes.shape),
  styleName: PropTypes.string,
};

export default CheckboxField;
