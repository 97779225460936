export const FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_REQUEST = 'FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_REQUEST';
export const FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_SUCCESS = 'FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_SUCCESS';
export const FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_FAILURE = 'FETCH_DESPATCH_CLOSED_FOR_RECEPTACLE_FAILURE';

export const FETCH_DESPATCH_CLOSED_FOR_DESPATCH_REQUEST = 'FETCH_DESPATCH_CLOSED_FOR_DESPATCH_REQUEST';
export const FETCH_DESPATCH_CLOSED_FOR_DESPATCH_SUCCESS = 'FETCH_DESPATCH_CLOSED_FOR_DESPATCH_SUCCESS';
export const FETCH_DESPATCH_CLOSED_FOR_DESPATCH_FAILURE = 'FETCH_DESPATCH_CLOSED_FOR_DESPATCH_FAILURE';

export const FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_REQUEST = 'FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_REQUEST';
export const FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_SUCCESS = 'FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_SUCCESS';
export const FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_FAILURE = 'FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_RECEPTACLE_FAILURE';

export const FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_REQUEST = 'FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_REQUEST';
export const FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_SUCCESS = 'FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_SUCCESS';
export const FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_FAILURE = 'FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_FAILURE';

export const FETCH_FULL_TRANSPORT_FOR_PREDES_REQUEST = 'FETCH_FULL_TRANSPORT_FOR_PREDES_REQUEST';
export const FETCH_FULL_TRANSPORT_FOR_PREDES_SUCCESS = 'FETCH_FULL_TRANSPORT_FOR_PREDES_SUCCESS';
export const FETCH_FULL_TRANSPORT_FOR_PREDES_FAILURE = 'FETCH_FULL_TRANSPORT_FOR_PREDES_FAILURE';

export const FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_REQUEST = 'FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_REQUEST';
export const FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_SUCCESS = 'FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_SUCCESS';
export const FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FAILURE = 'FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FAILURE';

export const FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_REQUEST = 'FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_REQUEST';
export const FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_SUCCESS = 'FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_SUCCESS';
export const FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_FAILURE = 'FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_FAILURE';

export const FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_REQUEST = 'FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_REQUEST';
export const FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_SUCCESS = 'FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_SUCCESS';
export const FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FAILURE = 'FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FAILURE';

export const FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_REQUEST = 'FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_REQUEST';
export const FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_SUCCESS = 'FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_SUCCESS';
export const FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_FAILURE = 'FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_FAILURE';

export const FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_REQUEST = 'FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_REQUEST';
export const FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_SUCCESS = 'FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_SUCCESS';
export const FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FAILURE = 'FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FAILURE';

export const FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_REQUEST = 'FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_REQUEST';
export const FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_SUCCESS = 'FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_SUCCESS';
export const FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_FAILURE = 'FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_FAILURE';

export const FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_REQUEST = 'FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_REQUEST';
export const FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_SUCCESS = 'FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_SUCCESS';
export const FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FAILURE = 'FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FAILURE';

export const FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_REQUEST = 'FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_REQUEST';
export const FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_SUCCESS = 'FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_SUCCESS';
export const FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_FAILURE = 'FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_FAILURE';
