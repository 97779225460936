import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from '@ipc/ipc-core';
import noop from '../../../../core/modules/common/tools/noop';
import Sidebar from '../../../common/components/sidebar/Sidebar';
import Summary from '../../../common/components/Summary';
import Timeline from '../../../common/components/timeline/Timeline';
import { downloadXls, reportType } from '../../../common/components/timeline/timelineDownloadHelper';
import Title from '../../../common/components/Title';
import FullDetails from './full-details/FullDetails';
import { useDefaultPageState } from '../../../common/utils';

const defaultPageState = {
  summaryExpanded: false,
  fullDetailsInfo: null,
  renderedEventKeys: [],
  expandedEventKeys: [],
  expandedEventGroups: [],
  allEventsAreExpanded: false,
  expandedEventEdiKey: '',
};

export function ReceptaclePage({
  receptacle, fullDetailsInfo, hasError, requestedId, errorMessage, resetFilterForm, ...rest
}) {
  const timelineRef = useRef();
  const [{
    summaryExpanded, filters, sort, pageId,
  }, updateUI] = useDefaultPageState(rest.match, defaultPageState);
  const events = receptacle.allEvents;
  const receptacleFound = !!(!hasError && receptacle && receptacle.receptacleId);
  const showFullDetails = !!(fullDetailsInfo);
  const isRequestIdMatch = requestedId === receptacle.receptacleId;

  const onSummaryToggleExpand = () => {
    updateUI('summaryExpanded', !summaryExpanded);
  };

  const onTimelineRef = (ref) => {
    timelineRef.current = ref;
  };

  const download = () => {
    const timeline = timelineRef.current;

    downloadXls(
      reportType.receptacle,
      [timeline.getCurrentEvents(), receptacle],
      [receptacle.receptacleId],
    );
  };

  const getReceptacleLabelandTabs = () => {
    let label = '';
    let tabs = [];

    if (receptacleFound) {
      label = receptacle.receptacleId;
      tabs = [
        {
          label: 'receptacle id',
          value: receptacle.receptacleId,
        },
        {
          label: 'origin impc',
          value: receptacle.originImpc,
        },
        {
          label: 'destination impc',
          value: receptacle.destImpc,
        },
        {
          label: 'mail category',
          value: receptacle.mailCategory,
        },
        {
          label: 'mail subclass',
          value: receptacle.mailSubclass,
        },
        {
          label: 'receptacle serial number',
          value: receptacle.receptacleSerialNumber,
        },
        {
          label: 'highest numbered receptacle indicator',
          value: receptacle.highestNumberedReceptacleIndicator,
        },
        {
          label: 'registered / insured indicator',
          value: receptacle.registeredInsuredIndicator,
        },
        {
          label: 'receptacle weight',
          value: receptacle.weight,
        },
      ];
    } else {
      label = pageId;
    }
    label = label ? label.toUpperCase() : '';

    return { label, tabs };
  };

  const getFullDetailsEvent = () => {
    let fullDetailsEvent = {};
    if (showFullDetails) {
      fullDetailsEvent = _.find(events, {
        messageAggregateId: fullDetailsInfo.messageAggregateId,
      });
    }

    return fullDetailsEvent;
  };

  const fullDetailsEvent = useMemo(getFullDetailsEvent, [showFullDetails, events, fullDetailsInfo]);
  const { label, tabs } = getReceptacleLabelandTabs();

  return (
    <div className="page">
      {receptacleFound && showFullDetails
          && (
            <FullDetails
              id="fullDetailsOverlay"
              event={fullDetailsEvent}
              activeTab={fullDetailsInfo.activeTab || ''}
            />
          )}
      <Sidebar
        id="sidebar"
        events={events}
        contentFound={receptacleFound}
        onClearFilter={resetFilterForm}
      />
      <div className="timeline-wrapper">
        <Title id="title" translationKey="app.tracking.receptacle.title" />

        {!receptacle.isLoaded
            && (
              <div className="loading-page">
                <FormattedMessage id="app.loading" />
              </div>
            )}

        {receptacle.isLoaded && receptacleFound && isRequestIdMatch
            && (
              <div>
                <Summary
                  id="summary"
                  onSummaryToggleExpand={onSummaryToggleExpand}
                  expanded={summaryExpanded}
                  tabs={tabs}
                  label={label}
                />
                <div className="timeline-container">
                  <Timeline
                    id="timeline"
                    onRef={onTimelineRef}
                    filters={filters}
                    sort={sort}
                    downloadCallback={download}
                    events={events}
                    canExpandCollapse
                  />
                </div>
              </div>
            )}

        {receptacle.isLoaded && hasError
            && (
              <div className="search">
                <div className="search-panel">
                  Receptacle
                  {' '}
                  <span className="bold">
                    {label}
                  </span>
                  {' - '}
                  <FormattedMessage id={errorMessage} />
                </div>
              </div>
            )}
      </div>
    </div>
  );
}

ReceptaclePage.propTypes = {
  receptacle: PropTypes.objectOf(PropTypes.shape),
  fullDetailsInfo: PropTypes.objectOf(PropTypes.shape),
  resetFilterForm: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  requestedId: PropTypes.string,
};

ReceptaclePage.defaultProps = {
  receptacle: {},
  resetFilterForm: noop,
  hasError: false,
  errorMessage: '',
};

export default injectIntl(withRouter(ReceptaclePage));
