import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from '@ipc/ipc-core';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import noop from '../../../../../core/modules/common/tools/noop';
import CollapsablePanel from '../../../../common/components/collapsablePanel/CollapsablePanel';
import ItemsTable from '../../../../common/components/tables/ItemsTable';

export const CollapsableReceptacle = (props) => {
  const {
    receptacle, items, onExpand, onCollapse,
    isCollapsed, isLoaded, showPagination, showAllColumns, error,
  } = props;
  const { receptacleId } = receptacle;

  return (
    <div style={{ marginBottom: '10px' }}>
      <CollapsablePanel
        isCollapsed={isCollapsed}
        onCollapse={() => onCollapse(receptacleId)}
        onExpand={() => onExpand(receptacleId)}
        headerComponent={(
          <div id="collapsableReceptacleHeader">
            <FormattedMessage id="app.tracking.timeline.despatch.items.receptacle" />
            <span>: </span>
            <Link to={getUrl('receptacle', receptacleId)}>{receptacleId}</Link>
          </div>
        )}
        bodyComponent={(
          <ItemsTable
            items={items}
            isLoaded={isLoaded}
            error={error}
            showPagination={showPagination}
            showAllColumns={showAllColumns}
          />
        )}
      />
    </div>
  );
};

CollapsableReceptacle.propTypes = {
  receptacle: PropTypes.objectOf(PropTypes.shape).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isCollapsed: PropTypes.bool,
  isLoaded: PropTypes.bool,
  showPagination: PropTypes.bool,
  showAllColumns: PropTypes.bool,
  error: PropTypes.bool,
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func,
};

CollapsableReceptacle.defaultProps = {
  isCollapsed: true,
  isLoaded: false,
  showPagination: false,
  showAllColumns: false,
  onCollapse: noop,
  onExpand: noop,
};

export default injectIntl(CollapsableReceptacle);
