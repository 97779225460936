import React from 'react';
import { Flex, Box } from '@ipc/ipc-components';
import Search from '../../../../common/ipc-components/Search';

const SearchBar = (props) => (
  <Flex padding={3} backgroundColor="white" mb={6}>
    <Box width="100%" marginX="auto">
      <Search {...props} />
    </Box>
  </Flex>
);

export default SearchBar;
