/* eslint-disable no-restricted-globals */
function isViewportSmallerThanContentEDIPanel(viewportHeight, ediContentHeight) {
  return viewportHeight < ediContentHeight;
}

const getPositioningStyle = (clientHeight, eventContentValues, ediContentHeight) => {
  const headerSize = 70;
  const viewportHeight = clientHeight - headerSize;

  if (isViewportSmallerThanContentEDIPanel(viewportHeight, ediContentHeight)) {
    const topValue = -1 * (eventContentValues.top - headerSize);
    return {
      top: isNaN(topValue) ? 0 : topValue,
    };
  }

  if (eventContentValues.top + ediContentHeight < viewportHeight) {
    return { top: 0 };
  }

  const topPosition = Math.floor((viewportHeight - ediContentHeight) / 2);
  const topValue = -1 * (eventContentValues.top - topPosition - headerSize);

  return {
    top: isNaN(topValue) ? 0 : topValue,
  };
};

module.exports = {
  getPositioningStyle,
};
