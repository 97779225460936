import { getUrl } from '../../../core/modules/common/tools/URLTools';
import { pushHistory } from '../../../core/modules/common/tools/HistoryTools';
import SearchResultTypes from './search/SearchResultTypes';
import formatTypes from '../../common/enum/FormatTypes';

const getIdentifierForRedirection = (result) => (result.types[0] === SearchResultTypes.RECEPTACLE_CORE ? result.coreReceptacleIds[0] : result.identifier);

export function redirectToPageFromIdentifier(searchResults) {
  const identifier = getIdentifierForRedirection(searchResults);
  const formatType = searchResults.types[0] === SearchResultTypes.RECEPTACLE_CORE ? formatTypes.RECEPTACLE : formatTypes[searchResults.types[0]];
  const itemPath = getUrl(formatType, identifier);
  pushHistory(itemPath);
}

export default {
  getIdentifierForRedirection,
  redirectToPageFromIdentifier,
};
