import {
  DECODE_SECURITY_TOKEN,
  LOGGED_IN,
  LOGGED_OUT,
  SET_LOGGED_IN,
} from '../actions/types/SecurityActionTypes';

const initialState = {
  loggedIn: typeof localStorage !== 'undefined' ? !!window.localStorage.getItem('id_token') : false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case DECODE_SECURITY_TOKEN:
    return {
      ...state,
      ...action.payload,
    };
  case SET_LOGGED_IN:
    return {
      ...state,
      loggedIn: action.loggedIn,
    };
  case LOGGED_IN:
    return {
      ...state,
      loggedIn: true,
    };
  case LOGGED_OUT:
    return {
      ...state,
      loggedIn: false,
    };
  default:
    return state;
  }
};
