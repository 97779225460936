import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import { fullDetailsOverviewCarditTemplate } from '../../../constants/ExcelTemplates';
import getOverviewMapper from '../../../../tracking/components/consignment/getOverviewMapper';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

const VALUE_IF_ABSENT = '';

function generateExcelWorkbookOverviewCarditForFullDetails(overviewData) {
  const file = base64.decode(fullDetailsOverviewCarditTemplate);
  const template = new XlsxTemplate(file);
  const overview = getOverviewMapper('CARDIT', overviewData, VALUE_IF_ABSENT);
  const dateFormatter = fieldFormatter('date');

  ['consignmentCompletionDate', 'handoverOriginLocationCutOff', 'handoverDestinationLocationCutOff']
    .forEach((key) => {
      overview[key] = dateFormatter({ value: overview[key] }, null, VALUE_IF_ABSENT);
    });

  template.substitute('Overview', { overview });

  return template.generate();
}

export default generateExcelWorkbookOverviewCarditForFullDetails;
