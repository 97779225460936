import PropTypes from 'prop-types';
import React from 'react';
import ItemsTable from '../../../../common/components/tables/ItemsTable';
import TransportTable from '../../../../common/components/tables/TransportTable';
import TabsPanel from '../../../../common/components/TabsPanel';
import { downloadXls, reportType } from '../../../../common/components/timeline/timelineDownloadHelper';
import DespatchEventGeneralInfo from './DespatchEventGeneralInfo';

const PredesFullDetails = (props) => {
  const { activeTab, event } = props;
  const {
    receptacleId,
    eventInfo = { transportLegs: [], items: [] },
    error,
    errorMessage,
    eventName,
  } = event;

  const map = {
    transport: {
      tabContent: <TransportTable
        isLoaded={!!eventInfo.transportLegs}
        transportLegs={eventInfo.transportLegs}
        error={error}
        showPagination
        isFullDetails
      />,
      actionButtons: [{
        key: 'download',
        onClick: () => {
          downloadXls(
            reportType.fullDetails.transport.get(),
            [eventInfo.transportLegs],
            [receptacleId, eventName],
          );
        },
      }],
    },
    items: {
      tabContent: <ItemsTable
        isLoaded={!!eventInfo.items}
        items={eventInfo.items}
        error={error}
        showAllColumns
        showPagination
        isFullDetails
      />,
      actionButtons: [{
        key: 'download',
        onClick: () => {
          downloadXls(
            reportType.fullDetails.items,
            [eventInfo.items],
            [receptacleId, eventName],
          );
        },
      }],
    },
  };

  return (
    <div className="full-details-guttered">
      <DespatchEventGeneralInfo id="despatchEventGeneralInfo" {...props} />
      <TabsPanel id="tabsPanel" map={map} eventError={{ error, errorMessage }} activeTab={activeTab} />
    </div>
  );
};

PredesFullDetails.propTypes = {
  activeTab: PropTypes.string,
  event: PropTypes.objectOf(PropTypes.shape),
};

PredesFullDetails.defaultProps = {
  event: {},
};

export default PredesFullDetails;
