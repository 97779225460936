import { css } from 'styled-components';
import {
  ButtonDefaultBackground, ButtonDefaultText, ButtonPrimaryBackground, ButtonPrimaryText,
} from '../../elements/Colors';

const defaultColors = `
  background-color: ${ButtonDefaultBackground};
  border-color: ${ButtonDefaultBackground};
  color: ${ButtonDefaultText};
`;

const primaryStyle = `
  background-color: ${ButtonPrimaryBackground};
  border-color: ${ButtonPrimaryBackground};  
  color: ${ButtonPrimaryText};

  &:hover {
    border: 1px solid;
    background-color: ${ButtonPrimaryBackground};
    border-color: ${ButtonPrimaryBackground};  
    color: ${ButtonPrimaryText};
  }

  &.Mui-disabled {
    border: 1px solid;
    border-color: ${ButtonPrimaryBackground};  
    color: ${ButtonPrimaryText};
  }
`;

const disabledStyle = `
  opacity: 0.5;
`;

export const ButtonStyle = css`
  &.MuiButton-root {
    padding: 0 ${({ size, theme }) => theme.sizes.buttons[size]};
    ${defaultColors}
    ${({ primary }) => (primary ? primaryStyle : '')};  
    ${({ disabled }) => (disabled ? disabledStyle : '')};  
    
    &:focus {
      outline:0;
    }
  }
`;
