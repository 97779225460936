/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Pagination } from '@ipc/ipc-components';
import _ from 'lodash';

import ListHeader from './Header/SortHeader';
import FullScreenLoading from '../Loading';
import { paginate, usePagination } from './utils/utils';
import { noDataStyles } from './List.styles';

const NoData = styled.div`
  ${noDataStyles}
`;

const PaginationWrapper = styled.div`
  display:flex;
  justify-content : flex-end;
  margin-top : ${({ theme }) => theme.sizes[3]};
  `;

const List = ({
  config,
  data = [],
  defaultPage = 1,
  defaultPageSize = 5,
  page,
  size,
  totalItems,
  headerProps,
  paginationProps,
  loading = false,
  noDataText = 'No rows found',
  sorting = true,
  showPagination = true,
  paging = true,
  onSortedChange,
  onPageChange,
  onPageSizeChange,
  children,
}) => {
  const {
    page: pageState, size: sizeState, handlePageChange, handlePageSizeChange,
  } = usePagination({
    defaultPage,
    defaultPageSize,
  });
  const [{ sortedField, sortedDirection }, setSortedState] = useState({
    sortedField: '',
    sortedDirection: '',
  });
  const isPaginationControlled = useRef(!!page || !!size).current;
  const truePage = isPaginationControlled ? page : pageState;
  const truePageSize = isPaginationControlled ? size : sizeState;

  const handleSortChange = (event, fieldName, sortOrder) => {
    event.preventDefault();

    setSortedState({ sortedField: fieldName, sortedDirection: sortOrder });
    if (onSortedChange) onSortedChange(fieldName, sortOrder);
  };

  const handleListPageChange = (newPage) => {
    handlePageChange(newPage);
    if (onPageChange) onPageChange(newPage);
  };

  const handleListPageSizeChange = (newSize) => {
    handlePageSizeChange(newSize);
    if (onPageSizeChange) onPageSizeChange(newSize);
  };

  const sortData = React.useMemo(() => {
    const configField = config && config.find(({ fieldName }) => fieldName === sortedField);

    return configField && sorting
      ? _.orderBy(
        data,
        (item) => (configField.accessor ? configField.accessor(item) : _.get(item, configField.fieldName)),
        sortedDirection.toLowerCase(),
      )
      : data;
  }, [data, sortedField, sortedDirection, sorting]);

  const paginatedData = paging ? paginate(sortData, truePageSize, truePage) : sortData;

  return (
    <>
      <div style={{ position: 'relative', overflow: 'auto' }}>
        <ListHeader config={config} onSortedChange={handleSortChange} {...headerProps} />
        {<FullScreenLoading loading={loading} />}

        {paginatedData.length === 0 && noDataText && <NoData>{noDataText}</NoData>}
        {paginatedData.map((item) => children(item))}
      </div>

      {showPagination && (
        <PaginationWrapper>
          <Pagination
            {...paginationProps}
            total={totalItems}
            current={truePage}
            pageSize={truePageSize}
            onPageChange={handleListPageChange}
            onSizeChange={handleListPageSizeChange}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

List.propTypes = {
  config: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  defaultPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  page: PropTypes.number,
  size: PropTypes.number,
  totalItems: PropTypes.number,
  headerProps: PropTypes.objectOf(PropTypes.any),
  paginationProps: PropTypes.objectOf(PropTypes.any),
  noDataText: PropTypes.string,
  sorting: PropTypes.bool,
  showPagination: PropTypes.bool,
  paging: PropTypes.bool,
  loading: PropTypes.bool,
  onSortedChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  children: PropTypes.func,
};

export default List;
