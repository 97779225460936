import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Icons from '../../../../../components/common/Icons';
import TransportTable from '../../../../common/components/tables/TransportTable';
import TabsPanel from '../../../../common/components/TabsPanel';
import { downloadXls, reportType } from '../../../../common/components/timeline/timelineDownloadHelper';
import { fetchFullTransportForPredesRequestActionCreator } from '../../../redux/actions/EventInfoActions';
import FullTransport from '../eventContentDetails/FullTransportForPredes';
import ReceptaclesTableForDespatch from '../eventContentDetails/ReceptaclesTableForDespatch';
import ItemsByReceptaclesForFullDetails from './ItemsByReceptaclesForFullDetails';
import PredesGeneralInfo from './PredesGeneralInfo';

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchFullTransportForPredesRequestActionCreator,
}, dispatch);

const { CollapseIcon, ExpandIcon } = Icons;

export class PredesFullDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedKeys: [],
      allAreExpanded: true,
    };
  }

  getMap() {
    const { allAreExpanded, collapsedKeys } = this.state;
    const { event } = this.props;
    const { generalInfo, eventInfo = {}, error } = event;
    const {
      receptacles, transportLegs, items, fullTransport,
    } = eventInfo;

    const filenameInfo = generalInfo && ([event.despatchId, generalInfo.eventName]);
    return {
      receptacles: {
        tabContent: (
          <ReceptaclesTableForDespatch
            receptacles={receptacles}
            isLoaded={!!(!error && receptacles)}
            error={error}
            showPagination
            showAllColumns
          />
        ),
        actionButtons: [{
          key: 'download',
          onClick: () => {
            downloadXls(
              reportType.fullDetails.receptacles.get(),
              [receptacles],
              filenameInfo,
            );
          },
        }],
      },
      transport: {
        tabContent: <TransportTable
          transportLegs={transportLegs}
          isLoaded={!!(!error && transportLegs)}
          error={error}
          showPagination
          isFullDetails
        />,
        actionButtons: [{
          key: 'download',
          onClick: () => {
            downloadXls(
              reportType.fullDetails.transport.get(),
              [transportLegs],
              filenameInfo,
            );
          },
        }],
      },
      items: {
        tabContent: (
          <ItemsByReceptaclesForFullDetails
            receptacles={receptacles}
            items={items}
            collapsedKeys={collapsedKeys}
            onReceptacleCollapse={this.handleReceptacleCollapse}
            onReceptacleExpand={this.handleReceptacleExpand}
            isLoaded={!!(!error && receptacles && items)}
            error={error}
          />
        ),
        actionButtons: [{
          key: 'collapsible',
          label: allAreExpanded ? 'app.tracking.timeline.collapse-all' : 'app.tracking.timeline.expand-all',
          icon: allAreExpanded ? CollapseIcon : ExpandIcon,
          onClick: () => {
            const areExpanded = !allAreExpanded;
            this.setState({
              allAreExpanded: areExpanded,
              collapsedKeys: areExpanded ? [] : receptacles.map((r) => r.receptacleId),
            });
          },
        }, {
          key: 'download',
          onClick: () => {
            downloadXls(
              reportType.fullDetails.items,
              [eventInfo.items],
              filenameInfo,
            );
          },
        }],
      },
      'full transport': {
        tabContent: (
          <FullTransport
            fullTransport={fullTransport}
            isLoaded={this.isDataLoadedToFullTransport()}
            showAllColumns
            showPagination
            isFullDetails
            error={error}
          />
        ),
        actionButtons: [{
          key: 'download',
          onClick: () => {
            downloadXls(
              reportType.fullDetails.fullTransport,
              [fullTransport],
              filenameInfo,
            );
          },
        }],
        onActive: () => {
          if (!this.isDataLoadedToFullTransport()) {
            this.requestFullTransportEventDetails();
          }
        },
      },
    };
  }

  isDataLoadedToFullTransport = () => {
    const { event } = this.props;
    return event.eventInfo && !_.isEmpty(event.eventInfo.fullTransport || {});
  };

  requestFullTransportEventDetails = () => {
    const { event, fetchFullTransportForPredesRequestActionCreator: fetchFullTransportForPredesRequestActionCreator1 } = this.props;
    fetchFullTransportForPredesRequestActionCreator1(
      event.generalInfo.messageAggregateId,
      event.generalInfo.aggregateId,
    );
  }

  handleReceptacleCollapse = (receptacleId) => {
    const { collapsedKeys } = this.state;
    const newCollapsedKeys = [...collapsedKeys, receptacleId];

    this.setState({
      collapsedKeys: newCollapsedKeys,
      allAreExpanded: newCollapsedKeys.length === 0,
    });
  }

  handleReceptacleExpand = (receptacleId) => {
    const { collapsedKeys } = this.state;
    const newCollapsedKeys = collapsedKeys.filter((key) => key !== receptacleId);

    this.setState({
      collapsedKeys: newCollapsedKeys,
      allAreExpanded: newCollapsedKeys.length === 0,
    });
  }

  render() {
    const { activeTab, event } = this.props;
    const { error, errorMessage } = event;
    return (
      <div className="full-details-guttered">
        <PredesGeneralInfo id="predesGeneralInfo" {...this.props} />
        <TabsPanel id="tabsPanel" map={this.getMap()} eventError={{ error, errorMessage }} activeTab={activeTab} />
      </div>
    );
  }
}

PredesFullDetails.propTypes = {
  activeTab: PropTypes.string,
  event: PropTypes.objectOf(PropTypes.shape),
  fetchFullTransportForPredesRequestActionCreator: PropTypes.func,
};

PredesFullDetails.defaultProps = {
  event: {},
};

export default connect(
  null,
  mapDispatchToProps,
)(PredesFullDetails);
