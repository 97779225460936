/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from '@ipc/ipc-core';
import { getViewAndIdentifierFromRoute } from '../../../../utils';
import GroupForm from './GroupForm';

const filterFields = {
  EMSEVT: ['EMA', 'EMB', 'EXA', 'EXB', 'EXC', 'EXD', 'EXX', 'EMC', 'EMJ', 'EMK', 'EMD', 'EDA', 'EDB', 'EME', 'EDC',
    'EMF', 'EDD', 'EDE', 'EMG', 'EDF', 'EDG', 'EDH', 'EMH', 'EMI'],
  ITMATT: ['ITMATT'],
  DESPATCH: ['PREDES', 'RESDES'],
  CONSIGNMENT: ['PRECON', 'RESCON', 'CARDIT', 'RESDIT'],
  MRD: ['POD', 'POC'],
  OTHER: [],
};

export const createCheckboxArrayBasedOnEvents = (events) => {
  const filterGroups = [];
  events.forEach((event) => {
    let groupIndex = 0;
    Object.keys(filterFields).some((group) => {
      let index = 0;
      if (group !== 'OTHER') {
        const tmp = filterFields[group]
          .find((filterField) => _.startsWith(event.eventName, filterField));
        index = filterFields[group].indexOf(tmp);
      }
      if (index > -1) {
        const found = filterGroups.find((filterGroup) => filterGroup.label === group);
        if (found) {
          if (!found.checkboxes.find((checkbox) => checkbox.label === event.eventName)) {
            found.checkboxes.push({ sortOrder: index, checked: false, label: event.eventName });
          }
        } else {
          filterGroups.push({
            label: group,
            sortOrder: groupIndex,
            checkboxes: [{ sortOrder: index, checked: false, label: event.eventName }],
          });
        }
        return true;
      }
      groupIndex += 1;
      return false;
    });
  });
  return filterGroups;
};

export const composeDoubleDigits = (input) => (input[1] < 10 ? `${input[0]} 0${input[1]}` : `${input[0]} ${input[1]}`);

export const returnWithDoubleDigits = (input) => {
  const splitted = _.split(input, ' ', 2);
  return splitted.length > 1 ? composeDoubleDigits(splitted) : `${input} 00`;
};

export const FilterForm = (props) => {
  const {
    availableTimelineEvents = [],
    change,
  } = props;

  const handleSelectAll = (fields) => {
    fields.forEach(({ label }) => {
      change(label, true);
    });
  };

  const handleUnselectAll = (fields) => {
    fields.forEach(({ label }) => {
      change(label, false);
    });
  };

  const filterGroups = createCheckboxArrayBasedOnEvents(availableTimelineEvents)
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((group) => {
      const sortedGroup = group;
      sortedGroup.checkboxes = _.sortBy(
        group.checkboxes,
        ['sortOrder', (checkbox) => returnWithDoubleDigits(checkbox.label)],
        ['asc'],
      );
      return sortedGroup;
    });

  return (
    <form className="filter-form">
      {filterGroups.map((group) => (
        <GroupForm key={group.label} group={group} onSelectAll={handleSelectAll} onUnselectAll={handleUnselectAll} />
      ))}
    </form>
  );
};

const FilterPerPage = (props) => {
  const { match } = props;
  const { view, pageId } = getViewAndIdentifierFromRoute(match);

  const Filter = useMemo(() => reduxForm({
    form: `${view}.${pageId}.filters`,
    destroyOnUnmount: false,
  })(FilterForm), [view, pageId]);

  return <Filter {...props} />;
};

FilterForm.defaultProps = {
  availableTimelineEvents: [],
};

FilterForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  availableTimelineEvents: PropTypes.arrayOf(PropTypes.object),
  change: PropTypes.func,
};

export default injectIntl(withRouter(FilterPerPage));
