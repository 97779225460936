import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { fullReceptaclesTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

const VALUE_IF_ABSENT = '';

function generateExcelWorkbookReceptaclesForFullDetails(fullReceptacles) {
  const fullReceptaclesTemplateFile = base64.decode(fullReceptaclesTemplate);
  const template = new XlsxTemplate(fullReceptaclesTemplateFile);
  const dateFormatter = fieldFormatter('date');
  const boolFormatter = fieldFormatter('bool');

  const receptacles = {
    receptacles: _.map(fullReceptacles, (receptacle) => ({
      receptacleId: receptacle.receptacleId || VALUE_IF_ABSENT,
      receptacleClass: receptacle.receptacleClass || VALUE_IF_ABSENT,
      receptacleType: receptacle.receptacleType || VALUE_IF_ABSENT,
      formatOfContents: receptacle.formatOfContents || VALUE_IF_ABSENT,
      handlingClass: receptacle.handlingClass || VALUE_IF_ABSENT,
      items: receptacle.items || VALUE_IF_ABSENT,
      express: boolFormatter({ value: receptacle.express }, null, VALUE_IF_ABSENT),
      weight: receptacle.weight || VALUE_IF_ABSENT,
      weightType: receptacle.weightType || VALUE_IF_ABSENT,
      exempt: boolFormatter({ value: receptacle.exempt }, null, VALUE_IF_ABSENT),
      eventTime: dateFormatter({ value: receptacle.eventTime }, null, VALUE_IF_ABSENT),
      conditionWhenReceived: receptacle.conditionWhenReceived || VALUE_IF_ABSENT,
    })),
  };

  template.substitute('Receptacles', receptacles);
  return template.generate();
}

export default generateExcelWorkbookReceptaclesForFullDetails;
