import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

const StyledButtonsBar = styled.div`
  display: flex;
  margin-top: 10px;
  
  > * {
    margin-right: 5px !important;  
  }  
`;
const ButtonsBar = ({ children, ...otherProps }) => <StyledButtonsBar {...otherProps}>{children}</StyledButtonsBar>;

ButtonsBar.propTypes = {
  children: PropTypes.node,
};

export default ButtonsBar;
