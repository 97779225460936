import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import Table from '../../../../components/common/Table';
import createColumnDefinition from '../../../react-table/helpers/createColumnDefinition';

export const getCarrierCodeAndConveyance = (row) => `${row.carrierCode || '-'} / ${row.conveyanceReference || '-'}`;

export class TransportTable extends React.Component {
  getColumnWidth = (smallColWidth, fullScreenWidth = 150) => {
    const {
      isFullDetails,
    } = this.props;

    return isFullDetails ? fullScreenWidth : smallColWidth;
  }

  render() {
    const { isLoaded, transportLegs } = this.props;
    const {
      error, intl, showPagination, pageSize,
    } = this.props;
    const noDataText = intl.formatMessage({ id: 'app.tracking.timeline.event.transport.table.noData' });
    const sortedTransportLegs = _.sortBy(transportLegs, ['transportLegNumber']);

    const tableColumns = [
      createColumnDefinition('app.tracking.timeline.event.transport.table.leg', 'transportLegNumber', 1, this.getColumnWidth(35, 95)),
      createColumnDefinition('app.tracking.timeline.event.transport.table.mode', 'transportMode', 2, this.getColumnWidth(50, 135)),
      createColumnDefinition('app.tracking.timeline.event.transport.table.carrierCodeAndConveyance', getCarrierCodeAndConveyance, 3, this.getColumnWidth(80, 215)),
      createColumnDefinition('app.tracking.timeline.event.transport.table.origin', 'departureLocationCode', 5, this.getColumnWidth(50, 135)),
      createColumnDefinition('app.tracking.timeline.event.transport.table.destination', 'arrivalLocationCode', 6, this.getColumnWidth(85, 230)),
      createColumnDefinition('app.tracking.timeline.event.transport.table.planned.departure', 'departureDate', 7, this.getColumnWidth(125, 300), 'date'),
      createColumnDefinition('app.tracking.timeline.event.transport.table.planned.arrival', 'arrivalDate', 8, this.getColumnWidth(110, 300), 'date'),
    ];

    return (
      <div className="TransportTable">
        <Table
          id="table"
          columns={tableColumns}
          data={sortedTransportLegs}
          noDataText={isLoaded && _.isEmpty(sortedTransportLegs) ? noDataText : ''}
          loading={!isLoaded && !error}
          hasPagination={showPagination}
          defaultPageSize={pageSize}
          minRows={1}
        />
      </div>
    );
  }
}

TransportTable.propTypes = {
  transportLegs: PropTypes.arrayOf(PropTypes.shape),
  isLoaded: PropTypes.bool,
  showPagination: PropTypes.bool,
  pageSize: PropTypes.number,
  error: PropTypes.bool,
  isFullDetails: PropTypes.bool,
};

TransportTable.defaultProps = {
  transportLegs: [],
  showPagination: false,
  pageSize: 10,
  isLoaded: false,
  error: false,
  isFullDetails: false,
};

export default injectIntl(TransportTable);
