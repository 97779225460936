import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from '@ipc/ipc-core';
import Table from '../../../../../components/common/Table';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import createColumnDefinition from '../../../../react-table/helpers/createColumnDefinition';

export class ReceptaclesTableForConsignment extends React.Component {
  linkCellRender = (row) => (
    <Link to={getUrl('receptacle', row.value)}>{row.value}</Link>
  );

  getColumnWidth = (smallColWidth, fullScreenWidth = 150) => {
    const {
      showAllColumns,
    } = this.props;

    return showAllColumns ? fullScreenWidth : smallColWidth;
  }

  prepareReceptaclesData() {
    const { receptacles: receptacles1 } = this.props;
    const receptacles = cloneDeep(receptacles1);

    return receptacles.map((rec) => ({
      ...rec,
      receptacleSequenceNumber: rec.receptacleSequenceNumber && Number(rec.receptacleSequenceNumber),
      weight: rec.weight && Number(rec.weight),
    }));
  }

  render() {
    const {
      isLoaded, receptacles, error, intl, showPagination, showAllColumns,
    } = this.props;
    const noDataText = intl.formatMessage({ id: 'app.tracking.timeline.event.detail.receptacle.table.noData' });

    const tableColumns = [
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.sequence', 'receptacleSequenceNumber', 1, this.getColumnWidth(20, 50), null, 25),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.receptacleId', 'receptacleId', 2, this.getColumnWidth(150, 430), {
        Cell: this.linkCellRender,
      }, 180),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.receptacleType', 'receptacleType', 3, this.getColumnWidth(50, 150)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.receptacleClass', 'receptacleClass', 4, this.getColumnWidth(50, 150)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.handlingClass', 'handlingClass', 5, this.getColumnWidth(55, 150)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.weight', 'weight', 6, this.getColumnWidth(35, 110)),
      createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.weightType', 'weightType', 7, this.getColumnWidth(35, 110)),
    ];

    if (showAllColumns) {
      tableColumns.push(
        createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.dangerousGoodsIndicator', 'dangerousGoodsIndicator', 8, 165, 'bool'),
        createColumnDefinition('app.tracking.timeline.event.detail.receptacle.table.container', 'container', 9, 65),
      );
    }

    return (
      <div className="ReceptaclesTableForConsignment">
        <Table
          id="table"
          columns={tableColumns}
          data={this.prepareReceptaclesData()}
          noDataText={isLoaded && isEmpty(receptacles) ? noDataText : ''}
          loading={!isLoaded && !error}
          hasPagination={showPagination}
          defaultPageSize={10}
          defaultSortBy={[{ id: 'app.tracking.timeline.event.detail.receptacle.table.sequence', asc: true }]}
          maxRows={10}
        />
      </div>
    );
  }
}

ReceptaclesTableForConsignment.propTypes = {
  receptacles: PropTypes.arrayOf(PropTypes.shape),
  isLoaded: PropTypes.bool,
  showPagination: PropTypes.bool,
  showAllColumns: PropTypes.bool,
  error: PropTypes.bool,
};

ReceptaclesTableForConsignment.defaultProps = {
  receptacles: [],
  isLoaded: false,
  showPagination: false,
  showAllColumns: false,
  error: false,
};

export default injectIntl(ReceptaclesTableForConsignment);
