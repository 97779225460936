import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import noop from '../../../../core/modules/common/tools/noop';
import CollapsablePanelDetails from './CollapsablePanelDetails';
import CollapsablePanelHeader from './CollapsablePanelHeader';

export class CollapsablePanel extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    isCollapsed: this.props.isCollapsed,
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { isCollapsed } = this.state;
    if (isCollapsed !== props.isCollapsed) {
      this.setState({ isCollapsed: props.isCollapsed });
    }
  }

  toggleDetails = () => {
    const { isCollapsed } = this.state;
    this.setState({ isCollapsed: !isCollapsed }, this.collapseOrExpand);
  };

  collapseOrExpand = () => {
    const { isCollapsed } = this.state;
    const { onCollapse, onExpand } = this.props;
    if (isCollapsed) {
      onCollapse();
    } else {
      onExpand();
    }
  }

  render() {
    const { isCollapsed } = this.state;
    const { headerComponent, bodyComponent } = this.props;
    return (
      <div className="collapsable-panel">
        <CollapsablePanelHeader
          isCollapsed={isCollapsed}
          toggleDetails={this.toggleDetails}
          headerComponent={headerComponent}
        />
        {
          !isCollapsed && (
            <CollapsablePanelDetails
              bodyComponent={bodyComponent}
            />
          )
        }
      </div>
    );
  }
}

CollapsablePanel.propTypes = {
  isCollapsed: PropTypes.bool,
  headerComponent: PropTypes.element,
  bodyComponent: PropTypes.element,
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func,
};

CollapsablePanel.defaultProps = {
  onCollapse: noop,
  onExpand: noop,
};

export default injectIntl(CollapsablePanel);
