import PropTypes from 'prop-types';
import React from 'react';
import { TimelineEventContentHeader as IpcTimelineEventContentHeader } from '@ipc/ipc-components';
import { useAuthorizationByPermissions } from '../../../../../components/common/ipc-core/useIsAuthorized';
import noop from '../../../../../core/modules/common/tools/noop';
import { getPermissionDetailForEvent } from '../../../../../security';
import { EventLocation } from '../event/header/location';

const SecureTimelineEventContentHeader = (props) => {
  const { event } = props;
  const { isAuthorize } = useAuthorizationByPermissions();
  const eventRole = getPermissionDetailForEvent(event);
  const canExpand = eventRole ? isAuthorize([eventRole]) : true;
  return (<TimelineEventContentHeader canExpand={canExpand} {...props} />);
};

SecureTimelineEventContentHeader.propTypes = {
  event: PropTypes.shape({}),
  isExpanded: PropTypes.bool,
  toggleEventDetails: PropTypes.func,
};

export const TimelineEventContentHeader = (props) => {
  const {
    event = {
      eventLocation: {},
    },
    isExpanded,
    toggleEventDetails = noop,
    canExpand = true,
  } = props;

  const handleHeaderClick = () => {
    const selection = window.getSelection();

    if (canExpand && !selection.toString()) {
      toggleEventDetails();
    }
  };

  return (
    <IpcTimelineEventContentHeader
      eventId={event.rawEventKey || event.aggregateId}
      eventType={event.eventType}
      isExpanded={isExpanded}
      canExpand={canExpand}
      onToggleEventDetails={handleHeaderClick}
    >
      <div className="event-header-type">
        {event.eventName}
      </div>
      <div className="event-header-title">
        {event.eventDescription}
      </div>
      <EventLocation event={event} />
    </IpcTimelineEventContentHeader>
  );
};

TimelineEventContentHeader.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  isExpanded: PropTypes.bool,
  toggleEventDetails: PropTypes.func,
  canExpand: PropTypes.bool,
};

export default SecureTimelineEventContentHeader;
