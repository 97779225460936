import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { VALUE_IF_ABSENT } from '../../../../common/constants/Constants';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

export class OverviewTable extends React.Component {
  getFallBackMessage() {
    const { isLoaded } = this.props;
    return isLoaded
      ? <FormattedMessage id="app.tracking.timeline.event.detail.overview.table.noData" />
      : <FormattedMessage id="app.loading" />;
  }

  createRow = (key, value) => {
    const isDate = _.includes([
      'consignmentCompletionDate', 'handoverOriginLocationCutOff',
      'handoverDestinationLocationCutOff', 'departureDateTime', 'arrivalDateTime',
    ], key);
    const rowValue = isDate ? fieldFormatter('date')({ value }) : (value || VALUE_IF_ABSENT);

    return (
      <div key={key} className="row-table">
        <div className="header" id={key}><FormattedMessage id={`app.tracking.timeline.event.detail.overview.table.${key}`} /></div>
        <div className="value" aria-labelledby={key}>{rowValue}</div>
      </div>
    );
  }

  createTable() {
    const { overview } = this.props;
    return _.keys(overview)
      .map((key) => this.createRow(key, overview[key]));
  }

  render() {
    const { overview } = this.props;
    return (
      <div>
        {!_.isEmpty(overview)
          ? (
            <div className="OverviewTable">
              { this.createTable() }
            </div>
          ) : this.getFallBackMessage()}
      </div>
    );
  }
}

OverviewTable.propTypes = {
  overview: PropTypes.objectOf(PropTypes.shape),
  isLoaded: PropTypes.bool,
};

OverviewTable.defaultProps = {
  overview: {},
  isLoaded: false,
};

export default injectIntl(OverviewTable);
