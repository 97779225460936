import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import DefaultFullDetails from '../../../../common/components/events/DefaultFullDetails';
import { LocationMapMarkerIcon } from '../../../../common/components/timeline/event/header/location/styles';
import { getOrDefault } from '../../../../common/MapUtils';
import { determineType } from '../../../helpers/EventTypeDeterminator';
import PredesFullDetails from './PredesFullDetails';
import ResdesFullDetails from './ResdesFullDetails';

export class FullDetails extends Component {
  eventFullDetailsMap = {
    PREDES: (props) => <PredesFullDetails id="predesFullDetails" {...props} />,
    RESDES: (props) => <ResdesFullDetails id="resdesFullDetails" {...props} />,
    default: (props) => <DefaultFullDetails id="defaultFullDetails" {...props} />,
  };

  getErrorMessage() {
    const { event } = this.props;
    const {
      errorMessage = 'app.tracking.timeline.event.detail.errorMessage',
    } = event;

    return errorMessage;
  }

  hasEventDetails() {
    const { event } = this.props;
    const { eventName, eventDescription, eventLocation } = event;
    return !!(eventName && eventDescription && eventLocation);
  }

  render() {
    const { event } = this.props;
    const { error } = event;
    const isEmptyEvent = _.isEmpty(event);
    let fullDetailsComponent;

    if (!isEmptyEvent) {
      const { event: event1 } = this.props;
      fullDetailsComponent = getOrDefault(
        this.eventFullDetailsMap,
        determineType(event1.eventName),
      )(this.props);
    }
    return (
      <div className="full-details">
        <div className="full-details-breadcrumb">
          <span className="full-details-breadcrumb-root">
            <FormattedMessage id="app.tracking.despatch.fullDetails.title" />
          </span>
          {' '}
          /
          {' '}
          <FormattedMessage
            id="app.tracking.timeline.event.details.full-details"
          />
        </div>

        {!isEmptyEvent && this.hasEventDetails()
        && (
          <div className="full-details-content">
            <div className="full-details-header">
              <div className="event-header-type">
                {event.eventName}
              </div>
              <div className="event-header-title">
                {event.eventDescription}
              </div>
              <div className="event-header-location">
                <LocationMapMarkerIcon />
                <span title={event.eventLocation.location}>{event.eventLocation.location}</span>
              </div>
            </div>
            {fullDetailsComponent}
          </div>
        )}

        {error && !this.hasEventDetails()
        && (
          <div className="default-error-message">
            <FormattedMessage id={this.getErrorMessage()} />
          </div>
        )}

        {isEmptyEvent && !error
        && (
          <div className="default-loading">
            <FormattedMessage id="app.loading" />
          </div>
        )}
      </div>
    );
  }
}

FullDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  activeTab: PropTypes.string,
};

FullDetails.defaultProps = {
  event: {},
  activeTab: '',
};

export default injectIntl(FullDetails);
