import { bool, func, string } from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import noop from '../../../../core/modules/common/tools/noop';
import { searchResultType } from '../../../../types';
import SearchPanel from './SearchPanel';
import SearchResultPanel from './SearchResultPanel';

export const SearchPage = (props) => {
  const {
    onSubmitSearchForm = noop,
    searchTerm = '',
    searchResult = {},
    hasError = false,
    errorMessage = '',
    isSearching = false,
  } = props;

  return (
    <div className="search-wrapper">
      <div className="search on-search-page">
        <FormattedMessage
          id="app.tracking.search.title"
          tagName="h1"
        />

        <SearchPanel
          id="searchPanel"
          onSubmitSearchForm={onSubmitSearchForm}
          searchTerm={searchTerm}
          isSearching={isSearching}
        />

        {(searchResult.identifier || hasError)
          && (
            <SearchResultPanel
              id="searchResultPanel"
              searchResult={searchResult}
              errorMessage={errorMessage}
            />
          )}
      </div>
    </div>
  );
};

SearchPage.propTypes = {
  onSubmitSearchForm: func,
  searchTerm: string,
  searchResult: searchResultType,
  hasError: bool,
  errorMessage: string,
  isSearching: bool,
};

export default injectIntl(SearchPage);
