/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Select, Flex, Input } from '@ipc/ipc-components';
import { FormattedMessage } from 'react-intl';
import Button from '../../Button';
import { IconStyle, InputStyles, SelectStyle } from './index.styles';

const StyledSelect = styled(Select)`
  ${SelectStyle}
`;

const StyledButton = styled(Button)`
  ${IconStyle}
`;

const StyledInput = styled(({ withSearchIcon, iconSpacing, ...props }) => <Input {...props} />)`
  ${InputStyles}
`;

const Search = ({
  selectProps = {},
  inputProps = {
    'data-testid': 'search-input',
  },
  onSearch,
  withSearchIcon = true,
  searchIcon = (
    <FormattedMessage id="administration.user.users.list.search" />
  ),
  iconSpacing = '2',
  ...rest
}) => {
  const { defaultValue, ...restSelectProps } = selectProps;
  const [fieldValue, setField] = useState(defaultValue || '');
  const [searchTerm, setSearchTerm] = useState(inputProps.defaultValue || '');

  const trueSelectedValue = selectProps && selectProps.value ? selectProps.value : fieldValue;
  const trueSearchTerm = inputProps && inputProps.value ? inputProps.value : searchTerm;

  const handleFieldChange = (event) => {
    const { value } = event.target;

    setField(value);

    if (selectProps.onChange) selectProps.onChange(event);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;

    setSearchTerm(value);

    if (inputProps.onChange) inputProps.onChange(event);
  };

  const searchHandler = (event) => {
    event.preventDefault();
    onSearch(trueSelectedValue, trueSearchTerm);
  };

  return (
    <Flex
      as="form"
      align="center"
      {...rest}
    >
      <StyledSelect variant="standard" {...restSelectProps} value={trueSelectedValue} onChange={handleFieldChange} />
      <StyledInput
        {...inputProps}
        fullWidth
        withSearchIcon={withSearchIcon}
        iconSpacing={iconSpacing}
        value={trueSearchTerm}
        onChange={handleSearchChange}
        data-testid="searchInput"
        type="search"
      />
      <StyledButton
        type="submit"
        ml={iconSpacing}
        startIcon={withSearchIcon ? <FontAwesomeIcon size="1x" icon={faSearch} /> : null}
        onClick={searchHandler}
        data-testid="searchButton"
      >
        {searchIcon}
      </StyledButton>
    </Flex>
  );
};

Search.propTypes = {
  selectProps: PropTypes.objectOf(PropTypes.any),
  inputProps: PropTypes.objectOf(PropTypes.any),
  onSearch: PropTypes.func,
  withSearchIcon: PropTypes.bool,
  searchIcon: PropTypes.node,
  iconSpacing: PropTypes.string,
};

export default Search;
