import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonEventContentDetails from '../../../../common/components/CommonEventContentDetails';
import TransportTable from '../../../../common/components/tables/TransportTable';
import { fetchDespatchArrivedInwardOeForDespatchRequestActionCreator } from '../../../redux/actions/EventInfoActions';
import ReceptaclesTableForDespatch from './ReceptaclesTableForDespatch';
import ResdesGeneralInfo from './ResdesGeneralInfo';

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDespatchArrivedInwardOeForDespatchRequestActionCreator,
}, dispatch);

const mapStateToProps = (state) => ({
  despatchId: state.tracking.despatch.despatch.despatchId,
});

export class ResdesEventContentDetails extends Component {
  getComponentMap = (props) => {
    const { eventInfo = {}, error } = props.event;

    return {
      receptacles: (
        <ReceptaclesTableForDespatch
          receptacles={eventInfo.receptacles}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
      transport: (
        <TransportTable
          transportLegs={eventInfo.transportLegs}
          isLoaded={props.isLoaded}
          error={error}
        />
      ),
    };
  };

  requestEventDetails = () => {
    const { event, fetchDespatchArrivedInwardOeForDespatchRequestActionCreator: fetchDespatchArrivedInwardOeForDespatchRequestActionCreator1 } = this.props;
    const { messageAggregateId, aggregateId } = event;
    fetchDespatchArrivedInwardOeForDespatchRequestActionCreator1(messageAggregateId, aggregateId);
  };

  render() {
    const { despatchId } = this.props;
    return (
      <CommonEventContentDetails
        {...this.props}
        entityId={despatchId}
        generalInfoComponent={<ResdesGeneralInfo {...this.props} />}
        requestEventDetails={this.requestEventDetails}
        getComponentMap={this.getComponentMap}
      />
    );
  }
}

ResdesEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  fetchDespatchArrivedInwardOeForDespatchRequestActionCreator: PropTypes.func,
  despatchId: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResdesEventContentDetails);
