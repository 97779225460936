import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import { uiReducer } from './uiReducer';

export const UiContext = createContext();

const UiProvider = ({ children, initialUiState = {} }) => {
  const [state, dispatch] = useReducer(uiReducer, initialUiState);

  return (
    <UiContext.Provider value={{ state, dispatch }}>
      {children}
    </UiContext.Provider>
  );
};

UiProvider.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  initialUiState: PropTypes.objectOf(PropTypes.any),
};

export const usePageUiState = (view, id) => {
  const { state } = useContext(UiContext);
  return state?.[view]?.[id];
};

export const useChangePageUi = (view, pageId) => {
  const { dispatch } = useContext(UiContext);

  return (key, value) => {
    let payload;
    if (typeof key === 'object' && value === undefined) {
      payload = {
        value: key, view, pageId,
      };
    } else {
      payload = {
        key, value, view, pageId,
      };
    }

    dispatch({
      type: 'CHANGE_PAGE_UI',
      payload,
    });
  };
};

export default UiProvider;
