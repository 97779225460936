import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { validateS8 } from '../../../../tracking/components/search/SearchValidator';
import { fullItemsTemplate, fullDetailsItemsByReceptacleTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

function generateExcelWorkbookItemsForFullDetails(fullItems, pageId) {
  const s8valid = validateS8(pageId);
  const template = s8valid ? fullDetailsItemsByReceptacleTemplate : fullItemsTemplate;
  const fullItemsTemplateFile = base64.decode(template);
  const xlsTemplate = new XlsxTemplate(fullItemsTemplateFile);
  const boolFormatter = fieldFormatter('bool');
  const dateFormatter = fieldFormatter('date');
  const VALUE_IF_ABSENT = '';

  const items = {
    items: _.map(fullItems, (item) => ({
      receptacleId: item.receptacleId,
      sequenceNumber: item.sequenceNumber,
      itemId: item.itemId,
      handlingClass: item.handlingClass,
      weight: item.weight,
      chargingMethod: item.chargingMethod,
      nonDeliveryReason: item.nonDeliveryReason,
      destinationCountry: item.destinationCountry,
      returnedCharge: item.returnedCharge,
      insuredValue: item.insuredValue,
      express: boolFormatter({ value: item.express }, null, VALUE_IF_ABSENT),
      dangerousGoodsClass: boolFormatter({ value: item.dangerousGoodsClass }, null, VALUE_IF_ABSENT),
      itemClass: item.itemClass,
      eventEMD: dateFormatter({ value: item.eventEMD }, null, VALUE_IF_ABSENT),
      eventEMI: dateFormatter({ value: item.eventEMI }, null, VALUE_IF_ABSENT),
    })),
  };
  xlsTemplate.substitute('Items', items);
  return xlsTemplate.generate();
}

export default generateExcelWorkbookItemsForFullDetails;
