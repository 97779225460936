import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonEventContentDetails from '../../../../common/components/CommonEventContentDetails';
import ItemsTable from '../../../../common/components/tables/ItemsTable';
import TransportTable from '../../../../common/components/tables/TransportTable';
import { fetchDespatchClosedForReceptacleRequestActionCreator } from '../../../redux/actions/EventInfoActions';
import DespatchEventGeneralInfo from './DespatchEventGeneralInfo';

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDespatchClosedForReceptacleRequestActionCreator,
}, dispatch);

const mapStateToProps = (state) => ({
  receptacleId: state.tracking.receptacle.receptacle.receptacleId,
});

export class PredesEventContentDetails extends Component {
  getComponentMap = (props) => {
    const { eventInfo = {}, error } = props.event;
    const commonProps = { isLoaded: props.isLoaded, error };
    const transportTableProps = { transportLegs: eventInfo.transportLegs || [], ...commonProps };
    const itemsTableProps = { items: eventInfo.items || [], ...commonProps };

    return {
      transport: <TransportTable {...transportTableProps} />,
      items: <ItemsTable {...itemsTableProps} />,
    };
  };

  requestEventDetails = () => {
    const { fetchDespatchClosedForReceptacleRequestActionCreator: fetchDespatchClosedForReceptacleRequestActionCreator1, receptacleId, event } = this.props;
    fetchDespatchClosedForReceptacleRequestActionCreator1(
      event.messageAggregateId,
      event.aggregateId,
      receptacleId,
    );
  };

  render() {
    const { receptacleId } = this.props;
    return (
      <CommonEventContentDetails
        {...this.props}
        entityId={receptacleId}
        generalInfoComponent={<DespatchEventGeneralInfo {...this.props} />}
        requestEventDetails={this.requestEventDetails}
        getComponentMap={this.getComponentMap}
      />
    );
  }
}

PredesEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  fetchDespatchClosedForReceptacleRequestActionCreator: PropTypes.func,
  receptacleId: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PredesEventContentDetails);
