import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from '../../../../../components/common/Table';
import { VALUE_IF_ABSENT } from '../../../../common/constants/Constants';
import createColumnDefinition from '../../../../react-table/helpers/createColumnDefinition';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

const transportHeaders = {
  transportStageQualifier: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.transportStageQualifier" tagName="b" />,
  carrierCode: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.carrierCode" tagName="b" />,
  carrierCodeSource: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.carrierCodeSource" tagName="b" />,
  carrierName: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.carrierName" tagName="b" />,
  conveyanceReference: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.conveyanceReference" tagName="b" />,
  transportMode: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.transportMode" tagName="b" />,
  departureLocationCode: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.departureLocationCode" tagName="b" />,
  departureLocationCodeSource: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.departureLocationCodeSource" tagName="b" />,
  departureLocationName: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.departureLocationName" tagName="b" />,
  departureDate: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.departureDate" tagName="b" />,
  arrivalLocationCode: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.arrivalLocationCode" tagName="b" />,
  arrivalLocationName: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.arrivalLocationName" tagName="b" />,
  arrivalLocationCodeSource: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.arrivalLocationCodeSource" tagName="b" />,
  arrivalDate: <FormattedMessage id="app.tracking.timeline.event.transportForConsignment.table.arrivalDate" tagName="b" />,
};

export const TransportTableForConsignment = ({
  intl, transportLegs, isLoaded, error, isFullDetails = false,
}) => {
  const sortedTransportLegs = useMemo(() => _.sortBy(transportLegs, ['transportLegNumber']), [transportLegs]);

  const getColumnWidth = (smallColWidth, fullScreenWidth = 150) => (isFullDetails ? fullScreenWidth : smallColWidth);

  const getTableColumns = () => [
    createColumnDefinition('app.tracking.timeline.event.transportForConsignment.table.element', 'element', 1, getColumnWidth(150, 200)),
    ...sortedTransportLegs.map((tl, idx) => ({
      Header: intl.formatMessage({ id: 'app.tracking.timeline.event.transportForConsignment.table.leg' }, { legNumber: tl.transportLegNumber }),
      currentlyResizing: true,
      accessor: `leg${tl.transportLegNumber}`,
      columnPosition: idx + 2,
      width: getColumnWidth(150, 400),
      minWidth: 10,
    })),
  ];

  const getTableData = () => {
    const dateFormatter = fieldFormatter('date');

    if (!isLoaded || error || _.isEmpty(sortedTransportLegs)) {
      return [];
    }

    const prepareObject = (key) => (data, tl) => {
      if (_.includes(['departureDate', 'arrivalDate'], key)) {
        data[`leg${tl.transportLegNumber}`] = dateFormatter({ value: tl[key] });
      } else {
        data[`leg${tl.transportLegNumber}`] = tl[key] || VALUE_IF_ABSENT;
      }
      return data;
    };

    const mountLine = (key) => _.reduce(
      sortedTransportLegs,
      prepareObject(key),
      { element: transportHeaders[key] },
    );

    return _.keys(transportHeaders).map(mountLine);
  };

  const noDataText = intl.formatMessage({ id: 'app.tracking.timeline.event.transport.table.noData' });
  const tableColumns = useMemo(getTableColumns, [sortedTransportLegs]);
  const tableData = useMemo(getTableData, [sortedTransportLegs, transportHeaders, isLoaded, error]);

  return (
    <div className="TransportTableForConsignment">
      <Table
        id="table"
        columns={tableColumns}
        data={tableData}
        noDataText={noDataText}
        loading={!isLoaded && !error}
        hasPagination={false}
        sortable={false}
        defaultPageSize={_.keys(transportHeaders).length}
        minRows={1}
      />
    </div>
  );
};

TransportTableForConsignment.propTypes = {
  transportLegs: PropTypes.arrayOf(PropTypes.shape),
  isLoaded: PropTypes.bool,
  error: PropTypes.bool,
  isFullDetails: PropTypes.bool,
};

TransportTableForConsignment.defaultProps = {
  transportLegs: [],
  isLoaded: false,
  error: false,
};

export default injectIntl(TransportTableForConsignment);
