import React, { useMemo } from 'react';
import styled from 'styled-components';
import { space3 } from '../../../../../elements/theme';
import { useTranslator, useUsers } from '../../../../../hooks';
import Title from '../../../../../modules/common/components/Title';
import { withSecurity } from '../../../../../security/page';
import Permission from '../../../../../security/permission';
import List from '../../../../common/ipc-components/List/List';
import UserDetail from '../../../../features/user/Detail/Detail';
import SearchBar from './SearchBar';

const { useState } = require('react');

const StyledHeader = styled.span`
  padding-left: ${space3};  
`;

const getTableFieldsConfig = (translate) => [{
  fieldName: 'username',
  sortable: false,
  isSearchable: true,
  Header: () => <StyledHeader>{translate('table.usernameCol')}</StyledHeader>,
}, {
  fieldName: 'email',
  sortable: false,
  isSearchable: true,
  Header: () => <StyledHeader>{translate('table.emailCol')}</StyledHeader>,
}, {
  fieldName: 'firstName',
  sortable: false,
  isSearchable: true,
  Header: () => <>{translate('table.firstNameCol')}</>,
}, {
  fieldName: 'lastName',
  sortable: false,
  isSearchable: true,
  Header: () => <>{translate('table.lastNameCol')}</>,
}];

const UserListPage = () => {
  const { users, isFetching } = useUsers([]);
  const defaultSearchField = 'email';
  const { translate } = useTranslator('administration.user.users.list');
  const [{ searchField, searchTerm }, setSearchTerms] = useState({
    searchField: defaultSearchField,
    searchTerm: '',
  });

  const filterUsers = (searchTermText) => users.filter((user) => user[searchField].indexOf(searchTermText) >= 0);

  const getOptionsFromConfig = (config) => config
    .filter((field) => field.isSearchable)
    .map(({ fieldName }) => ({ value: fieldName, label: translate(`details.${fieldName}`) }));

  const configTable = getTableFieldsConfig(translate);
  const filteredUser = searchTerm ? filterUsers(searchTerm) : users;

  return (
    <div>
      <Title id="title" translationKey="administration.user.users.list.title" />
      <SearchBar
        w="100%"
        selectProps={{
          options: useMemo(() => getOptionsFromConfig(configTable), [translate]),
          defaultValue: defaultSearchField,
          inputProps: { 'aria-labelledby': 'searchEmail' },
        }}
        defaultSearchField={searchField}
        onSearch={(newSearchField, newSearchTerm) => {
          setSearchTerms({
            searchField: newSearchField,
            searchTerm: newSearchTerm,
          });
        }}
      />

      <List
        config={configTable}
        data={filteredUser}
        totalItems={users.length}
        loading={isFetching}
      >
        {(user) => (<UserDetail key={user.id} user={user} />)}
      </List>
    </div>
  );
};

export default withSecurity(UserListPage, [Permission.READ_USER]);
