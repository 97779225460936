import PropTypes from 'prop-types';
import React from 'react';
import CommonEventContentDetails from '../../../../common/components/CommonEventContentDetails';
import RfIdEventGeneralInfo from './RfIdEventGeneralInfo';

function RfIdEventContentDetails(props) {
  const { receptacleId } = props;
  return (
    <CommonEventContentDetails
      {...props}
      entityId={receptacleId}
      showTabContent={false}
      showFullDetails={false}
      generalInfoComponent={<RfIdEventGeneralInfo {...props} />}
    />
  );
}

RfIdEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  receptacleId: PropTypes.string,
};

export default RfIdEventContentDetails;
