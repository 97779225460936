import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../../../../components/common/Button';
import Icons from '../../../../../components/common/Icons';
import IconText from '../../../../../components/common/IconText';
import noop from '../../../../../core/modules/common/tools/noop';
import themeStyles from '../../../../../elements/theme/materialUiThemeV0';

const EventContentEdiButton = (props) => {
  const {
    showEDI = false,
    toggleEventEDI = noop,
    ...rest
  } = props;
  const style = showEDI ? themeStyles.keepState : {};
  const Icon = () => (showEDI ? <Icons.EyeSlashIcon /> : <Icons.EyeIcon />);
  const buttonLabel = showEDI ? 'app.tracking.timeline.event.edi.hide' : 'app.tracking.timeline.event.edi.view';

  return (
    <div className="timeline-event-content-edi-button">
      <Button id="ediButton" data-testid="ediButton" onClick={toggleEventEDI} style={style} {...rest}>
        <IconText icon={Icon} labelId={buttonLabel} />
      </Button>
    </div>
  );
};

EventContentEdiButton.propTypes = {
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

export default EventContentEdiButton;
