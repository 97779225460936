import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import globalReducers from './components/application/redux/reducers/globalReducers';
import combinedSecurityReducers from './core/modules/security/redux/reducers';
import { tracking } from './modules/tracking/redux/reducers';
import { redirectReducer } from './components/application/redux/reducers/redirectReducer';

export default combineReducers({
  form: formReducer,
  global: globalReducers,
  redirect: redirectReducer,
  security: combinedSecurityReducers,
  tracking,
});
