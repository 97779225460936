import React from 'react';
import PropTypes from 'prop-types';

const renderSearchInput = (props) => {
  const {
    input,
    type,
    meta: { error, visited, dirty },
    className,
    placeholder,
  } = props;

  let errorElement;
  if (error && dirty && (input.value || visited)) {
    errorElement = (
      <div className="input-error">{error}</div>
    );
  }

  return (
    <div>
      <input {...input} placeholder={placeholder} type={type} className={className} />
      { errorElement}
    </div>
  );
};

renderSearchInput.propTypes = {
  input: PropTypes.objectOf(PropTypes.shape),
  type: PropTypes.string,
  meta: PropTypes.objectOf(PropTypes.shape),
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default renderSearchInput;
