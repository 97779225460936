import * as PropTypes from 'prop-types';
import React from 'react';
import { pushHistory } from '../../../../../core/modules/common/tools/HistoryTools';
import { useRole, useTranslator } from '../../../../../hooks';
import Title from '../../../../../modules/common/components/Title';
import { withSecurity } from '../../../../../security/page';
import Permission from '../../../../../security/permission';
import Button from '../../../../common/Button';
import ButtonsBar from '../../../../common/ButtonsBar';
import RoleForm from '../../../../features/role/RoleForm';

const redirectToRolePage = () => pushHistory('/administration/user-management/roles');

const EditRolePage = ({ match }) => {
  const { translate } = useTranslator();
  const { roleId } = match.params;
  const { editRole, role: roleData, permissions } = useRole(roleId);
  const role = {
    ...roleData,
    permissions,
  };

  const ConfirmButton = (props) => <Button data-testid="roleConfirm" primary type="submit" {...props}>{translate('button.confirm')}</Button>;
  const CancelButton = () => <Button data-testid="roleCancel" onClick={redirectToRolePage}>{translate('button.cancel')}</Button>;

  const onSubmit = async (data) => {
    // setSubmitting(true);
    await editRole(data);
    // setTimeout(() => setSubmitting(false), 2000);
  };

  return (
    <div>
      <Title id="title" translationKey="administration.user.roles.edit.title" />
      {role && permissions && (
        <RoleForm onSubmit={onSubmit} role={role}>
          <ButtonsBar>
            <ConfirmButton />
            <CancelButton />
          </ButtonsBar>
        </RoleForm>
      )}
    </div>
  );
};

EditRolePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default withSecurity(EditRolePage, [Permission.EDIT_ROLE]);
