import React from 'react';

export const paginate = (array, pageSize, pageNumber) => {
  if (pageNumber <= 0 || pageSize <= 0) {
    return [];
  }

  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const usePagination = ({ defaultPageSize = 10, defaultPage = 5 }) => {
  const [{ page, size }, setPageOptions] = React.useState({
    page: defaultPage,
    size: defaultPageSize,
  });

  const handlePageChange = (newPage) => {
    setPageOptions((previous) => ({ ...previous, page: newPage }));
  };

  const handlePageSizeChange = (newSize) => {
    setPageOptions((previous) => ({
      ...previous,
      size: newSize,
    }));
  };

  return {
    page, size, handlePageChange, handlePageSizeChange,
  };
};

export default usePagination;
