import { constructServiceURL } from '../../core/modules/common/tools/URLTools';
import { get, patch, post } from '../request';

export const fetchUsers = () => get(constructServiceURL({ endpoint: 'getUsers' }));

export const editUser = (data) => patch(constructServiceURL({
  endpoint: 'user',
  params: {
    userId: data.id,
  },
}), data.user);

export const fetchUserRoles = (userId) => get(constructServiceURL({
  endpoint: 'userRoles',
  params: { userId },
}));

export const editUserRoles = (data) => post(constructServiceURL({
  endpoint: 'userRoles',
  params: { userId: data.id },
}), data.roles);
