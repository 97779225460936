import * as PropTypes from 'prop-types';
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { GeneralErrorMessage } from './GeneralErrorMessage';

const logErrorToMyService = (error, errorInfo) => {
  Sentry.captureException(error, { extra: errorInfo });
};

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo);
  }

  render() {
    const { state, props } = this;
    return state.hasError ? <GeneralErrorMessage /> : props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
