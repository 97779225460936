import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Timeline } from '@ipc/ipc-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from '@ipc/ipc-core';
import Button from '../../../../components/common/Button';
import Icons from '../../../../components/common/Icons';
import IconText from '../../../../components/common/IconText';
import { useAuthorizationByPermissions } from '../../../../components/common/ipc-core/useIsAuthorized';
import { getPermissionDetailForEvent } from '../../../../security';
import { EventGroup } from '../../classes/EventGroup';
import TimelineEvent from './TimelineEvent';
import TimelineEventGroup from './TimelineEventGroup';
import timelineHelper from './TimelineHelper';
import { usePageState } from '../../utils';

const { ExpandIcon, CollapseIcon, DownloadIcon } = Icons;

const emptyArray = [];
const getFilteredEvents = (events, filter) => timelineHelper.filterEvents(events, filter);
const getSortedEvents = (events, sort) => timelineHelper.sortEvents(events, sort);

export function SecureTimeline({
  events, canExpandCollapse, noData, downloadCallback, customComponents, sort, filters, onRef, ...rest
}) {
  const [, uiState, updateUI] = usePageState(rest.match);
  const [groupedEvents, setGroupedEvents] = useState([]);
  const { isAuthorize } = useAuthorizationByPermissions();
  const {
    expandedEventKeys = emptyArray, expandedEventGroups = emptyArray, expandedEventEdiKey, allEventsAreExpanded,
  } = uiState || {};
  const hasEvents = events && events.length > 0;

  const eventFilter = (event) => {
    const eventRole = getPermissionDetailForEvent(event);
    return eventRole ? isAuthorize([eventRole]) : true;
  };

  const eventDetailsSecurityFilter = (_events) => _events.filter(eventFilter);
  const getExpandableEvents = () => eventDetailsSecurityFilter(events || []);
  const getFilteredAndSortedEvents = (_events, _filter, _sort) => getSortedEvents(getFilteredEvents(_events, _filter), _sort);
  const getFilteredAndSortedAndGroupedEvents = (_events, _filters, _sort) => timelineHelper.groupEvents(getFilteredAndSortedEvents(_events, _filters, _sort));

  const collapseAllTimelineEvents = () => {
    const filteredAndSortedEvents = getFilteredAndSortedEvents(
      events || [],
      filters,
      sort,
    );
    const groupEvents = timelineHelper.groupEvents(filteredAndSortedEvents);
    const renderedGroups = _.filter(groupEvents, (element) => element instanceof EventGroup);
    const renderedEventKeys = _.map(filteredAndSortedEvents, 'aggregateId');
    const newExpandedEventKeys = _.difference(expandedEventKeys, renderedEventKeys);
    const newExpandedEventGroups = _.differenceWith(
      expandedEventGroups,
      renderedGroups,
      (expandedEventGroup, group) => expandedEventGroup.groupedEventsKey === group.getGroupKey(),
    );

    updateUI({
      expandedEventKeys: newExpandedEventKeys,
      expandedEventGroups: newExpandedEventGroups,
      expandedEventEdiKey: '',
      allEventsAreExpanded: false,
    });
  };

  const expandAllTimelineEvents = () => {
    const groupEvents = getFilteredAndSortedAndGroupedEvents(
      getExpandableEvents(),
      filters,
      sort,
    );
    const newExpandedEventKeys = [...expandedEventKeys];
    const newExpandedEventGroups = [...expandedEventGroups];
    groupEvents.forEach((element) => {
      if (element instanceof EventGroup) {
        newExpandedEventGroups.push({
          groupedEventsKey: element.getGroupKey(),
          amountExpanded: element.getSize(),
        });
        element.events.forEach((event) => {
          newExpandedEventKeys.push(event.aggregateId);
        });
      } else {
        newExpandedEventKeys.push(element.aggregateId);
      }
    });

    updateUI({
      expandedEventKeys: newExpandedEventKeys,
      expandedEventGroups: newExpandedEventGroups,
      allEventsAreExpanded: true,
    });
  };

  const updateTimelineUI = () => {
    const filteredAndSortedEvents = getFilteredAndSortedEvents(
      events,
      filters,
      sort,
    );
    const expandableEventsKeys = _.map(eventDetailsSecurityFilter(filteredAndSortedEvents), 'aggregateId');
    const renderedEventKeys = _.map(filteredAndSortedEvents, 'aggregateId');
    const newUiState = {
      renderedEventKeys,
      allEventsAreExpanded: timelineHelper.areAllTheRenderedEventsExpanded(
        expandableEventsKeys,
        expandedEventKeys,
      ),
    };

    if (!_.find(filteredAndSortedEvents, { aggregateId: expandedEventEdiKey })
      || !_.includes(expandedEventKeys, expandedEventEdiKey)) {
      newUiState.expandedEventEdiKey = '';
    }

    updateUI(newUiState);
    setGroupedEvents(timelineHelper.groupEvents(filteredAndSortedEvents));
  };

  useEffect(() => {
    onRef({ getCurrentEvents: () => getFilteredAndSortedEvents(events, filters, sort) });
    return () => onRef(null);
  }, [events, sort, filters]);

  useEffect(updateTimelineUI, [events, sort, filters, expandedEventKeys]);

  return (
    <Timeline className="timeline-component">
      <div>
        <div className="text-align-center">
          <div className="fancy-triangle">&nbsp;</div>
        </div>
      </div>

      <div className="timeline-header">
        <div>
          <div className="timeline-start" />
          <div>
            {customComponents && customComponents.length > 0 && hasEvents
              && (
                <div className="custom-timeline-components">
                  {customComponents.map((component) => component)}
                </div>
              )}

            <div className="timeline-action-buttons">
              {downloadCallback && hasEvents
                && (
                  <Button
                    id="downloadButton"
                    className="action-button download-button"
                    onClick={downloadCallback}
                  >
                    <IconText icon={DownloadIcon} labelId="app.tracking.timeline.download" />
                  </Button>
                )}

              {canExpandCollapse && hasEvents
                && (
                  allEventsAreExpanded
                    ? (
                      <Button id="expandCollapseButton" className="action-button" onClick={collapseAllTimelineEvents}>
                        <IconText icon={CollapseIcon} labelId="app.tracking.timeline.collapse-all" />
                      </Button>
                    )
                    : (
                      <Button id="expandCollapseButton" className="action-button" onClick={expandAllTimelineEvents}>
                        <IconText icon={ExpandIcon} labelId="app.tracking.timeline.expand-all" />
                      </Button>
                    )
                )}
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      </div>

      {groupedEvents && groupedEvents.length > 0
        && (
          <div className="timeline-events">
            {groupedEvents.map((element) => {
              if (element instanceof EventGroup) {
                return (
                  <TimelineEventGroup
                    id="timelineEventGroup"
                    eventGroup={element}
                    key={element.getGroupKey()}
                  />
                );
              }
              return (
                <TimelineEvent
                  id="timelineEvent"
                  event={element}
                  key={element.aggregateId}
                  className="bottom-big"
                />
              );
            })}
          </div>
        )}

      {groupedEvents && groupedEvents.length <= 0
        && (
          <div className="eventsNotFound">
            <FormattedMessage id={noData} />
          </div>
        )}
    </Timeline>
  );
}

SecureTimeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape),
  canExpandCollapse: PropTypes.bool,
  customComponents: PropTypes.arrayOf(PropTypes.shape),
  onRef: PropTypes.func,
  downloadCallback: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.shape),
  sort: PropTypes.objectOf(PropTypes.shape),
  noData: PropTypes.string,
  eventDetailsSecurityFilter: PropTypes.func,
};

SecureTimeline.defaultProps = {
  events: [],
  customComponents: [],
  onRef: (ref) => ref,
  downloadCallback: null,
  noData: 'app.tracking.timeline.events.not.found',
};

export default injectIntl(withRouter(SecureTimeline));
