import React from 'react';
import { FormattedMessage } from 'react-intl';
import fieldFormatter from './fieldFormatter';

export default function createColumnDefinition(
  translationId,
  accessor,
  columnPosition,
  width,
  expectedType,
  options = {},
) {
  let opts = options;
  const columnDefinition = {
    Header: <FormattedMessage id={translationId} />,
    id: translationId,
    currentlyResizing: true,
    accessor,
    columnPosition,
    minWidth: 10,
    width,
  };

  if (typeof expectedType === 'object') {
    opts = expectedType;
  } else if (fieldFormatter(expectedType)) {
    columnDefinition.Cell = fieldFormatter(expectedType);
  } else {
    columnDefinition.Cell = fieldFormatter('text');
  }

  return {
    ...columnDefinition,
    ...opts,
  };
}
