import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';
import noop from '../../../core/modules/common/tools/noop';
import { withSecurity } from '../../../security/page';
import Permission from '../../../security/permission';
import ConsignmentPage from '../components/consignment/ConsignmentPage';
import { fetchConsignmentRequestActionCreator } from '../redux/actions/ConsignmentActions';
import { getViewAndIdentifierFromRoute } from '../../common/utils';
import { useGeneralErrorNotification } from '../../../hooks';

const mapStateToProps = (state) => ({
  consignment: state.tracking.consignment.consignment,
  hasError: state.tracking.consignment.error,
  errorMessage: state.tracking.consignment.errorMessage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchConsignmentRequestActionCreator,
  reset,
}, dispatch);

export function ConsignmentContainer({
  consignment, hasError, errorMessage, match, reset: resetForm, fetchConsignmentRequestActionCreator: fetchConsignmentRequestActionCreator1,
}) {
  const { params: { consignmentId } } = match;

  const fetchConsignment = (id) => {
    fetchConsignmentRequestActionCreator1(id);
  };

  const resetFilterForm = () => {
    const { view, pageId } = getViewAndIdentifierFromRoute(match);

    resetForm(`${view}.${pageId}.filters`);
  };

  useEffect(() => {
    if (consignmentId) {
      fetchConsignment(consignmentId);
    }
  }, [consignmentId]);

  useGeneralErrorNotification(errorMessage);

  return (
    <ConsignmentPage
      id="consignmentPage"
      resetFilterForm={resetFilterForm}
      consignment={consignment}
      requestedId={consignmentId}
      hasError={hasError}
      errorMessage={errorMessage === 'app.networkError' ? 'app.tracking.search.errors.not.found' : errorMessage}
    />
  );
}

ConsignmentContainer.defaultProps = {
  consignment: {},
  fetchConsignmentRequestActionCreator: noop,
};

ConsignmentContainer.propTypes = {
  consignment: PropTypes.objectOf(PropTypes.shape),
  reset: PropTypes.func,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  match: PropTypes.shape({ params: PropTypes.shape({ consignmentId: PropTypes.string }) }),
  fetchConsignmentRequestActionCreator: PropTypes.func,
};

const ConnectedConsignmentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsignmentContainer);

export default withSecurity(ConnectedConsignmentContainer, [Permission.READ_CONSIGNMENT]);
