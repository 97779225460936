export default {
  READ_USER: 'READ_USER',
  CREATE_USER: 'CREATE_USER',
  EDIT_USER: 'EDIT_USER',
  DELETE_USER: 'DELETE_USER',
  READ_ROLE: 'READ_ROLE',
  CREATE_ROLE: 'CREATE_ROLE',
  EDIT_ROLE: 'EDIT_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',
  READ_PERMISSION: 'READ_PERMISSION',
  READ_DATA_POLICY_GROUP: 'READ_DATA_POLICY_GROUP',
  READ_ITEM: 'READ_ITEM',
  READ_RECEPTACLE: 'READ_RECEPTACLE',
  READ_DESPATCH: 'READ_DESPATCH',
  READ_CONSIGNMENT: 'READ_CONSIGNMENT',
  READ_PREDES_DETAILS: 'READ_PREDES_DETAILS',
  READ_RESDES_DETAILS: 'READ_RESDES_DETAILS',
  READ_PRECON_DETAILS: 'READ_PRECON_DETAILS',
  READ_RESCON_DETAILS: 'READ_RESCON_DETAILS',
  READ_CARDIT_DETAILS: 'READ_CARDIT_DETAILS',
  READ_RESDIT_DETAILS: 'READ_RESDIT_DETAILS',
  READ_EDI: 'READ_EDI',
  READ_EDI_ITMATT: 'READ_EDI_ITMATT',
  READ_EDI_MRD: 'READ_EDI_MRD',
  READ_ITMATT_DETAILS: 'READ_ITMATT_DETAILS',
};
