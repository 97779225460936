import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import Table from '../../../../../components/common/Table';
import createColumnDefinition from '../../../../react-table/helpers/createColumnDefinition';

export class ContainerTable extends React.Component {
  getColumnWidth = (smallColWidth, fullScreenWidth = 150) => {
    const {
      isFullDetails,
    } = this.props;

    return isFullDetails ? fullScreenWidth : smallColWidth;
  }

  getColumns = () => [
    createColumnDefinition('app.tracking.timeline.event.detail.container.table.containerType', 'containerType', 1, this.getColumnWidth(50, 190)),
    createColumnDefinition('app.tracking.timeline.event.detail.container.table.containerNumber', 'containerNumber', 2, this.getColumnWidth(85, 310)),
    createColumnDefinition('app.tracking.timeline.event.detail.container.table.containerNumberSource', 'containerNumberSource', 3, this.getColumnWidth(80, 290)),
    createColumnDefinition('app.tracking.timeline.event.detail.container.table.receptacles', 'receptacles', 4, this.getColumnWidth(55, 190)),
    createColumnDefinition('app.tracking.timeline.event.detail.container.table.weight', 'weight', 5, this.getColumnWidth(35, 120)),
    createColumnDefinition('app.tracking.timeline.event.detail.container.table.seal', 'seal', 6, this.getColumnWidth(30, 100)),
    createColumnDefinition('app.tracking.timeline.event.detail.container.table.journeyId', 'journeyId', 7, this.getColumnWidth(55, 190)),
  ]

  render() {
    const {
      intl, containers, isLoaded, showPagination, error,
    } = this.props;

    return (
      <div className="ContainerTable">
        <Table
          id="table"
          columns={this.getColumns()}
          data={containers}
          noDataText={intl.formatMessage({ id: 'app.tracking.timeline.event.detail.container.table.noData' })}
          loading={!isLoaded && !error}
          hasPagination={showPagination}
          defaultPageSize={10}
          minRows={1}
        />
      </div>
    );
  }
}

ContainerTable.propTypes = {
  containers: PropTypes.arrayOf(PropTypes.shape),
  isLoaded: PropTypes.bool,
  showPagination: PropTypes.bool,
  error: PropTypes.bool,
  isFullDetails: PropTypes.bool,
};

ContainerTable.defaultProps = {
  containers: [],
  isLoaded: false,
  showPagination: false,
  error: false,
};

export default injectIntl(ContainerTable);
