import _ from 'lodash';
import { FETCH_EDI_FORMATTED_SUCCESS, FETCH_EDI_RAW_SUCCESS } from '../actions/types/EdiActionTypes';

const initialState = {
  ediMessages: [],
};

const reduce = (action, state, messageType) => {
  const { rawEventKey } = action.payload;
  const ediMessages = _.cloneDeep(state.ediMessages);
  let ediMessagesForRawEventKey = _.find(ediMessages, { rawEventKey });
  if (!ediMessagesForRawEventKey) {
    ediMessagesForRawEventKey = { rawEventKey };
    ediMessages.push(ediMessagesForRawEventKey);
  }
  ediMessagesForRawEventKey[messageType] = action.payload.messageContent;
  return { ediMessages };
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_EDI_RAW_SUCCESS: {
    return reduce(action, state, 'raw');
  }
  case FETCH_EDI_FORMATTED_SUCCESS: {
    return reduce(action, state, 'formatted');
  }
  default:
    return state;
  }
};
