import PropTypes from 'prop-types';
import React, { memo } from 'react';
import noop from '../../../../../core/modules/common/tools/noop';
import EventContentEdiContainer from './EventContentEdiContainer';
import { useItmattMessage, useEdiState } from './ediHooks';

function ItmattEventEdiContent(props) {
  const {
    event,
    toggleEventEDI,
    defaultSearchTerm,
    pageId,
  } = props;

  const {
    searchTerm, isFormatted, handleSearchTerm, handleFormattedText,
  } = useEdiState(defaultSearchTerm);
  const {
    ediMessage, isLoading, error, errorMessage,
  } = useItmattMessage(event.rawEventKey, event.aggregateId, pageId);

  return (
    <EventContentEdiContainer
      ediMessage={ediMessage}
      isEdiMessageLoading={isLoading}
      event={event}
      toggleEventEDI={toggleEventEDI}
      searchTerm={searchTerm}
      isTextFormatted={isFormatted}
      hasError={!!error}
      errorMessage={errorMessage}
      showEdiToggle={false}
      onToggle={handleFormattedText}
      onSearch={handleSearchTerm}
    />
  );
}

ItmattEventEdiContent.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  toggleEventEDI: PropTypes.func,
  defaultSearchTerm: PropTypes.string,
  pageId: PropTypes.string,
};

ItmattEventEdiContent.defaultProps = {
  event: {},
  toggleEventEDI: noop,
};

export default memo(ItmattEventEdiContent);
