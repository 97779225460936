import {
  arrayOf, number, shape, string,
} from 'prop-types';

export const matchType = shape({
  params: shape({}),
});

export const searchResultType = shape({
  identifier: string,
  types: arrayOf(string),
  coreReceptacleIds: arrayOf(string),
});

export const userType = shape({
  id: number,
  creationDate: string,
});

export const fullDetailsEventType = shape({});
