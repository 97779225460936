export const fontBodyHelper = ({ theme }) => ({ fontFamily: `${theme.fonts.body}, sans-serif`, fontSize: theme.fontSizes.paragraph });

export const backgroundColorWhite = ({ theme }) => ({ backgroundColor: theme.colors.white });

export const fontTitleHelper = ({ theme }) => ({ fontFamily: theme.fonts.header, fontSize: theme.fontSizes.md });

export const borderRight = (value) => `border-top-right-radius: ${value};border-bottom-right-radius: ${value};`;

export const borderLeft = (value) => `border-top-left-radius: ${value};border-bottom-left-radius: ${value};`;

export const space1 = ({ theme }) => theme.space[1];

export const space2 = ({ theme }) => theme.space[2];

export const space3 = ({ theme }) => theme.space[3];

export const space5 = ({ theme }) => theme.space[5];
