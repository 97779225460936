import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Views from './enum/Views';
import { usePageUiState, useChangePageUi } from '../../components/common/contexts';

export function occurrences(string, subString) {
  if (subString.length <= 0) return 0;

  let n = 0;
  let pos = 0;
  const step = subString.length;

  while (true) {
    pos = string.indexOf(subString, pos);
    if (pos >= 0) {
      n += 1;
      pos += step;
    } else break;
  }
  return n;
}

export const getViewAndIdentifierFromRoute = (match) => {
  const { params } = match;

  if (params.hasOwnProperty('itemId')) {
    return { view: Views.ITEM, pageId: params.itemId };
  }
  if (params.hasOwnProperty('receptacleId')) {
    return { view: Views.RECEPTACLE, pageId: params.receptacleId };
  }
  if (params.hasOwnProperty('despatchId')) {
    return { view: Views.DESPATCH, pageId: params.despatchId };
  }
  if (params.hasOwnProperty('consignmentId')) {
    return { view: Views.CONSIGNMENT, pageId: params.consignmentId };
  }

  return { view: 'DEFAULT', pageId: 'PAGE' };
};

export const usePageState = (match) => {
  const { view, pageId } = getViewAndIdentifierFromRoute(match);

  const filterState = useSelector((state) => ({
    filters: state.form[view] && state.form[view][pageId] ? state.form[view][pageId].filters : null,
    sort: state.form[view] && state.form[view][pageId] ? state.form[view][pageId].sort : null,
    pageId,
  }));
  const uiState = usePageUiState(view, pageId);
  const changeUiState = useChangePageUi(view, pageId);

  return [filterState, uiState, changeUiState];
};

export const useDefaultPageState = (match, defaultUiState) => {
  const [filterState, uiState, changeUiState] = usePageState(match);
  const pageState = useMemo(() => ({ ...filterState, ...uiState }), [filterState, uiState]);

  useEffect(() => {
    if (!uiState) {
      changeUiState(defaultUiState);
    }
  }, []);

  return [pageState, changeUiState];
};
