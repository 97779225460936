import React from 'react';
import styled from 'styled-components';
import { Link, SecureComponent } from '@ipc/ipc-core';
import PropTypes from 'prop-types';
import Icons from './Icons';

const Toolbar = styled.div`
    > * {
      margin-right: 10px;    
    }
`;

const EditButton = ({ url = '#', ...rest }) => (<Link to={url} {...rest}><Icons.EditIcon /></Link>);
const DeleteButton = ({ url = '#', onClick, ...rest }) => (
  <Link to={url} onClick={onClick} {...rest}><Icons.DeleteIcon /></Link>);

export const SecureTableActionToolbar = ({ id, ...other }) => (
  <Toolbar>
    <SecureComponent roles={other.editRoles}>
      <EditButton
        key={`action_edit_${id}`}
        data-testid={`roleEdit_${id}`}
        data-roleid={id}
        url={other.editUrl}
      />
    </SecureComponent>
    <SecureComponent roles={other.removeRoles}>
      <DeleteButton
        key={`action_delete_${id}`}
        data-testid={`roleDelete_${id}`}
        url={other.removeUrl}
        onClick={other.removeClick}
      />
    </SecureComponent>
  </Toolbar>
);

SecureTableActionToolbar.propTypes = {
  id: PropTypes.number,
  editRoles: PropTypes.arrayOf(PropTypes.string),
  editUrl: PropTypes.string,
  editClick: PropTypes.func,
  removeRoles: PropTypes.arrayOf(PropTypes.string),
  removeUrl: PropTypes.string,
  removeClick: PropTypes.func,
};
EditButton.propTypes = { url: PropTypes.string, onClick: PropTypes.func };
DeleteButton.propTypes = { url: PropTypes.string, onClick: PropTypes.func };
