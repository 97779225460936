import PropTypes from 'prop-types';
import React, { Component } from 'react';

const ButtonTab = (props) => {
  const {
    label,
    index,
    isActive,
    onClick,
    value,
  } = props;

  const onButtonClick = () => {
    onClick(label, value);
  };

  return (
    <button
      type="button"
      role="tab"
      className={`button-tabs__tab ${isActive ? 'active' : ''}`}
      style={{ zIndex: index }}
      onClick={onButtonClick}
      tabIndex={index}
      aria-selected={isActive}
    >
      {label}
    </button>
  );
};

ButtonTab.propTypes = {
  label: PropTypes.string,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
};

class ButtonTabs extends Component {
  constructor(props) {
    super(props);

    const { defaultLabel, tabs } = this.props;
    this.state = {
      tabs,
      activeTab: defaultLabel || '',
    };
  }

  setActive = (label, value) => {
    this.setState({ activeTab: label });
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const { activeTab, tabs } = this.state;
    return (
      <div className="button-tabs">
        {tabs.length > 0 && tabs.map((tab, index) => (
          <ButtonTab
            label={tab.label}
            index={tabs.length - index}
            isActive={tab.label === activeTab}
            value={tab.value}
            key={tab.value}
            onClick={this.setActive}
          />
        ))}
      </div>
    );
  }
}

ButtonTabs.defaultProps = {
  tabs: [],
};

ButtonTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  defaultLabel: PropTypes.string,
};

export default ButtonTabs;
