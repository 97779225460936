import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Switch, Typography } from '@ipc/ipc-components';
import noop from '../../../../core/modules/common/tools/noop';

const FullTraceLabel = styled.div`
   white-space: nowrap;
`;

const TimelineFullTraceToggle = (props) => {
  const {
    toggleFullTrace = noop(),
    isToggled = false,
    ...rest
  } = props;

  const toggleLabelId = isToggled ? 'app.tracking.timeline.toggle.on' : 'app.tracking.timeline.toggle.off';
  const toggleClassName = isToggled ? 'toggleOn' : 'toggleOff';

  const Label = (
    <Typography variant="label" color="label" textTransform="uppercase" fontSize="12px">
      <div className="timeline-toggle-component-label">
        <FullTraceLabel><FormattedMessage id="app.tracking.timeline.toggle.label" tagName="div" /></FullTraceLabel>
        <div className={toggleClassName}><FormattedMessage id={toggleLabelId} /></div>
      </div>
    </Typography>
  );

  return (
    <div className="timeline-toggle-component">
      <Switch label={Label} onChange={toggleFullTrace} checked={isToggled} size="medium" {...rest} />
    </div>
  );
};

TimelineFullTraceToggle.propTypes = {
  toggleFullTrace: PropTypes.func,
  isToggled: PropTypes.bool,
};

export default TimelineFullTraceToggle;
