import * as PropTypes from 'prop-types';
import React from 'react';
import { useIsAuthorized } from '../components/common/ipc-core/useIsAuthorized';
import { useTranslator } from '../hooks';

export const NotAuthorizedRoute = ({ errorMessage = '' }) => {
  const { translate } = useTranslator();

  return (
    <div>
      {translate(errorMessage || 'app.permission.notAuthorized')}
    </div>
  );
};

NotAuthorizedRoute.propTypes = {
  errorMessage: PropTypes.string,
};

export const SecurePage = ({ roles, children, fallback = <NotAuthorizedRoute /> }) => {
  const isAuthorized = useIsAuthorized(roles);

  return isAuthorized ? children : fallback;
};

SecurePage.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  fallback: PropTypes.node,
};

export const withSecurity = (WrappedComponent, roles, fallback = <NotAuthorizedRoute />) => ({ ...props }) => {
  const isAuthorized = useIsAuthorized(roles);

  return isAuthorized ? <WrappedComponent {...props} /> : fallback;
};
