export const Blue = '#0099CE';
export const LightBlue = '#E4ECF3';
export const BlueNavy = '#073142';
export const White = '#FFF';
export const Red = '#D20020';
export const Gray = '#575858';
export const Gray2 = '#6A6B6B';
export const Gray3 = '#CCD6DF';
export const ReceptacleMapMarker = '#EF696E';
export const ItemMapMarker = '#7DBD7B';

export const ButtonDefaultBackground = Gray3;
export const ButtonDefaultText = BlueNavy;

export const ButtonPrimaryBackground = Blue;
export const ButtonPrimaryText = White;

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',
  focus: '#5b9dd969',
  disabled: '#EDEDED',
  inputColor: '#626363',
  placeholder: '#8c8d8d',

  // main colors
  primary: Blue,
  secondary: '#575858',
  tertiary: '#89949B',
  quarternary: '#2DBAB9',
  quinary: '#496672',
  senary: '#96A7AD',
  septenary: '#E9F0F5',

  // complementary colors
  success: '#5BA13C',
  danger: '#D20020',
  info: '#6A6B6B',
  successLight: '#7DBD7B',
  dangerLight: '#EE696D',
  infoLight: '#DDDDDD',

  // additional colors
  grayLight: '#FAFBFB',
  grayLightest: '#F7F7F7',
  grayOther: '#EDEDED',
  warning: '#FFC107',
  blueLight: '#74c4e2',
  primaryDark: '#041D28',
  secondaryDark: '#333333',
  successDark: '#315820',
  dangerDark: '#64000F',
  blueNavy: BlueNavy,

  basic: {
    blue: '#0099CE',
  },

  // colors used for button variatons
  variant: {
    blue: {
      50: '#ebf8ff',
      500: '#3182ce',
      600: '#2a69ac',
      700: '#1e4e8c',
    },

    red: {
      50: '#fff5f5',
      500: '#e53e3e',
      600: '#c53030',
    },

    blue2: {
      50: '#ebf8ff',
      500: '#0099CE',
      600: '#2a69ac',
    },

    green: {
      50: '#f0fff4',
      500: '#38a169',
      600: '#2f855a',
    },

    gray: {
      50: '#F7FAFC',
      500: '#718096',
      600: '#4A5568',
    },

    secondary: {
      50: '#EDEDED',
      500: '#575858',
      600: '#333333',
    },

    primary: {
      50: '#EDEDED',
      500: '#073142',
      600: '#041D28',
    },

    quarternary: {
      500: '#2DBAB9',
    },
  },
};

export default colors;
