import generateExcelWorkbookForItemPage from './generateExcelWorkbookForItemPage';
import generateExcelWorkbookForReceptaclePage from './generateExcelWorkbookForReceptaclePage';
import generateExcelWorkbookForDespatchPage from './generateExcelWorkbookForDespatchPage';
import generateExcelWorkbookForConsignmentPage from './generateExcelWorkbookForConsignmentPage';
import generateExcelWorkbookItemsForFullDetails from './generateExcelWorkbookItemsForFullDetails';
import generateExcelWorkbookReceptaclesForFullDetails from './generateExcelWorkbookReceptaclesForFullDetails';
import generateExcelWorkbookTransportForFullDetails from './generateExcelWorkbookTransportForFullDetails';
import generateExcelWorkbookFullTransportForFullDetails from './generateExcelWorkbookFullTransportForFullDetails';
import generateExcelWorkbookOverviewCarditForFullDetails from './generateExcelWorkbookOverviewCarditForFullDetails';
import generateExcelWorkbookOverviewResditForFullDetails from './generateExcelWorkbookOverviewResditForFullDetails';
import generateExcelWorkbookOverviewPreconForFullDetails from './generateExcelWorkbookOverviewPreconForFullDetails';
import generateExcelWorkbookOverviewResconForFullDetails from './generateExcelWorkbookOverviewResconForFullDetails';
import generateExcelWorkbookTransportForConsignmentFullDetails from './generateExcelWorkbookTransportForConsignmentFullDetails';
import generateExcelWorkbookContainersForFullDetails from './generateExcelWorkbookContainersForFullDetails';
import generateExcelWorkbookReceptaclesForConsignmentFullDetails from './generateExcelWorkbookReceptaclesForConsignmentFullDetails';
import { CONSIGNMENT } from '../../../constants/Constants';

const reportType = {
  items: {
    generator: generateExcelWorkbookForItemPage,
    filename: (label) => `${label}_events`,
  },
  receptacle: {
    generator: generateExcelWorkbookForReceptaclePage,
    filename: (receptacleId) => `${receptacleId}_events`,
  },
  despatch: {
    generator: generateExcelWorkbookForDespatchPage,
    filename: (despatchId) => `${despatchId}_events`,
  },
  consignment: {
    generator: generateExcelWorkbookForConsignmentPage,
    filename: (consignmentId) => `${consignmentId}_events`,
  },
  fullDetails: {
    items: {
      generator: generateExcelWorkbookItemsForFullDetails,
      filename: (elementId, eventName) => `${elementId}_${eventName}_items`,
    },
    receptacles: {
      get(type = 'default') {
        return {
          generator: this.generator[type],
          filename: this.filename,
        };
      },
      generator: {
        [CONSIGNMENT]: generateExcelWorkbookReceptaclesForConsignmentFullDetails,
        default: generateExcelWorkbookReceptaclesForFullDetails,
      },
      filename: (elementId, eventName) => `${elementId}_${eventName}_receptacles`,
    },
    transport: {
      get(type = 'default') {
        return {
          generator: this.generator[type],
          filename: this.filename,
        };
      },
      generator: {
        [CONSIGNMENT]: generateExcelWorkbookTransportForConsignmentFullDetails,
        default: generateExcelWorkbookTransportForFullDetails,
      },
      filename: (elementId, eventName) => `${elementId}_${eventName}_transport`,
    },
    fullTransport: {
      generator: generateExcelWorkbookFullTransportForFullDetails,
      filename: (elementId, eventName) => `${elementId}_${eventName}_full_transport`,
    },
    overview: {
      get(type) {
        return {
          generator: this.generator[type],
          filename: this.filename,
        };
      },
      generator: {
        CARDIT: generateExcelWorkbookOverviewCarditForFullDetails,
        RESDIT: generateExcelWorkbookOverviewResditForFullDetails,
        PRECON: generateExcelWorkbookOverviewPreconForFullDetails,
        RESCON: generateExcelWorkbookOverviewResconForFullDetails,
      },
      filename: (elementId, eventName) => `${elementId}_${eventName}_overview`,
    },
    containers: {
      generator: generateExcelWorkbookContainersForFullDetails,
      filename: (elementId, eventName) => `${elementId}_${eventName}_containers`,
    },
  },
};

export default reportType;
