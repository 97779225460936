import {
  faSignInAlt, faSignOutAlt, faUser, faUserTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Link, OAuthAuthenticationService, withRouter, logoutAction,
} from '@ipc/ipc-core';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import './authInfo.scss';
import { getAuthState } from './redux/selectors/authInfoSelector';

const mapStateToProps = (state) => ({
  auth: getAuthState(state),
});

const Authenticated = (props) => {
  const {
    internalLogout,
  } = props;
  return (
    <div className="auth-info">
      <div className="icon-container">
        <FontAwesomeIcon icon={faUser} className="icon" />
      </div>
      <div className="auth-info-context-menu">
        <div className="choice" onClick={internalLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          <div className="label"><FormattedMessage id="app.signOut" /></div>
        </div>
      </div>
    </div>
  );
};

Authenticated.propTypes = {
  internalLogout: PropTypes.func,
};

const NoAuthenticated = (props) => {
  const { history } = props;
  return (
    <div className="auth-info">
      <div className="icon-container">
        <FontAwesomeIcon icon={faUserTimes} className="icon" />
      </div>
      <div className="auth-info-context-menu">
        <Link to={{ pathname: '/login', state: { from: history.location.pathname } }} className="choice">
          <FontAwesomeIcon icon={faSignInAlt} />
          <div className="label"><FormattedMessage id="app.signIn" /></div>
        </Link>
      </div>
    </div>
  );
};

NoAuthenticated.propTypes = {
  history: PropTypes.objectOf(PropTypes.shape),
};

export class AuthInfoComponent extends React.Component {
  render() {
    const { history, auth, logout } = this.props;
    const internalLogout = () => {
      history.location.pathname = '/';
      OAuthAuthenticationService.logout();
      logout();
    };

    return (
      auth.isAuthenticated() ? <Authenticated auth={auth} internalLogout={internalLogout} />
        : <NoAuthenticated history={history} />
    );
  }
}

AuthInfoComponent.propTypes = {
  history: PropTypes.objectOf(PropTypes.shape),
  logout: PropTypes.func,
  auth: PropTypes.objectOf(PropTypes.shape),
};

export default connect(mapStateToProps, { logout: logoutAction })(withRouter(AuthInfoComponent));
