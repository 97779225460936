import React from 'react';
import { MainMenu } from '../menu';

const AppHeaderCenterContainer = () => (
  <div className="header-center-container">
    <MainMenu />
  </div>
);

export default AppHeaderCenterContainer;
