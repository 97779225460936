import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { withRouter } from '@ipc/ipc-core';
import styled from 'styled-components';

import CheckboxField from '../../../../../../components/common/CheckboxField';
import { SelectStyle } from './FilterForm.styles';
import { getViewAndIdentifierFromRoute } from '../../../../utils';

const StyledSelect = styled.button`
  ${SelectStyle}
`;

export const GroupForm = ({
  group, onSelectAll, onUnselectAll, formValues,
}) => {
  const areAllSelected = formValues && group.checkboxes.length === Object.keys(formValues).filter((key) => formValues[key]).length;

  return (
    <div className="filter-form__group" key={group.label}>
      <div className="filter-form__group_label">
        {group.label}
        <StyledSelect type="button" onClick={() => (areAllSelected ? onUnselectAll(group.checkboxes) : onSelectAll(group.checkboxes))}>
          {areAllSelected ? 'Unselect All' : 'Select All'}
        </StyledSelect>
      </div>
      <div className="filter-form__group__checkboxes">
        {group.checkboxes.map((checkbox) => (
          <div key={checkbox.label}>
            <Field
              checked={checkbox.checked}
              component={CheckboxField}
              name={checkbox.label}
              type="checkbox"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

GroupForm.propTypes = {
  group: PropTypes.objectOf(PropTypes.shape),
  onSelectAll: PropTypes.func,
  onUnselectAll: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.shape),
};

const ConnectedGroupForm = withRouter(({
  group, ...rest
}) => {
  const { match } = rest;
  const { view, pageId } = getViewAndIdentifierFromRoute(match);

  const selector = formValueSelector(`${view}.${pageId}.filters`);
  const ConGroupForm = useMemo(() => connect((state) => (group.checkboxes.length === 1
    ? { formValues: { [group.checkboxes[0].label]: selector(state, group.checkboxes[0].label) } }
    : { formValues: selector(state, ...group.checkboxes.map(({ label }) => label)) }))(GroupForm), [view, pageId, group]);

  return <ConGroupForm group={group} {...rest} />;
});

export default ConnectedGroupForm;
