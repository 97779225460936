import axios from 'axios';
import { constructServiceURL } from '../../core/modules/common/tools/URLTools';
import config from '../../config/configureSettings';

export const fetchDespatchClosedForReceptacle = (messageAggregateId, aggregateId, receptacleId) => axios.get(constructServiceURL({
  endpoint: 'fetchDespatchClosedForReceptacle',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId, receptacleId },
}));

export const fetchDespatchClosedForDespatch = (messageAggregateId, aggregateId) => axios.get(constructServiceURL({
  endpoint: 'fetchDespatchClosedForDespatch',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId },
}));

export const fetchDespatchArrivedInwardOeForReceptacle = (
  messageAggregateId,
  aggregateId,
  receptacleId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchDespatchArrivedInwardOeForReceptacle',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId, receptacleId },
}));

export const fetchDespatchArrivedInwardOeForDespatch = (messageAggregateId, aggregateId) => axios.get(constructServiceURL({
  endpoint: 'fetchDespatchArrivedInwardOeForDespatch',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId },
}));

export const fetchDespatchClosedFullTransportForDespatch = (messageAggregateId, aggregateId) => axios.get(constructServiceURL({
  endpoint: 'fetchDespatchClosedFullTransportForDespatch',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId },
}));

export const fetchConsignmentAdvisedToDestinationPost = (
  messageAggregateId,
  aggregateId,
  receptacleId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchConsignmentAdvisedToDestinationPost',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId, receptacleId },
}));

export const fetchConsignmentAdvisedToDestinationPostForConsignment = (
  messageAggregateId,
  aggregateId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchConsignmentAdvisedToDestinationPostForConsignment',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId },
}));

export const fetchConsignmentAdvisedToCarrier = (
  messageAggregateId,
  aggregateId,
  receptacleId,
  consignmentId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchConsignmentAdvisedToCarrier',
  stub: config.useStubbedEndpoints,
  params: {
    messageAggregateId, aggregateId, receptacleId, consignmentId,
  },
}));

export const fetchConsignmentAdvisedToCarrierForConsignment = (
  messageAggregateId,
  aggregateId,
  consignmentId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchConsignmentAdvisedToCarrierForConsignment',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId, consignmentId },
}));

export const fetchConsignmentArrivedAtDestinationPost = (
  messageAggregateId,
  aggregateId,
  receptacleId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchConsignmentArrivedAtDestinationPost',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId, receptacleId },
}));

export const fetchConsignmentArrivedAtDestinationPostForConsignment = (
  messageAggregateId,
  aggregateId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchConsignmentArrivedAtDestinationPostForConsignment',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId },
}));

export const fetchConsignmentReportedOnByCarrier = (
  messageAggregateId,
  aggregateId,
  receptacleId,
  consignmentId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchConsignmentReportedOnByCarrier',
  stub: config.useStubbedEndpoints,
  params: {
    messageAggregateId, aggregateId, receptacleId, consignmentId,
  },
}));

export const fetchConsignmentReportedOnByCarrierForConsignment = (
  messageAggregateId,
  aggregateId,
  consignmentId,
) => axios.get(constructServiceURL({
  endpoint: 'fetchConsignmentReportedOnByCarrierForConsignment',
  stub: config.useStubbedEndpoints,
  params: { messageAggregateId, aggregateId, consignmentId },
}));
