import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const Title = (props) => {
  const {
    translationKey,
    ...others
  } = props;

  return translationKey ? (<h1 {...others}><FormattedMessage id={translationKey} /></h1>) : null;
};

Title.propTypes = {
  translationKey: PropTypes.string,
};

export default Title;
