import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Button from '../../../../components/common/Button';
import SearchFormInputField from './SearchFormInputField';

export const SearchForm = (props) => {
  const {
    handleSubmit,
    invalid,
    isSearching,
    searchValue,
  } = props;

  return (
    <form className="search-form" onSubmit={handleSubmit}>
      <div className="search-input-container">
        <FormattedMessage id="app.tracking.search.input.placeholder">
          {(txt) => (
            <Field
              className="search-input"
              name="searchInput"
              component={SearchFormInputField}
              type="text"
              placeholder={txt}
            />
          )}
        </FormattedMessage>
      </div>
      <div className="search-button-container">
        <Button
          type="submit"
          size="lg"
          className="search-button"
          disabled={isSearching || invalid || !searchValue}
          isLoading={isSearching}
          primary
        >
          {isSearching
            ? <FormattedMessage id="app.tracking.search.searching-button" />
            : <FormattedMessage id="app.tracking.search.search-button" />}
        </Button>
      </div>
    </form>
  );
};

SearchForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSearching: PropTypes.bool,
  invalid: PropTypes.bool,
  searchValue: PropTypes.string,
};

const ReduxSearchForm = injectIntl(reduxForm({
  form: 'search',
})(SearchForm));

const selector = formValueSelector('search');
export default connect((state) => ({ searchValue: selector(state, 'searchInput') }))(ReduxSearchForm);
