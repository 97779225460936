import * as PropTypes from 'prop-types';
import React from 'react';
import { Location, LocationMapMarkerIcon } from './styles';

export const EventLocation = ({ event }) => {
  const { eventType, eventLocation: { location } } = event;
  return (
    <Location>
      <LocationMapMarkerIcon eventType={eventType} />
      <span id="event-header-location" title={location}>{location}</span>
    </Location>
  );
};

EventLocation.propTypes = {
  event: PropTypes.shape({
    eventType: PropTypes.string,
    eventLocation: PropTypes.shape({
      location: PropTypes.string,
    }),
  }),
};
