import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TabsPanel from '../../../../common/components/TabsPanel';
import { downloadXls, reportType } from '../../../../common/components/timeline/timelineDownloadHelper';
import { CONSIGNMENT } from '../../../../common/constants/Constants';
import { determineType } from '../../../helpers/EventTypeDeterminator';
import ContainerTable from '../eventContentDetails/ContainerTable';
import OverviewTable from '../eventContentDetails/OverviewTable';
import ReceptaclesTableForConsignment from '../eventContentDetails/ReceptaclesTableForConsignment';
import TransportTableForConsignment from '../eventContentDetails/TransportTableForConsignment';
import getOverviewMapper from '../getOverviewMapper';
import ConsignmentEventGeneralInfo from './ConsignmentEventGeneralInfo';

export class CommonFullDetails extends Component {
  getMap() {
    const { event } = this.props;
    const {
      consignmentId,
      generalInfo,
      eventInfo = {},
      error, eventName,
    } = event;
    const filenameInfo = generalInfo && ([consignmentId, generalInfo.eventName]);

    const eventType = determineType(eventName);

    return {
      overview: {
        tabContent: <OverviewTable
          isLoaded={!!eventInfo.overview}
          overview={getOverviewMapper(generalInfo.eventName, eventInfo.overview)}
        />,
        actionButtons: [{
          key: 'download',
          onClick: () => {
            downloadXls(
              reportType.fullDetails.overview.get(eventType),
              [eventInfo.overview],
              filenameInfo,
            );
          },
        }],
      },
      transport: {
        tabContent: <TransportTableForConsignment
          transportLegs={eventInfo.transportLegs}
          isLoaded={!!eventInfo.transportLegs}
          error={error}
          isFullDetails
        />,
        actionButtons: [{
          key: 'download',
          onClick: () => {
            downloadXls(
              reportType.fullDetails.transport.get(CONSIGNMENT),
              [eventInfo.transportLegs],
              filenameInfo,
            );
          },
        }],
      },
      receptacles: {
        tabContent: <ReceptaclesTableForConsignment
          receptacles={eventInfo.receptacles}
          isLoaded={!!(!error && eventInfo.receptacles)}
          error={error}
          showPagination
          showAllColumns
        />,
        actionButtons: [{
          key: 'download',
          onClick: () => {
            downloadXls(
              reportType.fullDetails.receptacles.get(CONSIGNMENT),
              [eventInfo.receptacles],
              filenameInfo,
            );
          },
        }],
      },
      containers: {
        tabContent: <ContainerTable
          containers={eventInfo.containers}
          isLoaded={!!(!error && eventInfo.containers)}
          isFullDetails
        />,
        actionButtons: [{
          key: 'download',
          onClick: () => {
            downloadXls(
              reportType.fullDetails.containers,
              [eventInfo.containers],
              filenameInfo,
            );
          },
        }],
      },
    };
  }

  render() {
    const { activeTab } = this.props;
    return (
      <div className="full-details-guttered">
        <ConsignmentEventGeneralInfo id="consignmentEventGeneralInfo" {...this.props} />
        <TabsPanel id="tabsPanel" map={this.getMap()} activeTab={activeTab} />
      </div>
    );
  }
}

CommonFullDetails.propTypes = {
  activeTab: PropTypes.string,
  receptacleId: PropTypes.string,
  event: PropTypes.objectOf(PropTypes.shape),
};

CommonFullDetails.defaultProps = {
  event: {},
};

export default CommonFullDetails;
