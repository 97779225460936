// Look at https://github.com/callemall/material-ui/blob/master/src/styles/getMuiTheme.js
// for a full spec of possibilities.

/*
@deprecated This file is kept only to reference previous theme property when material-ui 0.9 was used
please don't modified it, is going to be deleted in the future.
*/

import _colors from '../../core/modules/common/colors';

export default {
  menuItem: {
    selectedTextColor: _colors.colorLightBlue,
  },
  checkbox: {
    label: {
      color: '#575858',
    },
    checkbox: {
      color: '#b9b9b9',
    },
    states: {
      active: {
        label: {
          color: _colors.colorLightBlue,
        },
        checkbox: {
          fill: _colors.colorLightBlue,
        },
      },
    },
  },
  selectDropDown: {
    style: {
      fontSize: '14px',
    },
    menuItem: {
      color: '#575858',
    },
    selectedMenuItem: {},
  },
  sidebar: {
    selectDropDown: {
      style: {
        fontSize: '14px',
        width: '100%',
      },
      menuItem: {
        color: '#575858',
        fontSize: '14px',
      },
      selectedMenuItem: {
        color: _colors.colorLightBlue,
      },
    },
  },
  redButton: {
    backgroundColor: _colors.backgroundColorTertiary,
    borderColor: _colors.backgroundColorTertiary,
    color: _colors.colorWhite,
    disabled: {
      backgroundColor: _colors.colorLightRed,
      borderColor: _colors.colorLightRed,
      color: _colors.colorWhite,
    },
  },
  blueButton: {
    backgroundColor: _colors.colorLightBlue,
    borderColor: _colors.colorLightBlue,
    color: _colors.colorWhite,
    disabled: {
      backgroundColor: _colors.backgroundColorDefault,
      borderColor: _colors.backgroundColorDefault,
      color: _colors.colorBlueNavy,
    },
  },
  keepState: {
    backgroundColor: _colors.colorLightBlue,
    color: _colors.colorWhite,
    borderColor: _colors.colorLightBlue,
  },
};
