import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../../../api';
import { fetchDespatchFailureActionCreator, fetchDespatchSuccessActionCreator } from '../actions/DespatchActions';
import { FETCH_DESPATCH_REQUEST } from '../actions/types/DespatchActionTypes';

function* fetchDespatchWorker(action) {
  const {
    params: {
      despatchId,
    },
  } = action;
  try {
    const res = yield call(api.fetchDespatch, despatchId);
    yield put(fetchDespatchSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchDespatchFailureActionCreator(error));
  }
}

export function* fetchDespatchWatcher() {
  yield takeLatest(FETCH_DESPATCH_REQUEST, fetchDespatchWorker);
}

export default fetchDespatchWatcher;
