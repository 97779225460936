import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import Root from './components/application/Root';
import './sass/main.scss';
import * as serviceWorker from './serviceWorker';
import './FontAwesomeIcons';

export default render(
  <Root />,
  document.getElementById('root') || document.createElement('div'),
);

serviceWorker.unregister();
