import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import noop from '../../../../../core/modules/common/tools/noop';
import { date } from '../../../../react-table/helpers/fieldFormatter';
import { GeneralInfo } from '../../../../common/components/GeneralInfo';

function getGeneralInfoFields(event) {
  if (!_.isEmpty(event)) {
    return [
      {
        className: 'interchange-reference',
        intlID: 'app.tracking.timeline.event.details.interchangeReference',
        value: event.messageInterchangeReference,
      },
      {
        className: 'reference',
        intlID: 'app.tracking.timeline.event.details.reference',
        value: event.messageReference,
      },
      {
        className: 'sender',
        intlID: 'app.tracking.timeline.event.details.sender',
        value: event.sender,
      },
      {
        className: 'receiver',
        intlID: 'app.tracking.timeline.event.details.receiver',
        value: event.receiver,
      },
      {
        className: 'preparation-date',
        intlID: 'app.tracking.timeline.event.details.preparation.date',
        value: date({ value: event.messagePreparationDate }),
      },
      {
        className: 'common-event-field',
        intlID: 'app.tracking.timeline.event.details.network.date',
        value: date({ value: event.onNetworkTime }),
      },
      {
        className: 'country-code',
        intlID: 'app.tracking.timeline.event.details.countryCode',
        value: event.eventLocation && (event.eventLocation.country),
      },
      {
        className: 'message-version',
        intlID: 'app.tracking.timeline.event.details.messageVersion',
        value: event.messageTypeVersion,
      },
      {
        className: 'status',
        intlID: 'app.tracking.timeline.event.details.status',
        value: event.messageStatus,
      },
      {
        className: 'legs',
        intlID: 'app.tracking.timeline.event.details.legs',
        value: event.legs,
      },
      {
        className: 'first-leg-transport',
        intlID: 'app.tracking.timeline.event.details.firstLegTransport',
        value: event.firstLegTransport,
      },
      {
        className: 'last-leg-transport',
        intlID: 'app.tracking.timeline.event.details.lastLegTransport',
        value: event.lastLegTransport,
      },
      {
        className: 'total-receptacles',
        intlID: 'app.tracking.timeline.event.details.totalReceptacles',
        value: event.totalReceptacles,
      },
    ];
  }
  return [];
}

const ConsignmentEventGeneralInfo = ({ event, showEDI, toggleEventEDI }) => {
  const infoFields = useMemo(() => getGeneralInfoFields(event), [event]);

  return (
    <GeneralInfo
      infoFields={infoFields}
      event={event}
      showEDI={showEDI}
      toggleEventEDI={toggleEventEDI}
    />
  );
};

ConsignmentEventGeneralInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

ConsignmentEventGeneralInfo.defaultPropTypes = {
  showEDI: false,
  toggleEventEDI: noop,
};

export default ConsignmentEventGeneralInfo;
