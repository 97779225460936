import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonEventContentDetails from '../../../../common/components/CommonEventContentDetails';
import { fetchConsignmentAdvisedToDestPostRequestActionCreator } from '../../../redux/actions/EventInfoActions';
import ConsignmentEventGeneralInfo from './ConsignmentEventGeneralInfo';

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchConsignmentAdvisedToDestPostRequestActionCreator,
}, dispatch);

const mapStateToProps = (state) => ({
  receptacleId: state.tracking.receptacle.receptacle.receptacleId,
});

export class PreconEventContentDetails extends Component {
  requestEventDetails = () => {
    const { fetchConsignmentAdvisedToDestPostRequestActionCreator: fetchConsignmentAdvisedToDestPostRequestActionCreator1, event, receptacleId } = this.props;
    fetchConsignmentAdvisedToDestPostRequestActionCreator1(
      event.messageAggregateId,
      event.aggregateId,
      receptacleId,
    );
  };

  render() {
    const { receptacleId } = this.props;
    return (
      <CommonEventContentDetails
        {...this.props}
        entityId={receptacleId}
        generalInfoComponent={<ConsignmentEventGeneralInfo {...this.props} />}
        requestEventDetails={this.requestEventDetails}
      />
    );
  }
}

PreconEventContentDetails.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  fetchConsignmentAdvisedToDestPostRequestActionCreator: PropTypes.func,
  receptacleId: PropTypes.string,
  consignment: PropTypes.objectOf(PropTypes.shape),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreconEventContentDetails);
