/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from '@ipc/ipc-core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Select } from '@ipc/ipc-components';
import { getViewAndIdentifierFromRoute } from '../../../../utils';

export const SortForm = () => {
  const sortOptions = [
    { value: 'desc', label: <FormattedMessage id="app.tracking.sorting.desc" /> },
    { value: 'asc', label: <FormattedMessage id="app.tracking.sorting.asc" /> },
  ];

  return (
    <form className="sort-form">
      <div className="sort-form__group">
        <div className="sort-form__group_label">
          <FormattedMessage id="app.tracking.sidebar.sorting" />
        </div>
        <div className="sort-form__group_sort-field">
          <Field
            component={({ input, ...props }) => (
              <Select
                {...input}
                {...props}
              />
            )}
            name="sort"
            variant="standard"
            defaultValue="desc"
            options={sortOptions}
          />
        </div>
      </div>
    </form>
  );
};

const SortPerPage = (props) => {
  const { match } = props;
  const { view, pageId } = getViewAndIdentifierFromRoute(match);

  const Sort = useMemo(() => reduxForm({
    form: `${view}.${pageId}.sort`,
    initialValues: { sort: 'desc' },
    destroyOnUnmount: false,
  })(SortForm), [view, pageId]);

  return <Sort {...props} />;
};

export default injectIntl(withRouter(SortPerPage));
