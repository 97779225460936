function getDefault(map) {
  return map.default;
}

function getOrDefault(map, key) {
  const value = map[key];
  return value || getDefault(map);
}

module.exports = {
  getOrDefault,
  getDefault,
};
