import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pushHistory } from '../../../core/modules/common/tools/HistoryTools';
import { secureFullDetails } from '../../../security';
import { FullDetails } from '../components/despatch/full-details/FullDetails';
import { fetchDespatchArrivedInwardOeForDespatchRequestActionCreator, fetchDespatchClosedForDespatchRequestActionCreator } from '../redux/actions/EventInfoActions';

const mapDispatchToProps = (dispatch) => (bindActionCreators({
  fetchDespatchArrivedInwardOeForDespatchRequestActionCreator,
  fetchDespatchClosedForDespatchRequestActionCreator,
}, dispatch));

const mapStateToProps = (state, props) => ({
  fullDetailsEvent: _.find(
    state.tracking.despatch.despatch.allEvents,
    { aggregateId: props.match.params.aggregateId },
  ) || {},
});

export class FullDetailsForDespatchContainer extends Component {
  componentDidMount() {
    const {
      match,
      fetchDespatchClosedForDespatchRequestActionCreator: fetchDespatchClosedForDespatchRequestActionCreator1,
      fetchDespatchArrivedInwardOeForDespatchRequestActionCreator: fetchDespatchArrivedInwardOeForDespatchRequestActionCreator1,
    } = this.props;
    const { params } = match;
    const {
      messageAggregateId,
      messageType,
      aggregateId,
    } = params;

    switch (messageType) {
    case 'RESDES':
      fetchDespatchArrivedInwardOeForDespatchRequestActionCreator1(
        messageAggregateId,
        aggregateId,
      );
      break;
    case 'PREDES':
      fetchDespatchClosedForDespatchRequestActionCreator1(
        messageAggregateId,
        aggregateId,
      );
      break;
    default:
      pushHistory('/');
    }
  }

  render() {
    const { fullDetailsEvent } = this.props;
    return secureFullDetails(FullDetails, fullDetailsEvent);
  }
}

FullDetailsForDespatchContainer.propTypes = {
  fetchDespatchArrivedInwardOeForDespatchRequestActionCreator: PropTypes.func,
  fetchDespatchClosedForDespatchRequestActionCreator: PropTypes.func,
  fullDetailsEvent: PropTypes.objectOf(PropTypes.shape),
  match: PropTypes.shape({
    params: PropTypes.shape({
      messageAggregateId: PropTypes.string,
      messageType: PropTypes.string,
      aggregateId: PropTypes.string,
      consignmentId: PropTypes.string,
    }),
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FullDetailsForDespatchContainer);
