import PropTypes from 'prop-types';
import React from 'react';
import DefaultEventContentDetails from '../../../../common/components/timeline/eventContentDetails/DefaultEventContentDetails';
import { getDefault, getOrDefault } from '../../../../common/MapUtils';
import { determineType } from '../../../helpers/EventTypeDeterminator';
import PredesEventContentDetails from './PredesEventContentDetails';
import ResdesEventContentDetails from './ResdesEventContentDetails';

const eventContentDetailsMap = {
  PREDES: (props) => <PredesEventContentDetails {...props} />,
  RESDES: (props) => <ResdesEventContentDetails {...props} />,
  default: (props) => <DefaultEventContentDetails {...props} />,
};

const EventContentDetailsForDespatchPage = (props) => ((props.event)
  ? getOrDefault(eventContentDetailsMap, determineType(props.event.eventName))(props)
  : getDefault(eventContentDetailsMap)());

EventContentDetailsForDespatchPage.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

export default EventContentDetailsForDespatchPage;
