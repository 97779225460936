import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@ipc/ipc-components';

const CollapsablePanelDetails = (props) => {
  const { bodyComponent } = props;
  return (
    <Box className="collapsable-panel__body" mt={3}>
      {bodyComponent}
    </Box>
  );
};

CollapsablePanelDetails.propTypes = {
  bodyComponent: PropTypes.element.isRequired,
};

export default CollapsablePanelDetails;
