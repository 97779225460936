import getMuiTheme from 'material-ui/styles/getMuiTheme';
import projectMaterialUISettings from '../../../elements/theme/materialUiThemeV0';

export const getTheme = ({ userAgent }) => {
  const coreSettings = {
    userAgent,
    palette: {
      primary1Color: '#004a9f',
      primary2Color: '#004a9f',
    },
    radioButton: {
      borderColor: '#c9c9c9',
    },
    timePicker: {
      accentColor: '#ec7814',
      headerColor: '#004a9f',
    },
    menuItem: {
      selectedTextColor: '#ec7814',
    },
    flatButton: {
      fontSize: '12px',
      fontWeight: '300',
    },
  };

  return getMuiTheme({
    ...coreSettings,
    ...projectMaterialUISettings,
  });
};

function throwNamespaceError(namespace) {
  throw new Error(`Style not found for the namespace "${namespace}", check if style exists in materialUITheme.js`);
}

export const getStyle = (namespace, data = getTheme({})) => {
  const pieces = namespace.split('.');
  let current = data;
  let i;
  const numberOfPieces = pieces.length;

  if (!data || typeof data !== 'object') {
    throwNamespaceError(namespace);
  }

  for (i = 0; i < numberOfPieces; i += 1) {
    current = current[pieces[i]];

    if (current === undefined || current === null) {
      throwNamespaceError(namespace);
    }
  }

  return current;
};

export default {
  getStyle,

};
