let history = {};

export const getHistory = () => history;
export const setHistory = (newHistory) => { history = newHistory; };

export const pushHistory = (path, delay) => {
  if (typeof delay === 'undefined') {
    getHistory().push(path);
  } else {
    setTimeout(getHistory().push.bind(null, path), delay);
  }
};
