import React from 'react';
import { useTranslator } from '../../../../hooks';
import { searchResultType } from '../../../../types';
import { containsType } from './SearchHelper';
import SearchResultTypes from './SearchResultTypes';
import SingleResult from './SingleResult';

const {
  RECEPTACLE_CORE,
} = SearchResultTypes;

const searchResultCore = (identifier) => (
  <SingleResult
    id="searchResultCore"
    searchResultId={identifier}
    className="search-result-core"
    type="receptacle"
    key={identifier}
  />
);

const SearchResultReceptacle = (props) => {
  const {
    searchResult = {},
  } = props;
  const { identifier, types, coreReceptacleIds } = searchResult;
  const { translate } = useTranslator('app.tracking.search');
  const filteredCoreReceptacleIds = coreReceptacleIds
    .filter((receptacleId) => receptacleId !== identifier);

  const isReceptacleCore = () => containsType(types, RECEPTACLE_CORE);

  return (
    <>
      {isReceptacleCore() && (
        coreReceptacleIds && coreReceptacleIds
          .map((receptacleId) => (
            <SingleResult
              id="searchResult"
              searchResultId={receptacleId}
              className="search-result"
              key={receptacleId}
              type="receptacle"
            />
          ))
      )}
      {!isReceptacleCore() && (
        <>
          <SingleResult
            searchResultId={identifier}
            className="search-result"
            key={identifier}
            type="receptacle"
          />
          {filteredCoreReceptacleIds?.length > 0 && (
            <>
              <h4>{translate('receptacle.core')}</h4>
              {
                filteredCoreReceptacleIds.map(searchResultCore)
              }
            </>
          )}
        </>
      )}
    </>
  );
};

SearchResultReceptacle.propTypes = {
  searchResult: searchResultType,
};

export default SearchResultReceptacle;
