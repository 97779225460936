import { useNotification } from '@ipc/ipc-components';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const defaultNotificationProps = {
  notifyOnError: true,
  messageTimeoutInSeconds: 5,
};

export const useErrorNotification = (errorMessage, notificationOptions = defaultNotificationProps) => {
  const { errorNotification } = useNotification();
  const intl = useIntl();
  const { notifyOnError, messageTimeoutInSeconds } = notificationOptions;

  useEffect(() => {
    if (notifyOnError && errorMessage) {
      errorNotification(intl.formatMessage({ id: errorMessage }), messageTimeoutInSeconds);
    }
  }, [errorMessage, notifyOnError, messageTimeoutInSeconds]);
};

export const useGeneralErrorNotification = (errorMessage) => {
  useErrorNotification(errorMessage, { notifyOnError: errorMessage === 'app.networkError', messageTimeoutInSeconds: 10 });
};
