import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../../../api';
import { fetchReceptacleFailureActionCreator, fetchReceptacleSuccessActionCreator } from '../actions/ReceptacleActions';
import { FETCH_RECEPTACLE_REQUEST } from '../actions/types/ReceptacleActionTypes';

function* fetchReceptacleWorker(action) {
  const {
    params: {
      receptacleId,
    },
  } = action;
  try {
    const res = yield call(api.fetchReceptacle, receptacleId);
    yield put(fetchReceptacleSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchReceptacleFailureActionCreator(error));
  }
}

export function* fetchReceptacleWatcher() {
  yield takeLatest(FETCH_RECEPTACLE_REQUEST, fetchReceptacleWorker);
}

export default fetchReceptacleWatcher;
