import { css } from 'styled-components';

export const LoginContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
  opacity: 1;
  pointer-events: none;
  color : ${({ color }) => color}
`;
