export default {
  lang: 'nl-be',
  messages: {
    'app.title': 'ATAT',
    'app.copyright': '© {copyrightYear} International Post Corporation',
    'app.header.navigation.logout': 'Afmelden',
    'app.forms.date_picker.label.ok': 'OK',
    'app.forms.date_picker.label.cancel': 'Annuleren',
    'app.login.username': 'Gebruikersnaam',
    'app.login.password': 'Wachtwoord',
    'app.login.login': 'AANMELDEN',
    'app.login.validations.required': 'Verplicht',
    'app.login.message.tokenExpired': 'Uw sessie is vervallen. Gelieve opnieuw aan te melden om de applicatie verder te gebruiken.',
    'app.login.errors.credentials': 'De combinatie van de gebruikersnaam en het wachtwoord is incorrect. Gelieve opnieuw te proberen met de juiste gegevens.',
    'app.close': 'sluiten',
    'app.tracking.search.handle': 'zoek / filter',
    'app.tracking.search.title': 'START MET ZOEKEN',
    'app.tracking.search.results': 'ZOEKRESULTATEN',
    'app.tracking.search.receptacle.core': 'RECEPTACLE CORE',
    'app.tracking.search.search-button': 'Zoek',
    'app.tracking.search.input.placeholder': 'Zoek een item, ...',
    'app.tracking.search.input.invalid': 'Zoek een item, ...',
    'app.tracking.item.title': 'ITEM',
    'app.tracking.receptacle.title': 'RECEPTACLE',
    'app.tracking.consignment.title': 'CONSIGNMENT',
    'app.tracking.search.errors.not.found': 'Identificator niet gevonden',
    'app.tracking.sidebar.sorting': 'SORTEREN',
    'app.tracking.sidebar.sorting.desc': 'Meest recente naar oudste',
    'app.tracking.sidebar.sorting.asc': 'Oudste naar meest recent',
    'app.tracking.sidebar.clear-filter-button': 'wis filter',
    'app.tracking.sidebar.errors.not.found': 'niet gevonden',
    'app.tracking.timeline.toggle.label': 'Alle events',
    'app.tracking.timeline.toggle.on': 'aan',
    'app.tracking.timeline.toggle.off': 'uit',
    'app.tracking.timeline.edi.toggle.on': 'edi zonder layout',
    'app.tracking.timeline.edi.toggle.off': 'edi met layout',
    'app.tracking.timeline.sidebar.search': 'zoek',
    'app.tracking.timeline.expand-all': 'Klap alles open',
    'app.tracking.timeline.collapse-all': 'Klap alles toe',
    'app.tracking.timeline.event-group.more': 'bekijk meer {amount}',
    'app.tracking.timeline.event.edi.view': 'Bekijk EDI',
    'app.tracking.timeline.event.edi.hide': 'Verberg EDI',
    'app.tracking.timeline.event.edi.copy': 'Kopiëren naar klembord',
    'app.tracking.timeline.event.edi.title': 'edi bericht',
    'app.tracking.timeline.event.details.preparation.date': 'Bericht aanmaak datum',
    'app.tracking.timeline.event.details.sender': 'Verzender',
    'app.tracking.timeline.event.details.receiver': 'Ontvanger',
    'app.tracking.timeline.event.details.receptacle.id': 'Pakket id',
    'app.tracking.timeline.event.details.receptacle.view': 'Bekijk pakket',
    'app.tracking.timeline.event.details.despatch.id': 'Verzend id',
    'app.tracking.timeline.event.details.consignment.id': 'Overdracht id',
    'app.tracking.timeline.event.details.full-details': 'Volledig detail',
    'app.tracking.timeline.event.transport.table.leg': 'Leg',
    'app.tracking.timeline.event.transport.table.mode': 'Modus',
    'app.tracking.timeline.event.transport.table.carrierCodeAndConveyance': 'Koerier / Vervoermiddel',
    'app.tracking.timeline.event.transport.table.origin': 'Oorsprong',
    'app.tracking.timeline.event.transport.table.destination': 'Bestemming',
    'app.tracking.timeline.event.transport.table.planned.departure': 'Gepland vertrek',
    'app.tracking.timeline.event.transport.table.planned.arrival': 'Geplande aankomst',
    'app.tracking.timeline.event.detail.items.table.sequence': '#',
    'app.tracking.timeline.event.detail.items.table.itemId': 'Item Id',
    'app.tracking.timeline.event.detail.items.table.handlingClass': 'Klasse afhandelen',
    'app.tracking.timeline.event.detail.items.table.weight': 'Gewicht',
    'app.tracking.timeline.event.detail.items.table.chargingMethod': 'Betaalwijze',
    'app.tracking.timeline.event.detail.items.table.nonDeliveryReason': 'Reden voor niet-levering',
    'app.tracking.timeline.event.detail.items.table.destinationCountry': 'Bestemmingsland',
    'app.tracking.timeline.event.detail.items.table.returnedCharge': 'Terugzending aangerekend',
    'app.tracking.timeline.event.detail.items.table.insuredValue': 'Verzekerde waarde',
    'app.tracking.timeline.event.detail.items.table.express': 'Expres',
    'app.tracking.timeline.event.detail.items.table.dangerousGoodsClass': 'Gevaarlijke items klasse',
    'app.tracking.timeline.event.detail.items.table.itemClass': 'Item klasse',
    'app.tracking.timeline.event.detail.items.table.eventEMD': 'Event EMD',
    'app.tracking.timeline.event.detail.items.table.eventEMI': 'Event EMI',
    'app.tracking.timeline.events.not.found': 'Geen evenementen gevonden',
    'app.tracking.timeline.download': 'opslaan',
    'app.tracking.timeline.edi.search': 'zoeken',
    'app.tracking.sorting.asc': 'oud naar nieuw',
    'app.tracking.sorting.desc': 'nieuw naar oud',
    'app.tracking.back': 'terug',
  },
};
