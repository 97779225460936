import moment from 'moment';
import { VALUE_IF_ABSENT } from '../../common/constants/Constants';
import { DATES } from '../../../utils/constants';

export function date(row, _, defaultValue = VALUE_IF_ABSENT) {
  if (!row.value) {
    return defaultValue;
  }
  const momentDate = moment(row.value);

  if (!momentDate.isValid()) {
    return row.value;
  }
  return momentDate.format(DATES.HOUR_FORMAT);
}

export function bool(row, _, defaultValue = VALUE_IF_ABSENT) {
  if (row.value == null) {
    return defaultValue;
  }
  return row.value ? 'Y' : 'N';
}

export function text(row, _, defaultValue = VALUE_IF_ABSENT) {
  return row.value || defaultValue;
}

export const formatMap = {
  date,
  bool,
  text,
};

export default function formatField(fieldType) {
  return formatMap[fieldType];
}
