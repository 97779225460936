import { all, fork } from 'redux-saga/effects';
import doSearchWatcher from './SearchSaga';
import { fetchItemWatcher } from './ItemSaga';
import { fetchReceptacleWatcher } from './ReceptacleSaga';
import { fetchConsignmentWatcher } from './ConsignmentSaga';
import { fetchDespatchWatcher } from './DespatchSaga';
import { fetchEdiFormattedWatcher, fetchEdiRawWatcher } from './EdiSaga';
import {
  fetchDespatchClosedForReceptacleWatcher,
  fetchDespatchClosedForDespatchWatcher,
  fetchDespatchArrivedInwardOeForReceptacleWatcher,
  fetchDespatchArrivedInwardOeForDespatchWatcher,
  fetchDespatchFullTransportForPredesWatcher,
  fetchConsignmentAdvisedToCarrierWatcher,
  fetchConsignmentAdvisedToCarrierForConsignmentWatcher,
  fetchConsignmentAdvisedToDestinationPostWatcher,
  fetchConsignmentAdvisedToDestinationPostForConsignmentWatcher,
  fetchConsignmentArrivedAtDestinationPostWatcher,
  fetchConsignmentArrivedAtDestinationPostForConsignmentWatcher,
  fetchConsignmentReportedOnByCarrierWatcher,
  fetchConsignmentReportedOnByCarrierForConsignmentWatcher,
} from './EventInfoSaga';

export function* searchSaga() {
  yield all([
    doSearchWatcher(),
  ]);
}

export function* itemSagas() {
  yield all([
    fetchItemWatcher(),
  ]);
}

export function* ediSagas() {
  yield all([
    fetchEdiRawWatcher(),
    fetchEdiFormattedWatcher(),
  ]);
}

export function* eventInfoSagas() {
  yield all([
    fetchDespatchClosedForReceptacleWatcher(),
    fetchDespatchClosedForDespatchWatcher(),
    fetchDespatchArrivedInwardOeForReceptacleWatcher(),
    fetchDespatchArrivedInwardOeForDespatchWatcher(),
    fetchDespatchFullTransportForPredesWatcher(),
    fetchConsignmentAdvisedToCarrierWatcher(),
    fetchConsignmentAdvisedToCarrierForConsignmentWatcher(),
    fetchConsignmentAdvisedToDestinationPostWatcher(),
    fetchConsignmentAdvisedToDestinationPostForConsignmentWatcher(),
    fetchConsignmentArrivedAtDestinationPostWatcher(),
    fetchConsignmentArrivedAtDestinationPostForConsignmentWatcher(),
    fetchConsignmentReportedOnByCarrierWatcher(),
    fetchConsignmentReportedOnByCarrierForConsignmentWatcher(),
  ]);
}

export function* receptacleSagas() {
  yield all([
    fetchReceptacleWatcher(),
  ]);
}
export function* consignmentSagas() {
  yield all([
    fetchConsignmentWatcher(),
  ]);
}

export function* despatchSagas() {
  yield all([
    fetchDespatchWatcher(),
  ]);
}

export default function* combinedSagas() {
  yield [
    fork(searchSaga),
    fork(itemSagas),
    fork(ediSagas),
    fork(receptacleSagas),
    fork(despatchSagas),
    fork(consignmentSagas),
    fork(eventInfoSagas),
  ];
}
