import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from '@ipc/ipc-core';
import { TimelineEventGroup as IpcTimelineEventGroup } from '@ipc/ipc-components';
import Button, { ButtonTypes } from '../../../../components/common/Button';
import Icons from '../../../../components/common/Icons';
import TimelineEvent from './TimelineEvent';
import { usePageState } from '../../utils';

const { ViewMoreIcon } = Icons;

export function TimelineEventGroup({ eventGroup, ...rest }) {
  const [, uiState, updateUI] = usePageState(rest.match);
  const { expandedEventGroups = [] } = uiState || {};

  const getIndexCurrentEvent = (_expandedEventGroups) => _.findIndex(_expandedEventGroups, (expandedEventGroup) => expandedEventGroup.groupedEventsKey === eventGroup.getGroupKey());

  const getCurrentEvent = (_expandedEventGroups) => {
    const foundIndex = getIndexCurrentEvent(_expandedEventGroups);
    return _expandedEventGroups[foundIndex];
  };

  const groupToggle = () => {
    const expandedEventGroupsCopy = [...expandedEventGroups];
    const foundIndex = getIndexCurrentEvent(expandedEventGroupsCopy);

    if (expandedEventGroupsCopy[foundIndex]) {
      _.pull(expandedEventGroupsCopy, expandedEventGroupsCopy[foundIndex]);
    } else {
      expandedEventGroupsCopy.push({
        groupedEventsKey: eventGroup.getGroupKey(),
        amountExpanded: 5,
      });
    }
    updateUI('expandedEventGroups', expandedEventGroupsCopy);
  };

  const { events } = eventGroup;
  const foundDetail = useMemo(() => getCurrentEvent([...expandedEventGroups]), [expandedEventGroups]);
  const shouldExpand = foundDetail && foundDetail.amountExpanded > 0;
  const amountHidden = shouldExpand && events.length - foundDetail.amountExpanded;

  const showMore = () => {
    const standardIncrease = 5;
    const expandedEventGroupsCopy = [...expandedEventGroups];

    const foundIndex = getIndexCurrentEvent(expandedEventGroupsCopy);
    const moreToShow = eventGroup.getSize() - foundDetail.amountExpanded;
    expandedEventGroupsCopy[foundIndex] = {
      ...foundDetail,
      amountExpanded: foundDetail.amountExpanded + ((moreToShow >= standardIncrease)
        ? standardIncrease
        : moreToShow
      ),
    };

    updateUI('expandedEventGroups', expandedEventGroupsCopy);
  };

  const renderViewMore = () => shouldExpand && (foundDetail.amountExpanded < events.length)
    && (
      <div className="actionHolder">
        <Button
          id="button"
          className="action_button view_more_button"
          onClick={showMore}
          styleType={ButtonTypes.STANDARD}
        >
          <ViewMoreIcon />
          <FormattedMessage id="app.tracking.timeline.event-group.more" values={{ amount: amountHidden }} />
        </Button>
      </div>
    );

  return (
    <IpcTimelineEventGroup
      className="timeline-component-event"
      shouldExpand={shouldExpand}
      total={events.length}
      onToggle={groupToggle}
      renderViewMore={renderViewMore}
    >
      {shouldExpand
        ? events.slice(0, foundDetail && foundDetail.amountExpanded)
          .map((event, index) => (
            <TimelineEvent
              id="timelineEvent"
              className="bottom-small"
              event={event}
              key={event.aggregateId || index}
            />
          ))
        : (
          <TimelineEvent
            id="timelineEvent"
            className="bottom-big"
            event={events[0]}
          />
        )}
    </IpcTimelineEventGroup>
  );
}

TimelineEventGroup.propTypes = {
  eventGroup: PropTypes.objectOf(PropTypes.shape),
  // eslint-disable-next-line react/forbid-prop-types
  expandedEventGroups: PropTypes.arrayOf(PropTypes.any),
};

export default withRouter(TimelineEventGroup);
