import { string } from 'prop-types';
import React from 'react';
import { useTranslator } from '../../../../hooks';
import { searchResultType } from '../../../../types';
import { SearchResultDetail } from './SearchResultDetail';

const SearchResultPanel = (props) => {
  const {
    searchResult = {},
    errorMessage = '',
  } = props;
  const { translate } = useTranslator();

  return errorMessage
    ? (
      <div className="search-result-panel">
        <h2>{translate('app.tracking.search.results')}</h2>
        <div className="error-message">
          {translate(errorMessage)}
        </div>
      </div>
    ) : (<SearchResultDetail id="searchResultMapper" searchResult={searchResult} />);
};

SearchResultPanel.propTypes = {
  searchResult: searchResultType,
  errorMessage: string,
};

export default SearchResultPanel;
