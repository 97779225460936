import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Icons from '../../../../../../components/common/Icons';
import SearchFormInputField from '../../../../../tracking/components/search/SearchFormInputField';
import { SearchBarInputIconWrapper } from './SidebarSearch.styles';

const { SearchIcon } = Icons;

const SearchBarInputIcon = () => <SearchBarInputIconWrapper data-testid="sidebar_searchi"><SearchIcon /></SearchBarInputIconWrapper>;

export const SidebarSearch = (props) => {
  const {
    handleSubmit,
  } = props;

  const { intl } = props;

  return (
    <form id="sidebarSearchForm" onSubmit={handleSubmit}>
      <SearchBarInputIcon />
      <Field
        className="sidebar__search_input"
        name="searchInput"
        component={SearchFormInputField}
        type="text"
        placeholder={intl.formatMessage({ id: 'app.tracking.timeline.sidebar.search' })}
      />
    </form>
  );
};

SidebarSearch.propTypes = {
  handleSubmit: PropTypes.func,
};

export default injectIntl(reduxForm({
  form: 'sidebarSearch',
})(SidebarSearch));
