import React from 'react';

const ItemDetailsForItmatt = React.lazy(() => import('./ItemDetailsForItmatt/ItemDetailsForItmatt'));
const EventDetailsForItmref = React.lazy(() => import('./EventDetailsForItmref/EventDetailsForItmref'));
const EventDetailsForRefrsp = React.lazy(() => import('./EventDetailsForRefrsp/EventDetailsForRefrsp'));

export const getItemDetailsComponent = (type) => {
  switch (type) {
  case 'ITMATT':
    return ItemDetailsForItmatt;
  case 'ITMREF':
    return EventDetailsForItmref;
  case 'REFRSP':
    return EventDetailsForRefrsp;
  default: return () => null;
  }
};
