import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import CollapsablePanel from '../../../../common/components/collapsablePanel/CollapsablePanel';
import { determineType } from '../../../helpers/EventTypeDeterminator';
import FullTransportTable from './FullTransportTable';
import HeaderCollapsableTransport from './HeaderCollapsableTransport';

export class CollapsableTransport extends Component {
  validateHighLight = (value) => {
    const type = determineType(value);
    return ['RESDES', 'RESDIT', 'RESCON'].includes(type);
  };

  render() {
    const {
      transportInfoType,
      transportInfo,
      isLoaded,
      isCollapsed,
      error,
      showAllColumns,
      showPagination,
      type,
    } = this.props;

    return (
      <div style={{ marginBottom: '10px' }}>
        <CollapsablePanel
          isCollapsed={isCollapsed}
          headerComponent={(
            <HeaderCollapsableTransport transportInfoType={transportInfoType} />
          )}
          bodyComponent={(
            <FullTransportTable
              transportInfo={transportInfo}
              validateHighLight={this.validateHighLight}
              showAllColumns={showAllColumns}
              showPagination={showPagination}
              isLoaded={isLoaded}
              type={type}
              error={error}
            />
          )}
        />
      </div>
    );
  }
}

CollapsableTransport.defaultProps = {
  showAllColumns: false,
  showPagination: false,
  isCollapsed: false,
  isLoaded: false,
  error: false,
};

CollapsableTransport.propTypes = {
  transportInfoType: PropTypes.string,
  transportInfo: PropTypes.arrayOf(PropTypes.shape),
  type: PropTypes.string,
  showAllColumns: PropTypes.bool,
  showPagination: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isLoaded: PropTypes.bool,
  error: PropTypes.bool,
};

export default injectIntl(CollapsableTransport);
