import {
  FETCH_RECEPTACLE_FAILURE,
  FETCH_RECEPTACLE_REQUEST,
  FETCH_RECEPTACLE_SUCCESS,
} from './types/ReceptacleActionTypes';

export function fetchReceptacleRequestActionCreator(receptacleId) {
  return {
    type: FETCH_RECEPTACLE_REQUEST,
    params: { receptacleId },
  };
}

export function fetchReceptacleSuccessActionCreator(payload) {
  return {
    type: FETCH_RECEPTACLE_SUCCESS,
    payload,
  };
}

export function fetchReceptacleFailureActionCreator(error) {
  return {
    type: FETCH_RECEPTACLE_FAILURE,
    payload: error,
    error: true,
  };
}
