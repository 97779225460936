import _ from 'lodash';
import {
  FETCH_DESPATCH_REQUEST,
  FETCH_DESPATCH_FAILURE,
  FETCH_DESPATCH_SUCCESS,
} from '../actions/types/DespatchActionTypes';
import {
  FETCH_DESPATCH_CLOSED_FOR_DESPATCH_FAILURE,
  FETCH_DESPATCH_CLOSED_FOR_DESPATCH_SUCCESS,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_SUCCESS,
  FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_FAILURE,
  FETCH_FULL_TRANSPORT_FOR_PREDES_SUCCESS,
  FETCH_FULL_TRANSPORT_FOR_PREDES_FAILURE,
} from '../actions/types/EventInfoActionTypes';
import { separatedBySlash } from '../../helpers/SeparatedBySlash';
import { getErrorMessage } from './utils';

const initialState = {
  error: false,
  errorMessage: '',
  despatch: {
    allEvents: [],
  },
};

const getSenderReceiver = (info) => ({
  sender: info ? separatedBySlash(info.senderMailbox, info.originPostalOperatorCode) : null,
  receiver: info
    ? separatedBySlash(info.recipientMailbox, info.destinationPostalOperatorCode)
    : null,
});

const prepareEvent = (payload, event) => {
  const info = (event && event.generalInfo) ? {
    ...event.generalInfo,
    ...payload.generalInfo,
  } : payload.generalInfo;

  return {
    ...event,
    ...payload,
    ...info,
    eventType: 'despatch',
    ...getSenderReceiver(info),
  };
};

const getEventInfo = ({
  transportLegs = [],
  receptacles = [],
  items = [],
  fullTransport = {},
}) => ({
  transportLegs,
  receptacles,
  items,
  fullTransport,
});

const getParties = (tInfo) => `${tInfo.senderMailbox} / ${tInfo.recipientMailbox}`;

export const getEventInfoForFullTransport = (event, payload) => {
  const {
    predesResdesTransportInfo = [],
    carditResditTransportInfo = [],
    preconResconTransportInfo = [],
  } = payload.transportInfo;

  const { eventInfo } = event;

  return {
    transportLegs: eventInfo.transportLegs || [],
    receptacles: eventInfo.receptacles || [],
    items: eventInfo.items || [],
    fullTransport: {
      predesResdesTransportInfo: predesResdesTransportInfo.map((tInfo) => ({
        ...tInfo,
        id: tInfo.despatchId,
        parties: getParties(tInfo),
      })),
      carditResditTransportInfo: carditResditTransportInfo.map((tInfo) => ({
        ...tInfo,
        id: tInfo.consignmentId,
        parties: getParties(tInfo),
      })),
      preconResconTransportInfo: preconResconTransportInfo.map((tInfo) => ({
        ...tInfo,
        id: tInfo.consignmentId,
        parties: getParties(tInfo),
      })),
    },
  };
};

const updateEvent = (event, payload) => ({
  ...prepareEvent(payload, event),
  eventInfo: getEventInfo(payload),
});

const newEvent = (payload) => ({
  ...prepareEvent(payload),
  eventInfo: getEventInfo(payload),
});

const putObjectsOnDespatchEvent = (state, action) => {
  const { payload } = action;
  const toFind = payload.generalInfo && { aggregateId: payload.generalInfo.aggregateId };
  const allEvents = _.cloneDeep(state.despatch.allEvents);
  const eventToUpdate = _.find(allEvents, toFind);
  const indexToUpdate = _.findIndex(allEvents, toFind);

  const event = eventToUpdate
    ? updateEvent(eventToUpdate, payload)
    : newEvent(payload);

  allEvents.splice(indexToUpdate, 1, event);

  return {
    ...state,
    despatch: {
      ...state.despatch,
      allEvents,
    },
  };
};

const addFullTransportToDespatchEvent = (state, action) => {
  const { payload } = action;
  const toFind = payload.generalInfo && { aggregateId: payload.generalInfo.aggregateId };
  const allEvents = _.cloneDeep(state.despatch.allEvents);
  const eventToUpdate = _.find(allEvents, toFind);
  const indexToUpdate = _.findIndex(allEvents, toFind);
  const info = eventToUpdate.generalInfo;

  const event = {
    ...eventToUpdate,
    ...payload,
    ...info,
    generalInfo: {
      ...info,
    },
    eventType: 'despatch',
    ...getSenderReceiver(info),
  };

  event.eventInfo = getEventInfoForFullTransport(event, payload);

  allEvents.splice(indexToUpdate, 1, event);
  return {
    ...state,
    despatch: {
      ...state.despatch,
      allEvents,
    },
  };
};

const putErrorOnDespatchEvent = (state, action) => {
  let allEvents = _.cloneDeep(state.despatch.allEvents);
  const errorMessage = getErrorMessage(action.payload);

  if (_.isEmpty(allEvents)) {
    allEvents = [{
      aggregateId: action.aggregateId,
      messageAggregateId: action.messageAggregateId,
      error: true,
      errorMessage,
    }];
  } else {
    _.map(allEvents, (event) => {
      if (event.aggregateId === action.aggregateId) {
        event.error = true;
        event.errorMessage = errorMessage;
      }
      return event;
    });
  }

  return {
    ...state,
    despatch: {
      ...state.despatch,
      allEvents,
    },
  };
};

const despatch = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_DESPATCH_REQUEST:
    return {
      ...state,
      despatch: {
        despatchId: action.params.despatchId,
        isLoaded: false,
      },
    };
  case FETCH_DESPATCH_SUCCESS: {
    return {
      error: false,
      errorMessage: '',
      despatch: {
        isLoaded: true,
        aggregateId: action.payload.aggregateId,
        despatchId: action.payload.despatchIdentifier.s8Id,
        originImpc: action.payload.despatchIdentifier.outwardOE,
        originPost: action.payload.despatchIdentifier.originPostalOperatorCode,
        destImpc: action.payload.despatchIdentifier.inwardOE,
        destPost: action.payload.despatchIdentifier.destinationPostalOperatorCode,
        mailCategory: action.payload.despatchIdentifier.mailCategory,
        mailSubclass: action.payload.despatchIdentifier.mailSubclass,
        despatchNumber: action.payload.despatchIdentifier.despatchNumber,
        despatchYear: action.payload.despatchIdentifier.yearIndicator,
        allEvents: action.payload.despatchEvents,
      },
    };
  }

  case FETCH_DESPATCH_FAILURE: {
    const errorMessage = getErrorMessage(action.payload);

    return {
      ...state,
      error: true,
      errorMessage,
      despatch: {
        ...state.despatch,
        isLoaded: true,
      },
    };
  }

  case FETCH_FULL_TRANSPORT_FOR_PREDES_SUCCESS:
    return addFullTransportToDespatchEvent(state, action);

  case FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_SUCCESS:
  case FETCH_DESPATCH_CLOSED_FOR_DESPATCH_SUCCESS:
    return putObjectsOnDespatchEvent(state, action);

  case FETCH_FULL_TRANSPORT_FOR_PREDES_FAILURE:
  case FETCH_DESPATCH_ARRIVED_INWARD_OE_FOR_DESPATCH_FAILURE:
  case FETCH_DESPATCH_CLOSED_FOR_DESPATCH_FAILURE:
    return putErrorOnDespatchEvent(state, action);

  default:
    return state;
  }
};

export default despatch;
