const setUiPageState = (state, prevPageState, view, pageId, key, value) => {
  const values = key ? { [key]: value } : value;

  return {
    ...state,
    [view]: {
      ...state[view],
      [pageId]: {
        ...prevPageState,
        ...values,
      },
    },
  };
};

export function uiReducer(state, action) {
  switch (action.type) {
  case 'CHANGE_PAGE_UI': {
    const {
      payload: {
        view, pageId, key, value,
      },
    } = action;

    return setUiPageState(state, state[view] && state[view][pageId], view, pageId, key, value);
  }
  default:
    return state;
  }
}
