import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';
import { withSecurity } from '../../../security/page';
import Permission from '../../../security/permission';
import noop from '../../../core/modules/common/tools/noop';
import DespatchPage from '../components/despatch/DespatchPage';
import { fetchDespatchRequestActionCreator } from '../redux/actions/DespatchActions';
import { getViewAndIdentifierFromRoute } from '../../common/utils';
import { useGeneralErrorNotification } from '../../../hooks';

const mapStateToProps = (state) => ({
  despatch: state.tracking.despatch.despatch,
  hasError: state.tracking.despatch.error,
  errorMessage: state.tracking.despatch.errorMessage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDespatchRequestActionCreator,
  reset,
}, dispatch);

export function DespatchContainer({
  despatch, hasError, errorMessage, match, reset: reset1, fetchDespatchRequestActionCreator: fetchDespatchRequestActionCreator1,
}) {
  const { params: { despatchId } } = match;

  const resetFilterForm = () => {
    const { view, pageId } = getViewAndIdentifierFromRoute(match);

    reset1(`${view}.${pageId}.filters`);
  };

  const fetchDespatch = (id) => {
    fetchDespatchRequestActionCreator1(id);
  };

  useEffect(() => {
    fetchDespatch(despatchId);
  }, [despatchId]);

  useGeneralErrorNotification(errorMessage);

  return (
    <DespatchPage
      id="despatchPage"
      resetFilterForm={resetFilterForm}
      despatch={despatch}
      hasError={hasError}
      errorMessage={errorMessage === 'app.networkError' ? 'app.tracking.search.errors.not.found' : errorMessage}
      requestedId={despatchId}
    />
  );
}

DespatchContainer.defaultProps = {
  despatch: {},
  fetchDespatchRequestActionCreator: noop,
};

DespatchContainer.propTypes = {
  despatch: PropTypes.objectOf(PropTypes.shape),
  reset: PropTypes.func,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  match: PropTypes.shape({ params: PropTypes.shape({ despatchId: PropTypes.string }) }),
  fetchDespatchRequestActionCreator: PropTypes.func,
};

const ConnectedDespatchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DespatchContainer);
export default withSecurity(ConnectedDespatchContainer, [Permission.READ_DESPATCH]);
