import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from '@ipc/ipc-core';
import noop from '../../../../core/modules/common/tools/noop';
import Sidebar from '../../../common/components/sidebar/Sidebar';
import Summary from '../../../common/components/Summary';
import Timeline from '../../../common/components/timeline/Timeline';
import { downloadXls, reportType } from '../../../common/components/timeline/timelineDownloadHelper';
import Title from '../../../common/components/Title';
import { useDefaultPageState } from '../../../common/utils';

const defaultPageState = {
  summaryExpanded: false,
  renderedEventKeys: [],
  expandedEventKeys: [],
  expandedEventGroups: [],
  allEventsAreExpanded: false,
  expandedEventEdiKey: '',
};

export function ConsignmentPage({
  consignment, resetFilterForm, hasError, errorMessage, requestedId, intl, ...rest
}) {
  const timelineRef = useRef();
  const [{
    summaryExpanded, filters, sort, pageId,
  }, updateUI] = useDefaultPageState(rest.match, defaultPageState);

  const {
    consignmentId,
    countryCode,
    allEvents = [],
    isLoaded,
  } = consignment;
  const label = (consignmentId || pageId).toUpperCase();
  const consignmentFound = !!(!hasError && consignmentId);
  const isRequestIdMatch = requestedId === consignment.consignmentId;

  const onSummaryToggleExpand = () => {
    updateUI('summaryExpanded', !summaryExpanded);
  };

  const onTimelineRef = (ref) => {
    timelineRef.current = ref;
  };

  const getTabs = () => [
    {
      label: intl.formatMessage({ id: 'app.tracking.consignment.tabs.label' }),
      value: countryCode,
    },
  ];

  const download = () => {
    const timeline = timelineRef.current;

    downloadXls(
      reportType.consignment,
      [timeline.getCurrentEvents(), consignment],
      [consignment.consignmentId],
    );
  };

  const tabs = getTabs();

  return (
    <div className="page">
      <Sidebar
        id="sidebar"
        events={allEvents}
        contentFound={consignmentFound}
        onClearFilter={resetFilterForm}
      />
      <div className="timeline-wrapper">
        <Title id="title" translationKey="app.tracking.consignment.title" />

        {!isLoaded
            && (
              <div className="loading-page">
                <FormattedMessage id="app.loading" />
              </div>
            )}

        {isLoaded && isRequestIdMatch && consignmentFound
            && (
              <div>
                <Summary
                  id="summary"
                  onSummaryToggleExpand={onSummaryToggleExpand}
                  expanded={summaryExpanded}
                  tabs={tabs}
                  label={label}
                />
                <div className="timeline-container">
                  <Timeline
                    id="timeline"
                    onRef={onTimelineRef}
                    events={allEvents}
                    filters={filters}
                    sort={sort}
                    canExpandCollapse
                    downloadCallback={download}
                  />
                </div>
              </div>
            )}

        {isLoaded && hasError
            && (
              <div className="search">
                <div className="search-panel">
                  Consignment
                  {' '}
                  <span className="bold">
                    {label}
                  </span>
                  {' - '}
                  <FormattedMessage id={errorMessage} />
                </div>
              </div>
            )}
      </div>
    </div>
  );
}

ConsignmentPage.propTypes = {
  consignment: PropTypes.objectOf(PropTypes.shape),
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  resetFilterForm: PropTypes.func,
  requestedId: PropTypes.string,
};

ConsignmentPage.defaultProps = {
  consignment: {},
  resetFilterForm: noop,
  hasError: false,
  errorMessage: '',
};

export default injectIntl(withRouter(ConsignmentPage));
