import { LightBlue } from '../../elements/Colors';

export const AppContentStyle = `
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  border-radius: 8px;
  padding: 30px 40px;
  background-color: ${LightBlue}; 
`;
