import axios from 'axios';
import config from '../../config/configureSettings';
import { get } from '../request';
import { constructServiceURL } from '../../core/modules/common/tools/URLTools';

export const fetchItem = (itemId) => axios.get(constructServiceURL({
  endpoint: 'fetchItem',
  stub: config.useStubbedEndpoints,
  params: { itemId },
}));

export const fetchContentForItem = ({ aggregateId, itemId }) => get(constructServiceURL({
  endpoint: 'fetchItmattEventDetails',
  stub: config.useStubbedEndpoints,
  params: { aggregateId, itemId },
}));

export const fetchItmrefDetails = ({ aggregateId, itemId }) => get(constructServiceURL({
  endpoint: 'fetchItmrefEventDetails',
  stub: config.useStubbedEndpoints,
  params: { aggregateId, itemId },
}));

export const fetchRefrspDetails = ({ aggregateId, itemId }) => get(constructServiceURL({
  endpoint: 'fetchRefrspEventDetails',
  stub: config.useStubbedEndpoints,
  params: { aggregateId, itemId },
}));
