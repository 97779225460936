import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import { fullDetailsOverviewResconTemplate } from '../../../constants/ExcelTemplates';
import getOverviewMapper from '../../../../tracking/components/consignment/getOverviewMapper';

function generateExcelWorkbookOverviewResconForFullDetails(overviewData) {
  const file = base64.decode(fullDetailsOverviewResconTemplate);
  const template = new XlsxTemplate(file);
  const overview = getOverviewMapper('RESCON', overviewData, '');

  template.substitute('Overview', { overview });
  return template.generate();
}

export default generateExcelWorkbookOverviewResconForFullDetails;
