import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, AccordionIcon } from '@ipc/ipc-components';

const UserDetailHeader = ({
  username, mail, firstName, lastName,
}) => (
  <Flex as="ul" align="center" height="40px" justifyContent="space-between" flexGrow="1">
    <Box as="li" flexBasis="25%">
      <span>{username}</span>
    </Box>
    <Box as="li" flexBasis="25%">
      <span>{mail}</span>
    </Box>
    <Box as="li" flexBasis="25%">
      <span>{firstName}</span>
    </Box>
    <Box as="li" display="flex" justifyContent="space-between" alignItems="center" flexBasis="25%">
      <span>{lastName}</span>
      <AccordionIcon />
    </Box>
  </Flex>
);

UserDetailHeader.propTypes = {
  username: PropTypes.string,
  mail: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default UserDetailHeader;
