import React from 'react';
import { pushHistory } from '../../../../../core/modules/common/tools/HistoryTools';
import { useTranslator, useRoles } from '../../../../../hooks';
import Title from '../../../../../modules/common/components/Title';
import { withSecurity } from '../../../../../security/page';
import Permission from '../../../../../security/permission';
import Button from '../../../../common/Button';
import ButtonsBar from '../../../../common/ButtonsBar';
import RoleForm from '../../../../features/role/RoleForm';

const { CREATE_ROLE } = Permission;

const redirectToRolePage = () => pushHistory('/administration/user-management/roles');

const CreateRolePage = () => {
  const { translate } = useTranslator();
  const { createRole } = useRoles();
  // const [isSubmitting, setSubmitting] = useState(false);
  const ConfirmButton = (props) => <Button data-testid="roleConfirm" primary type="submit" {...props}>{translate('button.confirm')}</Button>;
  const CancelButton = () => <Button data-testid="roleCancel" onClick={redirectToRolePage}>{translate('button.cancel')}</Button>;

  const onSubmit = async (data) => {
    // setSubmitting(true);
    await createRole(data);
    // setTimeout(() => setSubmitting(false), 2000);
  };

  return (
    <div>
      <Title id="title" translationKey="administration.user.roles.create.title" />
      <RoleForm onSubmit={onSubmit}>
        <ButtonsBar>
          <ConfirmButton />
          <CancelButton />
        </ButtonsBar>
      </RoleForm>
    </div>
  );
};

export default withSecurity(CreateRolePage, [CREATE_ROLE]);
