import { FETCH_ITEM_REQUEST, FETCH_ITEM_FAILURE, FETCH_ITEM_SUCCESS } from '../actions/types/ItemActionTypes';
import { getErrorMessage } from './utils';

const initialState = {
  error: false,
  errorMessage: '',
  item: {
    allEvents: [],
  },
};

export default (state = initialState, action) => {
  let allEvents;
  switch (action.type) {
  case FETCH_ITEM_REQUEST:
    return {
      ...state,
      item: {
        itemIdentifier: action.params.itemId,
        isLoaded: false,
      },
    };
  case FETCH_ITEM_SUCCESS: {
    const items = action.payload.itemEvents.map((item) => {
      item.eventType = 'item';
      return item;
    });
    const receptacles = action.payload.receptacleEvents.map((receptacle) => {
      receptacle.eventType = 'receptacle';
      return receptacle;
    });

    allEvents = [...items, ...receptacles];

    return {
      error: false,
      errorMessage: '',
      item: {
        isLoaded: true,
        aggregateId: action.payload.aggregateId,
        itemIdentifier: action.payload.itemIdentifier,
        allEvents,
      },
    };
  }
  case FETCH_ITEM_FAILURE: {
    const errorMessage = getErrorMessage(action.payload);

    return {
      ...state,
      error: true,
      errorMessage,
      item: {
        ...state.item,
        allEvents: allEvents || [],
        isLoaded: true,
      },
    };
  }
  default:
    return state;
  }
};
