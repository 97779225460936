import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../../../api';
import { fetchConsignmentFailureActionCreator, fetchConsignmentSuccessActionCreator } from '../actions/ConsignmentActions';
import { FETCH_CONSIGNMENT_REQUEST } from '../actions/types/ConsignmentActionTypes';

function* fetchConsignmentWorker(action) {
  const {
    params: {
      consignmentId,
    },
  } = action;
  try {
    const res = yield call(api.fetchConsignment, consignmentId);
    yield put(fetchConsignmentSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchConsignmentFailureActionCreator(error));
  }
}

export function* fetchConsignmentWatcher() {
  yield takeLatest(FETCH_CONSIGNMENT_REQUEST, fetchConsignmentWorker);
}

export default fetchConsignmentWatcher;
