import { withRouter } from '@ipc/ipc-core';
import { bool, func, string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { matchType, searchResultType } from '../../../types';
import { redirectToPageFromIdentifier } from '../components/RedirectHelper';
import SearchPage from '../components/search/SearchPage';
import SearchResultTypes from '../components/search/SearchResultTypes';
import { locationChangeActionCreator } from '../redux/actions/NavigationActions';
import { doSearchRequestActionCreator } from '../redux/actions/SearchActions';
import { useGeneralErrorNotification } from '../../../hooks';

const { RECEPTACLE_CORE, RECEPTACLE } = SearchResultTypes;

const mapStateToProps = (state) => ({
  result: state.tracking.search.result,
  errorMessage: state.tracking.search.errorMessage,
  error: state.tracking.search.error,
  isLoading: state.tracking.search.isLoading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  doSearchRequestActionCreator,
  locationChangeActionCreator,
}, dispatch);

const isReceptacleOrReceptacleCore = (type) => (type === RECEPTACLE_CORE || type === RECEPTACLE);

const resultsHaveOnlyOneResult = (result) => {
  const { types, coreReceptacleIds } = result;
  if (types.length === 1) {
    if (isReceptacleOrReceptacleCore(types[0])) {
      return coreReceptacleIds.length <= 1;
    }
    return true;
  }
  return false;
};

const isSearchReturnResult = (searchString, result) => !!(searchString && result.types && result.identifier);

export function SearchContainer({
  match, error, result, errorMessage, isLoading, ...rest
}) {
  const [searchString, setSearchString] = useState(match.params.searchTerm || '');

  const doSearch = (searchValue) => {
    const { doSearchRequestActionCreator: doSearchRequestAction } = rest;
    doSearchRequestAction({ searchString: searchValue });
  };

  const submitSearchForm = (values) => {
    const searchValue = values.searchInput;

    setSearchString(searchValue);
    doSearch(searchValue);
  };

  useEffect(() => {
    const { locationChangeActionCreator: locationChangeAction } = rest;
    if (searchString) {
      doSearch(searchString);
    }

    return () => locationChangeAction();
  }, []);

  useEffect(() => {
    if (isSearchReturnResult(searchString, result)
      && resultsHaveOnlyOneResult(result)) {
      redirectToPageFromIdentifier(result);
    }
  }, [result, searchString]);

  useGeneralErrorNotification(errorMessage);

  return (
    <SearchPage
      id="searchPage"
      onSubmitSearchForm={submitSearchForm}
      searchTerm={searchString}
      searchResult={result}
      hasError={error}
      errorMessage={errorMessage === 'app.networkError' ? 'app.tracking.search.errors.not.found' : errorMessage}
      isSearching={isLoading}
    />
  );
}

SearchContainer.propTypes = {
  doSearchRequestActionCreator: func,
  locationChangeActionCreator: func,
  error: bool,
  errorMessage: string,
  isLoading: bool,
  match: matchType,
  result: searchResultType,
};

const ConnectedSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchContainer);

ConnectedSearchContainer.displayName = 'ConnectedSearchContainer';

export default withRouter(ConnectedSearchContainer);
