import { Auth } from '@ipc/ipc-core';
import { createSelector } from 'reselect';
import { languageSelector } from './languageSelector';

const getAuth = (state) => state.global.auth;

export const getAuthState = createSelector(
  [getAuth, languageSelector],
  (auth) => new Auth(auth),
);
