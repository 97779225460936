import PropTypes from 'prop-types';
import React from 'react';
import TransportTable from '../../../../common/components/tables/TransportTable';
import TabsPanel from '../../../../common/components/TabsPanel';
import { downloadXls, reportType } from '../../../../common/components/timeline/timelineDownloadHelper';
import { determineType } from '../../../helpers/EventTypeDeterminator';
import ConsignmentEventGeneralInfo from './ConsignmentEventGeneralInfo';

const CommonFullDetails = (props) => {
  const { activeTab, event } = props;
  const {
    receptacleId,
    eventInfo = { transportLegs: [] },
    error,
    errorMessage,
    eventName,
  } = event;

  const eventNameParsed = determineType(eventName);

  const map = {
    transport: {
      tabContent: <TransportTable
        isLoaded={!!eventInfo.transportLegs}
        transportLegs={eventInfo.transportLegs}
        error={error}
        showPagination
        isFullDetails
      />,
      actionButtons: [{
        key: 'download',
        onClick: () => {
          downloadXls(
            reportType.fullDetails.transport.get(),
            [eventInfo.transportLegs],
            [receptacleId, eventNameParsed],
          );
        },
      }],
    },
  };

  return (
    <div className="full-details-guttered">
      <ConsignmentEventGeneralInfo id="consignmentEventGeneralInfo" {...props} />
      <TabsPanel id="tabsPanel" map={map} eventError={{ error, errorMessage }} activeTab={activeTab} />
    </div>
  );
};

CommonFullDetails.propTypes = {
  activeTab: PropTypes.string,
  event: PropTypes.objectOf(PropTypes.shape),
};

CommonFullDetails.defaultProps = {
  event: {},
};

export default CommonFullDetails;
