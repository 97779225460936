import PropTypes from 'prop-types';
import React from 'react';
import DefaultEventContentDetails from '../../../../common/components/timeline/eventContentDetails/DefaultEventContentDetails';
import { getDefault, getOrDefault } from '../../../../common/MapUtils';
import { determineType } from '../../../helpers/EventTypeDeterminator';
import CarditEventContentDetails from './CarditEventContentDetails';
import PreconEventContentDetails from './PreconEventContentDetails';
import ResconEventContentDetails from './ResconEventContentDetails';
import ResditEventContentDetails from './ResditEventContentDetails';

const eventContentDetailsMap = {
  PRECON: (props) => <PreconEventContentDetails {...props} />,
  RESCON: (props) => <ResconEventContentDetails {...props} />,
  CARDIT: (props) => <CarditEventContentDetails {...props} />,
  RESDIT: (props) => <ResditEventContentDetails {...props} />,
  default: (props) => <DefaultEventContentDetails {...props} />,
};

const EventContentDetailsForConsignmentPage = (props) => ((props.event)
  ? getOrDefault(eventContentDetailsMap, determineType(props.event.eventName))(props)
  : getDefault(eventContentDetailsMap)());

EventContentDetailsForConsignmentPage.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};

export default EventContentDetailsForConsignmentPage;
