import { func, string, bool } from 'prop-types';
import React from 'react';
import noop from '../../../../core/modules/common/tools/noop';
import SearchForm from './SearchForm';

const SearchPanel = (props) => {
  const {
    onSubmitSearchForm = noop,
    searchTerm = '',
    isSearching,
  } = props;

  return (
    <div className="search-panel on-search-page">
      <SearchForm
        onSubmit={onSubmitSearchForm}
        isSearching={isSearching}
        initialValues={{ searchInput: searchTerm }}
      />
    </div>
  );
};

SearchPanel.propTypes = {
  onSubmitSearchForm: func,
  searchTerm: string,
  isSearching: bool,
};

export default SearchPanel;
