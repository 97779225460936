import createXls from './createXls';

function downloadXls(reportType, reportData, filenameInfo) {
  const pageId = filenameInfo[0];
  const filename = reportType.filename(...filenameInfo);
  const wbout = reportType.generator(...reportData, pageId);

  const buf = new ArrayBuffer(wbout.length);
  const view = new Uint8Array(buf);
  // eslint-disable-next-line no-bitwise, no-plusplus
  for (let i = 0; i !== wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
  createXls(buf, `${filename}.xlsx`);
}

export default downloadXls;
