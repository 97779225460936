import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { despatchTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';
import { separatedBySlash } from '../../../../tracking/helpers/SeparatedBySlash';

function generateExcelWorkbookForDespatchPage(pFilteredEvents, pDespatch) {
  let despatch;
  let events;
  if (!pDespatch) {
    despatch = {
      despatchId: 'N/A',
      originImpc: 'N/A',
      destImpc: 'N/A',
      mailCategory: 'N/A',
      mailSubclass: 'N/A',
    };
  } else {
    despatch = { ...pDespatch };
  }

  events = pFilteredEvents;

  const despatchTemplateFile = base64.decode(despatchTemplate);
  const template = new XlsxTemplate(despatchTemplateFile);

  const dateFormatter = fieldFormatter('date');

  events = {
    events: _.map(pFilteredEvents, (event) => ({
      date: dateFormatter({ value: event.eventDate }),
      type: event.eventName,
      description: event.eventDescription,
      location: event.eventLocation ? event.eventLocation.location : '',
      sender: event && separatedBySlash(event.senderMailbox, event.originPostalOperatorCode),
      receiver: event && separatedBySlash(
        event.recipientMailbox,
        event.destinationPostalOperatorCode,
      ),
    })),
  };

  template.substitute('Despatch', despatch);
  template.substitute('Events', events);
  return template.generate();
}

export default generateExcelWorkbookForDespatchPage;
