import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from '@ipc/ipc-core';
import { date } from '../../../../react-table/helpers/fieldFormatter';
import { getUrl } from '../../../../../core/modules/common/tools/URLTools';
import { GeneralInfo } from '../../../../common/components/GeneralInfo';

const ResdesGeneralInfo = ({ event, event: { generalInfo, despatchId } }) => {
  function getGeneralInfoFields() {
    if (!_.isEmpty(event)) {
      return [
        {
          className: 'interchange-reference',
          intlID: 'app.tracking.timeline.event.details.interchangeReference',
          value: generalInfo.messageInterchangeReference,
        },
        {
          className: 'reference',
          intlID: 'app.tracking.timeline.event.details.reference',
          value: generalInfo.messageReference,
        },
        {
          className: 'status',
          intlID: 'app.tracking.timeline.event.details.status',
          value: generalInfo.messageStatus,
        },
        {
          className: 'sender',
          intlID: 'app.tracking.timeline.event.details.sender',
          value: event.sender,
        },
        {
          className: 'receiver',
          intlID: 'app.tracking.timeline.event.details.receiver',
          value: event.receiver,
        },
        {
          className: 'preparation-date',
          intlID: 'app.tracking.timeline.event.details.preparation.date',
          value: date({ value: generalInfo.messagePreparationDate }),
        },
        {
          className: 'common-event-field',
          intlID: 'app.tracking.timeline.event.details.network.date',
          value: date({ value: generalInfo.onNetworkTime }),
        },
        {
          className: 'load-date',
          intlID: 'app.tracking.timeline.event.details.loadDate',
          value: date({ value: generalInfo.messageLoadDate }),
        },
        {
          className: 'despatch-id',
          intlID: 'app.tracking.timeline.event.details.despatch.id',
          value: despatchId && (<Link id="despatchIdLink" to={getUrl('despatch', despatchId)}>{despatchId}</Link>),
        }];
    }
    return [];
  }

  return (
    <GeneralInfo
      infoFields={getGeneralInfoFields()}
      event={event}
      fullDetails
    />
  );
};

ResdesGeneralInfo.propTypes = {
  event: PropTypes.objectOf(PropTypes.shape),
};

ResdesGeneralInfo.defaultProps = {
  event: {},
};

export default ResdesGeneralInfo;
