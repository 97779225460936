import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@ipc/ipc-components';

const UserDetailItem = ({ title, value, ...rest }) => (
  <Flex component="li" direction="column">
    <Box component="span" color="primary.main">
      {title}
    </Box>
    <Box component="span" color="secondary.main" mb={2} {...rest}>
      {value}
    </Box>
  </Flex>
);

UserDetailItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default memo(UserDetailItem);
