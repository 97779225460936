import React from 'react';
import PropTypes from 'prop-types';
import noop from '../../../../core/modules/common/tools/noop';
import { constructPublicAssetPath } from '../../../../core/modules/common/tools/URLTools';

const CollapsablePanelHeader = (props) => {
  const {
    isCollapsed,
    toggleDetails,
    headerComponent,
  } = props;

  const collapsedClass = isCollapsed ? ' is-collapsed' : ' is-expanded';

  return (
    <div id="CollapsablePanelHeader" className={`collapsable-panel__header ${collapsedClass}`}>
      <div className="collapsable-panel__title">
        {headerComponent}
      </div>
      <div className="header-actions">
        <div onClick={toggleDetails} className="action">
          <img
            data-testid={isCollapsed ? 'header_collapsed' : 'header_expanded'}
            src={constructPublicAssetPath(`images/common/icon-${isCollapsed ? 'plus' : 'minus'}.svg`)}
            alt="expand"
            title="expand"
          />
        </div>
      </div>
      <div style={{ clear: 'both' }} />
    </div>
  );
};

CollapsablePanelHeader.propTypes = {
  headerComponent: PropTypes.element,
  isCollapsed: PropTypes.bool,
  toggleDetails: PropTypes.func,
};

CollapsablePanelHeader.defaultProps = {
  toggleDetails: noop,
  isCollapsed: true,
};

export default CollapsablePanelHeader;
