import PropTypes from 'prop-types';
import React from 'react';
import DefaultEventContentDetails from '../../../../common/components/timeline/eventContentDetails/DefaultEventContentDetails';
import { getDefault, getOrDefault } from '../../../../common/MapUtils';
import { determineType } from '../../../helpers/EventTypeDeterminator';
import CarditEventContentDetails from './CarditEventContentDetails';
import PodEventContentDetails from './PodEventContentDetails';
import PreconEventContentDetails from './PreconEventContentDetails';
import PredesEventContentDetails from './PredesEventContentDetails';
import ResconEventContentDetails from './ResconEventContentDetails';
import ResdesEventContentDetails from './ResdesEventContentDetails';
import ResditEventContentDetails from './ResditEventContentDetails';
import RfIdEventContentDetails from './RfIdEventContentDetails';

const eventContentDetailsMap = {
  PREDES: (props) => <PredesEventContentDetails {...props} />,
  RESDES: (props) => <ResdesEventContentDetails {...props} />,
  PRECON: (props) => <PreconEventContentDetails {...props} />,
  RESCON: (props) => <ResconEventContentDetails {...props} />,
  CARDIT: (props) => <CarditEventContentDetails {...props} />,
  RESDIT: (props) => <ResditEventContentDetails {...props} />,
  POD: (props) => <PodEventContentDetails {...props} />,
  default: (props) => <DefaultEventContentDetails {...props} />,
};

const rfidEventContentDetailsRender = (props) => <RfIdEventContentDetails {...props} />;

const isRfIdEvent = (event) => event !== undefined && event?.eventName?.startsWith('2');

export const EventContentDetailsForReceptaclePage = (props) => {
  const { event } = props;

  if (isRfIdEvent(event)) {
    return rfidEventContentDetailsRender(props);
  }

  return event
    ? getOrDefault(eventContentDetailsMap, determineType(event.eventName))(props)
    : getDefault(eventContentDetailsMap)();
};

EventContentDetailsForReceptaclePage.propTypes = {
  event: PropTypes.shape({
    eventName: PropTypes.string,
  }),
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

export default EventContentDetailsForReceptaclePage;
