import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from '@ipc/ipc-core';
import noop from '../../../../core/modules/common/tools/noop';
import Sidebar from '../../../common/components/sidebar/Sidebar';
import Summary from '../../../common/components/Summary';
import Timeline from '../../../common/components/timeline/Timeline';
import { downloadXls, reportType } from '../../../common/components/timeline/timelineDownloadHelper';
import Title from '../../../common/components/Title';
import { useDefaultPageState } from '../../../common/utils';

const defaultPageState = {
  summaryExpanded: false,
  renderedEventKeys: [],
  expandedEventKeys: [],
  expandedEventGroups: [],
  allEventsAreExpanded: false,
  expandedEventEdiKey: '',
};

export function DespatchPage({
  despatch, requestedId, hasError, resetFilterForm, errorMessage, ...rest
}) {
  const timelineRef = useRef();
  const [{
    summaryExpanded, filters, sort, pageId,
  }, updateUI] = useDefaultPageState(rest.match, defaultPageState);
  const events = despatch.allEvents;
  const despatchFound = !!(!hasError && despatch && despatch.despatchId);
  const isRequestIdMatch = requestedId === despatch.despatchId;

  const onSummaryToggleExpand = () => {
    updateUI('summaryExpanded', !summaryExpanded);
  };

  const onTimelineRef = (ref) => {
    timelineRef.current = ref;
  };

  const download = () => {
    const timeline = timelineRef.current;

    downloadXls(
      reportType.despatch,
      [timeline.getCurrentEvents(), despatch],
      [despatch.despatchId],
    );
  };

  const getDespatchLabelandTabs = () => {
    let label = '';
    let tabs = [];

    if (despatchFound) {
      label = despatch.despatchId;
      tabs = [
        {
          label: 'despatch id',
          value: despatch.despatchId,
        },
        {
          label: 'origin impc',
          value: despatch.originImpc,
        },
        {
          label: 'destination impc',
          value: despatch.destImpc,
        },
        {
          label: 'mail category',
          value: despatch.mailCategory,
        },
        {
          label: 'mail subclass',
          value: despatch.mailSubclass,
        },
        {
          label: 'despatch year',
          value: despatch.despatchYear,
        },
        {
          label: 'despatch serial number',
          value: despatch.despatchNumber,
        },
      ];
    } else {
      label = pageId;
    }
    label = label ? label.toUpperCase() : '';

    return { label, tabs };
  };

  const { label, tabs } = getDespatchLabelandTabs();

  return (
    <div className="page">
      <Sidebar
        id="sidebar"
        events={events}
        contentFound={despatchFound}
        onClearFilter={resetFilterForm}
      />
      <div className="timeline-wrapper">
        <Title id="title" translationKey="app.tracking.despatch.title" />

        {!despatch.isLoaded
            && (
              <div className="loading-page">
                <FormattedMessage id="app.loading" />
              </div>
            )}

        {despatch.isLoaded && isRequestIdMatch && despatchFound
            && (
              <div>
                <Summary
                  id="summary"
                  onSummaryToggleExpand={onSummaryToggleExpand}
                  expanded={summaryExpanded}
                  tabs={tabs}
                  label={label}
                />
                <div className="timeline-container">
                  <Timeline
                    id="timeline"
                    onRef={onTimelineRef}
                    downloadCallback={download}
                    events={events}
                    filters={filters}
                    sort={sort}
                    canExpandCollapse
                  />
                </div>
              </div>
            )}

        {despatch.isLoaded && hasError
            && (
              <div className="search">
                <div className="search-panel">
                  Despatch
                  {' '}
                  <span className="bold">
                    {label}
                  </span>
                  {' - '}
                  <FormattedMessage id={errorMessage} />
                </div>
              </div>
            )}
      </div>
    </div>
  );
}

DespatchPage.propTypes = {
  despatch: PropTypes.objectOf(PropTypes.shape),
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  resetFilterForm: PropTypes.func,
  requestedId: PropTypes.string,
};

DespatchPage.defaultProps = {
  despatch: {},
  resetFilterForm: noop,
  hasError: false,
  errorMessage: '',
};

export default injectIntl(withRouter(DespatchPage));
