import axios from 'axios';

const {
  get: axiosGet, post: axiosPost, put: axiosPut, delete: axiosDelete, patch: axiosPatch,
} = axios;

const onSuccess = (response) => response.data;

const onError = (error) => Promise.reject(error.response || error.message);

export const get = (...args) => axiosGet(...args)
  .then(onSuccess)
  .catch(onError);

export const put = (...args) => axiosPut(...args)
  .then(onSuccess)
  .catch(onError);

export const post = (...args) => axiosPost(...args)
  .then(onSuccess)
  .catch(onError);

export const destroy = (...args) => axiosDelete(...args)
  .then(onSuccess)
  .catch(onError);

export const patch = (...args) => axiosPatch(...args)
  .then(onSuccess)
  .catch(onError);
