import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Copyright, Footer } from './AppFooter.styles';

const AppFooter = ({ year }) => (
  <Footer>
    <Copyright>
      <FormattedMessage
        id="app.copyright"
        values={{ copyrightYear: year }}
      />
    </Copyright>
  </Footer>
);

AppFooter.propTypes = {
  year: PropTypes.number,
};

AppFooter.defaultProps = {
  year: new Date().getFullYear(),
};

export default AppFooter;
