import {
  Redirect, Route, Switch, useLanguage, withRouter,
} from '@ipc/ipc-core';
import React from 'react';
import styled from 'styled-components';
import { setHistory } from '../../core/modules/common/tools/HistoryTools';
import { routes } from '../../routes';
import Login from './Login';
import NotFound from './NotFound';
import PrivateRoute from './PrivateRoute';
import { AppContentStyle } from './AppContent.styles';

const StyledAppContent = styled.div`${AppContentStyle}`;

// eslint-disable-next-line react/prop-types
export const AppContent = ({ history }) => {
  const [, language] = useLanguage();
  const renderRoute = (route, index) => <PrivateRoute exact path={language(route.path)} component={route.component} key={`privateRoute_${index}`} />;
  setHistory(history);

  return (
    <Switch>
      <Route exact path={language('/login')}>
        <Route component={Login} />
      </Route>
      <Route exact path="/" render={() => <Redirect to={`/${language('/')}`} />} />
      <Route exact path={language('/')} render={() => <Redirect to={`/${language('search')}`} />} />
      <Route>
        <StyledAppContent>
          <Switch>
            {routes.map(renderRoute)}
            <Route component={NotFound} />
          </Switch>
        </StyledAppContent>
      </Route>
    </Switch>
  );
};

export default withRouter(AppContent);
