import PropTypes from 'prop-types';
import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from '@ipc/ipc-core';
import Sidebar from '../../../common/components/sidebar/Sidebar';
import Summary from '../../../common/components/Summary';
import Timeline from '../../../common/components/timeline/Timeline';
import { downloadXls, reportType } from '../../../common/components/timeline/timelineDownloadHelper';
import TimelineFullTraceToggle from '../../../common/components/timeline/TimelineFullTraceToggle';
import Title from '../../../common/components/Title';
import { useDefaultPageState } from '../../../common/utils';

const defaultPageState = {
  summaryExpanded: false,
  showFullTrace: false,
  renderedEventKeys: [],
  expandedEventKeys: [],
  expandedEventGroups: [],
  allEventsAreExpanded: false,
  expandedEventEdiKey: '',
};

export function ItemPage({
  item, requestedId, hasError, resetFilterForm, errorMessage, ...rest
}) {
  const [hasItemEvents, setItemEvents] = useState(true);
  const timelineRef = useRef();
  const [{
    showFullTrace, summaryExpanded, filters, sort, pageId,
  }, updateUI] = useDefaultPageState(rest.match, defaultPageState);

  const { allEvents, itemIdentifier } = item;
  const itemFound = !!(!hasError && item && itemIdentifier);
  const isRequestIdMatch = itemIdentifier && (requestedId === itemIdentifier.label);

  const onSummaryToggleExpand = () => {
    updateUI('summaryExpanded', !summaryExpanded);
  };

  const onTimelineRef = (ref) => {
    timelineRef.current = ref;
  };

  const getTimelineEvents = () => {
    if (allEvents) {
      return showFullTrace
        ? allEvents
        : allEvents.filter((event) => event.eventType !== 'receptacle');
    }
    return [];
  };

  const download = () => {
    const timeline = timelineRef.current;

    downloadXls(
      reportType.items,
      [
        timeline.getCurrentEvents(),
        itemIdentifier,
      ],
      [itemIdentifier.label],
    );
  };

  const toggleFullTrace = () => {
    resetFilterForm();
    updateUI('showFullTrace', !showFullTrace);
  };

  const getItemLabelAndTabs = () => {
    let label = '';
    let tabs = [];

    if (itemFound) {
      label = itemIdentifier.label;
      tabs = [{
        label: 'service indicator',
        value: itemIdentifier.serviceIndicator,
      }, {
        label: 'country code',
        value: itemIdentifier.countryCode,
      }];
    } else {
      label = pageId;
    }

    label = label ? label.toUpperCase() : '';

    return { label, tabs };
  };

  useEffect(() => {
    if (item.isLoaded && allEvents) {
      const hasItems = allEvents.filter((event) => event.eventType !== 'receptacle').length > 0;
      if (!showFullTrace && !hasItems) { updateUI('showFullTrace', true); }
      setItemEvents(hasItems);
    }
  }, [allEvents, item.isLoaded]);

  const events = useMemo(getTimelineEvents, [allEvents, showFullTrace]);
  const { label, tabs } = getItemLabelAndTabs();

  return (
    <div className="page">
      <Sidebar
        id="sidebar"
        events={events}
        contentFound={itemFound}
        onClearFilter={resetFilterForm}
      />
      <div className="timeline-wrapper">
        <Title id="title" translationKey="app.tracking.item.title" />

        {!item.isLoaded
            && (
              <div className="loading-page">
                <FormattedMessage id="app.loading" />
              </div>
            )}

        {item.isLoaded && isRequestIdMatch && itemFound && (
          <div>
            <Summary
              id="summary"
              onSummaryToggleExpand={onSummaryToggleExpand}
              expanded={summaryExpanded}
              tabs={tabs}
              label={label}
            />
            <div className="timeline-container">
              <Timeline
                id="timeline"
                filters={filters}
                sort={sort}
                events={events}
                canExpandCollapse
                downloadCallback={download}
                onRef={onTimelineRef}
                customComponents={[
                  <TimelineFullTraceToggle
                    id="timelineToggle"
                    disabled={!hasItemEvents}
                    isToggled={showFullTrace}
                    toggleFullTrace={toggleFullTrace}
                    key={1}
                  />,
                ]}
              />
            </div>
          </div>
        )}

        {item.isLoaded && hasError
            && (
              <div className="search">
                <div className="search-panel">
                  Item
                  {' '}
                  <span className="bold">
                    {label}
                  </span>
                  {' - '}
                  <FormattedMessage id={errorMessage} />
                </div>
              </div>
            )}
      </div>
    </div>
  );
}

ItemPage.propTypes = {
  pageId: PropTypes.string,
  item: PropTypes.objectOf(PropTypes.shape),
  resetFilterForm: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  requestedId: PropTypes.string,
};

ItemPage.defaultProps = {
  item: {
    allEvents: [],
  },
  hasError: false,
  errorMessage: '',
};

export default injectIntl(withRouter(ItemPage));
