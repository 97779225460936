import _ from 'lodash';
import {
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_SUCCESS,
  FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_FAILURE,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_SUCCESS,
  FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_FAILURE,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_SUCCESS,
  FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_FAILURE,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_SUCCESS,
  FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_FAILURE,
} from '../actions/types/EventInfoActionTypes';
import { FETCH_CONSIGNMENT_REQUEST, FETCH_CONSIGNMENT_FAILURE, FETCH_CONSIGNMENT_SUCCESS } from '../actions/types/ConsignmentActionTypes';
import { CONSIGNMENT } from '../../../common/constants/Constants';
import { separatedBySlash } from '../../helpers/SeparatedBySlash';
import { getErrorMessage } from './utils';

export const initialState = {
  error: false,
  errorMessage: '',
  consignment: {
    consignmentId: '',
    allEvents: [],
  },
};

const prepareEvent = (payload, event) => {
  const info = (event && event.generalInfo) ? {
    ...event.generalInfo,
    ...payload.generalInfo,
  } : payload.generalInfo;

  return {
    ...event,
    ...payload,
    ...info,
    eventType: CONSIGNMENT,
    sender: info && separatedBySlash(info.senderMailbox, info.originPostalOperatorCode),
    receiver: info && separatedBySlash(info.recipientMailbox, info.destinationPostalOperatorCode),
  };
};

export const getEventInfo = ({
  consignmentId,
  transportLegs = [],
  receptacles = [],
  containers = [],
  overview = {},
}) => ({
  eventId: consignmentId,
  transportLegs,
  receptacles,
  containers,
  overview,
});

const newEvent = (payload) => ({
  ...prepareEvent(payload),
  eventInfo: getEventInfo(payload),
});

const updateEvent = (payload, event) => ({
  ...prepareEvent(payload, event),
  eventInfo: getEventInfo(payload),
});

const putEventInfoOnConsignmentEvent = (state, action) => {
  const { payload } = action;
  const toFind = payload.generalInfo && { aggregateId: payload.generalInfo.aggregateId };
  const allEvents = _.cloneDeep(state.consignment.allEvents);
  const eventToUpdate = _.find(allEvents, toFind);
  const indexToUpdate = _.findIndex(allEvents, toFind);

  const event = eventToUpdate
    ? updateEvent(payload, eventToUpdate)
    : newEvent(payload);

  allEvents.splice(indexToUpdate, 1, event);
  return {
    ...state,
    consignment: {
      ...state.consignment,
      allEvents,
    },
  };
};

export const setEventType = (eventType, events) => events.map((eventObj) => {
  eventObj.eventType = eventType;
  return eventObj;
});

function putErrorOnConsignmentEvent(state, action) {
  let allEvents = _.cloneDeep(state.consignment.allEvents);
  const errorMessage = getErrorMessage(action.payload);

  if (_.isEmpty(allEvents)) {
    allEvents = [{
      aggregateId: action.aggregateId,
      messageAggregateId: action.messageAggregateId,
      error: true,
      errorMessage,
    }];
  } else {
    _.map(allEvents, (event) => {
      if (event.aggregateId === action.aggregateId) {
        event.error = true;
        event.errorMessage = errorMessage;
      }
      return event;
    });
  }

  return {
    ...state,
    consignment: {
      ...state.consignment,
      allEvents,
    },
  };
}

const consignment = (state = initialState, action) => {
  switch (action.type) {
  case FETCH_CONSIGNMENT_REQUEST:
    return {
      ...state,
      consignment: {
        isLoaded: false,
        consignmentId: action.params.consignmentId,
      },
    };
  case FETCH_CONSIGNMENT_SUCCESS: {
    const events = setEventType(CONSIGNMENT, action.payload.consignmentEvents);

    return {
      ...state,
      error: false,
      errorMessage: '',
      consignment: {
        ...state.consignment,
        isLoaded: true,
        allEvents: events,
        consignmentId: action.payload.consignmentIdentifier.s32Id,
        countryCode: action.payload.consignmentIdentifier.countryCode,
      },
    };
  }
  case FETCH_CONSIGNMENT_FAILURE: {
    const errorMessage = getErrorMessage(action.payload);

    return {
      ...state,
      error: true,
      errorMessage,
      consignment: {
        ...state.consignment,
        isLoaded: true,
      },
    };
  }

  case FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_SUCCESS:
  case FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_SUCCESS:
  case FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_SUCCESS:
  case FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_SUCCESS:
    return putEventInfoOnConsignmentEvent(state, action);

  case FETCH_CONSIGNMENT_ADVISED_TO_CARRIER_FOR_CONSIGNMENT_FAILURE:
  case FETCH_CONSIGNMENT_ADVISED_TO_DEST_POST_FOR_CONSIGNMENT_FAILURE:
  case FETCH_CONSIGNMENT_ARRIVED_AT_DEST_POST_FOR_CONSIGNMENT_FAILURE:
  case FETCH_CONSIGNMENT_REPORTED_ON_BY_CARRIER_FOR_CONSIGNMENT_FAILURE:
    return putErrorOnConsignmentEvent(state, action);

  default:
    break;
  }
  return state;
};

export default consignment;
