import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../../../api';
import {
  fetchEdiFormattedFailureActionCreator,
  fetchEdiFormattedSuccessActionCreator,
  fetchEdiRawFailureActionCreator,
  fetchEdiRawSuccessActionCreator,
} from '../actions/EdiActions';
import {
  FETCH_EDI_FORMATTED_REQUEST,
  FETCH_EDI_RAW_REQUEST,
} from '../actions/types/EdiActionTypes';

function* fetchEdiRawWorker(action) {
  const {
    params,
  } = action;
  try {
    const res = yield call(api.fetchEdiRaw, params);
    yield put(fetchEdiRawSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchEdiRawFailureActionCreator(error));
  }
}

export function* fetchEdiRawWatcher() {
  yield takeLatest(FETCH_EDI_RAW_REQUEST, fetchEdiRawWorker);
}

function* fetchEdiFormattedWorker(action) {
  const {
    params,
  } = action;
  try {
    const res = yield call(api.fetchEdiFormatted, params);
    yield put(fetchEdiFormattedSuccessActionCreator(res.data));
  } catch (error) {
    yield put(fetchEdiFormattedFailureActionCreator(error));
  }
}

export function* fetchEdiFormattedWatcher() {
  yield takeLatest(FETCH_EDI_FORMATTED_REQUEST, fetchEdiFormattedWorker);
}
