import { Link } from '@ipc/ipc-core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const AppHeaderLeftContainer = () => (
  <div className="header-left-container">
    <Link to="/">
      <div className="app-title">
        <FormattedMessage id="app.title" tagName="h1" />
      </div>
    </Link>
  </div>
);

export default AppHeaderLeftContainer;
