/* eslint-disable */
import { Button as IpcButton, Loading } from '@ipc/ipc-components';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import noop from '../../core/modules/common/tools/noop';
import { ButtonStyle } from './Button.style';

export const ButtonTypes = {
  DEFAULT: 'default',
};

const Button = styled(({ primary, active, ...props}) => <IpcButton {...props} />)`
  ${ButtonStyle}
`;

const LoadingContainer = styled.span`
  display: flex;
  margin-right: 10px;
`;

export const ButtonComponent = (props) => {
  const {
    className = '',
    disabled = false,
    isLoading = false,
    onClick = noop,
    styleType = ButtonTypes.DEFAULT,
    type = 'button',
    fontSize = 'xl',
    size = 'md',
    children,
    ...otherProps
  } = props;

  const buttonClass = `${className} button ${styleType}`;

  return (
    <Button
      type={type}
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
      fontSize={fontSize}
      size={size}
      {...otherProps}
    >
      {isLoading && <LoadingContainer>
          <Loading size={15} thickness={5} color="inherit" />
        </LoadingContainer>}
      {children}
    </Button>
  );
};

ButtonComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  styleType: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

export default injectIntl(ButtonComponent);
