import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import EventContentEdiButton from './timeline/eventContentEdi/EventContentEdiButton';
import { VALUE_IF_ABSENT } from '../constants/Constants';

export const GeneralInfo = (props) => {
  const {
    infoFields,
    event,
    fullDetails,
    showEDI,
    toggleEventEDI,
  } = props;

  const className = fullDetails
    ? 'general-info-full-details'
    : 'general-info-timeline';

  return (
    <div className={`general-info-container hide-inner-border ${className}`}>
      <div className="general-info-wrapper">

        {infoFields && infoFields.map((field) => (
          <div className={`general-info-field ${field.className}`} key={field.className}>
            <div className="general-info-title" key={field.eventName}>
              <FormattedMessage id={field.intlID} key={field.eventName} />
            </div>
            <div className="general-info-value" key={field.eventName}>
              {field.value || VALUE_IF_ABSENT}
            </div>
          </div>
        ))}

        {
          event.rawEventKey && !fullDetails
          && (
            <div className="general-info-field edi-button">
              <EventContentEdiButton
                id="eventContentEdiButton"
                showEDI={showEDI}
                toggleEventEDI={toggleEventEDI}
              />
            </div>
          )
        }
      </div>
    </div>
  );
};

GeneralInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  infoFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  event: PropTypes.objectOf(PropTypes.shape),
  fullDetails: PropTypes.bool,
  showEDI: PropTypes.bool,
  toggleEventEDI: PropTypes.func,
};

export default injectIntl(GeneralInfo);
