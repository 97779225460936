import XlsxTemplate from 'xlsx-template';
import base64 from 'base-64';
import _ from 'lodash';
import { ItemTemplate } from '../../../constants/ExcelTemplates';
import fieldFormatter from '../../../../react-table/helpers/fieldFormatter';

function generateExcelWorkbookForItemPage(filteredEvents, identifier) {
  const identifierOrDefault = identifier || {
    label: 'No Identifier Label provided',
    serviceIndicator: 'No Custom Service Indicator provided',
    countryCode: 'No Country Code provided',
  };

  const itemTemplateFile = base64.decode(ItemTemplate);
  const template = new XlsxTemplate(itemTemplateFile);

  const item = {
    item: identifierOrDefault.label ? identifierOrDefault.label.toUpperCase() : '',
    service: identifierOrDefault.serviceIndicator,
    country: identifierOrDefault.countryCode,
  };

  const dateFormatter = fieldFormatter('date');

  const events = {
    events: _.map(filteredEvents, (event) => ({
      date: dateFormatter({ value: event.eventDate }),
      type: event.eventName,
      description: event.eventDescription,
      location: event.eventLocation ? event.eventLocation.location : '',
      sender: event.senderMailbox,
      recipient: event.recipientMailbox,
      receptacle: event.eventType === 'receptacle' ? event.receptacleId : '',
    })),
  };

  template.substitute('Item', item);
  template.substitute('Events', events);
  return template.generate();
}

export default generateExcelWorkbookForItemPage;
