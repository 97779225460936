import CreateRolesPage from './components/pages/administration/roles/create';
import EditRolesPage from './components/pages/administration/roles/edit';
import RolesListPage from './components/pages/administration/roles/list';
import UserListPage from './components/pages/administration/users/list';
import ConsignmentContainer from './modules/tracking/containers/ConsignmentContainer';
import DespatchContainer from './modules/tracking/containers/DespatchContainer';
import FullDetailsForConsignmentContainer from './modules/tracking/containers/FullDetailsForConsignmentContainer';
import FullDetailsForDespatchContainer from './modules/tracking/containers/FullDetailsForDespatchContainer';
import FullDetailsForReceptacleContainer from './modules/tracking/containers/FullDetailsForReceptacleContainer';
import ItemContainer from './modules/tracking/containers/ItemContainer';
import ReceptacleContainer from './modules/tracking/containers/ReceptacleContainer';
import SearchContainer from './modules/tracking/containers/SearchContainer';
// import Sandbox from './utils/development/sandbox';
// import { isDev } from './utils/development/tools';

const createPrivateRoute = (path, component) => ({
  path,
  component,
  private: true,
});

export const routes = [
  createPrivateRoute('/search', SearchContainer),
  createPrivateRoute('/search/:searchTerm', SearchContainer),
  createPrivateRoute('/item/:itemId', ItemContainer),
  createPrivateRoute('/receptacle/:receptacleId', ReceptacleContainer),
  createPrivateRoute('/receptacle/:receptacleId/full-details/:messageAggregateId/:messageType/:aggregateId', FullDetailsForReceptacleContainer),
  createPrivateRoute('/receptacle/:receptacleId/:consignmentId/full-details/:messageAggregateId/:messageType/:aggregateId', FullDetailsForReceptacleContainer),
  createPrivateRoute('/despatch/:despatchId', DespatchContainer),
  createPrivateRoute('/despatch/:despatchId/full-details/:messageAggregateId/:messageType/:aggregateId', FullDetailsForDespatchContainer),
  createPrivateRoute('/consignment/:consignmentId', ConsignmentContainer),
  createPrivateRoute('/consignment/:consignmentId/full-details/:messageAggregateId/:messageType/:aggregateId', FullDetailsForConsignmentContainer),
  createPrivateRoute('/administration/user-management/roles', RolesListPage),
  createPrivateRoute('/administration/user-management/roles/create', CreateRolesPage),
  createPrivateRoute('/administration/user-management/roles/:roleId', EditRolesPage),
  createPrivateRoute('/administration/user-management/users', UserListPage),
  /* isDev() && {
    path: '/sandbox',
    component: Sandbox,
  }, */
];
